import type {
  CogniteClient,
  EdgeDefinition,
  FileInfo,
  FileLink,
  InternalId,
  NodeOrEdge,
  QueryRequest,
  ViewDefinition,
  ViewReference,
} from '@cognite/sdk';

import type { CdfProperty } from './types';
import { mapNodeDefinitionToNodeWithView } from './utils';
import type { ViewCache } from './view-cache';

export class QueryResultSetMerger<T extends Record<string, any>> {
  cogniteSDK: CogniteClient;
  viewReference: ViewReference;
  defaultInstanceSpace: string;
  viewDetails: ViewDefinition | null;
  viewCache: ViewCache;

  rootQuery: QueryRequest = { select: {}, with: {} };

  constructor(
    cogniteSDK: CogniteClient,
    viewReference: Omit<ViewReference, 'type'>,
    defaultInstanceSpace: string,
    viewCache: ViewCache
  ) {
    this.cogniteSDK = cogniteSDK;
    this.defaultInstanceSpace = defaultInstanceSpace;
    this.viewReference = { ...viewReference, type: 'view' };
    this.viewCache = viewCache;
    this.viewDetails = null;
  }

  /**
   * Takes the repsonse of the CDF DM Query endpoint, which contains multiple sets of results, and joins those results together into a single object.
   * e.g. The query endpoint will return Checklist, ChecklistItems, and Observations in 3 different lists.
   * This function joins them together under a single array of Checklist objects.
   *
   * @param query Original query sent to endpoint
   * @param resultSets Result sets from that endpoint
   * @param fileSets Additional files to be connected to the result sets
   * @param viewReference View reference that acts as the root
   * @param level Current nesting level (do not pass in)
   * @returns A single array of objects with all the data connected
   */
  connectData = (
    query: QueryRequest,
    resultSets: Record<string, NodeOrEdge[]>,
    fileSets: Record<string, FileInfo[]>,
    viewReference: ViewReference,
    level = 0
  ) => {
    const viewReferenceKey = `${viewReference.externalId}/${viewReference.version}`;
    const connectedInstances = resultSets[viewReferenceKey].map((result) => {
      const instance = mapNodeDefinitionToNodeWithView<T>(
        result,
        viewReference
      );

      return this.mapInstanceToConnections(
        instance,
        query,
        resultSets,
        fileSets,
        viewReference,
        level
      );
    });

    return connectedInstances;
  };

  /**
   * Fetches required files based on the query response and CDF properties
   * @param queryInstances Query response from CDF DM Query
   * @param cdfProperties Properties that are typed as CDF instances (e.g. files)
   * @returns List of items
   */
  fetchCDFResourceResultSets = async (
    queryInstances: Record<string, NodeOrEdge[]>,
    cdfProperties: CdfProperty[]
  ): Promise<
    Record<string, (FileInfo & { downloadLink: FileLink & InternalId })[]>
  > => {
    const filesByQueryInstanceKey: Record<
      string,
      (FileInfo & { downloadLink: FileLink & InternalId })[]
    > = {};
    await Promise.all(
      Object.values(cdfProperties).map(
        async ({ property, type, key, viewDefinition, queryInstanceKey }) => {
          const fileExternalIdsFromNodes = queryInstances[key]
            .map(
              (node) =>
                mapNodeDefinitionToNodeWithView<T>(node, {
                  ...viewDefinition,
                  type: 'view',
                })[property] as string[]
            )
            .flat()
            .filter(Boolean);
          if (type === 'file' && fileExternalIdsFromNodes.length > 0) {
            const files = await this.cogniteSDK.files.retrieve(
              fileExternalIdsFromNodes.map((externalId) => ({
                externalId,
              })),
              { ignoreUnknownIds: true }
            );
            if (files.length > 0) {
              const downloadUrls = await this.cogniteSDK.files.getDownloadUrls(
                files.slice(0, 99).map(({ id }) => ({
                  id,
                }))
              );
              filesByQueryInstanceKey[queryInstanceKey] = files.map((file) => ({
                ...file,
                downloadLink: (downloadUrls as (FileLink & InternalId)[]).find(
                  (downloadUrl) => downloadUrl.id === file.id
                )!,
              }));
            }
          }
        }
      )
    );

    return filesByQueryInstanceKey;
  };

  /**
   * Recursively maps instances to their connected instances
   */
  mapInstanceToConnections = (
    instance: Record<string, any>,
    query: QueryRequest,
    resultSets: Record<string, NodeOrEdge[]>,
    fileSets: Record<string, FileInfo[]>,
    viewReference: ViewReference,
    level = 0
  ) => {
    const viewReferenceKey = `${viewReference.externalId}/${viewReference.version}`;
    const nextInstance: Record<string, any> = { ...instance };

    Object.keys(fileSets).forEach((key) => {
      if (!key.startsWith(viewReferenceKey) || !key.endsWith(String(level))) {
        return;
      }

      const property = key
        .replace(`${viewReference.externalId}/${viewReference.version}_`, '')
        .replace(`_${level}`, '');

      if (fileSets[key] !== undefined && nextInstance[property] !== undefined) {
        nextInstance[property] = nextInstance[property].map(
          (fileId: string) => {
            return fileSets[key].find((file) => file.externalId === fileId);
          }
        );
      }
    });

    Object.keys(resultSets).forEach((key) => {
      if (!key.startsWith(viewReferenceKey) || !key.endsWith(String(level))) {
        return;
      }

      // Extract property from key name
      const property = key
        .replace(`${viewReference.externalId}/${viewReference.version}_`, '')
        .replace(`_${level}`, '');

      // Determine type of result set key based on key name
      const isEdgeMappingKey = key.endsWith(`_edges_${level}`);
      const isInwards = property.endsWith(':inwards');

      const keyViewReference = query.select[key].sources?.[0].source;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mapDirectRelation = (item: any) => {
        if (item === undefined) {
          return undefined;
        }

        const connectedNode = resultSets[key].find(
          (connectedItem) => connectedItem.externalId === item.externalId
        );

        if (connectedNode === undefined || keyViewReference === undefined) {
          return undefined;
        }

        return this.mapInstanceToConnections(
          mapNodeDefinitionToNodeWithView<T>(connectedNode, keyViewReference),
          query,
          resultSets,
          fileSets,
          keyViewReference,
          level + 1
        );
      };

      // Ensure sort is based on order of nodes from query set, not based on order of nodes in original set

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sort = (a: any, b: any) => {
        return (
          resultSets[key].findIndex((x) => x.externalId === a.externalId) -
          resultSets[key].findIndex((x) => x.externalId === b.externalId)
        );
      };

      if (isEdgeMappingKey) return;

      const edgeMappingKey = `${key.split(`_${level}`)[0]}_edges_${level}`;
      if (resultSets[edgeMappingKey] !== undefined) {
        nextInstance[property] = (
          resultSets[edgeMappingKey] as EdgeDefinition[]
        )
          .filter((x) => x.startNode.externalId === instance.externalId)
          .map((edge) => ({
            externalId: edge.endNode.externalId,
            space: edge.endNode.space,
          }))
          .map(mapDirectRelation)
          .filter(Boolean)
          .sort(sort);
      } else if (Array.isArray(nextInstance[property])) {
        nextInstance[property] = nextInstance[property]
          .map(mapDirectRelation)
          .filter(Boolean)
          .sort(sort);
      } else if (isInwards) {
        const throughKey = property.split(':')[1];
        nextInstance[property.split(':')[0]] = keyViewReference
          ? resultSets[key]
              .filter(
                (x) =>
                  mapNodeDefinitionToNodeWithView(x, keyViewReference)[
                    throughKey
                  ].externalId === instance.externalId
              )
              .map((nodeOrEdge) => {
                return this.mapInstanceToConnections(
                  mapNodeDefinitionToNodeWithView<T>(
                    nodeOrEdge,
                    keyViewReference
                  ),
                  query,
                  resultSets,
                  fileSets,
                  keyViewReference,
                  level + 1
                );
              })
          : undefined;
      } else {
        nextInstance[property] = mapDirectRelation(nextInstance[property]);
      }
    });

    return nextInstance;
  };
}
