import type { Template } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdateTemplate = () => {
  const { templateService } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.auditTemplate);
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  return useMutation(
    (changedTemplate: Template) => {
      return templateService.updateTemplate(changedTemplate, user!);
    },
    {
      // When mutate is called:
      onMutate: async (newTemplate: Partial<Template>) => {
        const sliTimerStartTime = Date.now();
        const queryCache = queryClient.getQueryCache();

        // Get the active queryKey
        const activeTemplateQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        // Optimistically update to the new value
        if (activeTemplateQuery.length > 0) {
          // Cancel any outgoing refetches
          // (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries({
            queryKey: [QueryKeys.TEMPLATE],
          });

          const [{ queryKey: activeQueryKey }] = activeTemplateQuery;

          // Snapshot the previous value
          const previousTemplate = queryClient.getQueryData(
            activeQueryKey
          ) as Template;

          const templateUpdatedOptimistically = {
            ...previousTemplate,
            ...newTemplate,
          };

          queryClient.setQueryData<Template>(
            activeQueryKey,
            templateUpdatedOptimistically
          );

          // Return a context with the previous value
          return { previousTemplate, activeQueryKey, sliTimerStartTime };
        }
      },
      // If the mutation fails, use the context we returned above
      onError: (err, _, context) => {
        queryClient.setQueryData(
          context?.activeQueryKey ?? [QueryKeys.TEMPLATE],
          context?.previousTemplate
        );
        makeToast({
          type: 'danger',
          body: t('UPSERT_TEMPLATE_ERROR', `Unable to update template.`),
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.TEMPLATE_UPDATE,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_UPDATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.SEARCH_TEMPLATES],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE_LIST],
        });
      },
      onSuccess: (_, _changedTemplate, context) => {
        metrics.track('Update');

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_UPDATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
    }
  );
};
