import Konva from 'konva';

import { Size } from '../annotations/types';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';

// TODO(FUS-000): This needs more care. For now setting it to zero as to not get weird artifacts.
// The problem is that we want the boundary rect to be fully contained within the sizing
// constraints of the content node, but since the primary contents of the content node
// in many cases will expand fully, then the stroke will be covered by the content.
// The "old" behaviour was that the stroke was not part of the sizing calculations when laying
// out the node, but the issue is then that if you center the viewport around a specific node,
// the sizing of the viewport will be incorrect as it will exclude the boundary rect's strokeWidth.
const BOUNDARY_RECT_STROKE_WIDTH = 0;

class BoundaryRect {
  private node: Konva.Rect;

  public constructor({
    width,
    height,
    fill,
    cornerRadius,
  }: Size & { fill?: string; cornerRadius?: number }) {
    this.node = new Konva.Rect({
      x: 0,
      y: 0,
      width: width - 2 * BOUNDARY_RECT_STROKE_WIDTH,
      height: height - 2 * BOUNDARY_RECT_STROKE_WIDTH,
      strokeWidth: BOUNDARY_RECT_STROKE_WIDTH,
      stroke: 'black',
      cornerRadius: cornerRadius,
      fill: fill ?? 'white',
      [UNIFIED_VIEWER_NODE_TYPE_KEY]:
        UnifiedViewerNodeType.CONTAINER_BOUNDARY_RECT,
    });
  }

  public getWidth = (): number => this.node.width();
  public getHeight = (): number => this.node.height();

  public getNode = (): Konva.Rect => this.node;

  public setNode = (node: Konva.Rect): void => {
    this.node = node;
  };

  public setDimensions = ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }): void => {
    this.node.size({
      width: width - 2 * BOUNDARY_RECT_STROKE_WIDTH,
      height: height - 2 * BOUNDARY_RECT_STROKE_WIDTH,
    });
  };
}

export default BoundaryRect;
