import type { UserProfileItem } from '@cognite/sdk';

export const isServicePrincipal = (
  userProfile?: UserProfileItem | undefined
) => {
  if (!userProfile) {
    return false;
  }
  return userProfile.identityType === 'SERVICE_PRINCIPAL';
};

export const getUserName = (userProfile?: UserProfileItem) => {
  return (
    userProfile?.displayName ||
    userProfile?.email ||
    userProfile?.userIdentifier
  );
};
