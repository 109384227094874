import { Select } from '@cognite/cogs-lab';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';

import * as S from './elements';
import './styles.css';

type AssignedTo = 'user' | 'all';

interface AssignedToFilterProps {
  assignFilter: AssignedTo;
  setAssignFilter: (value: AssignedTo) => void;
}

export const AssignedToFilter = ({
  assignFilter,
  setAssignFilter,
}: AssignedToFilterProps) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const assignedOptions = [
    {
      label: t('CHECKLISTS_LIST_FILTER_ASSIGNED_TO_ME', 'Assigned to me'),
      value: 'user',
    },
    {
      label: t('CHECKLISTS_LIST_FILTER_ALL_CHECKLISTS', 'All checklists'),
      value: 'all',
    },
  ];

  return (
    <Select
      size="large"
      aria-label={t('selector.label.assignedToFilter', 'Assigned to filter')}
      options={assignedOptions}
      value={assignFilter}
      onChange={(_, value) =>
        value ? setAssignFilter(value as AssignedTo) : undefined
      }
      variant="solid"
      renderOption={(option) => <S.SelectOption>{option.label}</S.SelectOption>}
    />
  );
};
