import type {
  ChecklistItem,
  ChecklistItemStatus,
  Measurement,
} from '@cognite/apm-client';
import { AddIcon, Avatar, Flex, Tooltip } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  MeasurementLabelReadingList,
  MeasurementMessageReadingList,
  MeasurementNumericReadingContainer,
} from '@infield/features/measurements';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '@infield/utils/defaultDateFormats';
import { linkifyOptions } from '@infield/utils/linkify';
import dayjs from 'dayjs';
import Linkify from 'linkify-react';
import type { FunctionComponent } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ConditionMessage } from '../checklist-item-conditional-message';
import { ChecklistItemLabels } from '../checklist-item-labels';
import { ChecklistStatusButtons } from '../checklist-status-buttons';
import { LAST_VISITED_CHECKLIST_ITEM_KEY } from '../utils';

import { ChecklistItemNote } from './checklist-item-note';
import * as S from './elements';
import { SearchResultHighlight } from './search-result-highlight';
import {
  getChecklistStatusOnMeasurementUpdate,
  getShouldShowAvatar,
} from './utils';

type Props = {
  isLocked: boolean;
  checklistItem: ChecklistItem;
  id?: string;
  debouncedQuery: string;
  onAssetClick?: (assetExternalId: string) => void;
  onStatusClick: (
    status: ChecklistItemStatus,
    operationExternalId: string
  ) => void;
  onOtherStateClick: () => void;
  onAddReportClick: () => void;
  onUpdateNote: (note: string) => void;
  onObservationsLabelClick: () => void;
};

export const ChecklistItemComponent: FunctionComponent<Props> = ({
  isLocked,
  checklistItem,
  id,
  debouncedQuery,
  onAssetClick,
  onStatusClick,
  onOtherStateClick,
  onAddReportClick,
  onUpdateNote,
  onObservationsLabelClick,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);
  const { isOnline } = useNetworkStatusContext();

  const navigate = useNavigate();
  const location = useLocation();

  const isDesktop = useIsDesktop();

  const [conditionMessage, setConditionMessage] = useState<string>();

  const {
    title,
    description,
    asset,
    note,
    lastUpdatedTime,
    observations,
    measurements,
    updatedBy,
  } = checklistItem;

  const assetName = asset?.title;
  const assetDescription = asset?.description;

  const descriptionRef = useRef<HTMLDivElement>(null);
  const [overflowActive, setOverflowActive] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);

  const handleOnStatusClick = (clickedStatus: ChecklistItemStatus) => {
    if (!isLocked) {
      onStatusClick(clickedStatus, checklistItem.externalId);
    }
  };

  const setLastVisitedChecklistItem = (checklistItemExternalId = '') => {
    localStorage.setItem(
      LAST_VISITED_CHECKLIST_ITEM_KEY,
      checklistItemExternalId
    );
  };

  const handleOnOtherStateClick = () => {
    if (!isLocked) {
      onOtherStateClick();
    }
  };

  const handleOnAddReportClick = () => {
    if (!isLocked) {
      onAddReportClick();
    }
  };

  const handleUpdateNote = (note: string) => {
    if (!isLocked) {
      onUpdateNote(note);
    }
  };

  const handleOnObservationsLabelClick = () => {
    onObservationsLabelClick();
    if (!isDesktop) {
      navigate(
        `/checklistItem/${encodeURIComponent(
          checklistItem.externalId
        )}/observations`,
        {
          state: {
            from: location.pathname,
          },
        }
      );
    }
  };

  const showAssetInformation = assetName || assetDescription || title;

  const numericalMeasurement = measurements?.filter(
    (measurement) => measurement.type === 'numerical'
  );

  const labelMeasurement = measurements?.filter(
    (measurement) => measurement.type === 'label'
  );

  const messageMeasurement = measurements?.filter(
    (measurement) => measurement.type === 'message'
  );

  const handleOnChangeMeasurement = (measurement: Measurement) => {
    if (measurements) {
      const status = getChecklistStatusOnMeasurementUpdate(
        measurements,
        measurement
      );
      if (status !== checklistItem.status) {
        onStatusClick(status || 'To do', checklistItem.externalId);
      }
    }
  };

  useEffect(() => {
    // As we currently only support one condition and one action per conditional action,
    // Only to monitor the state of the checklist item and display a message,
    // we hardcode this evaluation instead having a more generic evaluation.
    // Must be updated when we have more complex conditional actions
    if (checklistItem.conditionalActions?.length) {
      const matchingConditionalAction = checklistItem.conditionalActions.find(
        (conditionalAction) =>
          conditionalAction?.conditions?.[0].value === checklistItem.status
      );

      if (matchingConditionalAction?.actions?.[0].parameters?.message) {
        setConditionMessage(
          matchingConditionalAction.actions[0].parameters.message
        );
      } else {
        setConditionMessage(undefined);
      }
    }
  }, [checklistItem.conditionalActions, checklistItem.status]);

  useLayoutEffect(() => {
    const { current } = descriptionRef;
    const hasOverflow = current
      ? current.scrollHeight > current.offsetHeight
      : false;
    setOverflowActive(hasOverflow);
  }, []);

  const shouldShowAvatar = getShouldShowAvatar(updatedBy);

  return (
    <S.Container id={id}>
      <S.Information>
        <S.TaskInformation>
          <Flex justifyContent="space-between">
            {showAssetInformation && (
              <S.AssetInformation>
                {assetName && (
                  <S.AssetName
                    onClick={() => {
                      setLastVisitedChecklistItem(checklistItem.externalId);
                      onAssetClick?.(asset.externalId);
                    }}
                    disabled={!isOnline}
                  >
                    <SearchResultHighlight
                      text={assetName}
                      searchQuery={debouncedQuery}
                    />
                  </S.AssetName>
                )}
                {assetDescription && (
                  <S.AssetDescription>{assetDescription}</S.AssetDescription>
                )}
                {title && <S.OperationTitle>{title}</S.OperationTitle>}
              </S.AssetInformation>
            )}
            {shouldShowAvatar && (
              <Tooltip
                content={
                  <>
                    <div>{updatedBy?.email}</div>
                    <div>
                      {dayjs(lastUpdatedTime).format(DEFAULT_DATE_FORMAT)} –{' '}
                      {dayjs(lastUpdatedTime).format(DEFAULT_TIME_FORMAT)}
                    </div>
                  </>
                }
                placement="left"
                disabled={!isDesktop}
                interactive
              >
                <Avatar
                  size="small"
                  text={updatedBy?.name || undefined}
                  tooltip={false}
                  data-testid="checklist-item-avatar"
                />
              </Tooltip>
            )}
          </Flex>
          {description && (
            <Flex direction="column">
              <S.OperationDescription>
                <S.OperationDescriptionText>
                  <Linkify options={linkifyOptions}>
                    <S.DescriptionContainer
                      ref={descriptionRef}
                      $expanded={descriptionExpanded}
                    >
                      {description}
                    </S.DescriptionContainer>
                  </Linkify>
                </S.OperationDescriptionText>
                {overflowActive && (
                  <S.ExpandDescriptionButton
                    type="secondary"
                    onClick={() => {
                      setDescriptionExpanded(!descriptionExpanded);
                      descriptionRef.current?.scrollIntoView();
                    }}
                  >
                    {descriptionExpanded
                      ? t(
                          'CHECKLIST_ITEM_DESCRIPTION_OVERFLOW_BUTTON_EXPANDED_TEXT',
                          'Show less'
                        )
                      : t(
                          'CHECKLIST_ITEM_DESCRIPTION_OVERFLOW_BUTTON_TEXT',
                          'Show more'
                        )}
                  </S.ExpandDescriptionButton>
                )}
              </S.OperationDescription>
            </Flex>
          )}
        </S.TaskInformation>
      </S.Information>
      {measurements && (
        <Flex direction="column" gap={12}>
          {numericalMeasurement?.map((measurement) => (
            <MeasurementNumericReadingContainer
              key={measurement.externalId}
              isLocked={isLocked}
              measurement={measurement}
              onChange={handleOnChangeMeasurement}
            />
          ))}

          {labelMeasurement?.map((measurement) => (
            <MeasurementLabelReadingList
              key={measurement.externalId}
              isLocked={isLocked}
              measurement={measurement}
              onChange={handleOnChangeMeasurement}
            />
          ))}

          {messageMeasurement?.map((measurement) => (
            <MeasurementMessageReadingList
              key={measurement.externalId}
              isLocked={isLocked}
              measurement={measurement}
              onChange={handleOnChangeMeasurement}
            />
          ))}
        </Flex>
      )}
      <S.StatusButtonsWrapper>
        <S.PrimaryStatusButtonWrapper>
          <ChecklistStatusButtons
            disabled={isLocked}
            status={checklistItem.status ?? ''}
            onStatusClick={handleOnStatusClick}
          />
        </S.PrimaryStatusButtonWrapper>
        <S.OtherStatusesButton
          disabled={isLocked}
          onClick={handleOnOtherStateClick}
        >
          {t('CHECKLIST_ITEM__STATUS_BUTTON_OTHER', 'Other')}
        </S.OtherStatusesButton>
        <S.AdditionalReportsButton
          disabled={isLocked}
          icon={<AddIcon />}
          aria-label="add-additional-reports-button"
          data-testid="add-additional-reports-button"
          onClick={handleOnAddReportClick}
        />
      </S.StatusButtonsWrapper>
      <ConditionMessage conditionMessage={conditionMessage} />
      <ChecklistItemLabels
        fileInstanceIds={checklistItem.files
          ?.filter((file) => file?.externalId)
          .map((file) => ({
            externalId: file!.externalId,
            space: file!.space,
          }))}
        observations={observations}
        onObservationsLabelClick={handleOnObservationsLabelClick}
      />
      {note !== undefined && note !== null && (
        <ChecklistItemNote
          isLocked={isLocked}
          message={note}
          onUpdateNote={(note) => {
            handleUpdateNote(note);
          }}
        />
      )}
    </S.Container>
  );
};
