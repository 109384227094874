import type { Measurement } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import type { Edge } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useMeasurementCreate = () => {
  const { measurementsService, apmClient } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.measurement);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.auditMeasurement);

  return useMutation<
    {
      newMeasurement: Measurement;
      templateItemExternalId: string;
    },
    Error,
    { newMeasurement: Measurement; templateItemExternalId: string }
  >(
    async ({ newMeasurement, templateItemExternalId }) => {
      await measurementsService.createMeasurements([newMeasurement], user!);

      const measurementEdges: Edge = {
        externalId: `${templateItemExternalId}_${newMeasurement.externalId}_relation`,
        modelName: 'referenceMeasurements',
        startNode: templateItemExternalId,
        endNode: newMeasurement.externalId,
      };

      await apmClient.edgeService.upsert([measurementEdges]);

      return {
        newMeasurement,
        templateItemExternalId,
      };
    },
    {
      onError: (err) => {
        makeToast({
          body: t(
            'MEASUREMENT_REQUEST_CREATE_ERROR',
            'Failed to create measurement'
          ),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-measurement-create-mutation',
          },
        });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE],
        });
      },
      onSuccess: async () => {
        metrics.track('Create');
      },
    }
  );
};
