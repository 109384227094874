import type { TemplateItem } from '@cognite/apm-client';

import type { TemplateItemAndGroupList } from '../types';
import { flattenTemplateItemsAndGroups } from '../utils';

export const reorderItems = (
  list: TemplateItemAndGroupList,
  startIndex: number,
  endIndex: number
): TemplateItem[] => {
  if (
    startIndex < 0 ||
    endIndex < 0 ||
    startIndex >= list.length ||
    endIndex >= list.length
  ) {
    throw new Error('Invalid index: out of bounds');
  }

  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  const result2 = flattenTemplateItemsAndGroups(result).map((task, index) => ({
    ...task,
    order: index,
  }));

  return result2;
};
