import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client';
import type { UserProfileItem } from '@cognite/sdk';

import type { ChecklistAggregate } from '../../types';

import { valueAsInstanceId } from './type-utils';

export const getCreatedByFilterOptions = (
  createdByAggregate: ChecklistAggregate[],
  userIdToNameMap: Record<string, UserProfileItem>,
  servicePrincipalName: string
): GridFilterOptionsType[] => {
  // Using a flatMap to cleanly filter out items with no corresponding user in userIdMap
  return createdByAggregate.flatMap((item) => {
    // The created by items should always be instanceIds, but we do this type check to be safe
    const instanceId = valueAsInstanceId(item.value);
    if (!instanceId || item.count === undefined) return [];

    const userId = instanceId.externalId;
    const user = userIdToNameMap[userId];
    if (!user) return [];

    const isServicePrincipal = user.identityType === 'SERVICE_PRINCIPAL';
    return [
      {
        label: isServicePrincipal
          ? servicePrincipalName
          : user.displayName ?? userId,
        value: userId,
        count: item.count,
      },
    ];
  });
};

export const combineChecklistFilters = (
  filter: Filters | undefined,
  configFilter: { rootAssetExternalIds?: Filters },
  notArchivedFilter: Filters
): Filters => {
  const filters = filter ? [filter] : [];

  if (configFilter.rootAssetExternalIds) {
    filters.push(configFilter.rootAssetExternalIds);
  }

  filters.push(notArchivedFilter);

  return filters.length > 1 ? { and: filters } : filters[0];
};
