import type { UploadedMedia } from '@infield/features/media/types';
import type { FC } from 'react';

import * as S from './elements';
import { MediaItem } from './media-item';

type Props = {
  media: UploadedMedia;
  handleMediaClick: (image: UploadedMedia) => void;
};

export const MediaPreview: FC<Props> = ({ media, handleMediaClick }) => {
  return (
    <S.MediaContainer
      onClick={() => media.file && !media.isError && handleMediaClick(media)}
    >
      <MediaItem media={media} />
    </S.MediaContainer>
  );
};
