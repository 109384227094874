import type { DataGridRow } from './types';

export type TableData = {
  id: string;
  [key: string]: any;
};

const getTableData = (rows: DataGridRow[]): TableData[] => {
  return rows.map(({ id, cells, ...rest }) => ({
    ...rest,
    id,
    ...Object.fromEntries(
      Object.entries(cells).map(([key, cell]) => [key, cell.value])
    ),
  }));
};

export default getTableData;
