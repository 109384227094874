import type { FC } from 'react';

import startCase from 'lodash/startCase';

import { Body, Flex } from '@cognite/cogs.js-v10';
import type { CogniteClient } from '@cognite/sdk';

import type { AppConfig, NamedView } from '../../types';
import { ViewSelector } from '../view-selector';

type ViewConfigPageProps = {
  sdk: CogniteClient;
  config: AppConfig;
  onConfigChange: (config: AppConfig) => void;
};

type ViewKey = {
  name: NamedView;
  shouldImplementView: string;
};

const viewKeys: ViewKey[] = [
  {
    name: 'activity',
    shouldImplementView: 'Activity',
  },
  {
    name: 'asset',
    shouldImplementView: 'Asset',
  },
  {
    name: 'operation',
    shouldImplementView: 'Operation',
  },
  {
    name: 'notification',
    shouldImplementView: 'Notification',
  },
];

export const ViewConfigPage: FC<ViewConfigPageProps> = ({
  sdk,
  config,
  onConfigChange,
}) => {
  const renderViewSelector = (viewKey: ViewKey) => {
    return (
      <Flex direction="column" gap={8}>
        <Body size="x-small" strong>
          {startCase(viewKey.name)}
        </Body>
        <ViewSelector
          sdk={sdk}
          filterByInheritedView={viewKey.shouldImplementView}
          viewReference={
            config.featureConfiguration?.viewMappings?.[viewKey.name]
          }
          onViewReferenceChange={(viewReference) => {
            onConfigChange({
              ...config,
              featureConfiguration: {
                ...(config.featureConfiguration || {}),
                viewMappings: {
                  ...(config.featureConfiguration?.viewMappings || {}),
                  [viewKey.name]: viewReference,
                },
              },
            });
          }}
        />
      </Flex>
    );
  };

  return (
    <Flex direction="column" gap={16}>
      {viewKeys.map((key) => renderViewSelector(key))}
    </Flex>
  );
};
