import { useAuthContext } from '@cognite/e2e-auth';
import { useFlag } from '@cognite/react-feature-flags';
import { UnleashKeys } from '@infield/constants/unleash-keys';

export const useIsSelfServiceReleaseManagementEnabled = () => {
  const { authState } = useAuthContext();
  const { isEnabled } = useFlag(
    UnleashKeys.INAPP_SELF_SERVICE_RELEASE_MANAGEMENT,
    {
      fallback: false,
      forceRerender: false,
    }
  );

  return isEnabled || isCogniteUser(authState.user.email);
};

const isCogniteUser = (email: string) => email.endsWith('@cognitedata.com');
