import Konva from 'konva';
import { LineJoin } from 'konva/cmj/Shape';
import { $selectAll, type LexicalEditor } from 'lexical';
import isNil from 'lodash/isNil';
import { v4 as uuid } from 'uuid';

import UnifiedViewerEventType from '../../../core/UnifiedViewerEventType';
import { AnnotationType, Position, Size } from '../../annotations/types';
import {
  UNIFIED_VIEWER_NODE_TYPE_KEY,
  UnifiedViewerNodeType,
} from '../../types';
import { UnifiedViewer } from '../../UnifiedViewer';
import { UnifiedViewerPointerEvent } from '../../UnifiedViewerRenderer/UnifiedEventHandler';
import { getPlaceHolderFontSizeFromNode } from '../../utils/createPlaceholderElementFromNode';
import { isMouseEvent, MouseButton } from '../../utils/eventUtils';
import getTextContent from '../../utils/lexical/getTextContent';
import isAnchor from '../Anchor/isAnchor';
import { ContainerAttachmentHelper } from '../ContainerAttachmentHelper';
import { ANCHOR_CURSOR } from '../StickyAnchorHelper/constants';
import Tool from '../Tool';
import { StickyToolConfig, ToolType } from '../types';

import createEditableTextElements from './createEditableTextElements';
import findMaxFontSizeWithoutOverflow from './findMaxFontSizeWithoutOverflow';
import getPreviewNode from './getPreviewNode';
import sizeWithoutPx from './sizeWithoutPx';

const getNodeDimensions = (node: Konva.Node): Size & Position => ({
  width: node.width(),
  height: node.height(),
  x: node.x(),
  y: node.y(),
});

const isStickyAnnotation = (node: Konva.Node): boolean => {
  // Assuming that the clicked target is never the node carrying the AnnotationType
  // (which would be the case for StickyAnnotation nodes)
  const parentNode = node.parent;
  if (parentNode === null) {
    return false;
  }

  return parentNode.attrs.annotationType === AnnotationType.STICKY;
};

const getStickyNodeFromNode = (node: Konva.Node): Konva.Label => {
  if (!isStickyAnnotation(node)) {
    throw new Error('Expected a sticky node');
  }

  const parentNode = node.parent;
  if (parentNode === null || !(parentNode instanceof Konva.Label)) {
    throw new Error('Expected a sticky node');
  }

  return parentNode;
};
export default class StickyTool extends Tool {
  private config: StickyToolConfig;
  public readonly type = ToolType.STICKY;

  public static readonly CREATE_CURSOR = 'crosshair';
  public static readonly EDIT_CURSOR = 'text';

  public override cursor = StickyTool.CREATE_CURSOR;
  private pendingNode: Konva.Label | undefined;
  private domNode: HTMLElement | undefined;
  private editor: LexicalEditor | undefined;
  private placeholder: HTMLElement | undefined;
  private readonly STAGE_TRANSFORM_EVENTS = [
    UnifiedViewerEventType.ON_ZOOM_START,
    UnifiedViewerEventType.ON_ZOOM_CHANGE,
    UnifiedViewerEventType.ON_ZOOM_END,
    UnifiedViewerEventType.ON_PAN_START,
    UnifiedViewerEventType.ON_PAN_MOVE,
    UnifiedViewerEventType.ON_PAN_END,
  ];
  private containerAttachmentHelper: ContainerAttachmentHelper;

  private previewNode: Konva.Label | undefined;

  public constructor({
    unifiedViewer,
    config,
  }: {
    unifiedViewer: UnifiedViewer;
    config: StickyToolConfig;
  }) {
    super(unifiedViewer);
    this.config = config;
    this.containerAttachmentHelper = new ContainerAttachmentHelper(
      unifiedViewer
    );
    this.setConfig(config);
    this.STAGE_TRANSFORM_EVENTS.forEach((event) =>
      this.unifiedViewer.addEventListener(event, this.updateTextElements)
    );
    this.containerAttachmentHelper.start();
  }

  public setConfig = (config: StickyToolConfig): void => {
    this.config = config;
    this.previewNode?.destroy();
    this.previewNode = getPreviewNode({
      label: {},
      tag: this.getSharedTagProperties(),
      text: this.getSharedTextProperties(),
    });
    this.previewNode.hide();
    this.unifiedViewer.layers.main.add(this.previewNode);
  };

  private updateTextElements = (): void => {
    if (this.previewNode?.isVisible() === true) {
      const currentPosition =
        this.unifiedViewer.stage.getRelativePointerPosition();
      if (currentPosition === null) {
        return;
      }
      this.previewNode.position({
        x: currentPosition.x - this.previewNode.width() / 2,
        y: currentPosition.y - this.previewNode.height() / 2,
      });
    }

    const editorContainer = this.editor?.getRootElement();
    if (
      this.pendingNode === undefined ||
      this.domNode === undefined ||
      this.placeholder === undefined ||
      this.editor === undefined ||
      isNil(editorContainer)
    ) {
      return;
    }

    const tagNode = this.pendingNode.getTag();
    const textNode = this.pendingNode.getText();
    const {
      x: textX,
      y: textY,
      width: textWidth,
      height: textHeight,
    } = textNode.getClientRect();
    const scale = this.unifiedViewer.getScale();

    this.domNode.style.left = `${textX}px`;
    this.domNode.style.top = `${textY}px`;
    this.domNode.style.width = `${textWidth}px`;
    this.domNode.style.height = `${textHeight}px`;
    this.domNode.style.padding = `${textNode.padding() * scale}px`;
    this.domNode.style.border = `${
      tagNode.strokeWidth() * scale
    }px solid ${tagNode.stroke()}`;

    editorContainer.style.maxWidth = `${textWidth}px`;
    editorContainer.style.maxHeight = `${textHeight}px`;

    const text = getTextContent(this.editor.getEditorState());
    editorContainer.style.fontSize = `${
      findMaxFontSizeWithoutOverflow(text, {
        ...textNode.size(),
        padding: textNode.padding(),
      }) * scale
    }px`;

    this.placeholder.style.fontSize = `${getPlaceHolderFontSizeFromNode(
      this.pendingNode,
      scale
    )}px`;
  };

  public getStickyNode = ({
    dimensions,
  }: {
    dimensions: Size & Position;
  }): Konva.Label => {
    // TODO(FUS-000): Improve styles as much as possible with Konva
    const label = new Konva.Label({
      [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
      id: uuid(),
      x: dimensions.x,
      y: dimensions.y,
      source: this.type,
      containerId: undefined,
      annotationType: AnnotationType.STICKY,
      isSelectable: true,
      isDraggable: true,
      isResizable: true,
      strokeScaleEnabled: false, // Not sure if this should be added to the other ones
    });

    label.add(
      new Konva.Tag({
        ...this.getSharedTagProperties(),
      })
    );

    // add text to the label
    label.add(
      new Konva.Text({
        ...this.getSharedTextProperties(),
        text: '',
      })
    );

    return label;
  };

  public override onMouseUp = (e: UnifiedViewerPointerEvent): void => {
    this.updatePreview(e);
    // Re-focus the (editable) text element once panning, using the right mouse button, is done
    if (isMouseEvent(e.evt) && e.evt.button === MouseButton.SECONDARY) {
      this.editor?.focus();
    }
  };

  public override onMouseDown = (e: UnifiedViewerPointerEvent): void => {
    if (this.previewNode === undefined) {
      // eslint-disable-next-line no-console
      console.warn('StickyTool: preview node  is undefined on mouse down');
      return;
    }

    e.target.stopDrag();

    if (this.pendingNode !== undefined) {
      this.onEditTextComplete();
      return;
    }

    const dimensions = getNodeDimensions(this.previewNode);

    if (isStickyAnnotation(e.target)) {
      this.onEditSticky(getStickyNodeFromNode(e.target));
      return;
    }

    const stickyNode = this.getStickyNode({ dimensions });
    this.containerAttachmentHelper.lockTarget();

    this.onEditSticky(stickyNode);
  };

  public onEditSticky = (node: Konva.Label): void => {
    if (this.pendingNode !== undefined) {
      this.onEditTextComplete();
    }

    this.pendingNode = node;
    this.unifiedViewer.shamefullyRefreshAnchors([this.pendingNode]);
    this.unifiedViewer.setDirtyNodes([this.pendingNode]);
    this.onEditLabel(this.pendingNode);
  };

  private onEditableTextKeyDown = (e: KeyboardEvent) => {
    // We don't want to propagate the keydown event to the stage since it would
    // for instance trigger shortcuts both in the text annotation and the viewer (such as CMD + Z to undo)
    e.stopPropagation();

    if (e.key === 'Escape') {
      this.onEditTextComplete();
      return;
    }

    if (e.metaKey && e.key === 'Enter') {
      this.onEditTextComplete();
      return;
    }
  };

  private onEditLabel = (node: Konva.Label) => {
    node.hide();

    const { container, editor, placeholder } = createEditableTextElements(
      node,
      this.unifiedViewer.getScale
    );

    this.domNode = container;
    this.editor = editor;
    this.placeholder = placeholder;
    this.unifiedViewer.host.appendChild(container);
    container.addEventListener('keydown', this.onEditableTextKeyDown);

    setTimeout(() => {
      this.editor?.focus();
      this.editor?.update(() => {
        $selectAll();
      });
    });
  };

  private onEditTextComplete = () => {
    const editor = this.editor;
    const editorContainer = editor?.getRootElement();
    if (
      this.pendingNode !== undefined &&
      editor !== undefined &&
      !isNil(editorContainer)
    ) {
      const nextText = getTextContent(editor.getEditorState());
      this.pendingNode.getText().text(nextText.trim());
      this.pendingNode
        .getText()
        .fontSize(sizeWithoutPx(getComputedStyle(editorContainer).fontSize));
      this.containerAttachmentHelper.end([this.pendingNode]);
      this.unifiedViewer.commitDirtyNodes();
      this.pendingNode = undefined;
    }

    if (this.domNode !== undefined && this.domNode.parentNode !== null) {
      this.domNode.parentNode.removeChild(this.domNode);
      this.domNode = undefined;
      this.editor = undefined;
      this.placeholder = undefined;
    }

    this.containerAttachmentHelper.start();
  };

  public override onComplete(): void {
    this.previewNode?.hide();
    this.onEditTextComplete();
  }

  private updatePreview = (e: UnifiedViewerPointerEvent): void => {
    if (this.previewNode === undefined) {
      return;
    }

    const isNodePending = this.pendingNode !== undefined;
    const isTargetStickyAnnotation = isStickyAnnotation(e.target);

    if (isNodePending) {
      this.previewNode.hide();
      return;
    }

    if (isTargetStickyAnnotation) {
      this.previewNode.hide();
      return;
    }

    this.previewNode.show();

    const currentPosition =
      this.unifiedViewer.stage.getRelativePointerPosition();
    if (currentPosition === null) {
      return;
    }
    this.previewNode.x(currentPosition.x - this.previewNode.width() / 2);
    this.previewNode.y(currentPosition.y - this.previewNode.height() / 2);
  };

  private getCursor = (target: Konva.Node) => {
    if (isStickyAnnotation(target)) {
      return StickyTool.EDIT_CURSOR;
    }
    if (isAnchor(target)) {
      return ANCHOR_CURSOR;
    }
    return StickyTool.CREATE_CURSOR;
  };

  public override onMouseMove = (e: UnifiedViewerPointerEvent): void => {
    if (this.previewNode === undefined) {
      return;
    }
    this.cursor = this.getCursor(e.target);
    this.updatePreview(e);
  };

  public override onMouseLeave = (): void => {
    this.previewNode?.hide();
  };

  public override onMouseEnter = (): void => {
    this.previewNode?.show();
  };

  private getSharedTagProperties = (): {
    fill: string | undefined;
    stroke: string | undefined;
    strokeWidth: number | undefined;
    lineJoin: LineJoin;
    cornerRadius: number | undefined;
    shadowColor: string | undefined;
    shadowOffset: { x: number; y: number } | undefined;
    shadowBlur: number | undefined;
  } => {
    return {
      fill: this.config.backgroundColor,
      stroke: this.config.borderColor,
      strokeWidth: this.config.borderWidth,
      lineJoin: 'round',
      cornerRadius: this.config.borderRadius,
      shadowColor: this.config.shadowColor,
      shadowOffset: this.config.shadowOffset,
      shadowBlur: this.config.shadowBlur,
    };
  };

  private getSharedTextProperties = (): {
    padding: number | undefined;
    width: number;
    height: number;
    fill: string | undefined;
    lineHeight: number | undefined;
    align: 'center';
    verticalAlign: 'middle';
  } => {
    return {
      padding: this.config.padding,
      width: this.config.width,
      height: this.config.height,
      fill: this.config.color,
      lineHeight: this.config.lineHeight,
      align: 'center',
      verticalAlign: 'middle',
    };
  };

  public override onDestroy(): void {
    this.onComplete();
    this.previewNode?.destroy();
    this.previewNode = undefined;
    this.STAGE_TRANSFORM_EVENTS.forEach((event) =>
      this.unifiedViewer.removeEventListener(event, this.updateTextElements)
    );
    this.containerAttachmentHelper.onDestroy();
  }
}
