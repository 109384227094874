import type { FC } from 'react';

import { useQuery } from '@tanstack/react-query';

import { AutoComplete } from '@cognite/cogs.js-v10';
import type { CogniteClient } from '@cognite/sdk';

type ViewSelectorProps = {
  sdk: CogniteClient;
  selectedSpaces?: string[];
  onSpacesSelect: (nextSpaces?: string[]) => void;
  onSpaceCreate?: (nextSpace?: string) => void;
  placeholder?: string;
};

export const MultipleSpaceSelectOrCreator: FC<ViewSelectorProps> = ({
  sdk,
  selectedSpaces,
  onSpacesSelect,
  onSpaceCreate,
  placeholder,
}) => {
  const { data: spaces } = useQuery(['spaces'], () => {
    return sdk.spaces.list({
      includeGlobal: true,
      limit: 1000,
    });
  });

  const handleSpaceSelect = (
    // TODO(FUS-000): Disabling so we can enable linting
    // eslint-disable-next-line @typescript-eslint/no-shadow
    spaces: { label: string; value: string }[] | null
  ) => {
    const spaceValues = spaces?.map((space) => space.value);
    onSpacesSelect(spaceValues);
  };

  return (
    <AutoComplete
      isMulti
      placeholder={placeholder || 'Select a space'}
      options={spaces?.items.map((space) => ({
        label: space.name || space.space,
        value: space.space,
      }))}
      value={
        selectedSpaces
          ? selectedSpaces.map((space) => ({ label: space, value: space }))
          : undefined
      }
      onChange={handleSpaceSelect}
      onCreateOption={onSpaceCreate}
    />
  );
};
