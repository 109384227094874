export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const parseApiUrlToName = (apiUrl: string) => {
  const cluster = apiUrl.split('https://')[1].split('.')[0];
  if (cluster === 'api') {
    return 'Europe 1 (Google)';
  }

  return cluster;
};

export const isFusionHosted = () => {
  return (
    window.location.hostname.includes('fusion') ||
    window.location.hostname.includes('local.cognite.ai')
  );
};

export const isValidOrgOrProjectNameInput = (
  input: string | null | undefined
) => {
  return typeof input === 'string' && input.trim().length > 0;
};
