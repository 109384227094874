import { CogniteClient } from '@cognite/sdk';

import delayMs from './delay';

// This function is used to convert a file to a PDF preview using the Documents
// Preview API (see
// https://api-docs.cognite.com/20230101/tag/Document-preview/operation/documentsPreviewPdf).
// This function will retry the request up to `numRetries` times using
// exponential back off if it fails, with an initial delay time of delayTimeMs.
// We have this retry logic since the Documents API, in some clusters (e.g.,
// Azure), is not able to immediately record that a file has been uploaded,
// which results in an error overlay being rendered although the file is valid.
const getCogniteFileAsPdf = async (
  client: CogniteClient,
  fileId: number,
  numRetries = 5,
  delayTimeMs = 200
): Promise<ArrayBuffer> => {
  try {
    return await client.documents.preview.documentAsPdf(fileId);
  } catch (error) {
    if (numRetries === 0) {
      throw error; // No more retries left, throw the error
    }
    // eslint-disable-next-line no-console
    console.warn(
      `Attempt failed. Retries left: ${numRetries}. Retrying in ${delayTimeMs}ms...`,
      error
    );

    // Wait for the delay before retrying
    await delayMs(delayTimeMs);

    return getCogniteFileAsPdf(client, fileId, numRetries - 1, delayTimeMs);
  }
};

export default getCogniteFileAsPdf;
