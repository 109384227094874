import { RectangleStyleProperties } from '../../annotations/types';

// Annotation visual design is temporarily based on Interactive Diagrams Figma:
// https://www.figma.com/file/I2CY2isWDHv1qqbSeL1Kbf/Context-%7C-WIP-%7C-P%26ID-Contextualization?node-id=7812%3A178008

enum AnnotationColors {
  ASSET = '#D46AE2',
  FILE = '#FF8746',
  DEFAULT = '#A4B2FC',
}

// TODO(FUS-000): Resolve whether this resourceType belongs to a specific API or is Cognite wide.
const getDefaultStylesByResourceType = (
  resourceType?: string,
  isHovered?: boolean
): RectangleStyleProperties => {
  const defaultAnnotationStyle: RectangleStyleProperties = {
    stroke: AnnotationColors.DEFAULT,
    // Disable stroke scaling for contextualized annotations, as they otherwise
    // become too thin when the user is zoomed out
    shouldEnableStrokeScale: false,
    fill: isHovered ? `${AnnotationColors.DEFAULT}22` : 'transparent',
    strokeWidth: 2,
    opacity: 1,
    cornerRadius: 1,
  };

  if (resourceType === 'asset') {
    return {
      ...defaultAnnotationStyle,
      stroke: AnnotationColors.ASSET,
      fill: isHovered ? `${AnnotationColors.ASSET}22` : 'transparent',
    };
  }
  if (resourceType === 'file') {
    return {
      ...defaultAnnotationStyle,
      stroke: AnnotationColors.FILE,
      fill: isHovered ? `${AnnotationColors.FILE}22` : 'transparent',
    };
  }
  // if (resourceType === 'unknown') {
  // TODO(FUS-000): discuss:
  //  Might be useful for when we don't know resource type but still need a default style,
  //  for example in case of unlinked OCR results, or freshly created user annotation
  //  mihil: resource type is optional in legacyCognite annotations,
  //    i've encountered some event annotations without resource type
  return defaultAnnotationStyle;
  // }
  // return {};
};

export default getDefaultStylesByResourceType;
