import { MAX_TEMPLATE_NAME_LENGTH } from '../../constants';

export const getDuplicatedTemplateName = (
  templateName: string,
  nameSuffix: string
) => {
  return templateName
    .slice(0, MAX_TEMPLATE_NAME_LENGTH - nameSuffix.length)
    .concat(nameSuffix);
};
