import { useFlag } from '@cognite/react-feature-flags';
import type { Asset, AssetFilterProps } from '@cognite/sdk';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import { useAppConfigFiltersContext } from '@infield/providers/app-config-filters-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

type Props = {
  parentExternalId: string;
  query?: string;
  limit?: number;
  enabled: boolean;
};
export const useAssetDirectChildSearch = ({
  parentExternalId,
  query,
  limit = 50,
  enabled,
}: Props) => {
  const { assetService } = useFDMServices();
  const { asset: configFilters } = useAppConfigFiltersContext();

  const { isEnabled: isAkerbpCustomCode } = useFlag(
    UnleashKeys.akerbp_custom_code,
    { forceRerender: true, fallback: false }
  );

  const filter: AssetFilterProps = {
    parentExternalIds: [parentExternalId],
    externalIdPrefix: configFilters?.cdfClassicFilters.externalIdPrefix,
  };

  return useQuery<Asset[]>(
    [QueryKeys.ASSET, parentExternalId, 'children', query, filter],
    async () => {
      if (query) {
        return assetService.searchAssets(
          query,
          limit,
          filter,
          isAkerbpCustomCode
        );
      }

      return assetService.getAssets(filter, isAkerbpCustomCode);
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: `${QueryKeys.ASSET} children`,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(parentExternalId) && enabled,
    }
  );
};
