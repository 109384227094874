import type {
  ChecklistItem,
  TemplateItem,
  TemplateStatus,
} from '@cognite/apm-client';
import type { Filters } from '@cognite/fdm-client';

import { GROUP_PREFIX } from './constants';
import type { TemplateItemAndGroupList } from './types';

const capitalize = (s: string) => {
  if (s.length > 0) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  return '';
};

export const formatTemplateName = (name: string) => {
  // Capitalization is needed since sorting alphabetically in FDM is case sensitive. 'a' will appear after 'Z'.
  return capitalize(name.trim());
};

export const getTemplateItemsAndGroupsList = (tasks?: TemplateItem[]) => {
  return (tasks || []).reduce((accumulatedList, task: TemplateItem) => {
    const groupName = getGroupName(task.labels);
    const lastItem = accumulatedList[accumulatedList.length - 1];

    if (!groupName) {
      accumulatedList.push(task);
    } else if (
      lastItem &&
      'groupName' in lastItem &&
      lastItem.groupName === groupName
    ) {
      lastItem.tasks.push(task);
    } else {
      accumulatedList.push({ groupName, tasks: [task] });
    }

    return accumulatedList;
  }, [] as TemplateItemAndGroupList);
};

export const flattenTemplateItemsAndGroups = (
  taskAndGroupList: TemplateItemAndGroupList
) =>
  taskAndGroupList.flatMap((item) => {
    if ('groupName' in item) {
      return item.tasks;
    }
    return item;
  }) as TemplateItem[];

export const getGroupLastOrder = (groupName: string, tasks: any[]) => {
  const groupTasks = tasks.filter((task) => getTaskGroupLabel(task, groupName));
  const lastTask = groupTasks
    .sort((a, b) => (a.order || 0) - (b.order || 0))
    .pop();
  return lastTask?.order || 0;
};

export const getGroupLabel = (groupName: string) =>
  `${GROUP_PREFIX}${groupName}`;

export const removeGroupLabel = (labels?: string[]) => {
  return labels?.filter((label) => !label.startsWith(GROUP_PREFIX)) || [];
};

export const getTaskGroupLabel = (
  task: ChecklistItem | TemplateItem,
  groupName?: string
): string | undefined => {
  if (!groupName) {
    return undefined;
  }

  return task.labels?.find(
    (label) => label.toLowerCase() === getGroupLabel(groupName).toLowerCase()
  );
};

export const getGroupName = (labels?: string[]) => {
  const groupLabel = labels?.filter((label) => label.startsWith(GROUP_PREFIX));
  const groupName =
    groupLabel && groupLabel[0] ? groupLabel[0].split(GROUP_PREFIX)[1] : '';
  return groupName;
};

export const handleTemplateStatusFilter = (
  templateStatus?: TemplateStatus | 'All'
) => {
  const filters: Filters[] = [];

  if (templateStatus && ['Draft', 'Ready'].includes(templateStatus)) {
    filters.push({
      equals: {
        property: 'status',
        eq: templateStatus,
      },
    });
  }

  let showArchived = false;
  if (templateStatus === 'Deleted') {
    showArchived = true;
    filters.push({
      equals: {
        property: 'isArchived',
        eq: true,
      },
    });
  }

  if (templateStatus === 'All') {
    showArchived = true;
  }

  return { filters, showArchived };
};
