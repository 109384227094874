export const UnleashKeys = {
  akerbp_custom_code: 'INFIELD.akerbp_custom_code',
  INAPP_New_Config_Setup: 'INAPP_New_Config_Setup',
  INFIELD_IS_AWS_PROJECT: 'INFIELD_IS_AWS_PROJECT',
  windows_tablet_camera_oxy: 'INFIELD.windows_tablet_camera_oxy',
  INFIELD_SEARCH_EVERYTHING_IS_ENABLED: 'INFIELD_SEARCH_EVERYTHING_IS_ENABLED',
  INAPP_SELF_SERVICE_RELEASE_MANAGEMENT:
    'INAPP_SELF_SERVICE_RELEASE_MANAGEMENT',
  checklist_table_createdby: 'INFIELD.checklist_table_createdby',
  checklist_scheduler: 'INFIELD.checklist_scheduler',
  schedule_tab: 'INFIELD.schedule_tab',
  celanese_corrupted_readings: 'INFIELD.celanese_corrupted_readings',
};
