import { CDFView } from '@cognite/apm-client';
import type { CogniteClient, ViewReference } from '@cognite/sdk';

import { DEFAULT_CONFIG } from '../default-config';
import type {
  AppConfig,
  FeatureConfiguration,
  FieldConfiguration,
  MakeOptional,
  NamedView,
} from '../types';

import type {
  LocationFilterDTO,
  LocationFilterUpsertRequestDTO,
  ViewConfigEntities,
} from './ads-location-filters-sdk';
import { ADSLocationFilterSDK } from './ads-location-filters-sdk';

type SystemAppConfigDTO = {
  externalId: string;
  isDefault: boolean;
  locationFiltersExternalId: string;
  fieldConfiguration: Record<string, MakeOptional<FieldConfiguration, 'field'>>;
  featureConfiguration: FeatureConfiguration;
};

export class AppConfigClientV2<
  TSystemConfigSchema extends SystemAppConfigDTO = SystemAppConfigDTO
> {
  public space: string;
  public cogniteClient: CogniteClient;
  public configCDFView: CDFView<TSystemConfigSchema>;
  public locationFiltersClient: ADSLocationFilterSDK;
  public mapFunction?: (
    locationFilter: LocationFilterDTO,
    appConfig: TSystemConfigSchema
  ) => AppConfig;

  constructor(
    client: CogniteClient,
    viewReference: Omit<ViewReference, 'type'>,
    mapFunction?: (
      locationFilter: LocationFilterDTO,
      appConfig: TSystemConfigSchema
    ) => AppConfig
  ) {
    this.cogniteClient = client;
    this.locationFiltersClient = new ADSLocationFilterSDK(client);
    this.mapFunction = mapFunction;
    this.space = 'cognite_apps_shared';
    this.configCDFView = new CDFView(
      client,
      {
        externalId: viewReference?.externalId ?? 'CDF_AppLocationConfig',
        space: viewReference?.space ?? 'cdf_apps_shared',
        version: viewReference?.version ?? 'v1',
      },
      this.space
    );
  }

  mapViews(views: LocationFilterDTO['views']): AppConfig['viewMappings'] {
    return (views ?? []).reduce<AppConfig['viewMappings']>((acc, view) => {
      if (!view.representsEntity) return acc;
      if (!acc) return acc;
      acc[view.representsEntity as NamedView] = {
        externalId: view.externalId,
        space: view.space,
        version: view.version,
        type: 'view',
      };
      return acc;
    }, {});
  }

  mapCore(
    locationFilter: LocationFilterDTO,
    systemConfig: SystemAppConfigDTO
  ): AppConfig {
    return {
      appDataSpaceId:
        systemConfig.featureConfiguration.legacyConfigurations
          ?.appDataSpaceId ?? '',
      appDataSpaceVersion:
        systemConfig.featureConfiguration.legacyConfigurations
          ?.appDataSpaceVersion ?? '',
      createdOn: new Date(locationFilter.createdTime),
      customerDataSpaceId: locationFilter.instanceSpaces?.[0] ?? '',
      customerDataSpaceVersion:
        systemConfig.featureConfiguration.legacyConfigurations
          ?.customerDataSpaceVersion ?? '',
      externalId: systemConfig.externalId,
      fieldConfiguration: systemConfig.fieldConfiguration,
      isActive: true,
      isDefault: systemConfig.isDefault,
      rootLocationsConfiguration: systemConfig.featureConfiguration
        ?.legacyConfigurations?.maintain_rootLocationsConfiguration ?? {
        locations: [],
      },
      featureConfiguration: systemConfig.featureConfiguration,
      name: locationFilter.name,
      viewMappings: this.mapViews(locationFilter?.views || []),
      locationFiltersExternalId: locationFilter.externalId,
      locationInstanceSpaces: locationFilter.instanceSpaces || [],
    };
  }

  async list(): Promise<AppConfig[]> {
    // ADS Location filters
    const locationFilters = await this.locationFiltersClient.listLocationFilter(
      {
        flat: true,
      }
    );

    // DMS System configurations
    const uiConfigs = await this.configCDFView.list().then((res) => res.items);

    // Merge the two together
    const configurations = locationFilters.items.map<AppConfig | undefined>(
      (locationFilter) => {
        const dmsConfig =
          uiConfigs.find(
            (config) =>
              config.locationFiltersExternalId === String(locationFilter.id)
          ) ??
          ({
            externalId: locationFilter.externalId,
            isDefault: false,
            locationFiltersExternalId: locationFilter.externalId,
            featureConfiguration: DEFAULT_CONFIG.featureConfiguration,
            fieldConfiguration: DEFAULT_CONFIG.fieldConfiguration,
          } as TSystemConfigSchema);

        if (this.mapFunction) {
          return this.mapFunction(locationFilter, dmsConfig);
        }
        return this.mapCore(locationFilter, dmsConfig);
      }
    );
    return configurations.filter(Boolean) as AppConfig[];
  }

  async upsertSpaceIfNotExisting() {
    const existingSpace = await this.cogniteClient.spaces.retrieve([
      this.space,
    ]);
    if (existingSpace.items.length > 0) return existingSpace;

    await this.cogniteClient.spaces.upsert([
      {
        space: this.space,
        name: 'Cognite Apps Shared Space',
        description: 'Shared space for all Cognite Apps',
      },
    ]);
  }

  async upsertConfiguration(
    nextConfig: Partial<AppConfig> & { externalId: string },
    systemConfig: TSystemConfigSchema
  ) {
    try {
      await this.upsertSpaceIfNotExisting();
    } catch (e) {
      throw new Error(
        `The space ${this.space} does not exist, and the current user does not have the required permissions to create it.`
      );
    }

    const existingConfig = await this.configCDFView
      .byId([{ externalId: nextConfig.externalId }])
      .then((res) => res[0]);

    const locationFilterToUpsert: LocationFilterUpsertRequestDTO = {
      id: Number(existingConfig?.locationFiltersExternalId),
      externalId: nextConfig.externalId,
      name: nextConfig.name ?? 'Config',
      instanceSpaces: nextConfig.locationInstanceSpaces,
      // scene: {},
      views: Object.entries(nextConfig.viewMappings || {}).map(
        ([entity, view]) => ({
          externalId: view.externalId,
          space: view.space,
          version: view.version,
          representsEntity: entity as ViewConfigEntities,
        })
      ),
    };

    const locationFilter =
      await this.locationFiltersClient.upsertLocationFilter(
        locationFilterToUpsert
      );

    const configToUpsert: Partial<TSystemConfigSchema> & {
      externalId: string;
    } = {
      ...systemConfig,
      externalId: systemConfig.externalId,
      featureConfiguration: systemConfig.featureConfiguration,
      fieldConfiguration: systemConfig.fieldConfiguration,
      isDefault: systemConfig.isDefault,
      locationFiltersExternalId: String(locationFilter.id),
    };

    return this.configCDFView.upsert([configToUpsert]);
  }

  async deleteConfiguration(externalId: string) {
    const locationFilters = await this.locationFiltersClient.listLocationFilter(
      {
        flat: true,
      }
    );
    const listedViews = await this.list();
    const viewToDelete = listedViews.find((x) => x.externalId === externalId);
    const locationFilterToDelete = locationFilters.items.find(
      (x) => x.externalId === externalId
    );

    if (!viewToDelete || !locationFilterToDelete) {
      console.error({ viewToDelete, locationFilterToDelete });
      throw new Error('Could not find configuration to delete');
    }

    await this.locationFiltersClient.deleteLocationFilter(
      locationFilterToDelete.id
    );
    return this.configCDFView.delete([
      { externalId: viewToDelete!.externalId },
    ]);
  }
}
