import type { GridFilterModel } from '@cognite/apm-observation';
import type { Filters } from '@cognite/fdm-client';
import type { FieldInfo } from '@infield/features/activities/hooks/use-query/types';

export const gridFilterToFDMFilter = (
  filterModel: GridFilterModel,
  // Mapping between grid filter fields and FDM fields
  // Only provide this if fields are different
  fieldsMapping: { [key: string]: string } = {},

  // Fields info to determine if field is a list
  // No need to provide this if you don't have any list fields
  fieldsInfo: FieldInfo[] = [],

  // Allows you to merge existing filters with grid filters
  // Here we assume that grid filters and other filters doesn't have any common field
  otherFilters?: Filters
): Filters | undefined => {
  if (filterModel.length === 0) {
    return otherFilters ? { and: [otherFilters] } : undefined;
  }

  const isList = (fieldName: string) =>
    Boolean(fieldsInfo.find((field) => field.name === fieldName)?.list);

  const isDirectRelation = (fieldName: string) => {
    return (
      fieldsInfo.find((field) => field.name === fieldName)?.type === 'direct'
    );
  };

  const optimizedFilters = filterModel.map((filter) => {
    const property = fieldsMapping[filter.field] || filter.field;

    // Handle direct relation fields
    if (isDirectRelation(filter.field)) {
      return {
        directRelationFilter: {
          [property]: {
            in: {
              property: 'externalId',
              in: filter.value,
            },
          },
        },
        directRelationInstanceSpace: fieldsInfo.find(
          (field) => field.name === filter.field
        )?.directRelationInstanceSpace,
      };
    }

    // Handle list fields
    if (isList(filter.field)) {
      return {
        containsAny: {
          property,
          containsAny: filter.value,
        },
      };
    }

    // Handle other fields
    if (filter.value && filter.value.length > 0) {
      return {
        in: {
          property,
          in: filter.value,
        },
      };
    }
  });

  // Filter out undefined values
  const validFilters = optimizedFilters.filter(Boolean) as Filters[];

  return {
    and: otherFilters ? [otherFilters, ...validFilters] : validFilters,
  };
};

export const FDMFilterToGridFilter = (
  fdmFiltersToConvert: Filters,
  // Mapping between grid filter fields and FDM fields
  // Only provide this if fields are different
  fieldsMapping: { [key: string]: string } = {}
): GridFilterModel | undefined => {
  const fdmFilters = fdmFiltersToConvert.and;
  if (!fdmFilters || fdmFilters.length === 0) {
    return undefined;
  }

  const gridFilters = fdmFilters
    .flatMap((fdmFilter) => {
      // Recursively extract filters inside `and` blocks
      if (fdmFilter?.and) {
        return (
          FDMFilterToGridFilter({ and: fdmFilter.and }, fieldsMapping) || []
        );
      }

      // Recursively extract filters inside `or` blocks
      if (fdmFilter?.or) {
        return (
          FDMFilterToGridFilter({ and: fdmFilter.or }, fieldsMapping) || []
        );
      }

      return convertSingleFdmFilter(fdmFilter, fieldsMapping);
    })
    .filter(Boolean) as GridFilterModel;

  // Reduce to merge filters with the same field
  return gridFilters.reduce<GridFilterModel>(
    (gridFiltersList, currentValue) => {
      const field = currentValue.field;
      const index = gridFiltersList.findIndex(
        (filter) => filter.field === field
      );

      if (index >= 0) {
        gridFiltersList[index] = {
          field,
          value: [...gridFiltersList[index].value, ...currentValue.value],
          operator: 'and',
        };
      } else {
        gridFiltersList.push(currentValue);
      }

      return gridFiltersList;
    },
    []
  );
};

// Helper function to convert filters
const convertSingleFdmFilter = (
  fdmFilter: any,
  fieldsMapping: { [key: string]: string }
) => {
  if (fdmFilter?.equals) {
    return {
      field:
        fieldsMapping[fdmFilter.equals.property] || fdmFilter.equals.property,
      value: [fdmFilter.equals.eq],
      operator: 'and',
    };
  }

  if (fdmFilter?.containsAny) {
    return {
      field:
        fieldsMapping[fdmFilter.containsAny.property] ||
        fdmFilter.containsAny.property,
      value: fdmFilter.containsAny.containsAny,
      operator: 'and',
    };
  }

  if (fdmFilter?.in) {
    return {
      field: fieldsMapping[fdmFilter.in.property] || fdmFilter.in.property,
      value: fdmFilter.in.in,
      operator: 'and',
    };
  }

  if (fdmFilter?.directRelationFilter) {
    const [relationField] = Object.keys(fdmFilter.directRelationFilter);
    const relationFilter = fdmFilter.directRelationFilter[relationField];

    if (relationFilter?.in) {
      return {
        field: fieldsMapping[relationField] || relationField,
        value: relationFilter.in.in,
        operator: 'and',
      };
    }

    if (relationFilter?.equals) {
      return {
        field: fieldsMapping[relationField] || relationField,
        value: [relationFilter.equals.eq],
        operator: 'and',
      };
    }
  }

  return undefined;
};
