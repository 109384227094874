import Konva from 'konva';
import { v4 as uuid } from 'uuid';

import {
  COMMENT_FONT_SIZE_PX,
  COMMENT_PADDING_PX,
  DEFAULT_COMMENT_BACKGROUND_COLOR,
  DEFAULT_COMMENT_CORNER_RADIUS,
  DEFAULT_COMMENT_STROKE,
  DEFAULT_COMMENT_STROKE_WIDTH,
  DEFAULT_COMMENT_TEXT_COLOR,
} from '../annotations/constants';
import { AnnotationType } from '../annotations/types';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import { UnifiedViewer } from '../UnifiedViewer';
import getInitials from '../utils/getInitials';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';

import { ContainerAttachmentHelper } from './ContainerAttachmentHelper';
import Tool from './Tool';
import { CommentToolConfig, ToolType } from './types';

export default class CommentTool extends Tool {
  public readonly type = ToolType.COMMENT;
  public override readonly cursor = 'crosshair';
  private config: CommentToolConfig;

  private containerAttachmentHelper: ContainerAttachmentHelper;

  public constructor({
    unifiedViewer,
    config,
  }: {
    unifiedViewer: UnifiedViewer;
    config: CommentToolConfig;
  }) {
    super(unifiedViewer);
    this.containerAttachmentHelper = new ContainerAttachmentHelper(
      unifiedViewer
    );
    this.config = config;
    this.setConfig(config);
    this.containerAttachmentHelper.start();
  }

  public setConfig = (config: CommentToolConfig): void => {
    this.config = config;
  };

  public override onMouseDown = (): void => {
    const translatedMousePosition =
      this.unifiedViewer.stage.getRelativePointerPosition();

    const currentScale = this.unifiedViewer.getScale();
    const label = new Konva.Label({
      [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
      annotationType: AnnotationType.COMMENT,
      id: uuid(),
      userGenerated: true,
      name: 'user-drawing',
      source: 'CommentTool',
      containerId: undefined,
      author: this.config.author,
      isSelectable: true,
      isDraggable: true,
      isResizable: false,
      // Scale the node by the inverse of the current stage scale to keep the same size on the screen
      scale: { x: 1.0 / currentScale, y: 1.0 / currentScale },
      ...translatedMousePosition,
    });
    label.add(
      new Konva.Tag({
        fill: this.config?.backgroundColor ?? DEFAULT_COMMENT_BACKGROUND_COLOR,
        stroke: this.config?.borderColor ?? DEFAULT_COMMENT_STROKE,
        strokeWidth: this.config?.borderWidth ?? DEFAULT_COMMENT_STROKE_WIDTH,
        cornerRadius: DEFAULT_COMMENT_CORNER_RADIUS,
      })
    );
    label.add(
      new Konva.Text({
        text: getInitials(this.config.author),
        padding: COMMENT_PADDING_PX,
        fill: this.config?.color ?? DEFAULT_COMMENT_TEXT_COLOR,
        fontSize: COMMENT_FONT_SIZE_PX,
      })
    );
    this.unifiedViewer.setDirtyNodes([label]);
    this.containerAttachmentHelper.end([label]);
    this.unifiedViewer.commitDirtyNodes();

    getMetricsLogger()?.trackEvent(TrackedEventType.CREATE_COMMENT, {
      author: this.config.author,
      backgroundColor: this.config.backgroundColor,
      borderColor: this.config.borderColor,
      borderWidth: this.config.borderWidth,
      color: this.config.color,
    });
  };

  public override onMouseUp = (): void => {
    this.containerAttachmentHelper.start();
  };

  public override onDestroy(): void {
    this.containerAttachmentHelper.onDestroy();
  }
}
