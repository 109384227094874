import chroma from 'chroma-js';
import Konva from 'konva';
import { Filter } from 'konva/lib/Node';

const isPixelGrayscale = (
  r: number,
  g: number,
  b: number,
  tolerance: number = 10.0
): boolean =>
  Math.abs(r - g) <= tolerance &&
  Math.abs(g - b) <= tolerance &&
  Math.abs(b - r) <= tolerance;

const isFullyGrayscale = (data: Uint8ClampedArray): boolean => {
  for (let i = 0; i < data.length; i += 4) {
    if (data[i + 3] === 0) {
      continue;
    }
    if (!isPixelGrayscale(data[i], data[i + 1], data[i + 2])) {
      return false;
    }
  }
  return true;
};

const getColorTransformFilter =
  (targetRgb: [number, number, number]): Filter =>
  ({ data }) => {
    const [targetHue] = chroma.rgb(...targetRgb).hsl();

    // Set the color to the target color if the image is fully grayscale
    if (isFullyGrayscale(data)) {
      for (let i = 0; i < data.length; i += 4) {
        // Ignore transparent pixels
        if (data[i + 3] === 0) {
          continue;
        }
        data[i] = targetRgb[0];
        data[i + 1] = targetRgb[1];
        data[i + 2] = targetRgb[2];
      }
      return;
    }

    for (let i = 0; i < data.length; i += 4) {
      const [h, s, l] = chroma.rgb(data[i], data[i + 1], data[i + 2]).hsl();
      // Ignore invalid HSL triplets
      if (Number.isNaN(h) && s === 0 && l === 0) {
        continue;
      }

      // Special-case handling for grayscale pixels since saturation ends up being 0
      const correctedSaturation = isPixelGrayscale(
        data[i],
        data[i + 1],
        data[i + 2]
      )
        ? 1.0
        : s;

      const [r, g, b] = chroma.hsl(targetHue, correctedSaturation, l).rgb();
      data[i] = r;
      data[i + 1] = g;
      data[i + 2] = b;
    }
  };

const setNodeColor = (
  node: Konva.Node,
  rgb: [number, number, number]
): void => {
  node.cache();
  node.filters([getColorTransformFilter(rgb)]);
};

export default setNodeColor;
