import {
  importPDFJS,
  type DocumentInitParameters,
  type PDFDocumentLoadingTask,
  type PDFDocumentProxy,
} from '../../vendor/pdfjs-dist';

import getResponseCache from './getResponseCache';
import { isMobileOrTablet } from './isMobileOrTablet';

const getRangeHeader = (begin: number, end: number) =>
  `bytes=${begin}-${end - 1}`;

const getRangeTransport = async ({
  fileUrl,
  key,
  contentLengthCache,
}: {
  fileUrl: string;
  key: string;
  contentLengthCache: Map<string, number>;
}) => {
  let contentLength = contentLengthCache.get(key);
  if (contentLength === undefined) {
    const { headers } = await fetch(fileUrl, { method: 'HEAD' });
    contentLength = Number(headers.get('Content-Length'));
    if (contentLength === 0 || Number.isNaN(contentLength)) {
      throw new Error('Content-Length header is invalid or missing');
    }
    contentLengthCache.set(key, contentLength);
  }

  const PDFDataRangeTransport = (await importPDFJS()).PDFDataRangeTransport;
  const rangeTransport = new PDFDataRangeTransport(contentLength, null);

  rangeTransport.requestDataRange = async (begin, end) => {
    const rangeHeader = getRangeHeader(begin, end);
    const { data } = await getResponseCache().fetchFromCustomSource(
      `${key}-${rangeHeader}`,
      async () => await fetch(fileUrl, { headers: { Range: rangeHeader } })
    );
    rangeTransport.onDataProgress(end, contentLength);
    rangeTransport.onDataRange(begin, new Uint8Array(data));
    return data;
  };

  return rangeTransport;
};

const isPdfDataUrl = (fileUrl: string) =>
  fileUrl.startsWith('data:application/pdf');

const getDocumentParams = async ({
  fileUrl,
  options,
  contentLengthCache,
}: {
  fileUrl: string;
  options?: PdfCacheOptions;
  contentLengthCache: Map<string, number>;
}): Promise<DocumentInitParameters> => {
  // For data URLs, we can't use range requests, so we instead need to fetch the
  // entire file at once.
  if (isPdfDataUrl(fileUrl)) {
    const { data } = await getResponseCache().fetch(fileUrl);
    return { ...BASE_PDFJS_GET_DOCUMENT_PARAMS, data: new Uint8Array(data) };
  }

  try {
    const rangeTransport = await getRangeTransport({
      fileUrl,
      contentLengthCache,
      key: options?.key ?? fileUrl,
    });
    return {
      ...BASE_PDFJS_GET_DOCUMENT_PARAMS,
      url: fileUrl,
      range: rangeTransport,
      disableAutoFetch: true,
      disableStream: true,
    };
  } catch {
    // Fall back to fetching the entire file if we cannot construct a range transport.
    // This could e.g., happen if the file server does not support HEAD requests
    const { data } = await getResponseCache().fetch(fileUrl);
    return { ...BASE_PDFJS_GET_DOCUMENT_PARAMS, data: new Uint8Array(data) };
  }
};

const BASE_PDFJS_GET_DOCUMENT_PARAMS = {
  // A user reported crashes on their mobile devices when loading a PDF
  // which had a very large embedded image. The crash is due to
  // attempting to create too large of an OffscreenCanvas.
  //
  // To be safe, don't allow PDFJS to render such large images. The
  // value was chosen based on iOS's console logged warnings.
  maxImageSize: isMobileOrTablet ? 4096 ** 2 : undefined,
};
const DEFAULT_CACHE_AUTO_EXPIRE_MS = 30 * 1000;
type PdfCacheOptions = { key?: string };
const createAutoExpiringPdfCache = (
  autoExpireMs = DEFAULT_CACHE_AUTO_EXPIRE_MS
): {
  getPdfLoadingTask: (
    fileUrl: string,
    options?: PdfCacheOptions
  ) => Promise<PDFDocumentLoadingTask>;
  getPdf: (
    fileUrl: string,
    options?: PdfCacheOptions
  ) => Promise<PDFDocumentProxy>;
  getPdfNumPages: (
    fileUrl: string,
    options?: PdfCacheOptions
  ) => Promise<number>;
  deletePdfCacheEntry: (
    fileUrl: string,
    options?: PdfCacheOptions
  ) => Promise<void>;
} => {
  const pdfDocumentLoadingTaskByFile = new Map<
    string,
    PDFDocumentLoadingTask
  >();
  const ongoingPdfLoadingTasks = new Map<
    string,
    Promise<PDFDocumentLoadingTask>
  >();
  const expirationTimersByFile = new Map<string, NodeJS.Timeout>();

  const contentLengthCache = new Map<string, number>();

  const deleteCachedPdf = (fileUrl: string): void => {
    const pdfLoadingTask = pdfDocumentLoadingTaskByFile.get(fileUrl);
    if (pdfLoadingTask === undefined) {
      return;
    }
    pdfDocumentLoadingTaskByFile.delete(fileUrl);
    pdfLoadingTask.destroy();
  };

  const resetPdfFileCacheExpiration = (fileUrl: string): void => {
    const timer = expirationTimersByFile.get(fileUrl);
    if (timer) {
      clearTimeout(timer);
    }
    expirationTimersByFile.set(
      fileUrl,
      setTimeout(() => {
        deleteCachedPdf(fileUrl);
      }, autoExpireMs)
    );
  };

  const getPdfLoadingTask = async (
    fileUrl: string,
    options?: PdfCacheOptions
  ): Promise<PDFDocumentLoadingTask> => {
    resetPdfFileCacheExpiration(fileUrl);
    const existingPdfLoadingTask = pdfDocumentLoadingTaskByFile.get(fileUrl);
    if (existingPdfLoadingTask) {
      return existingPdfLoadingTask;
    }

    const PDFJS = await importPDFJS();

    // Check for an existing in-flight calls to PDFJS.getDocument
    const ongoingPdfLoadingTask = ongoingPdfLoadingTasks.get(fileUrl);
    if (ongoingPdfLoadingTask !== undefined) {
      return ongoingPdfLoadingTask;
    }

    const getPdfLoadingTask = async () => {
      const pdfDocumentParams = await getDocumentParams({
        fileUrl,
        options,
        contentLengthCache,
      });
      const pdfLoadingTask = PDFJS.getDocument(pdfDocumentParams);
      pdfDocumentLoadingTaskByFile.set(fileUrl, pdfLoadingTask);
      return pdfLoadingTask;
    };
    const pdfLoadingTaskPromise = getPdfLoadingTask();
    ongoingPdfLoadingTasks.set(fileUrl, pdfLoadingTaskPromise);

    try {
      return await pdfLoadingTaskPromise;
    } finally {
      ongoingPdfLoadingTasks.delete(fileUrl);
    }
  };

  const getPdf = async (
    fileUrl: string,
    options?: PdfCacheOptions
  ): Promise<PDFDocumentProxy> =>
    (await getPdfLoadingTask(fileUrl, options)).promise;

  const getPdfNumPages = async (
    fileUrl: string,
    options?: PdfCacheOptions
  ): Promise<number> => {
    const pdf = await getPdf(fileUrl, options);
    return pdf.numPages;
  };

  const deletePdfCacheEntry = async (
    fileUrl: string,
    options?: PdfCacheOptions
  ): Promise<void> => {
    await getResponseCache().delete(fileUrl, options);
  };

  return { getPdfLoadingTask, getPdf, getPdfNumPages, deletePdfCacheEntry };
};

export default createAutoExpiringPdfCache;
