import type { Template, TemplateItem } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';

type MutationContext = {
  previousTemplate: Template;
  sliTimerStartTime: number;
};

export const useTemplateItemsUpdate = () => {
  const { templateService } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.auditTemplateItem);
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  return useMutation<
    TemplateItem[],
    Error,
    {
      templateItemsToUpsert: TemplateItem[];
    },
    MutationContext
  >(
    async ({ templateItemsToUpsert }) => {
      const newTemplateItemsToUpsert = templateItemsToUpsert.map((item) => {
        const {
          measurements: _measurements,
          conditionalActions: _conditionalActions,
          space: _space,
          ...templateItem
        } = item;
        return templateItem;
      });

      const batchedTemplateItems = chunk(
        newTemplateItemsToUpsert,
        dmsItemIngestionLimit
      );

      await Promise.all(
        batchedTemplateItems.map((nodes) =>
          templateService
            .updateTemplateItems(nodes, user!)
            .then((result) => result.data.items)
        )
      );

      return newTemplateItemsToUpsert;
    },
    {
      onMutate: async ({ templateItemsToUpsert }) => {
        const sliTimerStartTime = Date.now();
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        if (activeQuery.length > 0) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries([QueryKeys.TEMPLATE]);

          const [{ queryKey: activeQueryKey }] = activeQuery;

          // Snapshot the previous value
          const previousTemplate = queryClient.getQueryData(
            activeQueryKey
          ) as Template;

          const previousTemplateItems = previousTemplate?.templateItems ?? [];

          // Optimistically update to the new value
          const nextTemplateItems = [...previousTemplateItems];

          templateItemsToUpsert.forEach((templateItem) => {
            const index = previousTemplateItems.findIndex(
              (po) => po.externalId === templateItem.externalId
            );
            if (index === -1) {
              // Insert new operation
              nextTemplateItems.push(templateItem);
            } else {
              const previousOperation = previousTemplateItems.find(
                (prevOperation) =>
                  prevOperation.externalId === templateItem.externalId
              );
              // Update existing operation
              nextTemplateItems[index] = {
                ...previousOperation,
                ...templateItem,
              };
            }
          });

          const updatedTemplate = {
            ...previousTemplate,
            templateItems: nextTemplateItems,
          };

          queryClient.setQueryData(activeQueryKey, updatedTemplate);

          // Return a context object with the snapshotted value
          return { previousTemplate, sliTimerStartTime };
        }
      },
      onError: (err, _, context) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        // set cache back to its original state
        if (activeQuery.length > 0) {
          const [{ queryKey: activeQueryKey }] = activeQuery;

          queryClient.setQueryData(activeQueryKey, context?.previousTemplate);
        }

        makeToast({
          body: t(
            'UPSERT_TEMPLATE_ITEM_ERROR',
            'Failed to update template items'
          ),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.TEMPLATE_ITEM_UPDATE,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_ITEM_UPDATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE],
        });
      },
      onSuccess: async (_, { templateItemsToUpsert }, context) => {
        templateItemsToUpsert.forEach(() => {
          metrics.track('Update');
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_ITEM_UPDATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      mutationKey: [MutationKeys.TEMPLATE_ITEM_UPDATE],
    }
  );
};
