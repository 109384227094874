import type {
  AnnotationModel,
  AnnotationsFileLink,
  AnnotationsTypesDiagramsAssetLink,
} from '@cognite/sdk';

import type { UfvAnnotationMetadata } from '../../types';

export const getMetadataFromCogniteAnnotation = (
  annotation: AnnotationModel
): UfvAnnotationMetadata | undefined => {
  switch (annotation.annotationType) {
    case 'diagrams.FileLink': {
      const {
        pageNumber = 1,
        fileRef: { id = 0 },
      } = annotation.data as AnnotationsFileLink;

      return {
        page: pageNumber,
        resourceType: 'file',
        resourceId: id,
        status: annotation.status,
      };
    }

    case 'diagrams.AssetLink': {
      const {
        pageNumber = 1,
        assetRef: { id = 0 },
      } = annotation.data as AnnotationsTypesDiagramsAssetLink;
      return {
        page: pageNumber,
        resourceType: 'asset',
        resourceId: id,
        status: annotation.status,
      };
    }

    default: {
      return undefined;
    }
  }
};
