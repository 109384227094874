import type { GridFilterOptionsType } from '@cognite/cogs-lab';
import type { Filters } from '@cognite/fdm-client';
import type { UserProfileItem } from '@cognite/sdk';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useAppConfigFiltersContext } from '@infield/providers/app-config-filters-provider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { getChecklistsCreatedByFilterOptionsContext } from './use-checklists-createdby-filter-options.context';
import {
  combineChecklistFilters,
  getCreatedByFilterOptions,
} from './utils/filter-utils';
import { valueAsInstanceId } from './utils/type-utils';

export const useChecklistsCreatedByFilterOptions = (
  isDisabled: boolean,
  filter?: Filters
) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.user);

  const { useChecklistsAggregate, useUserProfilesByIdsQuery } = useContext(
    getChecklistsCreatedByFilterOptionsContext()
  );
  const { checklist: configFilter, notArchivedFilter } =
    useAppConfigFiltersContext();

  const combinedFilters = combineChecklistFilters(
    filter,
    configFilter,
    notArchivedFilter
  );

  const { data: createdByAggregate = [] } = useChecklistsAggregate({
    field: 'createdBy',
    isDirectRelation: true,
    isDisabled,
    filter: combinedFilters,
  });

  const userIds = createdByAggregate?.map(
    (item) => valueAsInstanceId(item.value)?.externalId
  );

  const { data: users } = useUserProfilesByIdsQuery(userIds);

  if (!users || users.length === 0) return [];

  const userIdToNameMap = users.reduce<Record<string, UserProfileItem>>(
    (acc, user) => {
      acc[user.userIdentifier] = user;
      return acc;
    },
    {}
  );

  const filterOptions: GridFilterOptionsType[] = getCreatedByFilterOptions(
    createdByAggregate,
    userIdToNameMap,
    t('CHECKLIST_TABLE_CREATED_BY_AUTOMATIC', 'Auto-generated')
  );

  return filterOptions;
};
