import type { AppConfig } from './types';

export const DEFAULT_CONFIG: AppConfig = {
  name: 'Default',
  externalId: '',
  createdOn: new Date(),
  isActive: true,
  isDefault: false,
  locationFiltersExternalId: '',
  appDataSpaceId: '', // Must be populated before use
  appDataSpaceVersion: '2', // To be removed
  customerDataSpaceId: '', // Must be populated before use
  customerDataSpaceVersion: '1', // To be removed
  viewMappings: {
    activity: {
      externalId: 'CogniteMaintenanceOrder',
      space: 'cdf_idm',
      type: 'view',
      version: 'v1',
    },
    operation: {
      externalId: 'CogniteOperation',
      space: 'cdf_idm',
      type: 'view',
      version: 'v1',
    },
    asset: {
      externalId: 'CogniteAsset',
      space: 'cdf_cdm',
      type: 'view',
      version: 'v1',
    },
    notification: {
      externalId: 'CogniteNotification',
      space: 'cdf_idm',
      type: 'view',
      version: 'v1',
    },
  },
  fieldConfiguration: {
    name: {
      alias: 'Activity title',
      displayInGantt: true,
      uiIcon: 'WorkOrders',
    },
    startTime: {
      alias: 'Start Time',
      approximateDataSize: 'small',
      dateFormat: 'DD/MM/YYYY',
      displayInSortDropdown: true,
    },
    endTime: {
      alias: 'End Time',
      approximateDataSize: 'small',
      dateFormat: 'DD/MM/YYYY',
      displayInSortDropdown: true,
    },
  },
  featureConfiguration: {
    timezoneConfiguration: {
      startOfWeek: 1,
    },
    gridZones2dConfiguration: {
      enabled: false,
    },
    psnConfiguration: {
      disabled: true,
    },
    subactivitiesConfiguration: {
      enabled: true,
    },
  },
  rootLocationsConfiguration: {
    locations: [],
  },
};
