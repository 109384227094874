import type { FdmFile, Observation } from '@cognite/apm-client';
import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import type { ExternalFileType } from '@cognite/cogs-lab';
import { FileUpload } from '@cognite/cogs-lab';
import { Avatar, Body, Button, Flex, LoaderIcon } from '@cognite/cogs.js-v10';
import { useAssetQuery } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  useDeleteMedia,
  useFetchImagesByUrl,
  useUploadMedia,
} from '@infield/features/media';
import { convertBytesToMb } from '@infield/features/media/media-manager/utils';
import type { ImageToUpload } from '@infield/features/media/types';
import {
  MEDIA_METADATA_PROP_POINTING_TO_OBSERVATION,
  OBSERVATION_FILE_SIZE_LIMIT_BYTES,
  type Step,
} from '@infield/features/observation';
import { MediaCollectionViewer } from '@infield/features/ui/media-collection-viewer';
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { v4 as uuid } from 'uuid';

import { ObservationStepWrapper } from '../elements';

import * as S from './elements';

interface Props {
  step?: Step;
  observation?: Observation;
  isLocked?: boolean;
  handleObservationUpdate: (data: Partial<Observation>) => void;
}

export const ObservationDesktopFileUploader: FC<Props> = ({
  step,
  observation,
  isLocked,
  handleObservationUpdate,
}) => {
  const { t: tFileUploader } = useTranslation(LOCIZE_NAMESPACES.fileUploader);
  const { t: tMedia } = useTranslation(LOCIZE_NAMESPACES.mediaManager);
  const { mutateAsync: upsertMedia, isLoading: isUpserting } = useUploadMedia();
  const { mutateAsync: deleteMedia, isLoading: isDeleting } = useDeleteMedia();

  const { data: classicAsset } = useAssetQuery(
    observation?.asset?.externalId ? observation?.asset?.externalId : undefined
  );

  const [error, setError] = useState<string | undefined>();
  const [selectedImageForPreview, setSelectedImageForPreview] = useState<
    InstanceOrExternalId | undefined
  >();
  const [uploadedFiles, setUploadedFiles] = useState<ExternalFileType[]>([]);
  const media = useFetchImagesByUrl(observation?.files);

  useEffect(() => {
    const observationFiles =
      observation?.files
        ?.filter((fdmFile) => fdmFile)
        .map((fdmFile) => {
          const file = new File([], fdmFile!.name ?? fdmFile!.externalId);
          const externalFileTypes: ExternalFileType = {
            id: fdmFile!.externalId,
            progress: 100,
            file: {
              ...file,
              name: fdmFile!.name!,
              size: Number.isNaN(Number(fdmFile!.metadata?.size))
                ? 0
                : Number(fdmFile!.metadata?.size),
            } as File,
          };

          return externalFileTypes;
        }) ?? [];
    setUploadedFiles(observationFiles);
  }, [observation]);

  const getMedia = (id?: string | number) => {
    const filteredMedia = media?.filter(({ externalId }) => externalId === id);
    return filteredMedia[0]?.dataUrl;
  };

  const handleUploadMedia = async (files: File[]) => {
    const newMedia: ImageToUpload[] = files.map((file) => {
      return {
        externalId: uuid(),
        name: file.name,
        url: file,
        metadata: {
          fileName: file.name,
          uploadedToUiTime: Date.now(),
          size: file.size,
          [MEDIA_METADATA_PROP_POINTING_TO_OBSERVATION]:
            observation?.externalId,
        },
      };
    });

    setUploadedFiles([
      ...uploadedFiles,
      ...newMedia.map((media) => ({
        id: media.externalId,
        file: media.url,
      })),
    ]);

    const newFdmFiles: FdmFile[] = newMedia.map((media) => ({
      externalId: media.externalId!,
    }));

    upsertMedia(
      {
        media: newMedia,
        assetIds: classicAsset ? [classicAsset.id] : undefined,
      },
      {
        onSuccess: () => {
          handleObservationUpdate({
            files: [...(observation?.files ?? []), ...newFdmFiles],
          });
        },
      }
    );
  };

  const handleRemoveFiles = async (fileIds: string[]) => {
    if (!fileIds || fileIds.length === 0) return;
    await deleteMedia(
      {
        fileInstanceIds: fileIds.map((externalId) => ({ externalId })),
      },
      {
        onSuccess: () => {
          const newFiles = observation?.files?.filter(
            (fdmFile) => !fileIds.includes(fdmFile?.externalId || '')
          );
          handleObservationUpdate({
            files: newFiles,
          });
        },
      }
    );
  };

  const handleRemoveFile = (file: File) => {
    const fileToDelete = uploadedFiles.find(
      (uploadedFiles) => uploadedFiles.file.name === file.name
    );
    if (!fileToDelete) return;
    handleRemoveFiles([String(fileToDelete.id)]);
  };

  return (
    <ObservationStepWrapper gap={6}>
      <Body size="medium" strong aria-required>
        {step?.name}
        {step?.isRequired && (
          <span className="cogs cogs-textlabel--required">*</span>
        )}
      </Body>
      <FileUpload
        id="file-upload"
        data-testid="observation-file-upload"
        multiple
        disabled={isLocked || isUpserting}
        required={step?.isRequired}
        title={tFileUploader(
          'FILE_UPLOADER_DRAG_AND_DROP_TITLE',
          'Drag & drop files here or click to select'
        )}
        description={tFileUploader(
          'FILE_UPLOADER_DRAG_AND_DROP_SUPPORTED_FILES_DESCRIPTION',
          'Supported files: {{supportedFiles}}',
          { supportedFiles: '.jpg, .jpeg, .png, .mp4, .webm' }
        )}
        onChange={(_event, files) => {
          const acceptableFiles = files.filter(
            (file) => file.size < OBSERVATION_FILE_SIZE_LIMIT_BYTES
          );
          handleUploadMedia(acceptableFiles);
          if (acceptableFiles.length !== files.length) {
            setError(
              tMedia(
                'MEDIA_MANAGER_FILE_SIZE_EXCEEDING_LIMIT_ERROR',
                'File exceeded {{limit}}MB size limit',
                { limit: convertBytesToMb(OBSERVATION_FILE_SIZE_LIMIT_BYTES) }
              )
            );
          }
        }}
        isValid={!error}
        error={error}
      />
      {uploadedFiles.length > 0 && (
        <>
          <Flex justifyContent="space-between">
            <Body size="medium" strong>
              {tFileUploader(
                'FILE_UPLOADER_UPLOADED_FILES_TITLE',
                'Uploaded files'
              )}
            </Body>
            <Button
              disabled={isLocked}
              type="ghost"
              size="small"
              onClick={() =>
                handleRemoveFiles(uploadedFiles.map(({ id }) => String(id)))
              }
              loading={isDeleting || isUpserting}
            >
              {tFileUploader('FILE_UPLOADER_CLEAR_ALL_BUTTON', 'Clear all')}
            </Button>
          </Flex>
          <S.UploadedItemsContainer
            direction="column"
            gap={12}
            $disabled={isLocked}
          >
            {uploadedFiles.map((uploadedFile) => (
              <FileUpload.UploadedItem
                key={uploadedFile.id}
                file={uploadedFile.file}
                icon={
                  <Avatar
                    key={uploadedFile.id}
                    image={getMedia(uploadedFile.id)}
                    onClick={() =>
                      uploadedFile.id &&
                      setSelectedImageForPreview({
                        externalId: String(uploadedFile.id),
                      })
                    }
                  />
                }
                onRemove={handleRemoveFile}
              />
            ))}
            {(isDeleting || isUpserting) && (
              <S.Overlay>
                <LoaderIcon className="cogs-icon--rotating" />
              </S.Overlay>
            )}
            {selectedImageForPreview && (
              <MediaCollectionViewer
                fileInstanceIds={[selectedImageForPreview]}
                onClose={() => setSelectedImageForPreview(undefined)}
                hideDeleteButton={isLocked}
              />
            )}
          </S.UploadedItemsContainer>
        </>
      )}
    </ObservationStepWrapper>
  );
};
