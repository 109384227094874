/* eslint-disable class-methods-use-this */
import { cogIdpAuthority, getClientId } from '@cognite/login-utils';

import {
  getAccessToken as getCogniteAccessToken,
  getUserInfo as getCogniteUserInfo,
  logout as cogniteLogout,
} from './cogIdp';
import { UserInfo } from './types';

export type TokenRetreivalOpts = {
  auth0_ignoreCache?: boolean;
};

export class FusionTokenProvider {
  getTokenOpts: TokenRetreivalOpts = {};

  getAppId() {
    return 'fusion.cognite.com';
  }

  setTokenRetreivalOpts(opts: TokenRetreivalOpts) {
    this.getTokenOpts = opts;
  }

  async getToken(): Promise<string> {
    const token = await getCogniteAccessToken({
      authority: cogIdpAuthority,
      clientId: getClientId(),
    });
    return token;
  }

  async getUserInformation(): Promise<UserInfo> {
    return getCogniteUserInfo({
      authority: cogIdpAuthority,
      clientId: getClientId(),
    });
  }

  async logout(): Promise<void> {
    await cogniteLogout({
      authority: cogIdpAuthority,
      clientId: getClientId(),
    });
  }
}
