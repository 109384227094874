import styled from 'styled-components';

import { Flex } from '@cognite/cogs.js-v10';

export const UploadedItemsContainer = styled(Flex)<{
  $disabled?: boolean;
}>`
  // until UploadedItem gets disabled state in the package
  position: relative;
  .cogs-lab-fileupload-uploaded-file {
    ${(props) =>
      props.$disabled
        ? 'background-color: var(--cogs-themed-surface--interactive--disabled);'
        : ''};
  }
  .cogs-lab-fileupload-remove-button {
    ${(props) => (props.$disabled ? 'display: none' : '')};
  }
`;

export const Overlay = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
