import type { Checklist } from '@cognite/apm-client';
import type { ChecklistItemStatusAggregation } from '@infield/features/checklist';

import { translateChecklistStatus } from '../utils';

import type { ChecklistDataGridRow } from './types';

export const convertChecklistToDataGridRow = (
  t: any,
  checklist: Checklist,
  aggregatedChecklistStatuses?: ChecklistItemStatusAggregation
): ChecklistDataGridRow => {
  return {
    id: checklist.externalId,
    checklists: [checklist],
    title: checklist.title ?? '-',
    startTime: checklist.startTime ?? '-',
    endTime: checklist.endTime ?? '-',
    type: checklist.type ?? '-',
    status: translateChecklistStatus(t, checklist.status),
    createdBy: checklist.createdBy?.externalId,
    completedChecklistItems: aggregatedChecklistStatuses?.completed,
    assigneesCount: checklist.assignedTo?.length,
  };
};
