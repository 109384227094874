import type { ViewConfigEntities } from '@cognite/apm-config';
import type { ViewReference } from '@cognite/sdk';
import type {
  AppConfig,
  RootLocationConfiguration,
} from '@infield/features/app-config/types';
import { removeEntityFromView } from '@infield/providers/is-idm-provider/utils/remove-entity-from-view';

import type { InfieldIdmConfig } from '../is-idm-provider/types';

import {
  APM_Activity,
  APM_Notification,
  APM_Operation,
  baseActivityViewReference,
  baseAssetViewReference,
  baseIdmActivityViewReference,
  baseIdmAssetViewReference,
  baseIdmNotificationViewReference,
  baseIdmOperationViewReference,
  baseIdmViewDictionary,
  baseNotificationViewReference,
  baseOperationViewReference,
} from './consts';

/**
 * Retrieves the source data instance space ID based on the provided configuration.
 *
 * @param {AppConfig} params.appConfig - The APM_Config application configuration or defaultAppConfig.
 * @param {boolean} params.isIdm - A flag indicating whether the app is running on a IDM project.
 * @param {InfieldIdmConfig} [params.location] - The IDM configuration based uppon the ADSL location configuration.
 * @param {RootLocationConfiguration} [params.selectedRootLocationConfigData] - The configuration data which is part of APM_Config where all location configurations for hybrid projects exist.
 * @returns {string} The source data instance space ID.
 */
export const getSourceDataInstanceSpaceId = ({
  appConfig,
  isIdm,
  location,
  selectedRootLocationConfigData,
}: {
  appConfig: AppConfig;
  isIdm: boolean;
  location?: InfieldIdmConfig;
  selectedRootLocationConfigData?: RootLocationConfiguration;
}): string => {
  const locationSpace = isIdm
    ? location?.instanceSpaces?.[0]
    : selectedRootLocationConfigData?.sourceDataInstanceSpace;
  return locationSpace ?? appConfig?.customerDataSpaceId;
};

const findViewInIdmConfig = (
  representsEntity: ViewConfigEntities,
  idmConfig?: InfieldIdmConfig
): ViewReference => {
  const view = removeEntityFromView(
    idmConfig?.views?.find((view) => view.representsEntity === representsEntity)
  );
  if (view) return { ...view, type: 'view' };
  return baseIdmViewDictionary[representsEntity];
};

const getViewsFromIdmConfig = (
  idmConfig?: InfieldIdmConfig
): Record<string, ViewReference> => {
  const activitiesView = findViewInIdmConfig('MAINTENANCE_ORDER', idmConfig);
  const operationsView = findViewInIdmConfig('OPERATION', idmConfig);
  const notificationsView = findViewInIdmConfig('NOTIFICATION', idmConfig);
  const assetsView = findViewInIdmConfig('ASSET', idmConfig);
  return { activitiesView, operationsView, notificationsView, assetsView };
};

const buildViewFromcustomerDataSpaceInApmConfig = (
  appConfig: AppConfig,
  viewExternalId: string
): ViewReference | undefined => {
  if (!appConfig?.customerDataSpaceId && !appConfig?.customerDataSpaceVersion) {
    return undefined;
  }
  return {
    externalId: viewExternalId,
    space: appConfig.customerDataSpaceId,
    version: appConfig.customerDataSpaceVersion,
    type: 'view',
  };
};

const getViewsFromApmConfig = (
  appConfig: AppConfig
): Record<string, ViewReference> => {
  const activitiesView =
    appConfig?.featureConfiguration?.viewMappings?.activity ||
    buildViewFromcustomerDataSpaceInApmConfig(appConfig, APM_Activity) ||
    baseActivityViewReference;
  const operationsView =
    appConfig?.featureConfiguration?.viewMappings?.operation ||
    buildViewFromcustomerDataSpaceInApmConfig(appConfig, APM_Operation) ||
    baseOperationViewReference;
  const notificationsView =
    appConfig?.featureConfiguration?.viewMappings?.notification ||
    buildViewFromcustomerDataSpaceInApmConfig(appConfig, APM_Notification) ||
    baseNotificationViewReference;
  const assetsView =
    appConfig?.featureConfiguration?.viewMappings?.asset ||
    baseAssetViewReference;
  return { activitiesView, operationsView, notificationsView, assetsView };
};

/**
 * A helper funciton that retrieves the source views to be used for quering activities, operations,
 *  notifications, assets. The function selects views from the APMconfig or IDM config based on
 * the isIdm flag, or provide default views if the config views are not defined, the four scenarios are:
 * @param {AppConfig} params.appConfig - The APM_Config application configuration or defaultAppConfig.
 * @param {boolean} params.isIdm - A flag indicating whether the app is running on a IDM project.
 * @param {InfieldIdmConfig} [params.location] - The IDM configuration based uppon the ADSL location configuration.
 * @returns {Record<string, Omit<ViewReference, 'type'>>} a record of the views that APM; config needs.
 */

export const getSourceViewsFromApmOrIdmConfig = ({
  appConfig,
  isIdm,
  idmConfig,
}: {
  appConfig: AppConfig;
  isIdm: boolean;
  idmConfig?: InfieldIdmConfig;
}): Record<string, ViewReference> => {
  return isIdm
    ? getViewsFromIdmConfig(idmConfig)
    : getViewsFromApmConfig(appConfig);
};
