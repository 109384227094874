import { DocumentStatus } from '../types';

export const getDocumentsWithEmbeddings = (
  documentStatuses: DocumentStatus[]
) =>
  documentStatuses
    .filter((status) => status.passages.available)
    .map((status) => status.id)
    // This should never happen, as it is only undefined
    // when a file isn't in queue yet we return a "waiting" state.
    // Ref https://cognitedata.slack.com/archives/C07MYAF0YM6/p1738785436642539?thread_ts=1738775802.056269&cid=C07MYAF0YM6
    .filter((id) => id !== undefined);
