import assertNever from '../utils/assertNever';

import CauseMapNodeSerializer from './CauseMapNodeSerializer';
import CommentSerializer from './CommentSerializer';
import EllipseSerializer from './EllipseSerializer';
import ImageSerializer from './ImageSerializer';
import LabelSerializer from './LabelSerializer';
import PolylineSerializer from './PolylineSerializer';
import RectangleSerializer from './RectangleSerializer';
import StickySerializer from './StickySerializer';
import SvgSerializer from './SvgSerializer';
import TextSerializer from './TextSerializer';
import { AnnotationType } from './types';

const getAnnotationSerializerByType = (
  annotationType: AnnotationType
):
  | typeof RectangleSerializer
  | typeof EllipseSerializer
  | typeof PolylineSerializer
  | typeof SvgSerializer
  | typeof TextSerializer
  | typeof ImageSerializer
  | typeof LabelSerializer
  | typeof StickySerializer
  | typeof CommentSerializer
  | typeof CauseMapNodeSerializer => {
  // TODO(FUS-000): Fix the typing here.
  if (annotationType === AnnotationType.RECTANGLE) {
    return RectangleSerializer;
  }

  if (annotationType === AnnotationType.ELLIPSE) {
    return EllipseSerializer;
  }

  if (annotationType === AnnotationType.POLYLINE) {
    return PolylineSerializer;
  }

  if (annotationType === AnnotationType.SVG) {
    return SvgSerializer;
  }

  if (annotationType === AnnotationType.TEXT) {
    return TextSerializer;
  }

  if (annotationType === AnnotationType.IMAGE) {
    return ImageSerializer;
  }

  if (annotationType === AnnotationType.LABEL) {
    return LabelSerializer;
  }

  if (annotationType === AnnotationType.STICKY) {
    return StickySerializer;
  }

  if (annotationType === AnnotationType.CAUSE_MAP_NODE) {
    return CauseMapNodeSerializer;
  }

  if (annotationType === AnnotationType.COMMENT) {
    return CommentSerializer;
  }

  assertNever(annotationType, 'AnnotationType not supported ' + annotationType);
};

export default getAnnotationSerializerByType;
