import React, { useCallback, useMemo, useRef, useState } from 'react';

import { LineChart, PlotRange, ValueType } from '@cognite/plotting-components';
import { useSDK } from '@cognite/sdk-provider';

import { TimeseriesCdmDatapoints } from '../../utils/timeseries/getTimeseriesCdmDatapoints';
import { GetTimeseriesChartData } from '../../utils/timeseries/getTimeseriesChartData';
import useStatusChange from '../ReactContainer/useStatusChange';

import formatDate from './formatDate';
import formatHoverLineInfo from './formatHoverLine';
import formatTooltipContent from './formatTooltipContent';
import { TimeseriesContentProps } from './TimeseriesContent';
import { useTimeseriesData } from './useTimeseriesData';
import { isNumericalValueArray } from './utils';

type TimeseriesGraphContentProps = TimeseriesContentProps;

const PADDING_PERCENTAGE = 0.1;
const formatDateRangeForAxis = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}): [string, string] => {
  return [formatDate(startDate), formatDate(endDate)];
};

const isCdmData = (
  data: TimeseriesCdmDatapoints | GetTimeseriesChartData
): data is TimeseriesCdmDatapoints => 'instanceId' in data;

const TimeseriesGraphContent: React.FC<TimeseriesGraphContentProps> = ({
  width,
  height,
  unscaledWidth,
  unscaledHeight,
  startDate,
  endDate,
  onRangeChange,
  instance,
  setLoadingStatus,
  numberOfPoints,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const handleRangeChange = useCallback(
    (plotRange: PlotRange) =>
      onRangeChange(new Date(plotRange.x[0]), new Date(plotRange.x[1])),
    [onRangeChange]
  );
  const [isCursorInContainer, setIsCursorInContainer] = useState(false);

  const sdk = useSDK();

  const timeseries = useTimeseriesData(instance, sdk, {
    startDate,
    endDate,
    numberOfPoints,
  });

  useStatusChange({
    data: timeseries.data,
    isLoading: timeseries.isLoading && timeseries.isFetching,
    isError: timeseries.isError,
    setLoadingStatus,
  });

  const yDataRange = useMemo((): [ValueType, ValueType] | undefined => {
    if (timeseries.data === undefined) {
      return;
    }

    const values = timeseries.data.data.y;
    if (
      values === undefined ||
      values.length === 0 ||
      !isNumericalValueArray(values)
    ) {
      return undefined;
    }
    const [minValue, maxValue] = [Math.min(...values), Math.max(...values)];
    const padding = PADDING_PERCENTAGE * (maxValue - minValue);
    return [minValue - padding, maxValue + padding];
  }, [timeseries.data]);

  const scale = Math.min(width / unscaledWidth, height / unscaledHeight);

  return (
    <>
      <div
        style={{ transform: `scale(${scale})`, transformOrigin: 'top left' }}
      >
        <div
          ref={containerRef}
          style={{
            boxSizing: 'border-box',
            width: unscaledWidth,
            height: unscaledHeight,
            cursor: 'default',
            border: '1px solid #D9D9D9',
          }}
          onMouseEnter={() => setIsCursorInContainer(true)}
          onMouseLeave={() => setIsCursorInContainer(false)}
        >
          {timeseries.data !== undefined && (
            <LineChart
              {...{
                data: timeseries.data.data,

                //base config for both sources
                config: {
                  scrollZoom: false,
                  buttonZoom: false,
                  pan: false,
                  selectionZoom: [{ trigger: 'default', direction: 'x' }],
                },
                // layout config for both sources
                layout: {
                  showTitle: false,
                  showActions: false,
                  showFilters: false,
                  showLegend: false,
                  showTooltip: isCursorInContainer,
                  showHoverLineInfo: isCursorInContainer,
                  showHoverLine: isCursorInContainer,
                  showHoverMarker: isCursorInContainer,
                  showMarkers: isCdmData(timeseries.data)
                    ? false
                    : timeseries.data.metadata.dataFetchMode === 'raw',
                },
                shouldRecursivelyApplyScale: true,
                isContinuousHoverWithoutDatapoint: false,
                yAxis: {
                  name: isCdmData(timeseries.data)
                    ? timeseries.data.unitExternalId
                    : timeseries.data.metadata.unit,
                },
                range: {
                  x: formatDateRangeForAxis({ startDate, endDate }),
                  y: yDataRange,
                },

                formatTooltipContent: (tooltipProps) =>
                  formatTooltipContent(
                    tooltipProps,
                    isCdmData(timeseries.data)
                      ? timeseries.data.unitExternalId
                      : timeseries.data.metadata.unit
                  ),
                formatHoverLineInfo: formatHoverLineInfo,
              }}
              onRangeChange={handleRangeChange}
            />
          )}
        </div>
      </div>
      {timeseries.isFetching && (
        <div
          data-testid="timeseries-loading-overlay"
          style={{
            position: 'absolute',
            inset: 0,
            background: 'rgba(255,255,255,0.7)',
          }}
        />
      )}
    </>
  );
};

export default TimeseriesGraphContent;
