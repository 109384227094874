import { ChevronDownIcon, Dropdown, Menu } from '@cognite/cogs.js-v10';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import type { NavigationItem } from '@infield/features/top-bar';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import type { FunctionComponent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as S from './elements';
import { NavigationTabButton } from './navigation-tab-button';

type Props = {
  navigationItems: NavigationItem[];
};

export const NavigationDesktop: FunctionComponent<Props> = ({
  navigationItems,
}) => {
  const { isOnline } = useNetworkStatusContext();

  const metrics = useMetrics(METRICS_NAMESPACES.navigation);
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigateClick = (newRoute: string) => {
    metrics.track(newRoute);
    navigate(newRoute);
  };

  const getIsSelected = (to: string) => location.pathname.includes(to);

  if (navigationItems.length === 0) return null;

  return (
    <S.NavigationWrapper
      data-testid="desktop-navigation-buttons"
      data-chmln="infield-beginning-6581b8b08073d100194fb218-step-1"
    >
      {navigationItems.map((navigationItem) => {
        if (navigationItem.children)
          return (
            <NavigationDropdown
              key={navigationItem.key}
              navigationItem={navigationItem}
            />
          );
        return (
          <NavigationTabButton
            key={navigationItem.key}
            navigationItem={navigationItem}
            handleNavigateClick={handleNavigateClick}
            isSelected={getIsSelected(navigationItem.to)}
            isOnline={isOnline}
          />
        );
      })}
    </S.NavigationWrapper>
  );
};

type NavigationDropdownProps = {
  navigationItem: NavigationItem;
};

const NavigationDropdown: FunctionComponent<NavigationDropdownProps> = ({
  navigationItem,
}) => {
  const metrics = useMetrics(METRICS_NAMESPACES.navigation);
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigateClick = (newRoute: string) => {
    metrics.track(newRoute);
    navigate(newRoute);
  };
  const getIsSelected = (to: string) => location.pathname.includes(to);

  return (
    <Dropdown
      key={navigationItem.name}
      content={
        <Menu>
          {navigationItem.children
            ? navigationItem.children.map((child) => (
                <Menu.Item
                  key={child.name}
                  onClick={() => handleNavigateClick(child.to)}
                  toggled={getIsSelected(child.to)}
                >
                  {child.name}
                </Menu.Item>
              ))
            : null}
        </Menu>
      }
    >
      <S.NavigationButton
        toggled={navigationItem.children?.some((child) =>
          getIsSelected(child.to)
        )}
        icon={<ChevronDownIcon />}
        iconPlacement="right"
      >
        {navigationItem.name}
      </S.NavigationButton>
    </Dropdown>
  );
};
