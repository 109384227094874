import type {
  ChecklistItemStatus,
  InFieldUser,
  Measurement,
} from '@cognite/apm-client';

const isInValidNumber = (value: any) => {
  if (value === undefined || value === null) return true;

  return Number.isNaN(value);
};

export const isBelowMin = (
  measurement: Measurement,
  numericReading?: number | string | null
) =>
  isInValidNumber(numericReading) || isInValidNumber(measurement.min)
    ? false
    : Number(numericReading) < measurement.min!;

export const isAboveMax = (
  measurement: Measurement,
  numericReading?: number | string | null
) =>
  isInValidNumber(numericReading) || isInValidNumber(measurement.max)
    ? false
    : Number(numericReading) > measurement.max!;

const isInsideRange = (measurement: Measurement) =>
  !isInValidNumber(measurement.numericReading) &&
  !isBelowMin(measurement, measurement.numericReading) &&
  !isAboveMax(measurement, measurement.numericReading);

const getNumericalMeasurementStatus = (
  measurements: Measurement[]
): ChecklistItemStatus | undefined => {
  return measurements.every((numericalMeasurement) => {
    if (
      (numericalMeasurement.type === 'numerical' &&
        isInsideRange(numericalMeasurement)) ||
      numericalMeasurement.type === 'label'
    ) {
      return true;
    }

    return false;
  })
    ? 'Ok'
    : 'Not ok';
};

// Get the status of single string measurement
const getStringMeasurementStatus = (
  measurement: Measurement
): ChecklistItemStatus | undefined => {
  const haveStatusOption = measurement.options?.some((option) =>
    Boolean(option.status)
  );

  // If there is no status in the check item, every string measurement is Ok
  if (!haveStatusOption) {
    return 'Ok';
  }

  const status = measurement.options?.find(
    (option) => option.label === measurement.stringReading
  )?.status;

  return status;
};

// Get the combined status of all string measurements
const getStringMeasurementsStatus = (
  measurements: Measurement[]
): ChecklistItemStatus | undefined => {
  const uniqueStatusList = [
    ...new Set(measurements.map(getStringMeasurementStatus)),
  ];

  if (uniqueStatusList.length === 0) {
    return undefined;
  }

  if (uniqueStatusList.length === 1) {
    return uniqueStatusList[0];
  }

  // If there are 2 status and one of them is Ok, we want to set the other status
  if (uniqueStatusList.includes('Ok') && uniqueStatusList.length === 2) {
    return uniqueStatusList.find((status) => status !== 'Ok');
  }

  return 'Not ok';
};

export const getChecklistStatusOnMeasurementUpdate = (
  measurements: Measurement[],
  newMeasurement: Measurement
) => {
  const updatedMeasurements = measurements.map((measurement) => {
    if (measurement.externalId === newMeasurement.externalId) {
      return newMeasurement;
    }
    return measurement;
  });

  const isEveryMeasurementHaveValue = updatedMeasurements.every(
    (measurement) => {
      if (measurement.type === 'numerical') {
        return !isInValidNumber(measurement.numericReading);
      }

      return Boolean(measurement.stringReading);
    }
  );

  // If there is no value in any measurement, we don't want to update status
  if (!isEveryMeasurementHaveValue) {
    return undefined;
  }

  const numericalMeasurements = updatedMeasurements?.filter(
    (measurement) => measurement.type === 'numerical'
  );

  const stringMeasurements = updatedMeasurements?.filter(
    (measurement) => measurement.type !== 'numerical'
  );

  const numericalMeasurementStatus = getNumericalMeasurementStatus(
    numericalMeasurements
  );

  const stringMeasurementStatus =
    getStringMeasurementsStatus(stringMeasurements);

  // There is no numericalMeasurement we set the status of string measurement
  if (!numericalMeasurementStatus && numericalMeasurements.length === 0) {
    return stringMeasurementStatus;
  }

  // There is no string measurement we set the status of numerical measurement
  if (!stringMeasurementStatus && stringMeasurements?.length === 0) {
    return numericalMeasurementStatus;
  }

  if (numericalMeasurementStatus === 'Ok') {
    return stringMeasurementStatus;
  }

  return 'Not ok';
};

export const getShouldShowAvatar = (updatedBy?: InFieldUser): boolean => {
  if (!updatedBy) {
    return false;
  }
  // TODO: Remove this once the data is cleaned up [FO-758]
  if (updatedBy.name === 'Scheduling function') {
    return false;
  }

  return true;
};
