import { Button, CloseIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { ComponentErrorBoundary } from '@infield/features/ui/component-error-boundary';
import type { FC } from 'react';

import type { AssetInfoBaseProps } from '../types';

import { AssetInfoMobile } from './asset-info-mobile/asset-info-mobile';
import * as S from './elements';

// displays bottom panel for asset 3d page

export const AssetInfoMobileContainer: FC<AssetInfoBaseProps> = ({
  onClose,
  ...props
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.threeD);

  return (
    <S.MobileAssetInfoSection>
      <S.AssetInfoWrapper>
        <ComponentErrorBoundary sentryTag="AssetInfoMobile">
          <AssetInfoMobile {...props} />
        </ComponentErrorBoundary>
      </S.AssetInfoWrapper>
      <Button
        onClick={onClose}
        icon={<CloseIcon />}
        aria-label={t('THREE_D_ASSET_INFO_CLOSE', 'Close')}
      />
    </S.MobileAssetInfoSection>
  );
};
