import type { FC } from 'react';

import dayjs from 'dayjs';

import { DataGrid } from '@cognite/cogs-lab';
import { Button, DeleteIcon, EditIcon, Flex } from '@cognite/cogs.js-v10';

import { LocationFilterDTO } from '../../../sdk/ads-location-filters-sdk/types';

interface Props {
  locations: LocationFilterDTO[];
  onEdit?: (location: LocationFilterDTO) => void;
  onDelete?: (location: LocationFilterDTO) => void;
  onSelectLocation?: (location: LocationFilterDTO) => void;
}

export const ADSLocationList: FC<Props> = ({
  locations,
  onEdit,
  onDelete,
  onSelectLocation,
}) => {
  return (
    <DataGrid
      density="comfortable"
      style={{ padding: '40px 60px' }}
      data={locations ?? []}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          flex: 2,
          sortable: false,
          filter: {
            isSearchable: true,
            options: locations.map((location) => ({
              label: location.name,
              value: location.name,
            })),
          },
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 3,
          sortable: false,
        },
        {
          field: 'createdTime',
          headerName: 'Created time',
          flex: 1,
          sortable: false,
          renderCell: (params) => dayjs(params.value).format('ll'),
        },
        {
          field: 'lastUpdatedTime',
          headerName: 'Last updated time',
          flex: 1,
          sortable: false,
          renderCell: (params) => dayjs(params.value).format('ll'),
        },
        {
          field: 'actions',
          headerName: 'Actions',
          flex: 1,
          sortable: false,
          renderCell: (params) => {
            return (
              <Flex gap={16}>
                <Button
                  icon={<EditIcon />}
                  size="small"
                  onClick={(e) => {
                    onEdit?.(params.row);
                    e.stopPropagation();
                  }}
                />
                <Button
                  icon={<DeleteIcon />}
                  size="small"
                  type="destructive"
                  onClick={(e) => {
                    onDelete?.(params.row);
                    e.stopPropagation();
                  }}
                />
              </Flex>
            );
          },
        },
      ]}
      onRowClick={(params) => {
        onSelectLocation?.(params.row);
      }}
      pagination={false}
    />
  );
};
