import type { FdmFile, Observation } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { convertFilesToCorrectFormat } from './utils';

export const useObservationUpsertMutation = () => {
  const { observationService } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const { user } = useCurrentUserContext();
  const metrics = useMetrics(METRICS_NAMESPACES.autitObservation);

  return useMutation<Observation, Error, { observation: Observation }>(
    async ({ observation }) => {
      const {
        createdTime: _createdTime,
        lastUpdatedTime: _lastUpdatedTime,
        space: _space,
        files,
        ...restObservation
      } = observation;

      // When we update files we should use string[] format even though we receive and store files in FdmFile[] format
      // more on this - https://cognitedata.slack.com/archives/C03G11UNHBJ/p1692649029277259
      const convertedFiles = convertFilesToCorrectFormat(files) as
        | FdmFile[]
        | undefined;
      await observationService.updateObservations(
        [{ ...restObservation, files: convertedFiles }],
        user!
      );

      return observation;
    },
    {
      onMutate: async ({ observation }) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey for activity list
        const activeObservationListQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.OBSERVATION],
        });

        if (activeObservationListQuery.length > 0) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries([QueryKeys.OBSERVATION]);

          const [{ queryKey: activeQueryKey }] = activeObservationListQuery;

          // Snapshot the previous value
          const previousObservationList = queryClient.getQueryData(
            activeQueryKey
          ) as Observation[];

          if (previousObservationList.length > 0) {
            const nextObservationList = previousObservationList.map(
              (prevObservation) =>
                prevObservation.externalId === observation.externalId
                  ? { ...prevObservation, ...observation }
                  : prevObservation
            );

            queryClient.setQueryData(activeQueryKey, nextObservationList);
          }

          return previousObservationList;
        }
      },
      onError: (err, _, previousObservationList) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey for observation query
        const activeObservationQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.OBSERVATION],
        });

        // set cache back to its original state
        if (activeObservationQuery.length > 0) {
          const [{ queryKey: activeQueryKey }] = activeObservationQuery;

          queryClient.setQueryData(activeQueryKey, previousObservationList);
        }

        makeToast({
          body: t('OBSERVATION_UPSERT_ERROR', `Unable to update observation.`),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-observation-upsert-mutation',
          },
        });
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.OBSERVATION],
        });
        queryClient.removeQueries({
          queryKey: [QueryKeys.OBSERVATION_INFINITE],
          type: 'all',
        });

        queryClient.invalidateQueries({
          queryKey: [QueryKeys.CHECKLIST],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.CHECKLIST_ITEM_OBSERVATIONS],
        });
      },
      onSuccess: () => {
        metrics.track('Update');
      },
    }
  );
};
