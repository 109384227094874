import { useAuthContext } from '@cognite/e2e-auth';
import { useMetrics } from '@cognite/metrics';
import type { UserProfileItem } from '@cognite/sdk-beta';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { getUniqueIds } from './utils/utils';

export const useUserProfilesByIdsQuery = (userIds: (string | undefined)[]) => {
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);
  const { client } = useAuthContext();

  const uniqueUserIds = getUniqueIds(userIds);
  const sliTimerStartTime = useRef<number | undefined>(undefined);

  const query = useQuery<UserProfileItem[]>({
    queryKey: [QueryKeys.USER_PROFILES_BY_IDS, 'byIds', uniqueUserIds],
    queryFn: async () => {
      sliTimerStartTime.current = Date.now();
      try {
        const userIdItems = uniqueUserIds.map((userId) => ({
          userIdentifier: userId,
        }));
        const userProfiles = await client.profiles.retrieve(userIdItems);
        return userProfiles || []; // Ensure a valid response
      } catch (error) {
        return [];
      }
    },
    enabled: Boolean(uniqueUserIds) && uniqueUserIds.length > 0,
    onSuccess: () => {
      const sliTimerEndTime = Date.now();
      sliMetrics.track(QueryKeys.USER_PROFILES_BY_IDS, {
        sliTimerMilliseconds: sliTimerStartTime.current
          ? sliTimerEndTime - sliTimerStartTime.current
          : undefined,
        status: 'success',
        networkSpeedMbps: navigator.connection?.downlink,
      });
    },
    onError: (error) => {
      const sliTimerEndTime = Date.now();
      sliMetrics.track(QueryKeys.USER_PROFILES_BY_IDS, {
        sliTimerMilliseconds: sliTimerStartTime.current
          ? sliTimerEndTime - sliTimerStartTime.current
          : undefined,
        status: 'error',
        networkSpeedMbps: navigator.connection?.downlink,
      });
      // Log to Sentry
      captureException(error, {
        level: 'error',
        tags: {
          queryKey: QueryKeys.USER_PROFILES_BY_IDS,
        },
      });
    },
  });

  return { data: query.data };
};
