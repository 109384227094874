/* eslint-disable max-classes-per-file */
import mixpanel, {
  Dict,
  OutTrackingOptions,
  InTrackingOptions,
  HasOptedInOutOptions,
  Mixpanel,
} from 'mixpanel-browser';

import { ConsoleDebugger, NoopDebugger } from './debuggers';
import {
  Properties,
  ITimer,
  Callback,
  InitOptions,
  MetricsDebugger,
} from './types';

const globalProperties: Properties = {};

// See `preventOAuthCodeLeakageInProperties`.
const forbiddenQueryParameters = ['code', 'scope', 'state'];
const currentUrlKey = '$current_url';
const trackPropertiesKey = 'track_properties';

declare let window: any;

// The query parameters `code`, `scope`, and `state` are used in the OAuth callback route. The `code` parameter is
// particularly sensitive, as it contains the authorization code that will be exchanged for the OAuth tokens, so we
// need to prevent Mixpanel from leaking them (the library automatically adds the current URL to events). Because
// CogIdP uses PKCE, leaking the authorization code is not quite as disastrous as it sounds, as the code is useless
// without the "code challenge", which has not at this point been revealed. Still, leaking it does reduce the security
// of our system, so we want to remove the OAuth parameters from the URL. For now, we're universally filtering them out
// (so that they won't be leaked again if the callback route changes); if it turns out that other routes use them too,
// and we want them to be logged there, we'll need to add route-specific filtering.
//
// Algorithm: if `properties` already contains "$current_url", that is taken to be the current page URL; otherwise,
// `window.location` is used. If none of the OAuth parameters are present there, `properties` is returned unmodified.
// Otherwise, the property "$current_url" will be added to a copy of `properties` (or to a new object if it's
// undefined), with a value equal to the current page URL minus the OAuth parameters, and the copy is returned.
function preventOAuthCodeLeakageInProperties(
  properties?: Properties
): Properties | undefined {
  const originalUrl =
    properties?.[currentUrlKey] || Metrics.getWindowLocationHref();
  const url = new URL(originalUrl);
  forbiddenQueryParameters.forEach((p) => url.searchParams.delete(p));
  const cleanedUrl = url.href;
  if (cleanedUrl !== originalUrl) {
    return {
      ...properties,
      [currentUrlKey]: cleanedUrl,
    };
  } else {
    return properties;
  }
}

// Similar to `preventOAuthCodeLeakageInProperties`, but operates on an `options` object that may or may not contain a
// "track_properties" property that will be subjected to the same treatment as in that function. If the current page
// URL contains any of the OAuth parameters, the return value is a copy of the `options` object (or a new object if
// it's undefined) with an additional (or copied-and-updated) "track_properties" property with a nested "$current_url"
// property that contains a cleaned version of the current page URL (other properties in `options` or the nested
// "track_properties" will remain); otherwise, the original `options` is returned.
function preventOAuthCodeLeakageInOptions(
  options?: Properties
): Properties | undefined {
  const trackProperties = preventOAuthCodeLeakageInProperties(
    options?.[trackPropertiesKey]
  );
  if (trackProperties) {
    return {
      ...options,
      [trackPropertiesKey]: trackProperties,
    };
  } else {
    return options;
  }
}

class Timer implements ITimer {
  private timerEvent = '';

  private startProps: Properties = {};

  constructor(event: string, startProps: Properties) {
    this.timerEvent = event;
    this.startProps = startProps;
    try {
      mixpanel.time_event(event);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Unable to track ${this.timerEvent}`);
    }
  }

  public stop(properties: Properties = {}, callback?: Callback) {
    try {
      const combined = {
        ...globalProperties,
        ...this.startProps,
        ...properties,
      };
      // Mixpanel modifies their params, so spread the props.
      mixpanel.track(
        this.timerEvent,
        preventOAuthCodeLeakageInProperties({ ...combined }),
        callback
      );
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      Metrics.DEBUGGER.stop(this.timerEvent, combined);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Unable to track ${this.timerEvent}`);
    }
  }
}

class Metrics {
  static DEBUGGER: MetricsDebugger = NoopDebugger;

  private readonly className: string | undefined;

  private readonly properties: Properties = {};

  public static init({
    mixpanelToken,
    debug,
    metricsDebugger,
    persistence,
    loaded,
    ...properties
  }: InitOptions): void {
    if (!mixpanelToken) {
      throw new Error('Missing mixpanelToken parameter');
    }

    mixpanel.init(mixpanelToken, {
      persistence: persistence === 'cookie' ? 'cookie' : 'localStorage',
      loaded: (mixpanel: Mixpanel) => {
        if (window && window.mixpanel === undefined) {
          window.mixpanel = mixpanel;
        }
        if (loaded) {
          loaded(mixpanel);
        }
      },
    });

    if (metricsDebugger) {
      Metrics.DEBUGGER = metricsDebugger;
    } else if (
      debug === true ||
      debug === 'true' ||
      process.env.REACT_APP_DEBUG_METRICS === 'true'
    ) {
      Metrics.DEBUGGER = ConsoleDebugger;
    }

    Metrics.props({
      // Log this by default to be able to separate the metrics.
      environment:
        process.env.REACT_APP_ENV || process.env.NODE_ENV || 'unknown',
      // These are auto-populated when built with FAS.
      releaseId: process.env.REACT_APP_RELEASE_ID || 'unknown-release',
      applicationId: process.env.REACT_APP_APP_ID || 'unknown-app',
      versionName: process.env.REACT_APP_VERSION_NAME || '0.0.0',
      ...properties,
    });
  }

  public static optOut(options?: Partial<OutTrackingOptions>): void {
    mixpanel.opt_out_tracking(preventOAuthCodeLeakageInOptions(options));
  }

  public static optIn(options?: Partial<InTrackingOptions>): void {
    mixpanel.opt_in_tracking(preventOAuthCodeLeakageInOptions(options));
  }

  public static hasOptedOut(options?: Partial<HasOptedInOutOptions>): boolean {
    return mixpanel.has_opted_out_tracking(
      preventOAuthCodeLeakageInOptions(options)
    );
  }

  public static props(properties: Properties): void {
    Object.keys(properties)
      .filter((key) => key !== 'mixpanelToken' && key !== 'debug')
      .forEach((key) => {
        const value = properties[key] || null;
        switch (typeof value) {
          case 'string':
          case 'number':
          case 'boolean':
          case 'undefined':
            globalProperties[key] = value;
            break;
          default:
            if (value === null) {
              // typeof null is 'object', so it needs to be special-cased.
              globalProperties[key] = value;
            } else {
              /* eslint-disable-next-line no-console */
              console.warn(
                `Not adding { "${key}": "${
                  Metrics.DEBUGGER.isDebug ? value : '<value>'
                }" } to the metrics. Only simple data types are supported.`
              );
              delete globalProperties[key];
              break;
            }
        }
      });
  }

  public static identify(uid: string): void {
    mixpanel.identify(uid);
  }

  public static people(info: Dict): void {
    mixpanel.people.set(info);
  }

  public static create(className?: string, properties?: Properties): Metrics {
    return new Metrics(className, properties, false);
  }

  public static stop(
    possibleTimer: ITimer,
    properties: Properties = {},
    callback?: Callback
  ): void {
    if (Metrics.DEBUGGER.isDebug || (possibleTimer && possibleTimer.stop)) {
      possibleTimer.stop(properties, callback);
    }
  }

  private constructor(
    className?: string,
    properties: Properties = {},
    deprecated = true
  ) {
    if (deprecated === true) {
      // eslint-disable-next-line no-console
      console.warn(
        'new Metrics(..) has been deprecated; please use Metrics.create(..) instead.'
      );
    }

    this.className = className;
    this.properties = { ...properties };
  }

  private getEventString(name: string): string {
    if (this.className) {
      return `${this.className}.${name}`;
    }
    return name;
  }

  public start(name: string, properties: Properties = {}): ITimer {
    return new Timer(this.getEventString(name), {
      ...this.properties,
      ...properties,
    });
  }

  public track(
    name: string,
    properties: Properties = {},
    callback?: Callback
  ): void {
    const combined = { ...globalProperties, ...this.properties, ...properties };
    try {
      const event = this.getEventString(name);
      // Mixpanel modifies their params, so spread the props.
      mixpanel.track(
        event,
        preventOAuthCodeLeakageInProperties({ ...combined }),
        callback
      );
      Metrics.DEBUGGER.track(event, combined);
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.warn(`Unable to track ${this.className}.${name}`);
    }
  }

  // For mockability.
  public static getWindowLocationHref(): string {
    return window.location.href;
  }
}

export default Metrics;
