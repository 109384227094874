import { useMemo } from 'react';

import { CogIdPClient } from '../lib/client';

export function useCogIdPClient(
  org: string,
  getToken: () => Promise<string>
): CogIdPClient {
  const client = useMemo(
    () => new CogIdPClient(org, getToken),
    [org, getToken]
  );
  return client;
}
