import groupBy from 'lodash/groupBy';

import {
  Button,
  ChevronRightSmallIcon,
  Loader,
  LogoutIcon,
  Overline,
} from '@cognite/cogs.js-v10';

import type { Project } from '..';
import { parseApiUrlToName } from '../utils';

import { Container, LoginForm, ProjectList } from './elements';

export const MOST_RECENT_PROJECT_SELECTED_KEY = 'MOST_RECENT_PROJECT_SELECTED';

type ProjectSelectionProps = {
  onLogout: () => void;
  onProjectSelect?: (project: Project) => void;
  error?: string;
  isLoading?: boolean;
  projects?: Project[];
};

export const ProjectSelectionScreen = ({
  onLogout,
  onProjectSelect,
  error,
  isLoading,
  projects,
}: ProjectSelectionProps) => {
  const handleProjectSelect = (project: Project) => {
    localStorage.setItem(MOST_RECENT_PROJECT_SELECTED_KEY, project.name);
    onProjectSelect?.(project);
  };

  if (isLoading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (projects === undefined || projects.length === 0) {
    return (
      <Container>
        <LoginForm>
          <header>
            <h2>Select a project</h2>
          </header>
          {error && <div className="error">No projects available</div>}

          <Button type="secondary" onClick={() => onLogout()}>
            Logout
          </Button>
        </LoginForm>
      </Container>
    );
  }

  return (
    <Container>
      <LoginForm>
        <header>
          <h1>Select a project to continue</h1>
        </header>
        {error && <div className="error">{error}</div>}
        {Object.entries(groupBy(projects, 'apiUrl')).map(
          // TODO(FUS-000): Disabling so we can enable linting
          // eslint-disable-next-line @typescript-eslint/no-shadow
          ([apiUrl, projects]) => (
            <ProjectList key={apiUrl}>
              <Overline size="medium">{parseApiUrlToName(apiUrl)}</Overline>
              {projects.map((project) => (
                <Button
                  icon={<ChevronRightSmallIcon />}
                  iconPlacement="right"
                  key={project.name}
                  type="secondary"
                  style={{ width: '100%', justifyContent: 'space-between' }}
                  onClick={() => handleProjectSelect(project)}
                >
                  {project.name}
                </Button>
              ))}
            </ProjectList>
          )
        )}
        <Button
          type="tertiary"
          style={{ alignSelf: 'flex-end' }}
          icon={<LogoutIcon />}
          iconPlacement="right"
          onClick={() => onLogout()}
        >
          Logout
        </Button>
      </LoginForm>
    </Container>
  );
};

export default ProjectSelectionScreen;
