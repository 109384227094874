import { Modal } from '@cognite/cogs-core';
import { FallbackContent } from '@infield/providers/error-boundary-provider/fallback-content';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import { ComponentErrorBoundary } from '..';

import * as S from './elements';

interface Props {
  children: ReactNode;
  sentryTag: string;
  onClose: () => void;
}

export const ComponentErrorBoundaryModal = ({
  children,
  sentryTag,
  onClose,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isModalOpen) {
    return (
      <Modal
        visible
        onCancel={() => {
          onClose();
          setIsModalOpen(false);
        }}
        hideFooter
      >
        <S.FallbackContentContainer data-testid="error-boundary-fallback-modal">
          <FallbackContent />
        </S.FallbackContentContainer>
      </Modal>
    );
  }

  return (
    <ComponentErrorBoundary
      sentryTag={sentryTag}
      onError={() => setIsModalOpen(true)}
    >
      {children}
    </ComponentErrorBoundary>
  );
};
