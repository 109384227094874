import type { Observation } from '@cognite/apm-client';

export const isObservationMediaLocked = (
  mediaExternalId: string,
  observations: Observation[]
): boolean => {
  const observation = observations.find((observation) =>
    observation.files?.some(
      (file) => file && file.externalId === mediaExternalId
    )
  );
  return (
    !observation ||
    observation.status === 'Sent' ||
    observation.status === 'File not sent'
  );
};
