import type { FdmFile } from '@cognite/apm-client';
import { sleep } from '@cognite/lodashy';
import type { CogniteClient, ItemsResponse } from '@cognite/sdk';
import { getBaseUrlFromClient } from '@infield/utils/cognite-functions';

import type {
  SapAttachmentRequest,
  SapObservation,
  SapRequestItem,
  SapResponse,
} from './types';

const DEFAULT_HEADERS = { 'cdf-version': 'beta' };

const retrieveWriteBackResponse = async <SapResponse>(
  client: CogniteClient,
  requestId: string
): Promise<SapResponse> => {
  const res = await client.post<ItemsResponse<SapResponse>>(
    `${getBaseUrlFromClient(client)}/writeback/sap/requests/byids`,
    {
      headers: DEFAULT_HEADERS,
      data: {
        items: [
          {
            requestId,
          },
        ],
      },
    }
  );

  if (!res.data || !res.data.items.length) {
    throw new Error(`Write back call '${requestId}' did not have a response`);
  }
  return res.data.items[0] as SapResponse;
};

const getWriteBackResults = async <Response>(
  client: CogniteClient,
  requestId: string,
  numberOfTries = 10
): Promise<SapResponse> => {
  if (numberOfTries < 1) {
    throw new Error(`Write back call exceeded retry limit`);
  }
  await sleep(2000);
  const requestResponse = await retrieveWriteBackResponse<SapResponse>(
    client,
    requestId
  );
  const responseStatus = requestResponse.status;
  if (responseStatus === 'in_progress' || responseStatus === 'pending') {
    return getWriteBackResults<Response>(client, requestId, numberOfTries - 1);
  } else if (responseStatus === 'done') {
    return requestResponse;
  } else if (responseStatus === 'failed') {
    throw new Error(`Write back call '${requestId}' failed`);
  } else {
    throw new Error(`Write back call '${requestId}' timed out`);
  }
};

export const sendFilesToSAP = async (
  client: CogniteClient,
  observations: SapObservation[],
  item: SapRequestItem,
  endpointExternalId?: string
) => {
  const filesToSend = observations
    .find(({ externalId }) => externalId === item.key)
    ?.files?.filter(Boolean) as FdmFile[];

  const filesRequestMap: SapAttachmentRequest[] = filesToSend.map((file) => ({
    payload: { sourceId: item.sapObjectId },
    fileId: file.externalId,
  }));

  // Currently, files must be sent one by one as the service doesn't support batch uploads yet.
  // This will be updated later.
  const filesRequestInfo = await Promise.all(
    filesRequestMap.map((fileRequest) => {
      return client.post<ItemsResponse<{ requestId: string }>>(
        `${getBaseUrlFromClient(client)}/writeback/sap/requests`,
        {
          headers: DEFAULT_HEADERS,
          data: {
            items: [
              {
                endpointId: endpointExternalId,
                request: [fileRequest],
              },
            ],
          },
        }
      );
    })
  );

  const filesRequestResults = await Promise.all(
    filesRequestInfo.map((requestInfo) => {
      return getWriteBackResults(client, requestInfo.data.items[0].requestId);
    })
  );

  return filesRequestResults;
};

export const sendObservationsToSAP = async (
  client: CogniteClient,
  endpointExternalId?: string,
  observations: SapObservation[] = []
): Promise<SapResponse> => {
  const observationsToSend = observations.map(
    ({ externalId, files: _files, ...restOfObservation }) => ({
      key: externalId,
      payload: {
        ...restOfObservation,
      },
    })
  );

  const notificationsRequestInfo = await client.post<
    ItemsResponse<{ requestId: string }>
  >(`${getBaseUrlFromClient(client)}/writeback/sap/requests`, {
    headers: DEFAULT_HEADERS,
    data: {
      items: [
        {
          endpointId: endpointExternalId,
          request: observationsToSend,
        },
      ],
    },
  });

  const notificationsRequestResults = await getWriteBackResults(
    client,
    notificationsRequestInfo.data.items[0].requestId
  );

  return notificationsRequestResults;
};
