import { Button, PromoChip } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const NavigationWrapper = styled.div`
  margin: auto 0;
  display: flex;
  align-items: center;
`;

export const NavigationButton = styled(Button).attrs({
  type: 'ghost',
})<{ toggled?: boolean }>`
  margin-right: 8px;

  &.cogs-button--disabled.cogs-button--type-ghost {
    ${({ toggled }) =>
      toggled &&
      `background: var(--cogs-surface--action--muted--default--alt);`}
  }
`;

export const BetaChip = styled(PromoChip)`
  margin-left: 8px;
`;
