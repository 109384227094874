import type { FdmInstance } from '../../types';

import type { ParsedProperties } from './FdmPropertyList/parseInstance';

const getFdmPropertyListTitle = (
  instance: FdmInstance,
  properties: ParsedProperties | undefined
): string => {
  const instanceName =
    typeof properties?.name === 'string'
      ? properties.name
      : instance.instanceExternalId;
  return `${instance.viewExternalId} - ${instanceName}`;
};

export default getFdmPropertyListTitle;
