import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';

import DataGridContainer from '../../containers/DataGridContainer';
import getContainerFromContainerNode from '../../containers/getContainerFromContainerNode';
import { UpdateRequestSource, type UnifiedViewer } from '../../UnifiedViewer';
import UnifiedViewerEventType from '../../UnifiedViewerEventType';
import type UnifiedViewerRenderer from '../../UnifiedViewerRenderer';
import { isPrimaryMouseButtonPressed } from '../../utils/eventUtils';
import { ANCHOR_NAMES, ANCHOR_STYLE, AnchorName } from '../Anchor/constants';
import getAnchorCross from '../Anchor/getAnchorCross';
import getOffsetAnchorPosition from '../Anchor/getOffsetAnchorPosition';

import getUpdatedDataGridContainer from './getUpdatedDataGridContainer';

class DataGridAnchorHelper {
  private viewer: UnifiedViewer;
  private renderer: UnifiedViewerRenderer;
  private anchors: Konva.Group[] | undefined = undefined;
  private target: Konva.Node | undefined = undefined; // The data grid node we want to attach anchors to

  public constructor(viewer: UnifiedViewer, renderer: UnifiedViewerRenderer) {
    this.viewer = viewer;
    this.renderer = renderer;
  }

  public clear = (): void => {
    this.anchors?.forEach((anchor) => anchor.destroy());
    this.anchors = undefined;
  };

  private attachEventHandlersToAnchor = (anchor: Konva.Group): void => {
    const onMouseOver = (event: KonvaEventObject<MouseEvent>) => {
      this.viewer.setActiveToolCursor('pointer');

      const target = event.currentTarget;
      if (!(target instanceof Konva.Group)) {
        return;
      }
      target.add(getAnchorCross());
      const scale = ANCHOR_STYLE.hoveredRadius / ANCHOR_STYLE.radius;
      target.scale({ x: scale, y: scale });
    };

    const onMouseOut = (event: KonvaEventObject<MouseEvent>) => {
      this.viewer.setActiveToolCursor('default');

      const target = event.currentTarget;
      if (!(target instanceof Konva.Group)) {
        return;
      }
      target
        .getChildren()
        .filter((child) => child.name() === ANCHOR_STYLE.crossName)
        .forEach((child) => child.destroy());
      target.scale({ x: 1, y: 1 });
    };

    const onClick = () => {
      this.viewer.setActiveToolCursor('default');
    };

    const onMouseDown = (event: KonvaEventObject<MouseEvent>) => {
      if (event.target.id() !== anchor.id()) {
        return;
      }
      if (!isPrimaryMouseButtonPressed(event.evt)) {
        return;
      }
      // Prevent the stage from registering the click on the anchor
      event.evt.stopPropagation();

      const container =
        this.target !== undefined
          ? getContainerFromContainerNode(this.target)
          : undefined;
      if (!(container instanceof DataGridContainer)) {
        return;
      }

      const dataGridProps = container.serialize();
      const anchorName = anchor.getAttr('anchorName');
      this.viewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
        source: UpdateRequestSource.DATA_GRID_ANCHOR,
        annotations: [],
        containers: [getUpdatedDataGridContainer(dataGridProps, anchorName)],
      });
    };

    anchor.on('mousedown', onMouseDown);
    anchor.on('mouseover', onMouseOver);
    anchor.on('mouseout', onMouseOut);
    anchor.on('click', onClick);
  };

  private updateAnchorPosition = (anchor: Konva.Group): void => {
    const position = getOffsetAnchorPosition({ anchor, target: this.target });
    if (position !== undefined) {
      anchor.position(position);
    }
  };

  private createAnchor = (name: AnchorName): Konva.Group => {
    if (this.target === undefined) {
      throw new Error('target cannot be undefined when creating an anchor');
    }
    const anchor = new Konva.Group({ anchorName: name, listening: true });
    anchor.add(new Konva.Circle({ ...ANCHOR_STYLE }));
    this.updateAnchorPosition(anchor);
    this.attachEventHandlersToAnchor(anchor);
    return anchor;
  };

  public refreshAnchors = (nodes: Konva.Node[]): void => {
    // Only show anchors if we're targetting exactly one data grid container node
    if (
      nodes.length !== 1 ||
      !(getContainerFromContainerNode(nodes[0]) instanceof DataGridContainer)
    ) {
      this.target = undefined;
      this.clear();
      return;
    }
    const target = nodes[0];

    // Create new anchors iff we're targetting a new data grid container
    if (this.target?.id() !== target.id()) {
      this.clear();

      this.target = nodes[0];
      this.anchors = ANCHOR_NAMES.map(this.createAnchor);
      this.target.on('dragstart transformstart', this.clear);

      this.viewer.layers.transformer.add(...this.anchors);
      return;
    }

    // Otherwise, re-use the previous anchors
    this.anchors?.forEach(this.updateAnchorPosition);
    this.target.on('dragstart transformstart', this.clear);
  };
}

export default DataGridAnchorHelper;
