import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { Button, CameraIcon, Modal } from '@cognite/cogs.js-v10';
import { useFlag } from '@cognite/react-feature-flags';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { CameraComponent } from '../media-manager/camera-component';
import type { ImageItemMetadata, ImageToUpload } from '../types';

import { StyledModal } from './elements';
import { MediaManagerDialogueFooter } from './media-manager-dialogue-footer';
import { SelectedMediaDialogueContent } from './selected-media-dialogue-content';
import type { Props as MediaDialogueContentProps } from './selected-media-dialogue-content';

interface Props {
  mediaToUpload: ImageToUpload[];
  uploadedMediaToShow: InstanceOrExternalId[];

  onClickSelectFile: () => void;
  onSelectMedia: (media: ImageToUpload) => void;
  getMediaUpdatedMetadata: (
    externalId: string
  ) => ImageItemMetadata | undefined;
  onClose: () => void;
  onSaveMedia: () => void;
  visible: boolean;
  showEmptyView: boolean;

  isSaving: boolean;
  isLoading: boolean;
  isThereAChange: boolean;
  isUploadingMedia: boolean;
  loadingText?: string;
  onClickTakePicture: () => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  capturePhoto: () => void;
  closeCamera: () => void;
  isCameraOpen: boolean;
}

export const SelectedMediaDialogue: FC<Props & MediaDialogueContentProps> = ({
  mediaToUpload,
  showEmptyView,
  getMediaUpdatedMetadata,
  uploadedMediaToShow,
  onClickSelectFile,
  onSelectMedia,
  onSaveMedia,
  visible,
  onClose,
  isSaving,
  isLoading,
  isUploadingMedia,
  isThereAChange,
  loadingText,
  onClickTakePicture,
  videoRef,
  capturePhoto,
  closeCamera,
  isCameraOpen,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);
  const { isEnabled: isOxyCustomer } = useFlag(
    UnleashKeys.windows_tablet_camera_oxy,
    { forceRerender: true, fallback: false }
  );

  return (
    <StyledModal
      visible={visible}
      size={isCameraOpen ? 'full-screen' : 'small'}
      hideFooter
      title={t('MEDIA_MODAL_TITLE', 'Upload images or videos')}
      onCancel={isOxyCustomer && isCameraOpen ? closeCamera : onClose}
      isCameraOpen={isCameraOpen}
    >
      {isOxyCustomer && isCameraOpen && (
        <CameraComponent videoRef={videoRef} capturePhoto={capturePhoto} />
      )}
      {!isCameraOpen && (
        <SelectedMediaDialogueContent
          mediaToUpload={mediaToUpload}
          showEmptyView={showEmptyView}
          getMediaUpdatedMetadata={getMediaUpdatedMetadata}
          uploadedMediaToShow={uploadedMediaToShow}
          onSelectMedia={onSelectMedia}
          isLoading={isLoading}
          loadingText={loadingText}
          isUploadingMedia={isUploadingMedia}
        />
      )}
      {!isCameraOpen && (
        <MediaManagerDialogueFooter
          onClickSelectFile={onClickSelectFile}
          isUploadButtonDisabled={isSaving || isLoading}
          isSaveButtonDisabled={!isThereAChange || isSaving}
          isSaving={isSaving}
          onSave={onSaveMedia}
          onClickTakePicture={onClickTakePicture}
        />
      )}
    </StyledModal>
  );
};
