import type { NavigationItem } from '../../types';

import * as S from './elements';

interface Props {
  navigationItem: NavigationItem;
  handleNavigateClick: (newRoute: string) => void;
  isSelected: boolean;
  isOnline: boolean;
}

export const NavigationTabButton = ({
  navigationItem,
  handleNavigateClick,
  isSelected,
  isOnline,
}: Props) => {
  return (
    <S.NavigationButton
      onClick={() => handleNavigateClick(navigationItem.to)}
      toggled={isSelected}
      data-testid={navigationItem.name}
      disabled={!isOnline}
    >
      {navigationItem.name}
      {navigationItem.beta && <S.BetaChip> BETA </S.BetaChip>}
    </S.NavigationButton>
  );
};
