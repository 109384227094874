import type { TemplateItem } from '@cognite/apm-client';
import { Flex, Modal } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { TextInputModal } from '@infield/features/template';
import { getTaskGroupLabel } from '@infield/features/template/utils';
import type { FC } from 'react';
import { useState } from 'react';

export type GroupModalProps = {
  tasks: TemplateItem[];
  groupName?: string;
  showGroupModal: boolean;
  showConfirmationModal: boolean;
  setShowConfirmationModal: (visible: boolean) => void;

  onSubmit: (value: string) => unknown;
  onCancel: () => void;
};

export const GroupModal: FC<GroupModalProps> = ({
  tasks,
  groupName: existingGroupName,
  onSubmit,
  showGroupModal,
  showConfirmationModal,
  setShowConfirmationModal,
  onCancel,
}) => {
  const { Trans, t } = useTranslation(LOCIZE_NAMESPACES.template);

  const isEditing = Boolean(existingGroupName);

  const [groupName, setGroupName] = useState(existingGroupName || '');

  const handleSubmit = (groupName: string) => {
    setGroupName(groupName);
    const isDuplicateGroup = tasks.some((task) =>
      getTaskGroupLabel(task, groupName)
    );

    if (isDuplicateGroup) {
      setShowConfirmationModal(true);
    } else {
      onSubmit(groupName);
    }
  };

  const okText = isEditing
    ? t('TEMPLATE_GROUP_ACTION_RENAME_GROUP', 'Rename Group')
    : t('TEMPLATE_GROUP_ACTION_SAVE_GROUP', 'Save Group');

  if (showGroupModal && !showConfirmationModal)
    return (
      <TextInputModal
        size="small"
        visible
        title={t('TEMPLATE_GROUP_TITLE', 'Group information')}
        initialValue={groupName}
        inputLabel={t('TEMPLATE_GROUP_INPUT_NAME', 'Name')}
        inputDescription={t(
          'TEMPLATE_GROUP_INPUT_DESCRIPTION',
          'You can always rename the group later'
        )}
        okText={okText}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        okDisableIfNoChange
        maxCharacters={60}
      >
        {null}
      </TextInputModal>
    );

  return (
    <Modal
      size="small"
      visible={showConfirmationModal}
      title={t('TEMPLATE_GROUP_TITLE', 'Group information')}
      onOk={() => onSubmit(groupName)}
      onCancel={() => setShowConfirmationModal(false)}
      cancelText={t('TEMPLATE_GROUP_ACTION_CONFIRMATION_BACK', 'Back')}
      okText={t('TEMPLATE_GROUP_ACTION_SAVE_CONFIRMATION', 'Confirm')}
    >
      <Flex direction="column" gap={4}>
        <Trans i18nKey="TEMPLATE_GROUP_CONFIRMATION_TITLE">
          A group with the same name already exists.
        </Trans>
        <br />
        <br />
        <Trans i18nKey="TEMPLATE_GROUP_CONFIRMATION_DETAIL">
          It will be merged into one group once you click the
          &apos;Confirm&apos; button.
        </Trans>
      </Flex>
    </Modal>
  );
};
