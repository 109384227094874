import { useFlag } from '@cognite/react-feature-flags';
import type { Asset, AssetFilterProps } from '@cognite/sdk';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

export const useAssetSearch = (
  query?: string,
  limit?: number,
  filter?: AssetFilterProps,
  enabled = true
) => {
  const { assetService } = useFDMServices();
  const { asset: configFilters } = useAppConfigFiltersContext();

  const { isEnabled: isAkerbpCustomCode } = useFlag(
    UnleashKeys.akerbp_custom_code,
    { forceRerender: true, fallback: false }
  );

  const searchFilter: AssetFilterProps = {
    ...filter,
    ...configFilters?.cdfClassicFilters,
  };

  return useQuery<Asset[] | undefined>(
    [QueryKeys.SEARCH, 'asset', query, limit, searchFilter, isAkerbpCustomCode],
    async () => {
      if (query)
        return assetService.searchAssets(
          query,
          limit,
          searchFilter,
          isAkerbpCustomCode
        );
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: `${QueryKeys.SEARCH} asset`,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(query) && enabled,
    }
  );
};
