import { useFlag } from '@cognite/react-feature-flags';
import type { Asset } from '@cognite/sdk';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const useAssetQuery = (assetExternalId: string | undefined | null) => {
  const { assetService } = useFDMServices();

  const { isEnabled: isAkerbpCustomCode } = useFlag(
    UnleashKeys.akerbp_custom_code,
    { forceRerender: true, fallback: false }
  );

  return useQuery<Asset | undefined>(
    [QueryKeys.ASSET, assetExternalId, isAkerbpCustomCode],
    async () => {
      if (assetExternalId)
        return assetService.getAssetByExternalId(
          assetExternalId,
          isAkerbpCustomCode
        );
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.ASSET,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(assetExternalId),
    }
  );
};
