import type { CellComponent, ColumnDefinition } from 'tabulator-tables';

import type { DataGridColumn, DataGridRow } from './types';

const getTabulatorColumns = (
  columns: DataGridColumn[],
  data: DataGridRow[],
  onCellEdited: VoidFunction,
  onCellClick: (cell: CellComponent) => void,
  onCellContextMenu: (e: UIEvent, cell: CellComponent) => void
): ColumnDefinition[] => {
  const rowsById = new Map(data.map((d) => [d.id, d]));
  const cellFormatterFn = (cell: CellComponent) => {
    const element = cell.getElement();

    const rowId = cell.getData().id;
    const columnId = cell.getColumn().getField();
    const dataItem = rowsById.get(rowId);
    if (dataItem === undefined) {
      return cell.getValue();
    }

    const cellStyle = dataItem.cells[columnId]?.style;
    if (cellStyle === undefined) {
      return cell.getValue();
    }

    element.style.fontWeight = cellStyle.fontWeight ?? 'normal';
    element.style.fontStyle = cellStyle.fontStyle ?? 'normal';
    element.style.color = cellStyle.textColor ?? 'black';
    element.style.backgroundColor = cellStyle.backgroundColor ?? 'white';

    return cell.getValue();
  };

  return columns.map((column) => ({
    field: column.key,
    title: column.title ?? column.key,
    width: column.width,
    editor: 'input',
    cellEdited: onCellEdited,
    formatter: cellFormatterFn,
    cellContext: onCellContextMenu,
    cellClick: (_, cell) => onCellClick(cell),
  }));
};

export default getTabulatorColumns;
