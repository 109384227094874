import type { Checklist } from '@cognite/apm-client';
import type { RootLocationConfiguration } from '@cognite/apm-config';
import type { DateRangeType } from '@cognite/apm-observation';
import type { CogniteClient } from '@cognite/sdk';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

import type { SchedulerCreatedNode } from './types';

export class ChecklistSchedulerService {
  client;
  project;

  constructor(client: CogniteClient) {
    this.client = client;
    this.project = client.project;
  }

  async getPlannedChecklists(
    selectedRootLocation: RootLocationConfiguration | undefined,
    selectedDateFilter: DateRangeType
  ) {
    if (!selectedRootLocation) {
      throw new Error('Invalid root location');
    }

    let fromISO: string;
    let untilISO: string;
    try {
      const [start, end] = selectedDateFilter as [Dayjs, Dayjs]; // Already checked if valid, but TS doesn't know
      fromISO = start.utc().toISOString();
      untilISO = end.utc().toISOString();
    } catch (error: any) {
      throw new Error('Invalid date filter');
    }

    const rootLocation = selectedRootLocation.assetExternalId;
    const appInstanceSpace = selectedRootLocation.appDataInstanceSpace;
    const sourceInstaneSpace = selectedRootLocation.sourceDataInstanceSpace;

    const response = await this.client.get<{
      items: Checklist[];
    }>(
      `/api/v1/projects/${this.project}/checklistgenerator/checklists?rootLocation=${rootLocation}&appInstanceSpace=${appInstanceSpace}&sourceInstanceSpace=${sourceInstaneSpace}&fromTime=${fromISO}&untilTime=${untilISO}`
    );
    const plannedChecklists = response?.data?.items?.filter(
      (item) => item.status === 'Planned'
    );

    return plannedChecklists ?? [];
  }

  async createMissingChecklists(
    plannedChecklists: Checklist[]
  ): Promise<SchedulerCreatedNode[]> {
    const res = (await this.client.post(
      `/api/v1/projects/${this.project}/checklistgenerator/checklists`,
      { data: { items: plannedChecklists } }
    )) as {
      data: {
        items: SchedulerCreatedNode[];
      };
    };
    return res.data.items ?? [];
  }
}
