import { CellComponent } from 'tabulator-tables';

import {
  DataTableAddColumnLeftIcon,
  DataTableAddColumnRightIcon,
  DataTableAddRowBottomIcon,
  DataTableAddRowTopIcon,
  Divider,
  Menu,
} from '@cognite/cogs.js-v10';

import getUpdatedDataGridContainer, {
  DataGridAction,
} from './getUpdatedDataGridContainer';
import { DataGridColumn, DataGridRow } from './types';

type DataGridContextMenuProps = {
  selectedCell: CellComponent;
  columns: DataGridColumn[];
  rows: DataGridRow[];
  onRowsUpdated: (rows: DataGridRow[]) => void;
  onColumnUpdated: (columns: DataGridColumn[]) => void;
  onContextMenuItemClick?: () => void;
};

// TODO(CAN-000): Add translations to this component
const DataGridContextMenu = ({
  selectedCell,
  columns,
  rows,
  onRowsUpdated,
  onColumnUpdated,
  onContextMenuItemClick,
}: DataGridContextMenuProps) => {
  const getClickHandler = (action: DataGridAction) => {
    return () => {
      getUpdatedDataGridContainer({
        action,
        onRowsUpdated,
        onColumnUpdated,
        currentColumns: columns,
        currentRows: rows,
        selectedCell: selectedCell,
      });
      onContextMenuItemClick?.();
    };
  };
  return (
    <Menu onMouseDown={(e) => e.stopPropagation()}>
      <Menu.Item
        iconPlacement="left"
        icon={<DataTableAddColumnRightIcon />}
        onClick={getClickHandler('appendColumn')}
      >
        Add column to the right
      </Menu.Item>
      <Menu.Item
        iconPlacement="left"
        icon={<DataTableAddColumnLeftIcon />}
        onClick={getClickHandler('prependColumn')}
      >
        Add column to the left
      </Menu.Item>
      <Divider />
      <Menu.Item
        iconPlacement="left"
        icon={<DataTableAddRowTopIcon />}
        onClick={getClickHandler('prependRow')}
      >
        Add row above
      </Menu.Item>
      <Menu.Item
        iconPlacement="left"
        icon={<DataTableAddRowBottomIcon />}
        onClick={getClickHandler('appendRow')}
      >
        Add row below
      </Menu.Item>
      <Divider />
      <Menu.Item
        disabled={rows.length <= 1}
        onClick={getClickHandler('removeRow')}
      >
        Remove row
      </Menu.Item>
      <Menu.Item
        disabled={columns.length <= 1}
        onClick={getClickHandler('removeColumn')}
      >
        Remove column
      </Menu.Item>
    </Menu>
  );
};

export default DataGridContextMenu;
