import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { groupBy, isEmpty, sortBy, uniqBy } from 'lodash-es';

import { makeToast } from '@cognite/cogs-lab';
import {
  Textarea,
  Colors,
  Flex,
  Body,
  Button,
  InfoIcon,
  CopyIcon,
  ErrorIcon,
  LoaderIcon,
  SearchAiIcon,
  StickyNoteIcon,
  IconWrapper,
} from '@cognite/cogs.js-v10';

import { useTranslation, useClipboard } from '../../hooks';
import {
  AiButton,
  AnswerContainer,
  AnswerInnerContainer,
  ButtonsContainer,
  Container,
  ErrorContainer,
  HeaderContainer,
  InnerContainer,
  shamefulBorderInteractiveToggledDefaultAi,
  shamefulColorTextIconAi,
} from '../../styles';
import { AiDocument } from '../../types';
import { Source } from '../Source/Source';

export type AskQuestionPromptProps = {
  fileIds?: number[] | string[];
  answer: string | undefined;
  sources?: AiDocument[];
  question: string;
  progressPercentage: number | undefined;
  onChangeQuestion: (nextValue: string) => void;
  onAddAsStickyClick?: () => void;
  onSourceClick?: (source: AiDocument) => void;
  isLoading: boolean;
  isError?: boolean;
  onAskClick: () => void;
  onCloseClick: () => void;
};

export const AskQuestionPrompt: React.FC<AskQuestionPromptProps> = ({
  onCloseClick,
  answer,
  sources,
  onSourceClick,
  progressPercentage,
  onAskClick,
  onAddAsStickyClick,
  onChangeQuestion,
  question,
  fileIds,
  isLoading,
  isError,
}) => {
  const { t } = useTranslation();
  const questionTextareaRef = React.useRef<HTMLTextAreaElement>(null);
  const { onCopy } = useClipboard();
  const [submittedQuestion, setSubmittedQuestion] = useState('');

  const hasMultipleFiles = fileIds && !isEmpty(fileIds) && fileIds.length > 1;
  const handleSubmitQuestion = () => {
    setSubmittedQuestion(question);
    onAskClick();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    e.stopPropagation();

    if (!e.nativeEvent.isComposing && e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmitQuestion();
    }
  };

  useEffect(() => {
    if (questionTextareaRef.current) {
      questionTextareaRef.current.focus();
    }
  }, []);

  const sourcesGroupedByDocument = useMemo(
    () =>
      groupBy(sources, (source) =>
        hasMultipleFiles && source.metadata.fileId ? source.metadata.fileId : ''
      ),
    [sources, hasMultipleFiles]
  );

  return (
    <Container>
      <InnerContainer>
        <HeaderContainer>
          <IconWrapper size={14}>
            <SearchAiIcon style={{ color: shamefulColorTextIconAi }} />
          </IconWrapper>
          <Body size="medium" strong>
            {t('AI_ASK_A_QUESTION')}
          </Body>
        </HeaderContainer>

        <TextareaContainer>
          <AiTextarea
            ref={questionTextareaRef}
            placeholder={
              hasMultipleFiles
                ? t('AI_ASK_A_QUESTION_ABOUT_FILES_PLACEHOLDER')
                : t('AI_ASK_A_QUESTION_ABOUT_FILE_PLACEHOLDER')
            }
            fullWidth
            disabled={isLoading}
            rows={3}
            autoResize
            value={question}
            onKeyDown={handleKeyDown}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              onChangeQuestion(e.target.value)
            }
          />
          <Flex direction="column" gap={8} style={{ marginBottom: '8px' }}>
            <Body size="x-small" muted>
              <InfoIcon style={{ verticalAlign: 'middle' }} />{' '}
              {t('AI_COPILOT_RESULTS_DISCLAIMER_LABEL_V1')}
            </Body>
          </Flex>
        </TextareaContainer>

        {answer && (
          <AnswerContainer>
            <Flex direction="column" gap={8}>
              <Body size="medium" muted style={{ display: 'block' }}>
                {t('AI_ANSWER_HEADING')}
                <StyledCopyButton
                  type="ghost"
                  icon={<CopyIcon />}
                  aria-label="Copy"
                  title="Copy answer to clipboard"
                  size="small"
                  onClick={() => {
                    onCopy(answer);
                    makeToast({
                      body: t('AI_TEXT_COPIED_TO_CLIPBOARD'),
                      type: 'success',
                    });
                  }}
                />
              </Body>
              <AnswerInnerContainer size="small">{answer}</AnswerInnerContainer>
            </Flex>
          </AnswerContainer>
        )}

        {isError && (
          <ErrorContainer>
            <ErrorIcon />
            <Body size="small">{t('AI_ANSWER_ERROR')}</Body>
          </ErrorContainer>
        )}

        {!answer && isLoading && progressPercentage !== undefined && (
          <Alert>
            <IconContainer>
              <LoaderIcon />
            </IconContainer>
            <AlertMessageContainer>
              <Body size="medium">{t('AI_PROCESSING_EXPLANATION')}</Body>

              <Body size="medium">
                {t('AI_PROCESSED_PERCENTAGE')}:{' '}
                <span
                  style={{
                    color: shamefulColorTextIconAi,
                    fontWeight: '500',
                  }}
                >
                  {progressPercentage}%
                </span>
              </Body>
            </AlertMessageContainer>
          </Alert>
        )}

        <ButtonsContainer>
          <Button onClick={onCloseClick}>{t('COMMON_CLOSE')}</Button>
          {!isLoading && (
            <AiButton
              type="primary"
              onClick={handleSubmitQuestion}
              icon={<SearchAiIcon />}
              disabled={question.length === 0 || question === submittedQuestion}
            >
              {t('AI_ASK_COGNITE_AI_V1')}
            </AiButton>
          )}
          {!answer && isLoading && (
            <AiButton type="primary" disabled icon={<LoaderIcon />} isLoading>
              {t('AI_INVESTIGATING')}
            </AiButton>
          )}
          {answer && onAddAsStickyClick && (
            <AiButton
              icon={<StickyNoteIcon />}
              type="primary"
              onClick={onAddAsStickyClick}
            >
              {t('COMMON_ADD_AS_STICKY')}
            </AiButton>
          )}
        </ButtonsContainer>
      </InnerContainer>
      {!isEmpty(sources) && (
        <Footer>
          <Body size="medium" muted>
            {t('AI_ANSWER_BASED_ON_SOURCES')}
          </Body>

          <StyledContainer>
            {Object.keys(sourcesGroupedByDocument).map((fileId, index) => (
              <Source
                key={fileId}
                documentName={
                  sourcesGroupedByDocument[fileId][0].metadata?.title || ''
                }
                fileId={fileId}
                expanded={!hasMultipleFiles || index === 0}
                hideHeader={!hasMultipleFiles}
                pageNumbers={sortBy(
                  uniqBy(
                    sourcesGroupedByDocument[fileId],
                    (source) => source.metadata.page
                  ),
                  (source) => source.metadata.page
                ).map((source) => +source.metadata.page)}
                onSourceClick={(_fileId, pageNumber) => {
                  const sourceDoc = sourcesGroupedByDocument[fileId][0];

                  onSourceClick?.({
                    ...sourceDoc,
                    metadata: {
                      ...sourceDoc.metadata,
                      page: pageNumber,
                      fileId: +_fileId || +sourceDoc.metadata.fileId!,
                    },
                  });
                }}
              />
            ))}
          </StyledContainer>
        </Footer>
      )}
    </Container>
  );
};

const TextareaContainer = styled.div`
  padding: 4px 0 0 0;
`;

const Alert = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: rgba(102, 102, 102, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(102, 102, 102, 0.2);
  padding: 8px;
  margin-bottom: 4px;
`;

const IconContainer = styled.div`
  padding: 4px;
`;

const AlertMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 4px;

  & > *:not(:first-child) {
    margin-top: 8px;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border-top: 1px solid ${Colors['border--interactive--default']};
  background: ${Colors['surface--medium']};
  border-radius: 0 0 6px 6px;
`;

const AiTextarea = styled(Textarea)`
  textarea {
    &:hover,
    &:focus,
    &:focus-visible {
      border-color: ${shamefulBorderInteractiveToggledDefaultAi} !important;
    }
  }
`;

const StyledContainer = styled(Flex)`
  flex-direction: column;
  margin-left: -12px;
  margin-right: -12px;
`;

const StyledCopyButton = styled(Button)`
  &&&&& {
    display: block;
    width: auto;
    float: right;
  }
`;
