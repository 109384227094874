import type { ObservationFieldOption } from '@cognite/apm-config';
import { Option, Select } from '@cognite/cogs-lab';
import { type FC } from 'react';

import { DisabledOption } from '../elements';

interface Props {
  dataTestId?: string;
  label?: string;
  placeholder?: string;
  options?: ObservationFieldOption[];
  value?: string;
  isLocked?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
}

export const ObservationDesktopSelectInput: FC<Props> = ({
  label,
  placeholder,
  options,
  value,
  isLocked,
  required,
  onChange,
}) => {
  // Check if the selected value is part of the options array
  const isValidValue = options?.some((option) => option.value === value);

  return (
    <Select
      id={`select-${label?.toLowerCase()}`}
      fullWidth
      label={{ text: label || '', required }}
      value={value}
      disabled={isLocked}
      placeholder={placeholder}
      onChange={(_, newValue) => onChange(String(newValue))}
    >
      {/* If value is not part of the options, show it as a disabled option at the top */}
      {!isValidValue && value && (
        <DisabledOption value={value} label={value} disabled>
          {value}
        </DisabledOption>
      )}
      {options?.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};
