import { type FC, useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { Input, Flex, Modal } from '@cognite/cogs.js-v10';

import {
  LocationFilterDTO,
  LocationFilterUpsertRequestDTO,
} from '../../../sdk/ads-location-filters-sdk/types';

interface Props {
  visible: boolean;
  location?: LocationFilterDTO;
  onClose?: () => void;
  onSave?: (location: LocationFilterUpsertRequestDTO) => void;
}

export const ADSLocationEditModal: FC<Props> = ({
  visible,
  location,
  onClose,
  onSave,
}) => {
  const [nextLocation, setNextLocation] = useState<
    LocationFilterUpsertRequestDTO | undefined
  >();

  useEffect(() => {
    setNextLocation(location);
  }, [location]);

  const handleInputChange = (field: string, value: string) => {
    if (field === 'name') {
      setNextLocation((prev) => ({
        ...prev,
        externalId: prev?.externalId || uuid(),
        name: value,
      }));
    } else {
      setNextLocation((prev) => ({
        ...prev,
        [field]: value,
        externalId: prev?.externalId || uuid(),
        name: prev?.name || '',
      }));
    }
  };

  const handleSave = () => {
    if (!nextLocation) return;
    onSave?.(nextLocation);
  };

  return (
    <Modal
      size="small"
      title={location ? 'Edit location' : 'Add location'}
      visible={visible}
      onCancel={onClose}
      onOk={handleSave}
    >
      <Flex direction="column" gap={8}>
        <Input
          label="Name"
          fullWidth
          value={nextLocation?.name || ''}
          onChange={(e) => handleInputChange('name', e.target.value)}
        />
        <Input
          label="Description"
          fullWidth
          value={nextLocation?.description || ''}
          onChange={(e) => handleInputChange('description', e.target.value)}
        />
      </Flex>
    </Modal>
  );
};
