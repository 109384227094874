import Konva from 'konva';
import type { IRect } from 'konva/lib/types';
import { partition, throttle } from 'lodash';

import { NodeEventMap } from './annotations/setAnnotationNodeEventHandlers';
import StickySerializer from './annotations/StickySerializer';
import {
  isCommentAnnotationNode,
  isStickyAnnotationNode,
} from './annotations/types';
import LayerContainer from './containers/LayerContainer';
import { ToolType } from './tools/types';
import { UnifiedViewer } from './UnifiedViewer';
import UnifiedViewerEventType from './UnifiedViewerEventType';
import areCirclesOverlapping from './utils/areCirclesOverlapping';
import getBoundingBoxCornerPosition from './utils/getBoundingBoxCornerPosition';
import isAnnotationNode from './utils/isAnnotationNode';
import isContainerNode from './utils/isContainerNode';
import LineTransformer, {
  shouldNodeUseLineTransformer,
} from './utils/LineTransformer';
import { isResizableNode } from './utils/nodeUtils';

export const SELECTION_RECTANGLE_ID = 'ufv-selection-rectangle';
export const SELECTION_RECTANGLE_STYLE = {
  fill: 'rgba(74, 103, 251, 0.18)', // cogs-border--interactive--toggled-pressed
  stroke: 'rgb(64, 120, 240)', // cogs-border--status-neutral--strong
};

const ZOOM_CHANGE_THROTTLE_MS = 200;

const DEFAULT_MODE_BORDER_STROKE_WIDTH = 2;
const DEFAULT_MODE_BORDER_STROKE = 'rgba(74, 103, 251, 1)'; // cogs--border--interactive--toggled-default,
const DEFAULT_MODE_PADDING = 0;

const ACTIVE_MODE_BORDER_STROKE_WIDTH = 4;
const ACTIVE_MODE_BORDER_STROKE = 'rgba(74, 103, 251, 1)'; // Tweaked arbitrarily to differentiate from above
// In active mode, we increase the selection box since it otherwise pops under the HTML-container
// since that has a higher z-index
const ACTIVE_MODE_PADDING = 2;

const ANCHOR_HIDE_MARGIN_PX = 3;

export const ANCHOR_STYLE = {
  anchorCornerRadius: 6,
  anchorStrokeWidth: 3,
  anchorStroke: 'rgba(83, 88, 127, 0.24)', // cogs-border--interactive--default--alt
  anchorFill: 'rgba(255, 255, 255, 1)', // cogs--surface--muted
  borderStroke: DEFAULT_MODE_BORDER_STROKE,
  borderStrokeWidth: DEFAULT_MODE_BORDER_STROKE_WIDTH,
  padding: DEFAULT_MODE_PADDING,
};

const calculateArea = (box: IRect): number => box.width * box.height;

const CONTAINER_MIN_SIZE = 25;
const CONTAINER_MAX_SIZE = 7000;

const shouldConstrainToMinimumSize = (node: Konva.Node): boolean =>
  isContainerNode(node) &&
  (node.width() * node.scaleX() < CONTAINER_MIN_SIZE ||
    node.height() * node.scaleY() < CONTAINER_MIN_SIZE);

const shouldConstrainToMaximumSize = (node: Konva.Node): boolean =>
  isContainerNode(node) &&
  (node.width() * node.scaleX() > CONTAINER_MAX_SIZE ||
    node.height() * node.scaleY() > CONTAINER_MAX_SIZE);

class UnifiedViewerTransformer {
  private transformer: Konva.Transformer;
  private lineTransformer: LineTransformer;
  private layer: LayerContainer | undefined = undefined;
  private viewer: UnifiedViewer;
  private selectionRectanglePerNode: Konva.Rect[] = [];

  public constructor(unifiedViewer: UnifiedViewer) {
    this.viewer = unifiedViewer;
    this.transformer = new Konva.Transformer({
      ...ANCHOR_STYLE,
      rotateEnabled: false,
      flipEnabled: false,
      ignoreStroke: true,
      boundBoxFunc: this.constrainBoundingBox,
    });
    this.lineTransformer = new LineTransformer(unifiedViewer);
    this.on('dragmove transform', this.updateSelectionRectangles);
    this.on('transform', this.refreshFontSizes);
    this.viewer.addEventListener(
      UnifiedViewerEventType.ON_CONTAINER_LOAD,
      this.updateSelectionRectangles
    );
    this.viewer.addEventListener(
      UnifiedViewerEventType.ON_ZOOM_CHANGE,
      this.hideAnchorsIfTheyOverlap
    );
  }

  public setShouldGenerateConnections = (
    shouldGenerateConnections: boolean
  ): void => {
    this.lineTransformer.setShouldGenerateConnections(
      shouldGenerateConnections
    );
  };

  public setTransformerLayer = (layer: LayerContainer): void => {
    this.layer = layer;
    this.layer.add(this.transformer);
    this.lineTransformer.setTransformerLayer(layer);
  };

  public getNodes = (): Konva.Node[] => [
    ...this.transformer.nodes(),
    ...this.lineTransformer.getLineNodes(),
  ];
  public setNodes = (nodes: Konva.Node[]): void => {
    this.refreshSelectionBoxStylingBasedOnActiveMode(nodes);
    this.addSelectionRectangleToNodes(nodes);

    // We set the selection box to be invisible for comment annotations since
    // the selection box does not look good on them. Moreover, comments aren't
    // intended to be multi-selected
    if (nodes.length === 1 && isCommentAnnotationNode(nodes[0])) {
      this.transformer.setAttrs({ borderStrokeWidth: 0 });
    }

    // For multi-selection, only use the Konva transformer since it is more
    // practical (for the user) to use when multiple nodes are supposed to be
    // moved + scaled at once
    if (nodes.length > 1) {
      this.lineTransformer.setLineNodes([]);
      this.transformer.nodes(nodes);
      return;
    }
    const [lineNodes, nonLineNodes] = partition(
      nodes,
      shouldNodeUseLineTransformer
    );
    this.lineTransformer.setLineNodes(lineNodes);
    this.transformer.nodes(nonLineNodes);
  };

  public onDestroy = (): void => {
    this.off('dragmove transform', this.updateSelectionRectangles);
    this.viewer.removeEventListener(
      UnifiedViewerEventType.ON_CONTAINER_LOAD,
      this.updateSelectionRectangles
    );
    this.viewer.removeEventListener(
      UnifiedViewerEventType.ON_ZOOM_CHANGE,
      this.hideAnchorsIfTheyOverlap
    );

    this.clearSelectionRectangles();
    this.lineTransformer.onDestroy();
    this.transformer.destroy();
  };

  public on = <K extends keyof NodeEventMap>(
    evtStr: K,
    handler: Konva.KonvaEventListener<Konva.Transformer, NodeEventMap[K]>
  ): void => {
    this.lineTransformer.on(evtStr, handler);
    this.transformer.on(evtStr, handler);
  };

  public off = (evtStr?: string, callback?: Function): void => {
    this.transformer.off(evtStr, callback);
  };

  public setResizingEnabled = (shouldEnableResizing: boolean): void => {
    this.transformer.resizeEnabled(shouldEnableResizing);
  };

  public setEnabledAnchors = (anchorNames: string[]): void => {
    this.transformer.enabledAnchors(anchorNames);
  };

  public setRotateEnabled = (shouldEnableRotate: boolean): void => {
    this.transformer.rotateEnabled(shouldEnableRotate);
  };

  public setFlipEnabled = (shouldEnableFlip: boolean): void => {
    this.transformer.flipEnabled(shouldEnableFlip);
  };

  public stopTransform = (): void => {
    this.transformer.stopTransform();
  };

  private clearSelectionRectangles(): void {
    this.selectionRectanglePerNode.forEach((rect) => {
      rect.destroy();
    });
    this.selectionRectanglePerNode = [];
  }

  public updateSelectionRectangles = (): void => {
    const selectionRectanglesToKeep: Konva.Rect[] = [];
    this.selectionRectanglePerNode.forEach((selectionRectangle) => {
      const parentId = selectionRectangle.getAttr('parentId');
      const isParentAnnotationNode = selectionRectangle.getAttr(
        'isParentAnnotationNode'
      );
      if (parentId === undefined) {
        return;
      }

      const rect =
        isParentAnnotationNode === true
          ? this.viewer.getAnnotationRectRelativeToStageById(parentId)
          : this.viewer.getContainerRectRelativeToStageById(parentId);
      if (rect === undefined) {
        selectionRectangle.destroy();
        return;
      }

      const { x, y, width, height } = rect;
      selectionRectangle.position({ x, y });
      selectionRectangle.size({ width, height });
      selectionRectanglesToKeep.push(selectionRectangle);
    });

    this.selectionRectanglePerNode = selectionRectanglesToKeep;
  };

  public refreshFontSizes = (): void => {
    this.getNodes().forEach((node) => {
      if (isStickyAnnotationNode(node)) {
        StickySerializer.refreshFontSize(node);
        return;
      }
    });
  };

  private addSelectionRectangleToNodes = (nodes: Konva.Node[]): void => {
    if (this.viewer.options.shouldShowSelectionRectanglePerNode === false) {
      return;
    }

    // Remove the previous selection rectangle
    this.clearSelectionRectangles();

    // Add a rectangle around the selected nodes. Return early when there is
    // only one node, since the main selection rectangle should be enough
    if (nodes.length <= 1) {
      return;
    }
    nodes.forEach((node) => {
      const rect = isAnnotationNode(node)
        ? this.viewer.getAnnotationRectRelativeToStageById(node.id())
        : this.viewer.getContainerRectRelativeToStageById(node.id());
      if (rect === undefined) {
        return;
      }

      const { x, y, width, height } = rect;
      const selectionRectangle = new Konva.Rect({
        id: `${SELECTION_RECTANGLE_ID}-${node.id()}`,
        parentId: node.id(),
        isParentAnnotationNode: isAnnotationNode(node),
        x,
        y,
        width,
        height,
        stroke: SELECTION_RECTANGLE_STYLE.stroke,
        strokeWidth: 2,
        strokeScaleEnabled: false,
      });
      selectionRectangle.listening(false);

      this.selectionRectanglePerNode.push(selectionRectangle);
      // Note: We add the selection rectangle to the transformer layer which allows them
      // to be rendered separately from the nodes, which improves performance.
      this.viewer.layers.transformer.add(selectionRectangle);
    });
  };

  private setActiveModeSelectionBox = (isActive: boolean): void => {
    this.transformer.setAttrs({
      borderStroke: isActive
        ? ACTIVE_MODE_BORDER_STROKE
        : DEFAULT_MODE_BORDER_STROKE,
      borderStrokeWidth: isActive
        ? ACTIVE_MODE_BORDER_STROKE_WIDTH
        : DEFAULT_MODE_BORDER_STROKE_WIDTH,
      padding: isActive ? ACTIVE_MODE_PADDING : DEFAULT_MODE_PADDING,
    });
  };

  private activeModeForNodeId = new Map<string, boolean>();

  private refreshSelectionBoxStylingBasedOnActiveMode = (
    currentlySelectedNodes = this.getNodes()
  ) => {
    const shouldBeInActiveMode =
      currentlySelectedNodes.length === 1 &&
      this.isNodeInActiveMode(currentlySelectedNodes[0].id());
    this.setActiveModeSelectionBox(shouldBeInActiveMode);
  };

  public registerActiveModeForNodeId = (nodeId: string): void => {
    this.activeModeForNodeId.set(nodeId, true);
    this.refreshSelectionBoxStylingBasedOnActiveMode();
  };

  public unregisterActiveModeForNodeId = (nodeId: string): void => {
    this.activeModeForNodeId.delete(nodeId);
    this.refreshSelectionBoxStylingBasedOnActiveMode();
  };

  public isNodeInActiveMode = (nodeId: string): boolean => {
    return this.activeModeForNodeId.has(nodeId);
  };

  private constrainBoundingBox = <Box extends IRect>(
    oldBoundingBox: Box,
    newBoundingBox: Box
  ): Box => {
    this.hideAnchorsIfTheyOverlap();

    const nodes = this.transformer.nodes();
    const oldBoxArea = calculateArea(oldBoundingBox);
    const newBoxArea = calculateArea(newBoundingBox);

    // If the box size has increased, check if any of the nodes exceeds the maximum size limits
    if (newBoxArea > oldBoxArea && nodes.some(shouldConstrainToMaximumSize)) {
      return oldBoundingBox;
    }

    // If the box size has decreased, check if any of the nodes have dropped below the minimum limits
    if (newBoxArea < oldBoxArea && nodes.some(shouldConstrainToMinimumSize)) {
      return oldBoundingBox;
    }

    return newBoundingBox;
  };

  public areAnchorsOverlapping = (): boolean => {
    const transformerBoundingBox = {
      ...this.transformer.position(),
      ...this.transformer.size(),
    };
    const anchorRadius = this.transformer.anchorSize() / 2;
    const circles = this.transformer
      .enabledAnchors()
      .map((anchorName) =>
        getBoundingBoxCornerPosition(anchorName, transformerBoundingBox)
      )
      .map((position) => ({
        ...position,
        radius: anchorRadius + ANCHOR_HIDE_MARGIN_PX,
      }));
    return areCirclesOverlapping(circles);
  };

  private hideAnchorsIfTheyOverlap = throttle((): void => {
    if (this.viewer.getActiveToolType() !== ToolType.SELECT) {
      return;
    }
    if (!this.getNodes().every(isResizableNode)) {
      return;
    }
    const nextIsResizingEnabled = !this.areAnchorsOverlapping();
    if (nextIsResizingEnabled !== this.transformer.resizeEnabled()) {
      this.setResizingEnabled(nextIsResizingEnabled);
    }
  }, ZOOM_CHANGE_THROTTLE_MS);
}

export default UnifiedViewerTransformer;
