import Konva from 'konva';
import { IRect } from 'konva/lib/types';
import cloneDeep from 'lodash/cloneDeep';

import { Container } from '../containers/Container';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import UnifiedViewerRenderer from '../UnifiedViewerRenderer';
import getFontSizeInAbsoluteUnits from '../utils/getFontSizeInAbsoluteUnits';
import shamefulSafeKonvaScale from '../utils/shamefulSafeKonvaScale';

import getMaxDiffingScalingFactor from './getMaxDiffingScalingFactor';
import setAnnotationNodeEventHandlers from './setAnnotationNodeEventHandlers';
import shouldBeDraggable from './shouldBeDraggable';
import shouldBeResizable from './shouldBeResizable';
import shouldBeSelectable from './shouldBeSelectable';
import {
  AnnotationSerializer,
  AnnotationType,
  FontSize,
  LabelAnnotation,
  Scale,
  Size,
} from './types';

type LabelDimensions = { x: number; y: number; fontSize: number };

const getDimensions = (
  annotation: LabelAnnotation,
  getContainerById: (id: string) => Container | undefined
): LabelDimensions | undefined => {
  if (annotation.containerId === undefined) {
    // Note: The annotation validation we do earlier ensures that labels specified without a
    // parent container must have its font size given in absolute (pixel) units.
    return getLabelPropertiesFromAnnotation(annotation);
  }

  const container = getContainerById(annotation.containerId);
  if (container === undefined) {
    return undefined;
  }

  return getDenormalizedLabelPropertiesFromAnnotation(
    annotation,
    container.getContentNode().size()
  );
};

const getLabelPropertiesFromAnnotation = (
  annotation: LabelAnnotation
): LabelDimensions => ({
  x: annotation.x,
  y: annotation.y,
  fontSize: getFontSizeInAbsoluteUnits(annotation.style.fontSize),
});

const getNormalizedLabelPropertiesFromNode = (
  node: Konva.Label,
  getContainerRectById: (id: string) => IRect | undefined
): LabelDimensions => {
  const useNormalizedCoordinates = node.attrs.containerId !== undefined;

  const fontSize = node.getText().fontSize();

  if (!useNormalizedCoordinates) {
    return { x: node.x(), y: node.y(), fontSize };
  }

  const containerRect = getContainerRectById(node.attrs.containerId);
  if (containerRect === undefined) {
    throw new Error('Could not find container dimensions for annotation');
  }

  return {
    x: node.x() / containerRect.width,
    y: node.y() / containerRect.height,
    fontSize: fontSize / containerRect.width,
  };
};

const getDenormalizedLabelPropertiesFromAnnotation = (
  annotation: LabelAnnotation,
  containerRect: Size
): LabelDimensions => {
  return {
    x: containerRect.width * annotation.x,
    y: containerRect.height * annotation.y,
    fontSize: getFontSizeInAbsoluteUnits(
      annotation.style.fontSize,
      containerRect.width
    ),
  };
};

type LabelTransformationProperties = {
  scale: Scale;
  Text: {
    fontSize: number;
    padding: number;
  };
};

type LabelNodeProperties<MetadataType> = {
  [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION;
  annotationType: AnnotationType.LABEL;
  id: string;
  strokeScaleEnabled: false;
  userGenerated: true;
  containerId?: string;
  isSelectable: boolean;
  isDraggable: boolean;
  isResizable: boolean;
  x: number;
  y: number;
  metadata?: MetadataType;
};

type TagNodeProperties = {
  fill: string | undefined;
  stroke: string | undefined;
  strokeWidth: number | undefined;
  cornerRadius: number[] | number | undefined;
};

type TextNodeProperties = {
  text: string;
  fill: string | undefined;
  padding: number | undefined;
  fontSize: number;
  originalFontSize: FontSize;
};

type NodeProperties<MetadataType> = {
  labelNodeProperties: LabelNodeProperties<MetadataType>;
  tagNodeProperties: TagNodeProperties;
  textNodeProperties: TextNodeProperties;
};

const getNodePropertiesFromAnnotation = <MetadataType>(
  annotation: LabelAnnotation<MetadataType>,
  unifiedViewerRenderer: UnifiedViewerRenderer
): NodeProperties<MetadataType> | undefined => {
  const dimensions = getDimensions(
    annotation,
    unifiedViewerRenderer.getContainerById
  );
  if (dimensions === undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      `Could not get dimensions for annotation ${annotation.id}. If it has a container, maybe the container hasn't loaded yet?`
    );
    return undefined;
  }

  const labelNodeProperties: LabelNodeProperties<MetadataType> = {
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
    annotationType: AnnotationType.LABEL,
    id: annotation.id,
    strokeScaleEnabled: false,
    userGenerated: true,
    containerId: annotation.containerId,
    isSelectable: shouldBeSelectable(annotation.isSelectable),
    isDraggable: shouldBeDraggable(annotation.isDraggable),
    isResizable: shouldBeResizable(annotation.isResizable),
    x: dimensions.x,
    y: dimensions.y,
    metadata: annotation.metadata,
  };

  const tagNodeProperties: TagNodeProperties = {
    fill: annotation.style.backgroundColor,
    stroke: annotation.style.borderColor,
    strokeWidth: annotation.style.borderWidth,
    cornerRadius: annotation.style.borderRadius,
  };

  const textNodeProperties: TextNodeProperties = {
    text: annotation.text,
    fill: annotation.style.color,
    padding: annotation.style.padding,
    fontSize: dimensions.fontSize,
    originalFontSize: annotation.style.fontSize,
  };

  return {
    labelNodeProperties,
    tagNodeProperties,
    textNodeProperties,
  };
};

const LabelSerializer: AnnotationSerializer<
  LabelAnnotation,
  LabelTransformationProperties
> = {
  isSelectable: (node: Konva.Node) =>
    shouldBeSelectable(node.attrs.isSelectable),

  isDraggable: (node: Konva.Node) => shouldBeDraggable(node.attrs.isDraggable),

  isResizable: (node: Konva.Node) => shouldBeResizable(node.attrs.isResizable),

  getTransformationPropertiesFromNode: (node: Konva.Node) => {
    if (!(node instanceof Konva.Label)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    return {
      scale: shamefulSafeKonvaScale(node.scale()),
      Text: {
        fontSize: node.getText().fontSize(),
        padding: node.getText().padding(),
      },
    };
  },

  normalize: ({ scale, Text: { fontSize, padding } }) => {
    const scaleFactor = getMaxDiffingScalingFactor(scale);
    return {
      // Rescale the points to the new scale factor
      Text: {
        fontSize: fontSize * scaleFactor,
        padding: padding * scaleFactor,
      },
      scale: {
        x: 1,
        y: 1,
      },
    };
  },

  serialize: (node, unifiedViewerRenderer) => {
    if (!(node instanceof Konva.Label)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    const {
      x,
      y,
      fontSize: normalizedFontSize,
    } = getNormalizedLabelPropertiesFromNode(
      node as Konva.Label, // TODO(FUS-000): Improve typing
      unifiedViewerRenderer.getContainerRectRelativeToStageById
    );
    const tag = node.getTag();
    const text = node.getText();
    return {
      id: node.id(),
      type: AnnotationType.LABEL,
      text: text.text(),
      containerId: node.attrs.containerId,
      isSelectable: shouldBeSelectable(node.attrs.isSelectable),
      isDraggable: shouldBeDraggable(node.attrs.isDraggable),
      isResizable: shouldBeResizable(node.attrs.isResizable),
      x,
      y,
      style: {
        fontSize:
          node.attrs.containerId === undefined
            ? `${text.fontSize()}px`
            : normalizedFontSize,
        padding: text.padding(),
        color: text.fill(),
        backgroundColor: tag.fill(),
        borderColor: tag.stroke(),
        borderWidth: tag.strokeWidth(),
        borderRadius: tag.cornerRadius(),
      },
      metadata: cloneDeep(node.attrs.metadata),
    };
  },

  deserialize: (annotation, unifiedViewer, unifiedViewerRenderer) => {
    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return undefined;
    }

    const label = new Konva.Label(nodeProperties.labelNodeProperties);
    label.add(new Konva.Tag(nodeProperties.tagNodeProperties));
    label.add(new Konva.Text(nodeProperties.textNodeProperties));

    return setAnnotationNodeEventHandlers(
      label,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  updateNode: (
    node,
    annotation,
    unifiedViewer,
    unifiedViewerRenderer
  ): void => {
    if (!(node instanceof Konva.Label)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return;
    }

    node.setAttrs(nodeProperties.labelNodeProperties);
    node.getChildren().forEach((child) => {
      if (child instanceof Konva.Tag) {
        child.setAttrs(nodeProperties.tagNodeProperties);
      }

      if (child instanceof Konva.Text) {
        child.setAttrs(nodeProperties.textNodeProperties);
      }
    });

    setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },
};

export default LabelSerializer;
