import { getIsUsingUSFormat } from '@infield/features/i18n/utils';

export const getCorrectDateFormat = (selectedLanguage?: string) => {
  const isUSFormat = getIsUsingUSFormat();
  const isJapaneseFormat = selectedLanguage === 'ja';
  const isChineseFormat = selectedLanguage === 'zh';

  if (isJapaneseFormat || isChineseFormat) {
    return 'YYYY/MM/DD';
  }

  if (isUSFormat) {
    return 'MM/DD/YYYY';
  }
  return 'DD/MM/YYYY';
};
