import type { DateRangeType } from '@cognite/apm-observation';
import { makeToast } from '@cognite/cogs-lab';
import { useMetrics } from '@cognite/metrics';
import { dateFilterAtom } from '@infield/features/activities/activity-planning/state';
import { useSelectedRootLocationConfiguration } from '@infield/features/app-config';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/browser';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

export const useFetchPlannedChecklistsQuery = (
  dateRangeFilter: DateRangeType
) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const { checklistSchedulerService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);
  const metrics = useMetrics(METRICS_NAMESPACES.scheduledChecklists);
  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const sliTimerStartTime = useRef<number | undefined>(undefined);

  const query = useQuery({
    queryKey: [
      QueryKeys.PLANNED_CHECKLISTS_FROM_SCHEDULER,
      selectedRootLocation,
      dateRangeFilter,
    ],
    queryFn: async () => {
      sliTimerStartTime.current = Date.now();
      const checklists = await checklistSchedulerService.getPlannedChecklists(
        selectedRootLocation,
        dateRangeFilter
      );

      metrics.track('fetchPlannedChecklists', {
        numberOfReturnedItems: checklists?.length,
      });

      return checklists;
    },
    enabled: false, // Disabled by default, to be triggered manually
    onSuccess: () => {
      const sliTimerEndTime = Date.now();
      sliMetrics.track(QueryKeys.PLANNED_CHECKLISTS_FROM_SCHEDULER, {
        sliTimerMilliseconds: sliTimerStartTime.current
          ? sliTimerEndTime - sliTimerStartTime.current
          : undefined,
        status: 'success',
        networkSpeedMbps: navigator.connection?.downlink,
      });
    },
    onError: (error) => {
      const sliTimerEndTime = Date.now();
      sliMetrics.track(QueryKeys.PLANNED_CHECKLISTS_FROM_SCHEDULER, {
        sliTimerMilliseconds: sliTimerStartTime.current
          ? sliTimerEndTime - sliTimerStartTime.current
          : undefined,
        status: 'error',
        networkSpeedMbps: navigator.connection?.downlink,
      });

      captureException(error, {
        level: 'error',
        tags: {
          queryKey: QueryKeys.PLANNED_CHECKLISTS_FROM_SCHEDULER,
        },
      });

      makeToast(
        {
          body: t(
            'FETCH_PLANNED_CHECKLISTS_TOAST_ERROR',
            'An error occurred while fetching the planned checklists.'
          ),
          type: 'warning',
        },
        { autoClose: 7000 }
      );
    },
  });

  return {
    ...query,
  };
};
