import assertNever from '../utils/assertNever';
import isFontSizeInPixelUnits from '../utils/isFontSizeInPixelUnits';

import {
  Annotation,
  AnnotationType,
  isEllipseAnnotation,
  isImageAnnotation,
  isLabelAnnotation,
  isPolylineAnnotation,
  isSvgAnnotation,
  isRectangleAnnotation,
  isSingleDocumentAnnotation,
  isStickyAnnotation,
  isTextAnnotation,
  isCauseMapNodeAnnotation,
  isCommentAnnotation,
} from './types';

const validate = (annotation: Annotation): string | undefined => {
  if (!Object.values(AnnotationType).includes(annotation.type)) {
    return `Invalid annotation type: ${annotation.type}`;
  }

  if (isRectangleAnnotation(annotation) || isImageAnnotation(annotation)) {
    return undefined;
  }

  if (isEllipseAnnotation(annotation)) {
    if (typeof annotation.radius == 'number' && annotation.radius <= 0) {
      return 'Ellipsis radius must be greater than 0';
    }
    const radius = annotation.radius as { x: number; y: number };
    if (radius.x <= 0) {
      return 'Ellipsis radius in the x-axis must be greater than 0';
    }
    if (radius.y <= 0) {
      return 'Ellipsis radius in the y-axis must be greater than 0';
    }

    return undefined;
  }

  if (isPolylineAnnotation(annotation)) {
    if (
      annotation.fromId === undefined &&
      annotation.toId === undefined &&
      (annotation.vertices === undefined || annotation.vertices.length < 2)
    ) {
      return 'Polyline annotation must either have at least 2 vertices or be a connection between two annotations';
    }
    return undefined;
  }

  if (isSvgAnnotation(annotation)) {
    if (annotation.paths === undefined) {
      return 'SVG annotation must have a path value';
    }

    if (
      !isSingleDocumentAnnotation(annotation) &&
      !isFontSizeInPixelUnits(annotation.size)
    ) {
      return 'SVG annotation without parent container must have its size in absolute pixel units';
    }

    return undefined;
  }

  if (isTextAnnotation(annotation)) {
    if (annotation.text.length === 0) {
      return 'Text annotation must have text';
    }

    if (isSingleDocumentAnnotation(annotation)) {
      if (
        typeof annotation.style.fontSize === 'number' &&
        annotation.style.fontSize <= 0
      ) {
        return 'Text font size must be greater than 0';
      }
    } else {
      if (!isFontSizeInPixelUnits(annotation.style.fontSize)) {
        return 'Text annotation without parent container must have its font size in absolute pixel units';
      }
    }

    return undefined;
  }

  if (isLabelAnnotation(annotation)) {
    if (annotation.text === '') {
      return 'Label text cannot be empty';
    }

    if (isSingleDocumentAnnotation(annotation)) {
      if (
        typeof annotation.style.fontSize === 'number' &&
        annotation.style.fontSize <= 0
      ) {
        return 'Label font size must be greater than 0';
      }
    } else {
      if (!isFontSizeInPixelUnits(annotation.style.fontSize)) {
        return `Label without parent container must have its font size in absolute pixel units, received ${annotation.style.fontSize}`;
      }
    }

    return undefined;
  }

  if (isStickyAnnotation(annotation)) {
    if (annotation.width < 0) {
      return 'Sticky annotation width cannot be less than 0';
    }

    if (annotation.height < 0) {
      return 'Sticky annotation height cannot be less than 0';
    }

    return undefined;
  }

  if (isCauseMapNodeAnnotation(annotation)) {
    if (annotation.width < 0) {
      return 'Cause map node annotation width cannot be less than 0';
    }

    if (annotation.height < 0) {
      return 'Cause map node annotation height cannot be less than 0';
    }

    return undefined;
  }

  if (isCommentAnnotation(annotation)) {
    if (annotation.author === '') {
      return 'Comment annotation author cannot be empty';
    }
    return undefined;
  }

  assertNever(annotation, 'Annotation type not validated:' + annotation);
};

export default validate;
