import { useFlag } from '@cognite/react-feature-flags';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import {
  ACTIVITY_BLACKLIST_FIELDS,
  ACTIVITY_BLACKLIST_FIELDS_AKERBP,
} from '@infield/features/activities/consts';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { useQuery } from '@tanstack/react-query';

import type { FieldInfo } from './types';
import { getTableFieldsFromViewDefinition } from './utils';

export const useActivityViewFields = () => {
  const { activityService } = useFDMServices();
  const { isEnabled: isAkerbpCustomCode } = useFlag(
    UnleashKeys.akerbp_custom_code,
    { forceRerender: true, fallback: false }
  );

  return useQuery<{ fields: FieldInfo[] }>(
    [QueryKeys.ACTIVITY_INTROSPECTION],
    async () => {
      const activityView = await activityService.getActivityView();

      const fields = getTableFieldsFromViewDefinition(
        activityView,
        isAkerbpCustomCode
          ? ACTIVITY_BLACKLIST_FIELDS_AKERBP
          : ACTIVITY_BLACKLIST_FIELDS
      );

      return { fields };
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
