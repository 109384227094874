import { randomInteger, sleep } from '@cognite/lodashy';

import { OrgAdmin } from './OrgAdmin';
import { Principal, PrincipalId, PrincipalType } from './Principal';
import {
  ServiceAccount,
  ServiceAccountCreateRequest,
  ServiceAccountDeleteRequest,
  ServiceAccountId,
  ServiceAccountUpdateRequest,
} from './ServiceAccount';
import {
  ServiceAccountSecret,
  ServiceAccountSecretCreateRequest,
  ServiceAccountSecretDeleteRequest,
} from './ServiceAccountSecret';
import { User } from './User';

type ListResponse<T> = {
  items: T[];
  nextCursor?: string;
};

type SignIn = {
  orgId: string;
};

type InvitationResourceTypes = 'Canvas';

export type InvitedUser = { user: User };
export type InvitedEmail = { email: string };
export type InvitedId = { id: string };
export type InvitationByResourceResponseItem = InvitedEmail | InvitedUser;
export type InvitationByUserResponseItem = {
  resource: {
    kind: InvitationResourceTypes;
    externalId: string;
  };
};
export type OemStyle = string | undefined;
type GetTokenFn = () => Promise<string>;

export enum DataModelingStatus {
  DataModelingOnly = 'DATA_MODELING_ONLY',
  DataModelingFirst = 'DATA_MODELING_FIRST',
  Hybrid = 'HYBRID',
}

export interface DataModelingStatusResponseItem {
  apiUrl: string;
  dataModelingStatus: DataModelingStatus;
  name: string;
}

export interface CogniteApiErrorResponse {
  error: {
    code: number;
    message: string;
    duplicated: object[];
    missing: object[];
  };
}

// TODO(FUS-000): long-term, we should move this logic to the JS SDK
export class CogIdPClient {
  private organization: string;
  private getToken: GetTokenFn;

  constructor(organization: string, getToken: GetTokenFn) {
    this.organization = organization;
    this.getToken = getToken;
  }

  public async getAdmins(organization: string): Promise<OrgAdmin[]> {
    return await this.get<ListResponse<OrgAdmin>>(
      `/api/admin/orgs/${encodeURIComponent(organization)}/admins`
    ).then((res) => res.items);
  }

  public async addOrgAdmins(ids: string[]): Promise<void> {
    return await this.post(
      `/api/admin/orgs/${encodeURIComponent(this.organization)}/admins`,
      true,
      { items: ids },
      new Headers({ 'cdf-version': 'beta' })
    );
  }

  public async deleteOrgAdmins(ids: string[]): Promise<void> {
    return await this.post(
      `/api/admin/orgs/${encodeURIComponent(this.organization)}/admins/delete`,
      true,
      { items: ids },
      new Headers({ 'cdf-version': 'beta' })
    );
  }

  public async *getPrincipals(
    ...principalTypes: PrincipalType[]
  ): AsyncGenerator<Principal[], void, void> {
    let cursor: string | undefined = undefined;
    const org = encodeURIComponent(this.organization);
    const queryParams = new URLSearchParams();

    // The `types` filter must not be changed between requests.
    // It can be repeated to include multiple types.
    for (const t of principalTypes) {
      queryParams.append('types', t);
    }

    do {
      let path = `/api/v1/orgs/${org}/principals`;

      // Cursor will be set for the 2nd request and onwards
      if (cursor !== undefined) {
        queryParams.set('cursor', cursor);
      }
      if (queryParams.size > 0) {
        path = path.concat(`?${queryParams.toString()}`);
      }
      const batch: ListResponse<Principal> = await this.get<
        ListResponse<Principal>
      >(path);

      cursor = batch.nextCursor;
      yield batch.items;
    } while (cursor !== undefined);
  }

  public async getAllPrincipals(
    ...identityType: PrincipalType[]
  ): Promise<Principal[]> {
    const allPrincipals: Principal[] = [];
    for await (const principals of this.getPrincipals(...identityType)) {
      allPrincipals.push(...principals);
    }
    return allPrincipals;
  }

  public async getServiceAccounts(): Promise<ServiceAccount[]> {
    return (await this.getAllPrincipals('SERVICE_ACCOUNT')) as ServiceAccount[];
  }

  public async getAllUsers(): Promise<User[]> {
    return (await this.getAllPrincipals('USER')) as User[];
  }

  public async createServiceAccount(
    request: ServiceAccountCreateRequest
  ): Promise<ServiceAccount> {
    return await this.post<ListResponse<ServiceAccount>>(
      `/api/v1/orgs/${encodeURIComponent(this.organization)}/principals`,
      true,
      { items: [request] },
      new Headers({ 'cdf-version': 'beta' })
    ).then((res) => res.items[0]);
  }

  public async getPrincipalsByReference(
    ...principalIds: PrincipalId[]
  ): Promise<ListResponse<ServiceAccount>> {
    return await this.post<ListResponse<ServiceAccount>>(
      `/api/v1/orgs/${encodeURIComponent(this.organization)}/principals/byids`,
      true,
      { items: principalIds.map((id) => ({ id: id })) },
      new Headers({ 'cdf-version': 'beta' })
    );
  }

  public async updateServiceAccount(
    request: ServiceAccountUpdateRequest
  ): Promise<ServiceAccount> {
    return await this.post<ListResponse<ServiceAccount>>(
      `/api/v1/orgs/${encodeURIComponent(this.organization)}/principals/update`,
      true,
      { items: [request] },
      new Headers({ 'cdf-version': 'beta' })
    ).then((res) => res.items[0]);
  }

  public async deleteServiceAccount(
    request: ServiceAccountDeleteRequest
  ): Promise<void> {
    return await this.post<void>(
      `/api/v1/orgs/${encodeURIComponent(this.organization)}/principals/delete`,
      true,
      { items: [request] },
      new Headers({ 'cdf-version': 'beta' })
    );
  }

  public async getServiceAccountSecrets(
    clientId: ServiceAccountId
  ): Promise<ServiceAccountSecret[]> {
    return await this.get<ListResponse<ServiceAccountSecret>>(
      `/api/v1/orgs/${encodeURIComponent(
        this.organization
      )}/principals/${clientId}/secrets`,
      new Headers({ 'cdf-version': 'beta' })
    ).then((res) => res.items);
  }

  public async createServiceAccountSecret(
    clientId: string,
    request: ServiceAccountSecretCreateRequest
  ): Promise<ListResponse<ServiceAccountSecret>> {
    return await this.post<ListResponse<ServiceAccountSecret>>(
      `/api/v1/orgs/${encodeURIComponent(
        this.organization
      )}/principals/${clientId}/secrets`,
      true,
      { items: [request] },
      new Headers({ 'cdf-version': 'beta' })
    );
  }

  public async deleteServiceAccountSecret(
    clientId: string,
    request: ServiceAccountSecretDeleteRequest
  ): Promise<void> {
    return await this.post<void>(
      `/api/v1/orgs/${encodeURIComponent(
        this.organization
      )}/principals/${clientId}/secrets/delete`,
      true,
      { items: [request] },
      new Headers({ 'cdf-version': 'beta' })
    );
  }

  public static getSigninFromState = async (
    clientId: string,
    state: string
  ): Promise<SignIn> => {
    const path = `/api/v0/oauth2/clients/${encodeURIComponent(
      clientId
    )}/signins/${encodeURIComponent(state)}`;
    const res = await this.request<SignIn>({
      method: 'GET',
      path,
      retriable: true,
    });

    return res;
  };

  public getInvitationsByUser = async ({
    project,
  }: {
    project: string;
  }): Promise<InvitationByUserResponseItem[]> => {
    const response = await this.post<
      ListResponse<InvitationByUserResponseItem>
    >(`${this.computePathWithProject(project)}/invitations/byuser`, true, {});

    return response.items;
  };

  public getInvitationsByResource = async ({
    project,
    resourceType,
    externalId,
  }: {
    project: string;
    resourceType: InvitationResourceTypes;
    externalId: string;
  }): Promise<InvitationByResourceResponseItem[]> => {
    const response = await this.post<
      ListResponse<InvitationByResourceResponseItem>
    >(`${this.computePathWithProject(project)}/invitations/byresource`, true, {
      resource: {
        kind: resourceType,
        externalId,
      },
    });
    return response.items;
  };

  public deleteInvitationsByResource = async ({
    project,
    resourceType,
    externalId,
    usersToUninvite,
  }: {
    project: string;
    resourceType: InvitationResourceTypes;
    externalId: string;
    usersToUninvite: (InvitedId | InvitedEmail)[];
  }): Promise<{}> => {
    const response = await this.post<{}>(
      `${this.computePathWithProject(project)}/invitations/delete`,
      true,
      {
        resource: {
          kind: resourceType,
          externalId,
        },
        usersToUninvite,
      }
    );

    return response;
  };

  // TODO(FUS-000): rename to 'inviteToResource'?
  public setInvitationsByResource = async ({
    project,
    resourceType,
    externalId,
    title,
    canvasUrl,
    usersToInvite,
    sendEmail = false,
  }: {
    project: string;
    resourceType: InvitationResourceTypes;
    externalId: string;
    title?: string;
    canvasUrl?: string;
    usersToInvite?: (InvitedId | InvitedEmail)[];
    sendEmail?: boolean;
  }): Promise<(InvitedId | InvitedEmail)[]> => {
    const response = await this.post<ListResponse<InvitedId | InvitedEmail>>(
      `${this.computePathWithProject(project)}/invitations`,
      true,
      {
        resource: {
          kind: resourceType,
          externalId,
          title,
          url: canvasUrl,
        },
        usersToInvite,
        sendEmail,
      }
    );

    return response.items;
  };

  public getOemStyle = async (): Promise<OemStyle> => {
    const response = await this.get<{ style?: OemStyle }>(
      `/api/v0/orgs/${this.organization}/oem`
    );

    return response.style;
  };

  public getDataModelingStatus = async (): Promise<
    DataModelingStatusResponseItem[]
  > => {
    const response = await this.get<
      ListResponse<DataModelingStatusResponseItem>
    >(
      `/api/v0/orgs/${this.organization}/projects?includeDataModelingStatus=true`
    );

    return response.items;
  };

  private static async request<T>({
    method,
    path,
    retriable,
    body,
    token,
    requestHeaders,
  }: {
    method: 'GET' | 'POST';
    path: string;
    retriable: boolean;
    body?: string;
    token?: string;
    requestHeaders?: Headers;
  }): Promise<T> {
    const url = 'https://auth.cognite.com' + path;
    let response: Response;
    const headers = new Headers({
      'content-type': 'application/json',
    });
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }
    if (requestHeaders) {
      requestHeaders.forEach((value, key) => {
        headers.append(key, value);
      });
    }
    let shouldRetry = false;
    let retryCount = 0;
    do {
      response = await fetch(url, {
        method,
        headers,
        body,
      });
      if (!response.ok && retriable) {
        shouldRetry = this.shouldRetry(response.status, retryCount);
        if (shouldRetry) {
          await sleep(this.calculateRetryDelayInMs(retryCount));
          retryCount++;
        }
      } else {
        shouldRetry = false;
      }
    } while (shouldRetry);

    if (!response.ok) {
      const error = (await response.json()) as CogniteApiErrorResponse;
      throw new Error(error.error.message, { cause: error.error });
    }

    if (
      response.headers.get('content-length') === '0' ||
      response.status === 204
    ) {
      // there is no content, do not try to parse it
      return {} as T;
    }

    return (await response.json()) as T;
  }

  private async get<T>(path: string, headers?: Headers): Promise<T> {
    return CogIdPClient.request<T>({
      method: 'GET',
      path,
      retriable: true,
      token: await this.getToken(),
      requestHeaders: headers,
    });
  }

  private async post<T>(
    path: string,
    retriable: boolean,
    body: unknown,
    headers?: Headers
  ): Promise<T> {
    return CogIdPClient.request<T>({
      method: 'POST',
      path,
      retriable,
      body: JSON.stringify(body),
      token: await this.getToken(),
      requestHeaders: headers,
    });
  }

  private static shouldRetry(statusCode: number, retryCount: number): boolean {
    const MAX_RETRIES = 3;
    const inRange = (actual: number, min: number, max: number): boolean =>
      actual >= min && actual <= max;
    if (retryCount >= MAX_RETRIES) {
      return false;
    }
    return inRange(statusCode, 500, 599) || statusCode === 429;
  }

  // Calculate the delay for the next retry attempt
  // using exponential backoff with jitter
  private static calculateRetryDelayInMs(retryCount: number): number {
    const INITIAL_RETRY_DELAY_IN_MS = 250;
    return randomInteger(
      0,
      INITIAL_RETRY_DELAY_IN_MS + ((Math.pow(2, retryCount) - 1) / 2) * 1000
    );
  }

  private computePathWithProject = (project: string): string => {
    return `${this.computePathWithOrg()}/projects/${encodeURIComponent(
      project
    )}`;
  };

  private computePathWithOrg = (): string => {
    return `/api/v0/orgs/${encodeURIComponent(this.organization)}`;
  };
}
