import type {
  Action,
  Condition,
  ConditionalAction,
  Measurement,
  TaskInterval,
  TemplateItem,
} from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import type { Edge } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import {
  getGroupLastOrder,
  getGroupName,
} from '@infield/features/template/utils';
import { useCurrentUserContext } from '@infield/providers/current-user-provider';
import { useFDMServices } from '@infield/providers/fdm-services';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import { v4 as uuid } from 'uuid';

interface Props {
  templateItems: TemplateItem[];
  itemsToDuplicate: TemplateItem[];
  intervals?: TaskInterval[];
  measurements?: Measurement[];
  selectedTemplateId?: string;
}

type MutationContext = {
  sliTimerStartTime: number;
};

export const useTemplateItemsDuplicate = () => {
  const {
    templateService,
    measurementsService,
    intervalService,
    apmClient,
    conditionalActionsService,
    conditionsService,
    actionsService,
  } = useFDMServices();
  const queryClient = useQueryClient();
  const { user } = useCurrentUserContext();
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const templateItemAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditTemplateItem
  );
  const measurementAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditMeasurement
  );
  const scheduleAuditMetrics = useMetrics(METRICS_NAMESPACES.auditSchedule);
  const conditionalActionsAuditMetrics = useMetrics(
    METRICS_NAMESPACES.auditConditionalAction
  );
  const conditionAuditMetrics = useMetrics(METRICS_NAMESPACES.auditCondition);
  const actionAuditMetrics = useMetrics(METRICS_NAMESPACES.auditAction);
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  return useMutation<
    {
      duplicatedTemplateItems: TemplateItem[];
      duplicatedMeasurements: Measurement[];
      duplicatedIntervals: TaskInterval[];
      duplicatedConditionalActions: ConditionalAction[];
      duplicatedConditions: Condition[];
      duplicatedActions: Action[];
    },
    Error,
    Props,
    MutationContext
  >(
    async ({ templateItems, itemsToDuplicate, selectedTemplateId = '' }) => {
      const measurementsToDuplicate: Measurement[] = [];
      const measurementEdgesToDuplicate: Edge[] = [];
      const intervalsToDuplicate: TaskInterval[] = [];
      const conditionalActionsToDuplicate: ConditionalAction[] = [];
      const conditionsToDuplicate: Condition[] = [];
      const actionsToDuplicate: Action[] = [];

      const edgesToDuplicate: Edge[] = [];

      const duplicatedTemplateItems: TemplateItem[] = itemsToDuplicate.map(
        (templateItem) => {
          const taskMeasurements = templateItem.measurements;
          const newTaskId = uuid();
          taskMeasurements?.forEach((measurement) => {
            const newMeasurementId = uuid();
            measurementsToDuplicate.push({
              ...measurement,
              externalId: newMeasurementId,
            });
            measurementEdgesToDuplicate.push({
              externalId: `${newTaskId}_${newMeasurementId}_relation`,
              modelName: 'referenceMeasurements',
              startNode: newTaskId,
              endNode: newMeasurementId,
            });
          });
          const taskGroupName = getGroupName(templateItem.labels);
          const isGroupTask = Boolean(taskGroupName);
          const lastGroupOrder = getGroupLastOrder(
            taskGroupName,
            templateItems
          );

          const taskOrders = templateItems.map((task) => task.order || 0);
          // added +1 to cover the case when group is last in the list
          const lastOrder = Math.max(...taskOrders) + 1;

          templateItem.schedules?.forEach((interval) => {
            const newInterval = {
              ...interval,
              externalId: uuid(),
            };
            intervalsToDuplicate.push(newInterval);

            edgesToDuplicate.push({
              externalId: `${newTaskId}_${newInterval.externalId}_relation`,
              modelName: 'referenceSchedules',
              startNode: newTaskId,
              endNode: newInterval.externalId,
            });
          });

          templateItem.conditionalActions?.forEach((conditionalAction) => {
            const {
              conditions: _conditions,
              actions: _actions,
              ...conditionalActionToDuplicate
            } = conditionalAction;

            const newConditionalAction: ConditionalAction = {
              ...conditionalActionToDuplicate,
              externalId: uuid(),
              parentObject: {
                externalId: newTaskId,
                space: templateItem.space,
              },
            };

            conditionalActionsToDuplicate.push(newConditionalAction);

            conditionalAction.conditions?.forEach((condition) => {
              const newCondition: Condition = {
                ...condition,
                externalId: uuid(),
                source: {
                  externalId: newTaskId,
                  space: templateItem.space,
                },
                conditionalAction: {
                  externalId: newConditionalAction.externalId,
                  space: templateItem.space,
                },
              };

              conditionsToDuplicate.push(newCondition);
            });

            conditionalAction.actions?.forEach((action) => {
              const newAction: Action = {
                ...action,
                externalId: uuid(),
                conditionalActions: {
                  externalId: newConditionalAction.externalId,
                  space: templateItem.space,
                },
              };

              actionsToDuplicate.push(newAction);
            });
          });

          edgesToDuplicate.push({
            externalId: `${selectedTemplateId}_${newTaskId}_relation`,
            modelName: 'referenceTemplateItems',
            startNode: selectedTemplateId,
            endNode: newTaskId,
          });

          return {
            ...templateItem,
            externalId: newTaskId,
            // put task at the end of list or group , reordering logic will take care of the rest
            order: isGroupTask ? lastGroupOrder : lastOrder,
          };
        }
      );
      const duplicatedTemplateItemsToUpsert = duplicatedTemplateItems.map(
        (item) => {
          const {
            measurements: _measurements,
            schedules: _schedules,
            conditionalActions: _conditionalActions,
            space: _space,
            ...templateItem
          } = item;
          return templateItem;
        }
      );

      const batchedDuplicatedTemplateItems = chunk(
        duplicatedTemplateItemsToUpsert,
        dmsItemIngestionLimit
      );

      const batchedEdges = chunk(edgesToDuplicate, dmsItemIngestionLimit);

      const batchedMeasurementEdges = chunk(
        measurementEdgesToDuplicate,
        dmsItemIngestionLimit
      );

      const batchedConditionalActions = chunk(
        conditionalActionsToDuplicate,
        dmsItemIngestionLimit
      );

      const batchedConditions = chunk(
        conditionsToDuplicate,
        dmsItemIngestionLimit
      );

      const batchedActions = chunk(actionsToDuplicate, dmsItemIngestionLimit);

      try {
        // create nodes
        await Promise.all(
          batchedDuplicatedTemplateItems.map((nodes) =>
            templateService.createTemplateItems(nodes, user!)
          )
        );
        if (measurementsToDuplicate.length > 0) {
          await measurementsService.createMeasurements(
            measurementsToDuplicate,
            user!
          );
        }
        if (intervalsToDuplicate.length > 0) {
          await intervalService.createIntervals(intervalsToDuplicate, user!);
        }
        if (conditionalActionsToDuplicate.length > 0) {
          await Promise.all(
            batchedConditionalActions.map((nodes) =>
              conditionalActionsService.createConditionalActions(nodes, user!)
            )
          );
        }
        if (conditionsToDuplicate.length > 0) {
          await Promise.all(
            batchedConditions.map((nodes) =>
              conditionsService.createConditions(nodes, user!, 'TemplateItem')
            )
          );
        }
        if (actionsToDuplicate.length > 0) {
          await Promise.all(
            batchedActions.map((nodes) =>
              actionsService.createActions(nodes, user!)
            )
          );
        }

        // create edges
        await Promise.all(
          batchedEdges.map((edges) => apmClient.edgeService.upsert(edges))
        );
        await Promise.all(
          batchedMeasurementEdges.map((edges) =>
            apmClient.edgeService.upsert(edges)
          )
        );

        return {
          duplicatedTemplateItems: duplicatedTemplateItemsToUpsert,
          duplicatedMeasurements: measurementsToDuplicate,
          duplicatedIntervals: intervalsToDuplicate,
          duplicatedConditionalActions: conditionalActionsToDuplicate,
          duplicatedConditions: conditionsToDuplicate,
          duplicatedActions: actionsToDuplicate,
        };
      } catch (error) {
        await templateService.deleteTemplateItem(
          duplicatedTemplateItemsToUpsert.map(
            (templateItem) => templateItem.externalId
          )
        );
        if (measurementsToDuplicate.length > 0) {
          await measurementsService.deleteMeasurements(
            measurementsToDuplicate.map((measurement) => measurement.externalId)
          );
        }
        if (intervalsToDuplicate.length > 0) {
          await intervalService.deleteIntervals(
            intervalsToDuplicate.map(({ externalId }) => externalId)
          );
        }
        if (conditionalActionsToDuplicate.length > 0) {
          await conditionalActionsService.deleteConditionalActions(
            conditionalActionsToDuplicate.map(
              (conditionalAction) => conditionalAction.externalId
            )
          );
        }
        if (conditionsToDuplicate.length > 0) {
          await conditionsService.deleteConditions(
            conditionsToDuplicate.map((condition) => condition.externalId)
          );
        }
        if (actionsToDuplicate.length > 0) {
          await actionsService.deleteActions(
            actionsToDuplicate.map((action) => action.externalId)
          );
        }

        throw error;
      }
    },
    {
      onMutate: () => {
        const sliTimerStartTime = Date.now();
        return { sliTimerStartTime };
      },
      onSuccess: async (data, _, context) => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.TEMPLATE],
        });
        makeToast({
          body: t(
            'DUPLICATE_TEMPLATE_ITEMS_SUCCESS',
            '{{count}} tasks duplicated',
            {
              count: data.duplicatedTemplateItems.length,
            }
          ),
          type: 'success',
        });
        data.duplicatedTemplateItems.forEach(() => {
          templateItemAuditMetrics.track('Create');
        });
        data.duplicatedMeasurements.forEach(() => {
          measurementAuditMetrics.track('Create');
        });
        data.duplicatedIntervals.forEach(() => {
          scheduleAuditMetrics.track('Create');
        });
        data.duplicatedConditionalActions.forEach(() => {
          conditionalActionsAuditMetrics.track('Create');
        });
        data.duplicatedConditions.forEach(() => {
          conditionAuditMetrics.track('Create');
        });
        data.duplicatedActions.forEach(() => {
          actionAuditMetrics.track('Create');
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_ITEM_DUPLICATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onError: (err, _, context) => {
        makeToast({
          body: t(
            'DUPLICATE_TEMPLATE_ITEMS_ERROR',
            'Failed to duplicate tasks'
          ),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: MutationKeys.TEMPLATE_ITEM_DUPLICATE,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.TEMPLATE_ITEM_DUPLICATE, {
          sliTimerMilliseconds: context?.sliTimerStartTime
            ? sliTimerEndTime - context.sliTimerStartTime
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
    }
  );
};
