import { Infobox } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n/constants';
import { useTranslation } from '@infield/features/i18n/use-translation';

export const CorruptedDataInfoBox = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.infobar);
  return (
    <Infobox
      status="neutral"
      title={t('CORRUPTED_DATA_INFO_BOX_TITLE', 'Editing restricted')}
    >
      {t(
        'CORRUPTED_DATA_INFO_BOX_CONTENT',
        'This checklist was affected by a data issue and is locked for editing until auditing is complete.'
      )}
    </Infobox>
  );
};
