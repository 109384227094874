import type { Checklist } from '@cognite/apm-client';
import type { DateRangeType } from '@cognite/apm-observation';
import { createContext, useContext } from 'react';

import type { MissingChecklistsState } from './types';

export type FetchPlannedChecklistsResponse = {
  numberOfPlannedChecklists?: number;
};

export type ChecklistSchedulerContextType = {
  isDateFilterValid: boolean;
  numPlannedChecklists: number;
  fetchPlannedChecklists: () => Promise<void>;
  createChecklists: () => Promise<void>;
  missingChecklistsState: MissingChecklistsState;
  resetAndCloseBanner: () => void;
  numCreatedChecklists: number;
  isChecklistSchedulerEnabled: boolean;
  isSchedulesPageEnabled: boolean;
  viewCreatedChecklists: () => void;
  dateFilterWithoutFutureDays: DateRangeType;
  retryFetchAndCreateChecklists: () => Promise<void>;
};

export const ChecklistSchedulerContext = createContext<
  ChecklistSchedulerContextType | undefined
>(undefined);

export const useChecklistSchedulerContext =
  (): ChecklistSchedulerContextType => {
    const context = useContext(ChecklistSchedulerContext);
    if (!context) {
      throw new Error(
        'useChecklistScheduler must be used within a ChecklistSchedulerProvider'
      );
    }
    return context;
  };
