import { AppConfigClient, AppConfigClientV2 } from '@cognite/apm-config';
import { makeToast } from '@cognite/cogs-lab';
import { useAuthContext } from '@cognite/e2e-auth';
import { useFlag } from '@cognite/react-feature-flags';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import type { AppConfig } from '@infield/features/app-config/types';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpsertAppConfigMutation = () => {
  const coreAuth = useAuthContext();
  const queryClient = useQueryClient();

  const { isEnabled: isUsingNewConfigSetup } = useFlag(
    UnleashKeys.INAPP_New_Config_Setup
  );

  return useMutation<
    void,
    Error,
    { newAppConfig: Partial<AppConfig> & { externalId: string } }
  >(
    async ({ newAppConfig }) => {
      const schemaVersionOverride =
        coreAuth.authState?.status === 'AUTHENTICATED'
          ? coreAuth.authState.projectInfo?.configurationSchemaVersion
          : undefined;
      const appConfigClient = new AppConfigClient(
        coreAuth.client,
        schemaVersionOverride
      );

      const nextConfig: Partial<AppConfig> & { externalId: string } = {
        externalId: newAppConfig.externalId,
        customerDataSpaceId: newAppConfig.customerDataSpaceId,
        customerDataSpaceVersion: newAppConfig.customerDataSpaceVersion,
        appDataSpaceId: newAppConfig.appDataSpaceId,
        appDataSpaceVersion: newAppConfig.appDataSpaceVersion,
        featureConfiguration: newAppConfig.featureConfiguration,
      };

      if (isUsingNewConfigSetup) {
        const appConfigClientV2 = new AppConfigClientV2(coreAuth.client, {
          externalId: 'CDF_AppLocationConfig',
          space: 'cdf_apps_shared',
          version: 'v1',
        });

        await appConfigClientV2.upsertConfiguration(nextConfig, {
          externalId: nextConfig.externalId,
          featureConfiguration: {
            ...nextConfig.featureConfiguration,
            legacyConfigurations: {
              maintain_rootLocationsConfiguration:
                nextConfig.rootLocationsConfiguration,
              appDataSpaceId: nextConfig.appDataSpaceId,
              appDataSpaceVersion: nextConfig.appDataSpaceVersion,
              customerDataSpaceVersion: nextConfig.customerDataSpaceVersion,
            },
          },
          isDefault: nextConfig.isDefault ?? false,
          fieldConfiguration: nextConfig.fieldConfiguration ?? {},
          locationFiltersExternalId: '',
        });
        return;
      }

      await appConfigClient.upsertConfiguration(nextConfig);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.APP_CONFIG],
        });
      },
      onError: (err) => {
        makeToast({
          body: 'You do not have permission to create/edit configs.',
          type: 'danger',
        });
        captureException(err);
      },
    }
  );
};
