import { useEffect } from 'react';

import {
  ColumnComponent,
  RowComponent,
  TabulatorFull as Tabulator,
} from 'tabulator-tables';

import { DataGridColumn, DataGridRow } from './types';

const getRowHeight = (row: RowComponent) => {
  const shamefulRowHeight = (row as any)._row.height;
  if (typeof shamefulRowHeight !== 'number' || !row.getElement()) {
    return undefined;
  }
  return shamefulRowHeight;
};

const useAttachEventListeners = ({
  tableRef,
  rows,
  columns,
  onRowsUpdated,
  onColumnsUpdated,
  setForceUpdate,
}: {
  tableRef: React.MutableRefObject<Tabulator | null>;
  rows: DataGridRow[];
  columns: DataGridColumn[];
  onRowsUpdated: (rows: DataGridRow[]) => void;
  onColumnsUpdated: (columns: DataGridColumn[]) => void;
  setForceUpdate: React.Dispatch<React.SetStateAction<number>>;
}) => {
  useEffect(() => {
    const table = tableRef.current;
    const onRowResized = (row: RowComponent) => {
      const nextHeight = getRowHeight(row);
      if (nextHeight === undefined) {
        return;
      }
      onRowsUpdated(
        rows.map((r) =>
          r.id !== row.getData().id ? r : { ...r, height: nextHeight }
        )
      );
      setForceUpdate((prev) => prev + 1);
    };

    const onRowHeightChanged = (row: RowComponent) => {
      const nextHeight = getRowHeight(row);
      if (nextHeight !== undefined) {
        row.getElement().style.height = `${nextHeight}px`;
      }
    };

    const onRowMoved = () => {
      if (table === null) {
        return;
      }
      const rowsById = new Map(rows.map((r) => [r.id, r]));
      // On row move, update the order of the rows based on the new order (given by table.getData())
      onRowsUpdated(
        table
          .getData()
          .map((t) => rowsById.get(t.id))
          .filter((r) => r !== undefined)
      );
    };

    const onColumnWidthChanged = (column: ColumnComponent) => {
      column.getElement().style.width = `${column.getWidth()}px`;
    };

    const onColumnResized = (column: ColumnComponent) => {
      onColumnsUpdated(
        columns.map((c) =>
          c.key !== column.getField() ? c : { ...c, width: column.getWidth() }
        )
      );
      setForceUpdate((prev) => prev + 1);
    };

    // Set up event listeners for resizing the container when the table
    // rows/columns are resized
    table?.on('rowResized', onRowResized);
    table?.on('rowHeight', onRowHeightChanged);
    table?.on('rowMoved', onRowMoved);
    table?.on('columnWidth', onColumnWidthChanged);
    table?.on('columnResized', onColumnResized);

    return () => {
      table?.off('rowResized', onRowResized);
      table?.off('rowHeight', onRowHeightChanged);
      table?.off('rowMoved', onRowMoved);
      table?.off('columnWidth', onColumnWidthChanged);
      table?.off('columnResized', onColumnResized);
    };
  }, [
    columns,
    setForceUpdate,
    onColumnsUpdated,
    onRowsUpdated,
    rows,
    tableRef,
  ]);
};

export default useAttachEventListeners;
