import Konva from 'konva';

import pdfExporterContent from '../../../workers/pdfExporter.worker.esm';
import pngEncoderContent from '../../../workers/pngEncoder.worker.esm';
import type { Size } from '../../annotations/types';

import { DEFAULT_FOOT_NOTE_STYLE } from './constants';
import type {
  ExportPageData,
  FootNoteStyle,
  ImageBuffer,
  PngBuffer,
} from './types';

// WORKAROUND: We trick the bundler to import our worker source by adding an
// extra level of indirection by converting our source to json which is then
// loaded as a Javascript source code in the browser's blob storage. We create
// an URL for that blob and use that as our worker source. Amongst all the
// different approaches I've tried, this is the one that provided the best
// performance on our performance stories (perhaps even better than before).
const PNG_ENCODER_WORKER_URL = URL.createObjectURL(
  new Blob([pngEncoderContent], { type: 'text/javascript' })
);
const PDF_EXPORTER_WORKER_URL = URL.createObjectURL(
  new Blob([pdfExporterContent], { type: 'text/javascript' })
);

export const getPngEncodedBuffers = async (
  images: ImageBuffer[]
): Promise<PngBuffer[]> => {
  const worker = new Worker(PNG_ENCODER_WORKER_URL);
  worker.postMessage(images);
  return new Promise<PngBuffer[]>((resolve, reject) => {
    worker.onmessage = async ({ data }) => resolve(data);
    worker.onerror = (evt) => reject(evt.message);
  });
};

export const exportPageDataToPdf = async (
  buffers: ExportPageData[]
): Promise<string> => {
  const worker = new Worker(PDF_EXPORTER_WORKER_URL);
  worker.postMessage(buffers);
  return new Promise<string>((resolve, reject) => {
    worker.onmessage = async ({ data }) => resolve(data);
    worker.onerror = (evt) => reject(evt.message);
  });
};

export const createFootNotesTextNode = (
  footNotes: string[],
  contentRect: Size,
  style: FootNoteStyle = DEFAULT_FOOT_NOTE_STYLE
): Konva.Text => {
  const { fontSize, fontSizePercent, fontFamily, fill, lineHeight } = {
    ...DEFAULT_FOOT_NOTE_STYLE,
    ...style,
  };

  // TODO(FUS-000): Improve footNote rendering and API.
  return new Konva.Text({
    text: footNotes.join('\n'),
    x: 0,
    y: Math.round(contentRect.height),
    padding: 40,
    fontSize: fontSize ?? Math.round(contentRect.height * fontSizePercent),
    fill: fill,
    fontFamily: fontFamily,
    lineHeight: lineHeight,
  });
};
