import type { Checklist } from '@cognite/apm-client';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { MutationKeys, QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';

import type { SchedulerCreatedNode } from '../types';

export const useCreatePlannedChecklistsMutation = () => {
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);
  const metrics = useMetrics(METRICS_NAMESPACES.scheduledChecklists);
  const { checklistSchedulerService } = useFDMServices();
  const sliTimerStartTime = useRef<number | undefined>(undefined);
  const queryClient = useQueryClient();

  return useMutation<SchedulerCreatedNode[], Error, Checklist[]>(
    async (plannedChecklists) => {
      sliTimerStartTime.current = Date.now();

      const createdChecklists =
        await checklistSchedulerService.createMissingChecklists(
          plannedChecklists
        );
      return createdChecklists;
    },
    {
      onError: (error) => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.CHECKLIST_CREATE_FROM_SCHEDULER, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });

        captureException(error, {
          level: 'error',
          tags: {
            queryKey: MutationKeys.CHECKLIST_CREATE_FROM_SCHEDULER,
          },
        });
      },
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.OPERATION_CHECKLIST_LIST],
        });
        metrics.track('createChecklists', {
          numberOfReturnedItems: data.length,
          numberOfRequestedItems: variables.length,
        });
        const sliTimerEndTime = Date.now();
        sliMetrics.track(MutationKeys.CHECKLIST_CREATE_FROM_SCHEDULER, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
    }
  );
};
