import Konva from 'konva';
import { IRect } from 'konva/lib/types';
import { intersection, isEqual } from 'lodash';
import forEach from 'lodash/forEach';
import omit from 'lodash/omit';

import getAnnotationSerializerByType from '../../annotations/getAnnotationSerializerByType';
import {
  AnnotationType,
  isCauseMapNodeAnnotationNode,
  isCommentAnnotationNode,
  isPolylineNode,
  Position,
} from '../../annotations/types';
import getContainerFromContainerNode from '../../containers/getContainerFromContainerNode';
import type { UnifiedViewer } from '../../UnifiedViewer';
import UnifiedViewerEventType from '../../UnifiedViewerEventType';
import { UnifiedViewerPointerEvent } from '../../UnifiedViewerRenderer/UnifiedEventHandler';
import UnifiedViewerTransformer, {
  SELECTION_RECTANGLE_ID,
  SELECTION_RECTANGLE_STYLE,
} from '../../UnifiedViewerTransformer';
import {
  isPrimaryMouseButtonPressed,
  isSecondaryMouseButtonPressed,
  isShiftKeyPressed,
  isSingleTouch,
} from '../../utils/eventUtils';
import filterUniqueAncestorNodes from '../../utils/filterUniqueAncestorNodes';
import findNearestAncestor from '../../utils/findNearestAncestor';
import getEuclideanDistance from '../../utils/getEuclideanDistance';
import getNodeIdsByType from '../../utils/getNodeIdsByType';
import isAnnotationNode from '../../utils/isAnnotationNode';
import isContainerNode from '../../utils/isContainerNode';
import isNotUndefined from '../../utils/isNotUndefined';
import { isLineSegment } from '../../utils/mathUtils';
import {
  isDraggableNode,
  isResizableNode,
  isSelectableNode,
} from '../../utils/nodeUtils';
import {
  isLineIntersectingWithRectangle,
  areRectsOverlapping,
  isRectInsideRect,
} from '../../utils/rectUtils';
import findContainerHeightWithoutOverflow from '../CauseMapTool/findContainerHeightWithoutOverflow';
import getTextTagBoxPropertiesFromCauseMapNode from '../CauseMapTool/getTextTagBoxPropertiesFromCauseMapNode';
import { ContainerAttachmentHelper } from '../ContainerAttachmentHelper';
import Tool from '../Tool';
import { type SelectToolConfig, ToolType } from '../types';

const AnchorNames = [
  'top-left',
  'top-center',
  'top-right',
  'middle-right',
  'middle-left',
  'bottom-left',
  'bottom-center',
  'bottom-right',
];

const UniformScaleAnchorNames = [
  'top-left',
  'top-right',
  'bottom-left',
  'bottom-right',
];

const MAX_MOUSE_DELTA_PX = 10;
const CAUSE_MAP_AUTO_FIT_PADDING = 25;

const hasDependencies = (node: Konva.Node) =>
  node instanceof Konva.Arrow &&
  (node.attrs.fromId !== undefined || node.attrs.toId !== undefined);

const detachNodeFromDependencies = (node: Konva.Node) => {
  if (node instanceof Konva.Arrow) {
    node.attrs.fromId = undefined;
    node.attrs.toId = undefined;
  }

  return node;
};

const findNearestDraggableNode = (node: Konva.Node) => {
  return findNearestAncestor<Konva.Node>(isDraggableNode)(node);
};

const findNearestSelectableNode = (node: Konva.Node) => {
  return findNearestAncestor<Konva.Node>(isSelectableNode)(node);
};

const getValidAnchorNames = (node: Konva.Node): string[] => {
  if (isContainerNode(node)) {
    return UniformScaleAnchorNames;
  }
  if (node.attrs.annotationType === AnnotationType.SVG) {
    return UniformScaleAnchorNames;
  }
  return AnchorNames;
};

export default class SelectTool extends Tool {
  public readonly type = ToolType.SELECT;
  private config: SelectToolConfig | undefined = undefined;

  private isDraggingTargets: Konva.Node[] = [];

  private readonly transformer: UnifiedViewerTransformer;
  private mouseDownTarget: Konva.Shape | Konva.Stage | undefined = undefined;
  private mouseDownPosition: Position | null = null;
  private mouseDownScreenPosition: Position | null = null;
  private wasSecondaryMouseButtonPressed: boolean = false;

  private isUsingSelectionRectangle: boolean = false;
  private selectionRectangle: Konva.Rect | undefined = undefined;

  private selectionBeforeInteraction: Set<Konva.Node> = new Set();
  private containerAttachmentHelper: ContainerAttachmentHelper;

  public constructor({
    unifiedViewer,
    config,
  }: {
    unifiedViewer: UnifiedViewer;
    config: SelectToolConfig;
  }) {
    super(unifiedViewer);
    this.transformer = new UnifiedViewerTransformer(unifiedViewer);
    this.containerAttachmentHelper = new ContainerAttachmentHelper(
      unifiedViewer
    );
    this.setConfig(config);
    this.unifiedViewer._shamefulTransformerRef = this.transformer;
    this.transformer.setTransformerLayer(this.unifiedViewer.layers.transformer);
    this.transformer.on('transformstart', this.onTransformStart);
    this.transformer.on('transform', this.onTransform);
    this.transformer.on('transformend', this.onTransformEnd);
    this.unifiedViewer.host.addEventListener('keydown', this.onKeyDown);
  }

  public setConfig(config: SelectToolConfig): void {
    const prevConfig = this.config;
    this.config = config;

    if (
      prevConfig === undefined ||
      prevConfig.shouldGenerateConnections !== config.shouldGenerateConnections
    ) {
      const shouldGenerateConnections =
        config.shouldGenerateConnections === true;
      this.transformer.setShouldGenerateConnections(shouldGenerateConnections);
    }
  }

  public override onDestroy(): void {
    this.handleSelect([]);
    this.transformer.off('transformstart', this.onTransformStart);
    this.transformer.off('transformend', this.onTransformEnd);
    this.selectionRectangle?.destroy();
    this.transformer.onDestroy();
    this.unifiedViewer._shamefulTransformerRef = undefined;
    this.unifiedViewer.stage
      .container()
      .removeEventListener('keydown', this.onKeyDown);
    this.containerAttachmentHelper.onDestroy();
  }

  private handleSelect = (nodes: Konva.Node[]) => {
    const prevNodes = this.transformer.getNodes();
    this.transformer.setNodes(nodes);

    if (isEqual(getNodeIdsByType(prevNodes), getNodeIdsByType(nodes))) {
      return;
    }

    this.unifiedViewer.emit(
      UnifiedViewerEventType.ON_SELECT,
      getNodeIdsByType(nodes)
    );
  };

  public onSelect = (selectedNodes: Konva.Node[]): void => {
    // NOTE: only enable the common anchor(s) between all the selected nodes
    this.transformer.setEnabledAnchors(
      intersection(...selectedNodes.map(getValidAnchorNames))
    );
    this.handleSelect(selectedNodes);
    this.transformer.setResizingEnabled(
      selectedNodes.every(isResizableNode) &&
        !this.transformer.areAnchorsOverlapping()
    );
    // Enable rotation only if a single image annotation(stamp) is selected.
    this.transformer.setRotateEnabled(
      selectedNodes.length === 1 &&
        selectedNodes[0].attrs.annotationType === AnnotationType.IMAGE
    );
    this.unifiedViewer.shamefullyRefreshAnchors(selectedNodes);
  };

  private getMultiNodeSelection = (
    selectedNodes: Konva.Node[]
  ): Konva.Node[] => {
    // Toggle selection state of the given nodes compared to their state before
    // the interaction started
    const newSelection = new Set(this.selectionBeforeInteraction);
    selectedNodes.forEach((node) =>
      newSelection.has(node)
        ? newSelection.delete(node)
        : newSelection.add(node)
    );

    return filterUniqueAncestorNodes([...newSelection]);
  };

  private getStageNodesOverlappingWithSelectionRect = (
    selectionRect: IRect
  ): Konva.Node[] => {
    // Note: this function can get called multiple times per second
    // be aware of performance implications when making changes :)
    const selectedNodes = this.unifiedViewer.layers.main
      .find(isSelectableNode)
      .filter((node) => {
        const selectableNode =
          getContainerFromContainerNode(node)?.getContentNode() ?? node;
        // Don't select top level container nodes, only select the content node
        const nodeClientRect = selectableNode.getClientRect();
        const isSelectionRectangleSufficientlyEnclosingNode = this.unifiedViewer
          .options.shouldOnlySelectFullyEnclosedNodes
          ? isRectInsideRect(nodeClientRect, selectionRect)
          : areRectsOverlapping(nodeClientRect, selectionRect);

        if (!isSelectionRectangleSufficientlyEnclosingNode) {
          return false;
        }

        // Do more fine-grained intersection testing with lines so that we don't just
        // check if the selection rectangle overlaps with the bounding box of the line
        if (isLineSegment(node)) {
          return (
            nodeClientRect !== undefined &&
            isLineIntersectingWithRectangle(
              {
                start: {
                  x: nodeClientRect.x,
                  y: nodeClientRect.y + nodeClientRect.height,
                },
                end: {
                  x: nodeClientRect.x + nodeClientRect.width,
                  y: nodeClientRect.y,
                },
              },
              selectionRect
            )
          );
        }
        return true;
      });

    return filterUniqueAncestorNodes(selectedNodes);
  };

  public override onMouseDown = (e: UnifiedViewerPointerEvent): void => {
    // If we're currently box selecting, ignore other mouse clicks
    if (this.selectionRectangle !== undefined) {
      return;
    }

    const position = this.unifiedViewer.stage.getRelativePointerPosition();
    if (position === null) {
      return;
    }

    this.mouseDownTarget = e.target;
    this.mouseDownPosition = position;
    this.mouseDownScreenPosition =
      this.unifiedViewer.stage.getPointerPosition();
    this.wasSecondaryMouseButtonPressed = isSecondaryMouseButtonPressed(e.evt);
    this.selectionBeforeInteraction = new Set(this.transformer.getNodes());
    if (!isPrimaryMouseButtonPressed(e.evt)) {
      return;
    }

    if (e.target.name().includes('_anchor')) {
      // Ignore transform anchor points
      return;
    }

    const isUsingMultiSelect = isShiftKeyPressed(e.evt);

    const nearestSelectableNode = findNearestSelectableNode(e.target);
    if (nearestSelectableNode === undefined) {
      this.selectionRectangle = new Konva.Rect({
        ...(this.unifiedViewer.options.shouldUseShamefulFastMode
          ? omit(SELECTION_RECTANGLE_STYLE, 'fill')
          : SELECTION_RECTANGLE_STYLE),
        name: SELECTION_RECTANGLE_ID,
        x: this.mouseDownPosition.x,
        y: this.mouseDownPosition.y,
        strokeScaleEnabled: false,
      });
      this.unifiedViewer.layers.transformer.add(this.selectionRectangle);

      if (!isUsingMultiSelect) {
        // Clear selection
        this.onSelect([]);
        this.selectionBeforeInteraction = new Set();
      }
      return;
    }

    this.transformer.setFlipEnabled(false);

    // If the node isn't already selected, we can go ahead and select it
    // onMouseDown. Otherwise, the intent is ambiguous until onMouseMove and
    // onMouseUp.
    if (!this.selectionBeforeInteraction.has(nearestSelectableNode)) {
      this.onSelect(
        isUsingMultiSelect
          ? this.getMultiNodeSelection([nearestSelectableNode])
          : [nearestSelectableNode]
      );
    }
  };

  private hasMouseMoved = (
    maxMouseDeltaPx: number = MAX_MOUSE_DELTA_PX
  ): boolean => {
    const currentMousePosition =
      this.unifiedViewer.stage.getRelativePointerPosition();
    return (
      this.mouseDownPosition !== null &&
      currentMousePosition !== null &&
      getEuclideanDistance(this.mouseDownPosition, currentMousePosition) >
        maxMouseDeltaPx
    );
  };

  public override onMouseMove = (e: UnifiedViewerPointerEvent): void => {
    const target = this.mouseDownTarget;
    const isUsingMultiSelect = isShiftKeyPressed(e.evt);
    const mouseMovePosition =
      this.unifiedViewer.stage.getRelativePointerPosition();
    if (
      this.mouseDownPosition !== null &&
      mouseMovePosition !== null &&
      this.selectionRectangle !== undefined
    ) {
      if (!this.isUsingSelectionRectangle) {
        this.isUsingSelectionRectangle = true;
        this.unifiedViewer.emit(UnifiedViewerEventType.ON_SELECTION_DRAG_START);
      }

      this.selectionRectangle.size({
        width: mouseMovePosition.x - this.mouseDownPosition.x,
        height: mouseMovePosition.y - this.mouseDownPosition.y,
      });
      const selectionClientRect = this.selectionRectangle.getClientRect();
      const selectedNodes =
        this.getStageNodesOverlappingWithSelectionRect(selectionClientRect);
      this.onSelect(
        isUsingMultiSelect
          ? this.getMultiNodeSelection(selectedNodes)
          : selectedNodes
      );
      return;
    }

    if (target === undefined) {
      return;
    }

    if (target.name().includes('_anchor')) {
      // Ignore transform anchor points
      return;
    }

    if (!isPrimaryMouseButtonPressed(e.evt) && !isSingleTouch(e.evt)) {
      return;
    }

    const nearestDraggableNodes = this.transformer
      .getNodes()
      .map(findNearestDraggableNode)
      .filter(isNotUndefined);
    if (nearestDraggableNodes.length === 0) {
      return;
    }

    if (
      nearestDraggableNodes.some(
        (node) =>
          this.transformer.isNodeInActiveMode(node.id()) &&
          !node.attrs?.container.canBeDraggedWhileActive()
      )
    ) {
      return;
    }

    if (
      !isEqual(this.isDraggingTargets, nearestDraggableNodes) &&
      this.hasMouseMoved()
    ) {
      this.isDraggingTargets = nearestDraggableNodes;
      nearestDraggableNodes.forEach((n) => n.startDrag());
      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_DRAG_START,
        getNodeIdsByType(nearestDraggableNodes)
      );
      this.unifiedViewer.setDirtyNodes(nearestDraggableNodes);
    } else if (
      this.isDraggingTargets.length !== 0 &&
      isEqual(this.isDraggingTargets, nearestDraggableNodes)
    ) {
      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_DRAG_MOVE,
        getNodeIdsByType(nearestDraggableNodes)
      );
    }

    // Container Annotations interaction
    //
    // Allow dragging annotations onto a container to group them, or off of a
    // container to ungroup.
    if (
      !this.containerAttachmentHelper.isActive &&
      this.isDraggingTargets.length > 0 &&
      this.isDraggingTargets.every(
        (dragTarget) =>
          isAnnotationNode(dragTarget) &&
          // Cause map nodes cannot be dragged onto containers
          !isCauseMapNodeAnnotationNode(dragTarget)
      )
    ) {
      this.containerAttachmentHelper.start(this.isDraggingTargets);
    }
  };

  private clearSelectionRectangle = (): void => {
    if (this.isUsingSelectionRectangle) {
      this.unifiedViewer.emit(UnifiedViewerEventType.ON_SELECTION_DRAG_END);
    }
    this.isUsingSelectionRectangle = false;
    this.selectionRectangle?.destroy();
    this.selectionRectangle = undefined;
  };

  private handleContextMenu = (position: Position | null): void => {
    if (position === null) {
      return;
    }
    if (!this.wasSecondaryMouseButtonPressed) {
      return;
    }

    const selectableTarget =
      this.mouseDownTarget !== undefined
        ? findNearestSelectableNode(this.mouseDownTarget)
        : undefined;
    // Stage was clicked. Clear the current selection
    if (selectableTarget === undefined) {
      this.onSelect([]);
      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_CONTEXT_MENU,
        position,
        getNodeIdsByType([])
      );
      return;
    }

    const selection = this.transformer.getNodes();
    const selectionIds = selection.map((n) => n.id());
    // If we're clicking on an item that is *not* in the current
    // (multi-)selection. Select it, and emit an ON_CONTEXT_MENU.
    if (!selectionIds.includes(selectableTarget.id())) {
      this.onSelect([selectableTarget]);
      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_CONTEXT_MENU,
        position,
        getNodeIdsByType([selectableTarget])
      );
      return;
    }

    this.unifiedViewer.emit(
      UnifiedViewerEventType.ON_CONTEXT_MENU,
      position,
      getNodeIdsByType(selection)
    );
    return;
  };

  public override onMouseUp = (e: UnifiedViewerPointerEvent): void => {
    const selectionClientRect = this.selectionRectangle?.getClientRect();
    this.clearSelectionRectangle();

    const annotationsToAttachOrDetach = this.hasMouseMoved()
      ? this.isDraggingTargets
      : [];
    this.containerAttachmentHelper.end(annotationsToAttachOrDetach);

    const mouseUpPosition = this.unifiedViewer.stage.getPointerPosition();
    if (
      this.wasSecondaryMouseButtonPressed &&
      isEqual(this.mouseDownScreenPosition, mouseUpPosition)
    ) {
      this.handleContextMenu(mouseUpPosition);
      return;
    }

    const isUsingMultiSelect = isShiftKeyPressed(e.evt);

    if (selectionClientRect !== undefined) {
      const selectedNodes =
        this.getStageNodesOverlappingWithSelectionRect(selectionClientRect);
      this.onSelect(
        isUsingMultiSelect
          ? this.getMultiNodeSelection(selectedNodes)
          : selectedNodes
      );
      return;
    }

    const nearestSelectableTarget =
      this.mouseDownTarget !== undefined
        ? findNearestSelectableNode(this.mouseDownTarget)
        : undefined;
    if (
      !this.hasMouseMoved() &&
      nearestSelectableTarget !== undefined &&
      this.selectionBeforeInteraction.has(nearestSelectableTarget)
    ) {
      this.onSelect(
        isUsingMultiSelect
          ? this.getMultiNodeSelection([nearestSelectableTarget])
          : [nearestSelectableTarget]
      );
      return;
    }

    if (this.isDraggingTargets.length !== 0 && this.hasMouseMoved()) {
      this.onCompleteDrag();
    }
  };

  public override onMouseLeave = (): void => {
    this.clearSelectionRectangle();
    if (this.isDraggingTargets.length !== 0) {
      this.onCompleteDrag();
      return;
    }

    if (this.isDraggingTargets.length === 0) {
      // If we are not dragging anything, clear the mouseDownTarget, so that events originating
      // from outside the canvas don't trigger a move on the current selection. In IC
      // we had the case where you would select an item, then resize the ResourceSelector.
      // The mouse events would leak into the canvas and trigger a move on the selection,
      // even though the mousedown was not originating from there
      this.mouseDownTarget = undefined;
      return;
    }
  };

  public onCompleteDrag = (): void => {
    const isDraggingTargets = this.isDraggingTargets;
    if (isDraggingTargets.length !== 0) {
      isDraggingTargets.forEach((target) => {
        target.stopDrag();
      });

      if (this.isDraggingTargets.every(isPolylineNode)) {
        isDraggingTargets
          .filter(hasDependencies)
          .forEach(detachNodeFromDependencies);
      }

      this.unifiedViewer.commitDirtyNodes();

      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_DRAG_END,
        getNodeIdsByType(isDraggingTargets)
      );
      this.isDraggingTargets = [];
    }
  };

  public onKeyDown = (e: KeyboardEvent): void => {
    if (e.code === 'Backspace' || e.code === 'Delete') {
      const selectedNodes =
        this.unifiedViewer._shamefulTransformerRef?.getNodes() ?? [];

      if (selectedNodes.length === 0) {
        return;
      }

      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_DELETE_REQUEST,
        getNodeIdsByType(selectedNodes)
      );
      this.handleSelect([]);
    }
  };

  private onTransformStart = () => {
    const nearestSelectableNodes = this.transformer
      .getNodes()
      .map(findNearestSelectableNode)
      .filter(isNotUndefined);

    if (nearestSelectableNodes.length === 0) {
      return;
    }

    this.unifiedViewer.setDirtyNodes(nearestSelectableNodes);
    this.unifiedViewer.emit(
      UnifiedViewerEventType.ON_TRANSFORM_START,
      getNodeIdsByType(nearestSelectableNodes)
    );
  };

  private updateAnnotationNodeProperties = (node: Konva.Node): void => {
    const serializer = getAnnotationSerializerByType(node.attrs.annotationType);
    const properties = serializer.normalize(
      serializer.getTransformationPropertiesFromNode(node) as any // TODO(FUS-000): Improve type here
    ) as any;

    // TODO(FUS-000): Improve typing here
    const updateProperties = (node: Konva.Node, props: any): void => {
      const wasCached = node.isCached();
      if (wasCached) {
        node.clearCache();
      }

      forEach(props, (value, key) => {
        if (key in node) {
          (node as any)[key](value);
        }
      });

      if (wasCached) {
        node.cache();
      }
    };

    updateProperties(node, properties);
    // TODO(FUS-000): can/should we update group properties recursively?
    if (node instanceof Konva.Group) {
      forEach(node.getChildren(), (child: Konva.Node) => {
        if (child.className in properties) {
          updateProperties(child, properties[child.className]);
        }
      });
    }

    // Auto-fit cause map nodes in case the text overflows
    if (
      isCauseMapNodeAnnotationNode(node) &&
      // TODO(AH-3465): Auto-fitting cause map nodes is disabled for uniform
      // scaling of the nodes due to dramtic scaling issues that occur when
      // adjusting correcting the height while trying to uniformly resize the
      // node
      (properties.Text.width === undefined ||
        properties.Text.height === undefined)
    ) {
      this.constrainCauseMapHeight(node);
    }
  };

  // Note: onTransform is called *per node* in the current selection
  private onTransform = (e: UnifiedViewerPointerEvent) => {
    if (e.target instanceof Konva.Stage) {
      return;
    }

    // The event target for lines is their circle anchor. Use the
    // original/parent line of the anchor if it is defined, otherwise we can't
    // emit ON_TRANSFORM for the lines themselves.
    const targetNode =
      e.target.name().includes('_anchor') &&
      e.target.getAttr('parentLine') !== undefined
        ? e.target.getAttr('parentLine')
        : e.target;

    // TODO(FUS-000): Use node's original properties as reference instead of current properties
    // TODO(FUS-000): Doing it like this leads to cumulative errors

    if (isAnnotationNode(targetNode)) {
      this.updateAnnotationNodeProperties(targetNode);
    }

    this.unifiedViewer.emit(
      UnifiedViewerEventType.ON_TRANSFORM_CHANGE,
      getNodeIdsByType([targetNode])
    );
  };

  private constrainCauseMapHeight = (node: Konva.Label): void => {
    const textNode = node.getText();
    const scale = this.unifiedViewer.getScale();
    const overflowHeight = findContainerHeightWithoutOverflow(
      getTextTagBoxPropertiesFromCauseMapNode(node),
      scale
    );
    if (overflowHeight !== undefined) {
      const nextHeight =
        Math.ceil(overflowHeight / scale) + 2 * CAUSE_MAP_AUTO_FIT_PADDING;
      textNode.height(nextHeight);
      const statusNode = node.findOne('Image');
      statusNode?.height(nextHeight);
      if (statusNode?.attrs.shouldScaleUniformly === true) {
        const minSize = Math.min(nextHeight, node.width());
        statusNode.size({ width: minSize, height: minSize });
        statusNode.position({
          x: (textNode.width() - minSize) / 2,
          y: (textNode.height() - minSize) / 2,
        });
      }
    }
  };

  private onTransformEnd = () => {
    const transformTargets = this.transformer
      .getNodes()
      .map(findNearestSelectableNode)
      .filter(isNotUndefined);
    if (transformTargets.length === 0) {
      return;
    }

    transformTargets
      .filter(isCauseMapNodeAnnotationNode)
      .forEach(this.constrainCauseMapHeight);

    this.unifiedViewer.commitDirtyNodes();
    this.unifiedViewer.emit(
      UnifiedViewerEventType.ON_TRANSFORM_END,
      getNodeIdsByType(transformTargets)
    );
  };
}
