import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useChecklistSchedulerContext } from '@infield/providers/checklist-scheduler-provider/checklist-scheduler-context';

import { OverviewTableExtraOptionsUi } from './overview-table-extra-options-ui';

export const OverviewTableExtraOptions = () => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);
  const metrics = useMetrics(METRICS_NAMESPACES.scheduledChecklists);

  const { fetchPlannedChecklists, isDateFilterValid } =
    useChecklistSchedulerContext();

  const handleGetMissingChecklistsButtonClick = async () => {
    metrics.track('overviewTableExtraOptionsClicked', {
      invalidDateRange: !isDateFilterValid,
    });
    if (isDateFilterValid) {
      await fetchPlannedChecklists();
    }
  };

  return (
    <OverviewTableExtraOptionsUi
      isDateFilterValid={isDateFilterValid}
      translationFunction={t}
      handleGetMissingChecklistsButtonClick={
        handleGetMissingChecklistsButtonClick
      }
    />
  );
};
