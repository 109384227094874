import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { useDeleteMedia } from '@infield/features/media';
import { useFetchMediaByExternalIds } from '@infield/features/media/hooks/use-fetch-media-by-external-ids';
import { type FC } from 'react';

import { MediaCollectionViewerUi } from './media-collection-viewer-ui';

type Props = {
  fileInstanceIds?: InstanceOrExternalId[];
  onClose: () => void;
  hideDeleteButton?: boolean;
};

export const MediaCollectionViewer: FC<Props> = ({
  fileInstanceIds,
  onClose,
  hideDeleteButton = false,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.fileViewer);

  const { mediaFiles } = useFetchMediaByExternalIds(fileInstanceIds);

  const {
    mutateAsync: deleteMedia,
    isLoading: isDeletingMedia,
    isError: isDeleteMediaError,
  } = useDeleteMedia();

  return (
    <MediaCollectionViewerUi
      modalTitle={t('MEDIA_COLLECTION_VIEWER_HEADER', 'Attached media')}
      onClose={onClose}
      mediaFiles={mediaFiles}
      deleteMedia={deleteMedia}
      isDeletingMedia={isDeletingMedia}
      isDeleteMediaError={isDeleteMediaError}
      hideDeleteButton={hideDeleteButton}
    />
  );
};
