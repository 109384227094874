import type { FdmFile } from '@cognite/apm-client';
import { useFlag } from '@cognite/react-feature-flags';
import type { FileFilterProps } from '@cognite/sdk';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

export const useDocumentSearch = (query?: string, limit?: number) => {
  const { mediaService } = useFDMServices();
  const { files: configFilters } = useAppConfigFiltersContext();

  const { isEnabled: isAkerbpCustomCode } = useFlag(
    UnleashKeys.akerbp_custom_code,
    { forceRerender: true, fallback: false }
  );

  const searchFilter: FileFilterProps | undefined = {
    ...configFilters?.cdfClassicFilters,
  };

  return useQuery<FdmFile[] | undefined>(
    [QueryKeys.SEARCH, 'documents', query, searchFilter],
    async () => {
      if (query)
        return mediaService.searchDocuments(
          query,
          limit,
          searchFilter,
          isAkerbpCustomCode
        );
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: `${QueryKeys.SEARCH} documents`,
          },
        }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(query),
    }
  );
};
