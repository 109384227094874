import { PropsWithChildren } from 'react';

import { TabContent } from '../tab-content/TabContent';

export const DateTimeTab: React.FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  return (
    <TabContent.Container>
      <TabContent.Body compact>{children}</TabContent.Body>
    </TabContent.Container>
  );
};
