const getInitials = (name: string): string => {
  const parts = name.split(' ').filter((part) => part.length > 0); // Ensure no empty parts due to extra spaces

  if (parts.length === 0) {
    return '';
  }

  if (parts.length === 1) {
    return parts[0][0].toUpperCase(); // Handle single-word names
  }

  const firstInitial = parts[0][0].toUpperCase();
  const lastInitial = parts[parts.length - 1][0].toUpperCase();

  return firstInitial + lastInitial;
};

export default getInitials;
