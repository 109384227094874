export const DEFAULT_STROKE_SCALE_ENABLED = true;

export const DEFAULT_COMMENT_BACKGROUND_COLOR = 'rgb(74, 103, 251)';
export const DEFAULT_COMMENT_STROKE = 'rgba(110, 133, 252, 0.5)';
export const DEFAULT_COMMENT_STROKE_WIDTH = 8;
export const DEFAULT_COMMENT_CORNER_RADIUS: [number, number, number, number] = [
  1000, 1000, 1000, 0,
]; // Set a high radius for the first 3 corners to make those corners round
export const DEFAULT_COMMENT_TEXT_COLOR = 'white';
export const COMMENT_FONT_SIZE_PX = 10;
export const COMMENT_PADDING_PX = 8;
