import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { makeToast } from '@cognite/cogs-lab';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useDeleteMedia = () => {
  const { mediaService } = useFDMServices();
  const { t } = useTranslation(LOCIZE_NAMESPACES.mediaManager);

  const queryClient = useQueryClient();

  const mutation = useMutation<
    Record<string, never> | undefined,
    Error,
    {
      fileInstanceIds: InstanceOrExternalId[];
    }
  >(
    ({ fileInstanceIds }) => {
      return new Promise((resolve) => {
        const deletedMedia = mediaService.deleteMedia({
          fileInstanceIds,
        });
        setTimeout(() => resolve(deletedMedia), 2500); // need to await file deletion to be reflected in CDF before refetch
      });
    },
    {
      onError: (err) => {
        makeToast({
          body: t('deleteMediaFailed', 'Failed to delete media'),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-delete-media',
          },
        });
      },
      onSuccess: async () => {
        makeToast({
          body: t('deleteMediaSuccess', 'Media deleted'),
          type: 'success',
        });
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.MEDIA_LIST],
        });
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.CHECKLIST],
        });
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.OBSERVATION],
        });
      },
    }
  );

  return {
    mutateAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
  };
};
