import type { TemplateItem } from '@cognite/apm-client';

export function orderTemplateItems(
  templateItems: TemplateItem[]
): TemplateItem[] {
  return templateItems.map((task, index) => ({
    ...task,
    order: index,
  }));
}
