import { useMemo } from 'react';

import { styled } from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import { AssetsIcon } from '@cognite/cogs.js-v10';
import { CogniteClient } from '@cognite/sdk';

import { FdmInstance } from '../../../types';
import { TimeDisplay } from '../../common/TimeDisplay';
import { ReactContainerRenderContentProps } from '../../ReactContainer';
import useStatusChange from '../../ReactContainer/useStatusChange';
import { TableItem } from '../../TableContainer';
import TableContent from '../../TableContainer/TableContent';
import { FdmClient } from '../FdmPropertyList/FdmClient';
import parseInstance from '../FdmPropertyList/parseInstance';
import { useView } from '../FdmPropertyList/useView';
import getFdmPropertyListTitle from '../getFdmPropertyListTitle';

type AcdmTableProps = Pick<
  ReactContainerRenderContentProps,
  | 'width'
  | 'height'
  | 'unscaledWidth'
  | 'unscaledHeight'
  | 'setLoadingStatus'
  | 'onContentSizeChange'
  | 'shouldAutoSize'
> & {
  instance: FdmInstance;
  sdk: CogniteClient;
  properties?: string[];
  containerPadding: number;
};

export function CdmAssetTable({
  instance,
  sdk,
  width,
  height,
  unscaledHeight,
  unscaledWidth,
  shouldAutoSize,
  containerPadding,
  setLoadingStatus,
  onContentSizeChange,
  properties,
}: AcdmTableProps): React.ReactNode {
  const fdmClient = useMemo(() => new FdmClient(sdk), [sdk]);

  const view = useView(instance, fdmClient);

  const cdmAsset = useQuery(
    ['fdm', instance],
    async () => {
      if (view.data === undefined) {
        return undefined;
      }
      return await fdmClient.getInstanceById(
        instance.instanceSpace,
        instance.instanceExternalId,
        {
          externalId: instance.viewExternalId,
          space: instance.viewSpace,
          version: view.data.version,
        }
      );
    },
    {
      enabled: view.data !== undefined,
    }
  );

  const isLoading = cdmAsset.isLoading || view.isLoading;
  useStatusChange({
    data: cdmAsset.data,
    isLoading: isLoading,
    isError: cdmAsset.isError || view.isError,
    setLoadingStatus,
  });

  const instanceProperties = useMemo(() => {
    if (cdmAsset.data === undefined || view.data === undefined) {
      return undefined;
    }
    return parseInstance(
      view.data,
      cdmAsset.data.instance,
      cdmAsset.data.typing
    );
  }, [cdmAsset.data, view.data]);

  const instanceTableItems = useMemo(() => {
    if (instanceProperties === undefined) {
      return undefined;
    }
    return Object.entries(instanceProperties)
      .filter(([key]) =>
        properties !== undefined ? properties?.includes(key) : true
      )
      .map(
        ([property, value]): TableItem => ({
          label: property,
          value:
            value instanceof Date ? (
              <TimeDisplay value={value.getTime()} />
            ) : (
              value
            ),
        })
      );
  }, [instanceProperties, properties]);

  if (instanceTableItems === undefined) {
    return null;
  }

  if (isLoading) {
    return (
      <div
        style={{
          position: 'absolute',
          inset: 0,
          background: 'rgba(255,255,255,0.7)',
        }}
      />
    );
  }

  return (
    <TableContent
      width={width}
      height={height}
      unscaledWidth={unscaledWidth}
      unscaledHeight={unscaledHeight}
      shouldAutoSize={shouldAutoSize}
      containerPadding={containerPadding}
      title={
        <TitleWrapper>
          <ColoredAssetsIcon /> {}
          {getFdmPropertyListTitle(instance, instanceProperties)}
        </TitleWrapper>
      }
      items={instanceTableItems}
      setLoadingStatus={setLoadingStatus}
      onContentSizeChange={onContentSizeChange}
    />
  );
}

const ColoredAssetsIcon = styled(AssetsIcon)`
  color: #4a67fb;
  position: relative;
  margin-right: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
