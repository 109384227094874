import type { CellComponent } from 'tabulator-tables';

import type { DataGridColumn, DataGridRow } from './types';

export type DataGridAction =
  | 'appendRow'
  | 'prependRow'
  | 'appendColumn'
  | 'prependColumn'
  | 'removeRow'
  | 'removeColumn';

const getUpdatedDataGridContainer = ({
  action,
  selectedCell,
  currentRows,
  currentColumns,
  onRowsUpdated,
  onColumnUpdated,
}: {
  action: DataGridAction;
  selectedCell: CellComponent;
  currentRows: DataGridRow[];
  currentColumns: DataGridColumn[];
  onRowsUpdated: (nextRows: DataGridRow[]) => void;
  onColumnUpdated: (nextColumns: DataGridColumn[]) => void;
}): void => {
  const getNewRow = () => ({
    id: crypto.randomUUID(),
    height: 45,
    cells: Object.fromEntries(
      currentColumns.map(({ key }) => [key, { value: undefined }])
    ),
  });

  const getNewColumn = () => ({
    key: crypto.randomUUID(),
    title: `Column ${currentColumns.length + 1}`,
    width: 150,
  });

  const selectedRowIndex = currentRows.findIndex(
    ({ id }) => id === selectedCell.getRow().getData().id
  );
  const selectedColumnKey = selectedCell.getColumn().getField();
  const selectedColumnIndex = currentColumns.findIndex(
    ({ key }) => key === selectedColumnKey
  );

  switch (action) {
    case 'appendRow': {
      if (currentRows.length > 0 && selectedRowIndex === -1) {
        return;
      }
      const newRow = getNewRow();
      const nextRows =
        currentRows.length === 0
          ? [newRow]
          : [
              ...currentRows.slice(0, selectedRowIndex + 1),
              newRow,
              ...currentRows.slice(selectedRowIndex + 1),
            ];
      onRowsUpdated(nextRows);
      break;
    }
    case 'prependRow': {
      if (currentRows.length > 0 && selectedRowIndex === -1) {
        return;
      }
      const newRow = getNewRow();
      const nextRows =
        currentRows.length === 0
          ? [newRow]
          : [
              ...currentRows.slice(0, selectedRowIndex),
              newRow,
              ...currentRows.slice(selectedRowIndex),
            ];
      onRowsUpdated(nextRows);
      break;
    }
    case 'removeRow': {
      if (selectedRowIndex === -1) {
        return;
      }
      const nextRows = currentRows.filter(
        (_, index) => index !== selectedRowIndex
      );
      onRowsUpdated(nextRows);
      break;
    }
    case 'appendColumn': {
      if (currentColumns.length > 0 && selectedColumnIndex === -1) {
        return;
      }
      const newColumn = getNewColumn();
      const nextRows = currentRows.map((item) => ({
        ...item,
        cells: { ...item.cells, [newColumn.key]: { value: undefined } },
      }));
      const updatedColumns =
        currentColumns.length === 0
          ? [newColumn]
          : [
              ...currentColumns.slice(0, selectedColumnIndex + 1),
              newColumn,
              ...currentColumns.slice(selectedColumnIndex + 1),
            ];
      onRowsUpdated(nextRows);
      onColumnUpdated(updatedColumns);
      break;
    }
    case 'prependColumn': {
      if (currentColumns.length > 0 && selectedColumnIndex === -1) {
        return;
      }
      const newColumn = getNewColumn();
      const nextRows = currentRows.map((item) => ({
        ...item,
        cells: { ...item.cells, [newColumn.key]: { value: undefined } },
      }));
      const updatedColumns =
        currentColumns.length === 0
          ? [newColumn]
          : [
              ...currentColumns.slice(0, selectedColumnIndex),
              newColumn,
              ...currentColumns.slice(selectedColumnIndex),
            ];
      onRowsUpdated(nextRows);
      onColumnUpdated(updatedColumns);
      break;
    }
    case 'removeColumn': {
      if (selectedColumnIndex === -1) {
        return;
      }
      const updatedColumns = currentColumns.filter(
        (_, index) => index !== selectedColumnIndex
      );
      const nextRows = currentRows.map(
        ({
          id,
          cells: { [selectedColumnKey]: _, ...nextCells },
          ...rest
        }): DataGridRow => ({
          ...rest,
          id,
          cells: nextCells,
        })
      );
      onRowsUpdated(nextRows);
      onColumnUpdated(updatedColumns);
      break;
    }
    default:
      break;
  }
};

export default getUpdatedDataGridContainer;
