import Konva from 'konva';
import { IRect } from 'konva/cmj/types';
import { KonvaEventObject } from 'konva/lib/Node';
import { keyBy, uniqBy } from 'lodash';
import clamp from 'lodash/clamp';
import throttle from 'lodash/throttle';
import { v4 as uuid } from 'uuid';

import { CogniteClient } from '@cognite/sdk/dist/src';

import PolylineSerializer from './annotations/PolylineSerializer';
import {
  Annotation,
  AnnotationType,
  CauseMapNodeAnnotation,
  isCauseMapNodeAnnotationNode,
  isCauseMapNodeAnnotation,
  isLabelNode,
  isPolylineAnnotation,
  isTextNode,
  Position,
} from './annotations/types';
import {
  UNIFIED_VIEWER_SCALE_MAX,
  UNIFIED_VIEWER_SCALE_MIN,
} from './constants';
import BackgroundLayerContainer from './containers/BackgroundLayer';
import { ShamefulChartsContext } from './containers/ChartsContainer/ChartsContainer';
import ForegroundLayerContainer from './containers/ForegroundLayerContainer';
import LayerContainer from './containers/LayerContainer';
import { ContainerConfig } from './containers/types';
import getInteractionsHandler from './interactions/getInteractionsHandler';
import InteractionMode from './interactions/InteractionMode';
import Interactions from './interactions/Interactions';
import CauseMapAnchorHelper from './tools/CauseMapAnchorHelper';
import CauseMapTool from './tools/CauseMapTool';
import { DataGridAnchorHelper } from './tools/DataGridAnchorHelper';
import SelectTool from './tools/SelectTool';
import StickyAnchorHelper from './tools/StickyAnchorHelper';
import StickyTool from './tools/StickyTool';
import TextTool from './tools/TextTool';
import { ToolManager } from './tools/ToolManager';
import { ToolConfig, ToolType } from './tools/types';
import { IdsByType } from './types';
import UnifiedViewerClipboard from './UnifiedViewerClipboard';
import UnifiedViewerEventType from './UnifiedViewerEventType';
import UnifiedViewerRenderer from './UnifiedViewerRenderer';
import UnifiedEventHandler, {
  UnifiedViewerPointerEvent,
} from './UnifiedViewerRenderer/UnifiedEventHandler';
import UnifiedViewerTransformer from './UnifiedViewerTransformer';
import layoutCauseMapNodes, {
  DEFAULT_LAYOUT_OPTIONS,
} from './utils/causeMapLayout/layoutCauseMapNodes';
import EventEmitter from './utils/EventEmitter';
import {
  isCKeyPressed,
  isOsDependentMetaOrCtrlKeyPressed,
  isShiftKeyPressed,
  isVKeyPressed,
} from './utils/eventUtils';
import {
  type ExportPdfOptions,
  exportWorkspaceToPdf,
} from './utils/exportWorkspaceToPdf';
import getCauseMapEdges from './utils/getCauseMapEdges';
import getConnectedComponents from './utils/getConnectedComponents';
import { FileContainerProps } from './utils/getContainerConfigFromUrl';
import getFileDataFromDropEvent from './utils/getFileDataFromDropEvent';
import getMetricsLogger from './utils/getMetricsLogger';
import getOrPrependDomNodeToHost from './utils/getOrCreateDomNodeById';
import isNotUndefined from './utils/isNotUndefined';
import isSizeAndPositionApproximatelyEqual from './utils/isSizeAndPositionApproximatelyEqual';
import mapEventForMobile from './utils/mapEventForMobile';
import { getShamefulContainerTypeFromMimeType } from './utils/mimeTypes/getSupportedMimeTypeFromUrl';
import partitionIntoContainersAndAnnotations from './utils/partitionIntoContainersAndAnnotations';
import { addRelativeMarginToRect } from './utils/rectUtils';
import shamefulSafeKonvaScale from './utils/shamefulSafeKonvaScale';

const RESIZE_THROTTLE_MS = 50;
const ZOOM_EMIT_DEBOUNCE_MS = 25;
const MAX_EXTERNAL_FILE_CONTAINER_WIDTH = 2000;
const MAX_EXTERNAL_FILE_CONTAINER_HEIGHT = 2000;

export type UnifiedViewerMouseEvent = KonvaEventObject<MouseEvent>;
export type UnifiedViewerErrorEvent = {
  message: string;
  e: any;
};

export enum UpdateRequestSource {
  CLIPBOARD = 'clipboard',
  AUTO_RESIZE = 'autoResize',
  STICKY_ANCHOR = 'stickyAnchor',
  CAUSE_MAP_ANCHOR = 'causeMapAnchor',
  DATA_GRID_ANCHOR = 'dataGridAnchor',
  RELAYOUT_CAUSE_MAP = 'relayoutCauseMap',
}

export type UnifiedViewerEventListenerMap = {
  [UnifiedViewerEventType.ON_UPDATE_REQUEST]: (update: {
    source?: UpdateRequestSource | undefined;
    containers: ContainerConfig[];
    annotations: Annotation[];
  }) => void;
  [UnifiedViewerEventType.ON_DELETE_REQUEST]: (deletions: IdsByType) => void;
  [UnifiedViewerEventType.ON_CONTAINER_LOAD]: () => void;
  [UnifiedViewerEventType.ON_CONTAINER_ERROR]: (
    container: ContainerConfig,
    error: UnifiedViewerErrorEvent
  ) => void;
  [UnifiedViewerEventType.ON_ANNOTATIONS_LOAD]: () => void;
  [UnifiedViewerEventType.ON_NODES_LOAD]: () => void;
  [UnifiedViewerEventType.ON_ZOOM_START]: () => void;
  [UnifiedViewerEventType.ON_ZOOM_CHANGE]: (newScale: number) => void;
  [UnifiedViewerEventType.ON_ZOOM_END]: (newScale: number) => void;
  [UnifiedViewerEventType.ON_PAN_START]: () => void;
  [UnifiedViewerEventType.ON_PAN_MOVE]: () => void;
  [UnifiedViewerEventType.ON_PAN_END]: () => void;
  [UnifiedViewerEventType.ON_VIEWPORT_CHANGE]: () => void;
  [UnifiedViewerEventType.ON_DRAG_START]: (ids: IdsByType) => void;
  [UnifiedViewerEventType.ON_DRAG_MOVE]: (ids: IdsByType) => void;
  [UnifiedViewerEventType.ON_DRAG_END]: (ids: IdsByType) => void;
  [UnifiedViewerEventType.ON_TRANSFORM_START]: (ids: IdsByType) => void;
  [UnifiedViewerEventType.ON_TRANSFORM_CHANGE]: (ids: IdsByType) => void;
  [UnifiedViewerEventType.ON_TRANSFORM_END]: (ids: IdsByType) => void;
  [UnifiedViewerEventType.ON_CLICK]: (event: UnifiedViewerPointerEvent) => void;
  [UnifiedViewerEventType.ON_SELECT]: (idsByType: IdsByType) => void;
  [UnifiedViewerEventType.ON_SELECTION_DRAG_START]: () => void;
  [UnifiedViewerEventType.ON_SELECTION_DRAG_END]: () => void;
  [UnifiedViewerEventType.ON_TOOL_CHANGE]: (tool: ToolConfig) => void;
  [UnifiedViewerEventType.ON_TOOL_START]: () => void;
  [UnifiedViewerEventType.ON_TOOL_END]: () => void;
  [UnifiedViewerEventType.ON_CONTEXT_MENU]: (
    position: Position,
    idsByType: IdsByType
  ) => void;
};

export type UnifiedViewerOptions = {
  hostElementId: string;
  applicationId: string;
  defaultTool?: ToolConfig;
  interactionMode?: InteractionMode;
  shouldLogMetrics?: boolean;
  shouldUseAdaptiveRendering?: boolean;
  shouldUseShamefulFastMode?: boolean;
  shouldShowSelectionRectanglePerNode?: boolean;
  shouldOnlySelectFullyEnclosedNodes?: boolean;
  cogniteClient?: CogniteClient;
  // The ChartsContext must be instantiated in the application layer and we have to manually propagate it to the ChartsContainer
  // since it's rendered in an isolated React tree. In the future, once we move away from Firebase for charts and the SDK has been updated,
  // we can probably thin out or entirely remove passing the Charts context since most / all of it will be provided directly from the SDK.
  shamefulChartsContext?: ShamefulChartsContext;
  namespace?: string;
  /*
   * https://cognitedata.atlassian.net/browse/AH-2689
   */
  shamefulShouldUseAlwaysActiveMode?: boolean;
};

export type UnifiedViewerZoomOptions = {
  scale?: number;
  duration?: number;
  shouldKeepScale?: boolean;
};

export enum ZoomToFitMode {
  DEFAULT = 'default',
  NATURAL = 'natural',
}

/**
 * Shameful because we should have a better way of triggering a selection of items that are
 * not yet rendered (i.e. that they would be selected once they are rendered)
 */
const SHAMEFUL_PASTE_SELECT_TIMEOUT_MS = 250;

export class UnifiedViewer extends EventEmitter<UnifiedViewerEventListenerMap> {
  public static readonly SCALE_MAX = UNIFIED_VIEWER_SCALE_MAX;
  public static readonly SCALE_MIN = UNIFIED_VIEWER_SCALE_MIN;
  public static readonly ZOOM_TO_FIT_MIN_SCALE_X = UNIFIED_VIEWER_SCALE_MIN;
  public static readonly ZOOM_TO_FIT_MIN_SCALE_Y = UNIFIED_VIEWER_SCALE_MIN;
  public static readonly ZOOM_SCALE_FACTOR = 1.4;
  public static readonly DEFAULT_ZOOM_DURATION = 0.2;

  public host: HTMLDivElement;
  public stage: Konva.Stage;
  private resizeObserver: ResizeObserver;
  private renderer: UnifiedViewerRenderer = new UnifiedViewerRenderer(this);
  private clipboard: UnifiedViewerClipboard = new UnifiedViewerClipboard(
    this,
    this.renderer
  );
  // TODO(CAN-1786): We should have a more generic way of handling anchor helpers
  private stickyAnchorHelper: StickyAnchorHelper;
  private causeMapAnchorHelper: CauseMapAnchorHelper;
  private dataGridAnchorHelper: DataGridAnchorHelper;
  private toolManager: ToolManager;

  private pendingZoomIds: string[] = [];

  public interactionHandler: Interactions;
  public eventHandler: UnifiedEventHandler;
  public readonly options: UnifiedViewerOptions;

  private readonly cogniteClient: CogniteClient | undefined;
  private readonly shamefulChartsContext: ShamefulChartsContext | undefined;

  public layers = {
    background: new BackgroundLayerContainer({ name: 'backgroundLayer' }),
    main: new ForegroundLayerContainer({ name: 'mainLayer' }),
    animation: new LayerContainer({ name: 'animationLayer' }),
    // Transformer is kept in a separate top layer to prevent it from being
    // obscured by nodes in other layers. If it's placed in the main layer,
    // the ordering in which the transformer is added matters fro the visibility
    // of the anchors of the selection area.
    transformer: new LayerContainer({ name: 'transformerLayer' }),
  } satisfies Record<string, LayerContainer>;

  public readonly namespace: string = 'ufv';

  public _shamefulTransformerRef?: UnifiedViewerTransformer;

  /**
   * Initialise an instance of Cognite UnifiedViewer
   * @param defaultTool
   * @param interactionMode
   * @param options Several initialisation options
   */
  public constructor({
    defaultTool = { type: ToolType.PAN },
    interactionMode = InteractionMode.SCROLL_TO_ZOOM,
    ...options
  }: UnifiedViewerOptions) {
    super();
    const host = document.getElementById(
      options.hostElementId
    ) as HTMLDivElement;
    if (!host) {
      throw new Error('UnifiedViewer: Failed to get HTML element to attach to');
    }

    if (options.namespace !== undefined) {
      this.namespace = options.namespace;
    }

    this.cogniteClient = options.cogniteClient;
    this.shamefulChartsContext = options.shamefulChartsContext;

    this.host = host;
    this.options = {
      ...options,
      interactionMode,
      defaultTool,
    };

    // Setup metrics logger
    getMetricsLogger({
      shouldLogMetrics: Boolean(options.shouldLogMetrics),
      applicationId: options.applicationId,
      eventProps: {
        hostElementId: options.hostElementId,
        applicationId: options.applicationId,
        defaultTool,
        interactionMode,
      },
    });

    const stageElementId = `${options.hostElementId}-stage`;
    const stageDomNode = getOrPrependDomNodeToHost(stageElementId, host);
    stageDomNode.style.position = 'absolute';
    stageDomNode.style.top = '0';
    stageDomNode.style.left = '0';
    stageDomNode.style.right = '0';
    stageDomNode.style.bottom = '0';

    // Setup stage
    this.stage = new Konva.Stage({
      container: stageDomNode,
      width: host.clientWidth,
      height: host.clientHeight,
      scale: { x: 1, y: 1 },
    });
    this.stage.container().tabIndex = 1; // Focusable

    this.stickyAnchorHelper = new StickyAnchorHelper(this, this.renderer);
    this.causeMapAnchorHelper = new CauseMapAnchorHelper(this, this.renderer);
    this.dataGridAnchorHelper = new DataGridAnchorHelper(this, this.renderer);

    // Make responsive
    this.fitStageIntoParentContainer();
    const throttledResizeHandler = throttle(() => {
      this.fitStageIntoParentContainer();
    }, RESIZE_THROTTLE_MS);

    this.resizeObserver = new ResizeObserver(throttledResizeHandler);
    this.resizeObserver.observe(this.host);

    // Add layers to stage
    Object.values(this.layers).forEach((layer) =>
      this.stage.add(layer.getNode())
    );

    // Initialize mouse events
    this.interactionHandler = getInteractionsHandler(interactionMode, this);
    this.eventHandler = new UnifiedEventHandler(this);
    this.toolManager = new ToolManager({
      unifiedViewer: this,
      defaultTool,
    });

    this.eventHandler.addMultipleEventListeners(
      this.stage,
      mapEventForMobile('click'),
      this.onClick
    );
    this.stage.container().addEventListener('keydown', this.onKeyDown);

    // Update background based on scale level
    this.addEventListener(UnifiedViewerEventType.ON_ZOOM_CHANGE, (newScale) => {
      this.layers.background.possiblyUpdateBackgroundByScale(newScale);
    });
    this.addEventListener(UnifiedViewerEventType.ON_ZOOM_END, (newScale) => {
      this.layers.background.possiblyUpdateBackgroundByScale(newScale);
    });
  }

  /**
   * Base zoom function to zoom the stage to the center of a set of x and y coordinates
   * @param location specifies the center of the location to zoom to
   * @param options
   */
  public zoomTo(
    location: {
      x: number;
      y: number;
    },
    options?: UnifiedViewerZoomOptions
  ): void {
    const { zoomId } = this.onZoomStart();

    const { duration = UnifiedViewer.DEFAULT_ZOOM_DURATION } = options ?? {};

    const getScaleValue = (scale?: number) => {
      if (scale === undefined || Number.isNaN(scale)) {
        return 1;
      }
      return scale;
    };
    const scale = options?.shouldKeepScale
      ? this.getScale()
      : getScaleValue(options?.scale);

    const deltaX = this.stage.width() / 2;
    const deltaY = this.stage.height() / 2;
    const x = scale * location.x + deltaX;
    const y = scale * location.y + deltaY;
    const tween = new Konva.Tween({
      duration,
      easing: Konva.Easings.EaseInOut,
      node: this.stage,
      scaleX: scale,
      scaleY: scale,
      x,
      y,
      onUpdate: () => {
        this.onZoomChange();
      },
    });
    tween.onFinish = () => {
      tween.destroy();
      this.onZoomEnd(zoomId, scale);
      this.onViewportChange();
    };

    tween.play();
  }

  private zoomToNode = (
    node: Konva.Node,
    options?: UnifiedViewerZoomOptions & {
      relativeMargin?: number;
    }
  ): void => {
    const nodeRect = this.renderer.getNodeRectRelativeToStage(node);
    if (nodeRect === undefined) {
      return;
    }

    const rect = addRelativeMarginToRect(
      nodeRect,
      options?.relativeMargin ?? 0
    );
    const rawScale = Math.min(
      this.stage.width() / rect.width,
      this.stage.height() / rect.height
    );

    const scale = clamp(
      rawScale,
      UnifiedViewer.SCALE_MIN,
      UnifiedViewer.SCALE_MAX
    );

    // Scale the location
    const location = {
      x: -rect.x - rect.width / 2,
      y: -rect.y - rect.height / 2,
    };

    this.zoomTo(location, { scale, ...options });
  };

  public zoomToNodeById = (
    id: string,
    options?: UnifiedViewerZoomOptions & {
      relativeMargin?: number;
    }
  ): void => {
    const node = this.renderer.getNodeById(id);
    if (node === undefined) {
      // eslint-disable-next-line no-console
      console.warn(`Node with id ${id} not found`);
      return;
    }

    this.zoomToNode(node, options);
  };

  /**
   * Zoom out and center so the entire workspace is visible at once, prioritizing fitting width.
   */
  public zoomToFit(
    mode: ZoomToFitMode = ZoomToFitMode.DEFAULT,
    options?: {
      duration?: number;
      relativeMargin?: number;
    }
  ): void {
    // To properly compute the client rectangle of the main layer, we need to
    // mark all nodes in the main layer as visible. Otherwise, they won't be
    // accounted for in the rectangle calculation. We maintain a list of nodes
    // to hide, so that the nodes that were previously hidden gets marked as
    // hidden again once the client rect calculations are finished
    const nodesToHide: Konva.Node[] = [];
    [...this.layers.main.layer.getChildren()].forEach((node) => {
      if (node.visible()) {
        return;
      }
      nodesToHide.push(node);
      node.visible(true);
    });
    // We ignore the transform since we want to get the "ground truth"
    // resolution of the workspace. Otherwise, the returned box will be dynamic
    // and its width, height and position will be based on the current scale and
    // location of the stage.
    const box = addRelativeMarginToRect(
      this.layers.main.getClientRect({ skipTransform: true }),
      options?.relativeMargin ?? 0
    );
    nodesToHide.forEach((node) => node.visible(false));

    if (Object.values(box).some((value) => Number.isNaN(value))) {
      // eslint-disable-next-line no-console
      console.warn(
        'Some dimensions are NaN, this might be due to width or height not being set on one of the descendant nodes'
      );
      return;
    }

    const { duration = UnifiedViewer.DEFAULT_ZOOM_DURATION } = options ?? {};

    // Fit to width
    if (mode === ZoomToFitMode.NATURAL) {
      this.setViewport(
        {
          x: box.x + box.width / 2,
          y: box.y + box.height / 2,
          width: box.width,
        },
        { ...options, duration }
      );
      return;
    }

    // Fit everything
    this.setViewport(
      {
        x: box.x + box.width / 2,
        y: box.y + box.height / 2,
        width: box.width,
        height: box.height,
      },
      { ...options, duration }
    );
  }

  /**
   * Zoom to a specific location
   * @param viewport specify center of viewport and minimum width and height
   */
  public setViewport = (
    viewport:
      | IRect
      | {
          x: number;
          y: number;
          width: number;
          height?: never;
        }
      | {
          x: number;
          y: number;
          width?: never;
          height: number;
        },
    options?: {
      duration?: number;
    }
  ): void => {
    if (
      (viewport.width !== undefined && viewport.width <= 0) ||
      (viewport.height !== undefined && viewport.height <= 0)
    ) {
      return;
    }

    // Viewport is specified by center coordinates
    const stage = { width: this.stage.width(), height: this.stage.height() };
    const scaleX =
      viewport.width !== undefined ? stage.width / viewport.width : Infinity;
    const scaleY =
      viewport.height !== undefined ? stage.height / viewport.height : Infinity;
    const clampedScale = this.getMinClampedScale(scaleX, scaleY);

    const { duration = 0 } = options ?? {};
    this.zoomTo(
      { x: -viewport.x, y: -viewport.y },
      {
        duration,
        scale: clampedScale,
      }
    );
  };

  public getViewport = (): IRect => {
    return this.stage.getClientRect();
  };

  public setScale(
    scale: number,
    options?: {
      duration?: number;
    }
  ): void {
    const { zoomId } = this.onZoomStart();

    const oldScale = shamefulSafeKonvaScale(this.stage.scale());
    const clampedScale = this.getClampedScale(scale);

    const centerPositionAtScale = {
      x:
        this.stage.width() / 2 -
        ((this.stage.width() / 2 - this.stage.x()) / oldScale.x) * clampedScale,
      y:
        this.stage.height() / 2 -
        ((this.stage.height() / 2 - this.stage.y()) / oldScale.y) *
          clampedScale,
    };

    const tween = new Konva.Tween({
      duration: options?.duration ?? UnifiedViewer.DEFAULT_ZOOM_DURATION,
      easing: Konva.Easings.EaseInOut,
      node: this.stage,
      x: centerPositionAtScale.x,
      y: centerPositionAtScale.y,
      scaleX: clampedScale,
      scaleY: clampedScale,
      onUpdate: () => {
        this.onZoomChange();
      },
    });

    tween.onFinish = () => {
      tween.destroy();
      this.onZoomEnd(zoomId, clampedScale);
      this.onViewportChange();
    };

    tween.play();
  }

  public zoomIn = (): void =>
    this.setScale(this.getScale() * UnifiedViewer.ZOOM_SCALE_FACTOR);

  public zoomOut = (): void =>
    this.setScale(this.getScale() / UnifiedViewer.ZOOM_SCALE_FACTOR);

  public getScale = (): number => this.stage.scaleX();

  public getTransformer = (): UnifiedViewerTransformer | undefined =>
    this._shamefulTransformerRef;

  // Proxied methods from UnifiedViewerRenderer
  public getRectById = this.renderer.getRectById;
  public getRectByIds = this.renderer.getRectByIds;
  public setNodes = this.renderer.setNodes;
  public commitDirtyNodes = this.renderer.commitDirtyNodes;
  public setDirtyNodes = this.renderer.setDirtyNodes;
  public getContainerById = this.renderer.getContainerById;
  public getContainers = this.renderer.getContainers;
  public getContainerRectRelativeToStageById =
    this.renderer.getContainerRectRelativeToStageById;
  public getAnnotationRectRelativeToStageById =
    this.renderer.getAnnotationRectRelativeToStageById;
  public getActiveToolType = (): string | undefined =>
    this.toolManager.getActiveToolType();

  public onActiveToolComplete = (): void =>
    this.toolManager.onActiveToolComplete();

  public setTool: ToolManager['setTool'] = (...args) =>
    this.toolManager.setTool(...args);

  public onDestroy = (): void => {
    this.stage.destroy();
    this.eventHandler.onDestroy();
  };

  /**
   * This method is shameful and is *not meant for external usage*. The anchor
   * refreshing logic is placed here since we need to refresh the anchors at
   * specific places in multiple places – specifically, SelectTool, StickyTool,
   * UnifiedViewerRenderer, CauseMapTool and ToolManager – and since
   * UnifiedViewer is the only common denominator between them, this is the only
   * place where we can safely place this method.
   * Ideally, we should avoid exposing this method in the public API, but
   * instead have this logic placed somewhere more protected/hidden, and instead
   * let the anchor refreshing be done as a side effect of, for instance, a
   * sticky being marked as dirty and/or being committed. Right now the anchor
   * refreshing logic is spread over many places which makes it more tricky to
   * get an overview of when the refreshing operation occurs.
   * The refactoring work for this is tracked by AH-3284.
   */
  public shamefullyRefreshAnchors(nodes: Konva.Node[]): void {
    this.stickyAnchorHelper.refreshAnchors(nodes);
    this.dataGridAnchorHelper.refreshAnchors(nodes);
    this.causeMapAnchorHelper.refreshAnchors(nodes);
  }

  private refreshConnections = (
    causeMapNodes: CauseMapNodeAnnotation[]
  ): void => {
    const causeMapNodeById = new Map(causeMapNodes.map((a) => [a.id, a]));
    this.renderer
      .getAnnotations()
      .filter(isPolylineAnnotation)
      .filter(
        ({ fromId, toId }) =>
          (fromId !== undefined && causeMapNodeById.has(fromId)) ||
          (toId !== undefined && causeMapNodeById.has(toId))
      )
      .forEach((annotation) => {
        const node = this.renderer.getAnnotationNodeById(annotation.id);
        if (node === undefined) {
          return;
        }
        PolylineSerializer.updateNode(node, annotation, this, this.renderer);
      });
  };

  public setActiveToolCursor = (newCursor: string): void => {
    const activeTool = this.toolManager.getActiveTool();
    activeTool!.cursor! = newCursor;
  };

  /**
   * Resizes stage to fit the container its in
   */
  private fitStageIntoParentContainer = () => {
    const containerWidth = this.host.clientWidth;
    const containerHeight = this.host.clientHeight;

    this.stage.width(containerWidth);
    this.stage.height(containerHeight);
    this.onViewportChange();
  };

  private getMinClampedScale = (scaleX: number, scaleY: number) =>
    this.getClampedScale(Math.min(scaleX, scaleY));

  private getClampedScale = (scale: number) =>
    clamp(scale, UnifiedViewer.SCALE_MIN, UnifiedViewer.SCALE_MAX);

  public exportWorkspaceToPdf = (...args: ExportPdfOptions[]): Promise<void> =>
    exportWorkspaceToPdf(this, ...args);

  private onClick = (event: UnifiedViewerPointerEvent) => {
    this.emit(UnifiedViewerEventType.ON_CLICK, event);
  };

  private readFromClipboard = async () => {
    if (this.toolManager.getActiveToolType() !== ToolType.SELECT) {
      return;
    }

    const nodesFromClipboard =
      await this.clipboard.getClipboardNodesAtCurrentPointerPosition();

    if (nodesFromClipboard.length > 0) {
      const { containers, annotations } =
        partitionIntoContainersAndAnnotations(nodesFromClipboard);

      this.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
        source: UpdateRequestSource.CLIPBOARD,
        annotations,
        containers,
      });

      // TODO(FUS-000): Need to handle this better in general for UFV.
      // This pattern is reappearing and we are only handling it hackily
      setTimeout(
        () =>
          this.selectByIds({
            containerIds: containers.map((c) => c.id),
            annotationIds: annotations.map((a) => a.id),
          }),
        SHAMEFUL_PASTE_SELECT_TIMEOUT_MS
      );
    }
  };

  private writeSelectedNodesToClipboard = async (): Promise<void> => {
    const selectedNodes = this.getTransformer()?.getNodes() ?? [];
    await this.clipboard.writeNodes(selectedNodes);
  };

  private onKeyDown = async (event: KeyboardEvent) => {
    if (isOsDependentMetaOrCtrlKeyPressed(event) && !isShiftKeyPressed(event)) {
      if (isCKeyPressed(event)) {
        await this.writeSelectedNodesToClipboard();
      }
      if (isVKeyPressed(event)) {
        await this.readFromClipboard();
      }
    }
  };

  private isZooming(): boolean {
    return this.pendingZoomIds.length > 0;
  }

  public onZoomStart(): {
    zoomId: string;
  } {
    if (!this.isZooming()) {
      this.emit(UnifiedViewerEventType.ON_ZOOM_START);
    }

    const zoomId = uuid();
    this.pendingZoomIds.push(zoomId);

    return { zoomId };
  }

  public onZoomChange = (): void => {
    this.emit(UnifiedViewerEventType.ON_ZOOM_CHANGE, this.getScale());
  };

  public onZoomEnd = (zoomId: string, scale: number): void => {
    setTimeout(() => {
      this.pendingZoomIds = this.pendingZoomIds.filter(
        (pendingId) => pendingId !== zoomId
      );

      if (this.isZooming()) {
        this.emit(UnifiedViewerEventType.ON_ZOOM_CHANGE, scale);
        return;
      }

      this.emit(UnifiedViewerEventType.ON_ZOOM_END, scale);
    }, ZOOM_EMIT_DEBOUNCE_MS);
  };

  public isNodeInViewportById = (
    id: string,
    options?: {
      viewportMargin?: number;
      shouldAllowPartialFit?: boolean;
    }
  ): boolean => {
    const node = this.renderer.getNodeById(id);
    return node !== undefined && this.renderer.isNodeInViewport(node, options);
  };

  public onViewportChange(): void {
    this.emit(UnifiedViewerEventType.ON_VIEWPORT_CHANGE);
  }

  public toggleDragDrop = (isEnabled: boolean): void => {
    const hostElement = this.stage.container();
    if (isEnabled) {
      hostElement.addEventListener('dragover', this.onDragOver);
      hostElement.addEventListener('drop', this.onDrop);
    } else {
      hostElement.removeEventListener('dragover', this.onDragOver);
      hostElement.removeEventListener('drop', this.onDrop);
    }
  };

  private onDragOver = (evt: DragEvent): void => {
    evt.preventDefault();
    this.stage.setPointersPositions(evt);
    if (evt.dataTransfer !== null) {
      evt.dataTransfer.dropEffect = 'copy';
    }
  };

  private onDrop = async (evt: DragEvent): Promise<void> => {
    evt.preventDefault();
    this.stage.setPointersPositions(evt);
    const translatedMousePosition = this.stage.getRelativePointerPosition();
    if (translatedMousePosition === null) {
      return;
    }

    const fileData = await getFileDataFromDropEvent(evt);
    fileData.forEach((file) => {
      const fileContainer: FileContainerProps = {
        id: uuid(),
        type: getShamefulContainerTypeFromMimeType(file.mimeType),
        url: file.dataUrl,
        x: translatedMousePosition.x,
        y: translatedMousePosition.y,
        maxWidth: MAX_EXTERNAL_FILE_CONTAINER_WIDTH,
        maxHeight: MAX_EXTERNAL_FILE_CONTAINER_HEIGHT,
      };
      this.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
        annotations: [],
        containers: [fileContainer],
      });
    });
  };

  public getCogniteClient(): CogniteClient | undefined {
    return this.cogniteClient;
  }

  public getShamefulChartsContext(): ShamefulChartsContext | undefined {
    return this.shamefulChartsContext;
  }

  private getNodesByIds = (idsByType: IdsByType): Konva.Node[] => {
    const annotationNodes = idsByType.annotationIds
      .map((id) => this.renderer.getAnnotationNodeById(id))
      .filter(isNotUndefined);
    const containerNodes = idsByType.containerIds
      .map((id) => this.renderer.getContainerById(id)?.getNode())
      .filter(isNotUndefined);

    return [...annotationNodes, ...containerNodes];
  };

  /**
   * Note, you need to be in the select tool to use this method.
   */
  public selectByIds = (ids: IdsByType): void => {
    const selectTool = this.toolManager.getActiveTool();
    if (!(selectTool instanceof SelectTool)) {
      // eslint-disable-next-line no-console
      console.warn('You need to be in the select tool to use this method.');
      return;
    }

    selectTool.onSelect(this.getNodesByIds(ids));
  };

  public editAnnotationById = (
    id: string,
    options?: {
      shouldEditIfOutsideViewport?: boolean;
    }
  ): void => {
    const annotationNode = this.renderer.getAnnotationNodeById(id, false);
    if (annotationNode === undefined) {
      // eslint-disable-next-line no-console
      console.warn(`The passed annotation id ${id} does not exist.`);
      return;
    }

    if (
      !options?.shouldEditIfOutsideViewport &&
      !this.renderer.isNodeInViewport(annotationNode, {
        shouldAllowPartialFit: false,
      })
    ) {
      // eslint-disable-next-line no-console
      console.warn('The passed annotation is not in the viewport.');
      return;
    }

    const annotationType = annotationNode.attrs.annotationType;
    if (
      annotationType !== AnnotationType.STICKY &&
      annotationType !== AnnotationType.TEXT &&
      annotationType !== AnnotationType.CAUSE_MAP_NODE
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        'The passed annotation is not of a valid type.',
        annotationType
      );
      return;
    }

    const activeTool = this.toolManager.getActiveTool();
    if (
      annotationType === AnnotationType.STICKY &&
      isLabelNode(annotationNode) &&
      activeTool instanceof StickyTool
    ) {
      activeTool.onEditSticky(annotationNode);
      return;
    }

    if (
      annotationType === AnnotationType.CAUSE_MAP_NODE &&
      isCauseMapNodeAnnotationNode(annotationNode) &&
      activeTool instanceof CauseMapTool
    ) {
      activeTool.onEditCauseMapNode(annotationNode);
      return;
    }

    if (
      annotationType === AnnotationType.TEXT &&
      isTextNode(annotationNode) &&
      activeTool instanceof TextTool
    ) {
      activeTool.onEditText(annotationNode);
      return;
    }
  };

  private getGraphOffset = ({
    nodesInComponent,
    selectedNodeIds,
    previousComponent,
  }: {
    nodesInComponent: CauseMapNodeAnnotation[];
    selectedNodeIds: Set<string>;
    previousComponent?: CauseMapNodeAnnotation[];
  }): { minX: number; minY: number } => {
    const isEveryNodeInComponentSelected = nodesInComponent.every((node) =>
      selectedNodeIds.has(node.id)
    );

    const previousNodesById = keyBy(previousComponent, ({ id }) => id);
    const wereNewNodesAdded = nodesInComponent.some(
      ({ id }) => previousNodesById[id] === undefined
    );

    if (isEveryNodeInComponentSelected || wereNewNodesAdded) {
      return {
        minX: Math.min(...nodesInComponent.map(({ x }) => x)),
        minY: Math.min(...nodesInComponent.map(({ y }) => y)),
      };
    }

    if (previousComponent === undefined || previousComponent.length === 0) {
      return { minX: 0, minY: 0 };
    }

    return {
      minX: Math.min(...previousComponent.map(({ x }) => x)),
      minY: Math.min(...previousComponent.map(({ y }) => y)),
    };
  };

  private getPositionedCauseMapNodes = (
    nodes: CauseMapNodeAnnotation[]
  ): CauseMapNodeAnnotation[] => {
    const annotations = this.renderer.getAnnotations();
    const prevCauseMapNodes = annotations.filter(isCauseMapNodeAnnotation);
    const causeMapNodes = uniqBy(
      [...nodes, ...prevCauseMapNodes],
      ({ id }) => id
    );
    if (causeMapNodes.length === 0) {
      return [];
    }

    const edges = getCauseMapEdges({
      connections: annotations.filter(isPolylineAnnotation),
      causeMapNodesById: keyBy(causeMapNodes, 'id'),
    });

    const updatedComponents = getConnectedComponents(nodes, {
      nodes: causeMapNodes,
      edges,
    });

    const previousComponents = getConnectedComponents(
      nodes.map(
        (node) => prevCauseMapNodes.find(({ id }) => id === node.id) ?? node
      ),
      { nodes: prevCauseMapNodes, edges }
    );

    const selectedNodeIds = new Set(
      this.getTransformer()
        ?.getNodes()
        .filter(isCauseMapNodeAnnotationNode)
        .map((n) => n.id()) ?? []
    );
    const nextNodes = updatedComponents.flatMap((nodesInComponent) =>
      layoutCauseMapNodes(nodesInComponent, edges, {
        ...DEFAULT_LAYOUT_OPTIONS,
        ...this.getGraphOffset({
          nodesInComponent,
          selectedNodeIds,
          previousComponent: previousComponents.find((component) =>
            // Every connected component has (by definition) at least one node, so accessing the first element is safe.
            component.some(({ id }) => id === nodesInComponent[0].id)
          ),
        }),
      })
    );
    return nextNodes;
  };

  public needsRelayout = (nodes: CauseMapNodeAnnotation[]): boolean => {
    const nextNodes = this.getPositionedCauseMapNodes(nodes);
    if (nextNodes.length === 0) {
      return false;
    }
    const prevCauseMapNodesById = keyBy(
      this.renderer.getAnnotations().filter(isCauseMapNodeAnnotation),
      'id'
    );
    return nextNodes.some((node) => {
      const prevNode = prevCauseMapNodesById[node.id];
      return (
        prevNode === undefined ||
        !isSizeAndPositionApproximatelyEqual(prevNode, node)
      );
    });
  };

  public relayoutCauseMapNodes = (
    updatedNodes: CauseMapNodeAnnotation[]
  ): void => {
    const nextNodes = this.getPositionedCauseMapNodes(updatedNodes);
    if (nextNodes.length === 0) {
      return;
    }
    this.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      source: UpdateRequestSource.RELAYOUT_CAUSE_MAP,
      containers: [],
      annotations: nextNodes,
    });
    // Below, we force-update the connected edges of the updated nodes to ensure
    // that the edges are correctly positioned.
    // Misaligned/lingering edges could, for example, occur if you try to move
    // the same cause map node multiple times; since the layouting algorithm
    // will always place the manually moved node in the same position, our the
    // annotations reconciler will not update the node and thus not the
    // connected edges as well.
    this.refreshConnections(updatedNodes);
  };
}
