import type { Filters } from '@cognite/fdm-client/src/types';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useRef } from 'react';

import { useAppConfigFiltersContext } from '../../../../providers/app-config-filters-provider';

export const useObservationCountToday = () => {
  const { observationService } = useFDMServices();
  const sliMetrics = useMetrics(METRICS_NAMESPACES.SLI);

  const { observation: configFilters } = useAppConfigFiltersContext();

  const filters: Filters = {
    and: [
      {
        range: {
          property: 'createdTime',
          lte: dayjs().endOf('date').valueOf(),
          gte: dayjs().startOf('date').valueOf(),
        },
      },
    ],
  };

  if (configFilters.rootAssetExternalIds) {
    filters.and?.push(configFilters.rootAssetExternalIds);
  }

  const sliTimerStartTime = useRef<number | undefined>(undefined);

  return useQuery(
    [QueryKeys.OBSERVATION_COUNT_TODAY, filters],
    async () => {
      sliTimerStartTime.current = Date.now();

      const observations = await observationService.getObservations(filters);
      return observations.length;
    },
    {
      enabled: Boolean(configFilters.rootAssetExternalIds),
      onError: (err) => {
        captureException(err, {
          level: 'warning',
          tags: {
            queryKey: QueryKeys.OBSERVATION_COUNT_TODAY,
          },
        });

        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.OBSERVATION_COUNT_TODAY, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'error',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      onSuccess: () => {
        const sliTimerEndTime = Date.now();
        sliMetrics.track(QueryKeys.OBSERVATION_COUNT_TODAY, {
          sliTimerMilliseconds: sliTimerStartTime.current
            ? sliTimerEndTime - sliTimerStartTime.current
            : undefined,
          status: 'success',
          networkSpeedMbps: navigator.connection?.downlink,
        });
      },
      refetchOnWindowFocus: false,
    }
  );
};
