import { isEqual } from 'lodash-es';
import type { CellComponent, Tabulator } from 'tabulator-tables';

import getTableData from './getTableData';
import getTabulatorColumns from './getTabulatorColumns';
import type { DataGridColumn, DataGridRow } from './types';

const updateTableColumns = (
  table: Tabulator,
  columns: DataGridColumn[],
  rows: DataGridRow[],
  onCellContextMenu: (e: UIEvent, cell: CellComponent) => void,
  onCellClick: (cell: CellComponent) => void,
  onCellUpdated: VoidFunction
): void => {
  const prevColumns = table
    .getColumns()
    .map((c) => ({
      key: c.getField(),
      title: c.getDefinition().title,
      width: c.getWidth(),
    }))
    .filter(({ key }) => key !== undefined); // Key is undefined for the row header

  if (isEqual(prevColumns, columns)) {
    return;
  }
  table.setColumns(
    getTabulatorColumns(
      columns,
      rows,
      onCellUpdated,
      onCellClick,
      onCellContextMenu
    )
  );
};

const updateTableData = (table: Tabulator, rows: DataGridRow[]): void => {
  const data = getTableData(rows);
  const prevData = table.getData();
  if (isEqual(prevData, data)) {
    return;
  }
  if (prevData.length !== data.length) {
    table.setData(data);
  }
  table.updateData(data);
};

const updateTable = ({
  table,
  rows,
  columns,
  onCellContextMenu,
  onCellUpdated,
  onCellClick,
}: {
  table: Tabulator;
  rows: DataGridRow[];
  columns: DataGridColumn[];
  onCellContextMenu: (e: UIEvent, cell: CellComponent) => void;
  onCellClick: (cell: CellComponent) => void;
  onCellUpdated: VoidFunction;
}): void => {
  updateTableData(table, rows);
  updateTableColumns(
    table,
    columns,
    rows,
    onCellContextMenu,
    onCellClick,
    onCellUpdated
  );
  return;
};

export default updateTable;
