import { useFlag } from '@cognite/react-feature-flags';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import {
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import {
  IsSearchOpenAtom,
  TopBarDesktop,
  TopBarMobile,
  TopBarSearch,
} from '@infield/features/top-bar';
import type { NavigationItem } from '@infield/features/top-bar';
import { DismissableContent } from '@infield/features/ui';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import type { MobileVersionPageType } from '@infield/pages/page-layout';
import {
  getIsChecklistPageEnabled,
  getIsObservationsPageEnabled,
  getIsOverviewPageEnabled,
  getIsTemplatesPageEnabled,
} from '@infield/pages/utils';
import { useChecklistSchedulerContext } from '@infield/providers/checklist-scheduler-provider/checklist-scheduler-context';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { truthy } from '@infield/utils/filtering-helpers';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import * as S from './elements';

type Props = {
  page?: MobileVersionPageType;
};

export const TopBarPage: FC<Props> = ({ page }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.mainTopbar);
  const isDesktop = useIsDesktop();
  const [showSearch, setShowSearch] = useRecoilState(IsSearchOpenAtom);

  const { assetId: assetPageId } = useParams();
  const { isOnline } = useNetworkStatusContext();

  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );
  const isObservationsFeatureEnabled = getIsObservationsPageEnabled(
    rootLocationFeatureToggles
  );
  const isTemplatePageEnabled = getIsTemplatesPageEnabled(
    rootLocationFeatureToggles
  );
  const isOverviewPageEnabled = getIsOverviewPageEnabled(
    rootLocationFeatureToggles
  );
  const isChecklistPageEnabled = getIsChecklistPageEnabled(
    rootLocationFeatureToggles
  );
  const { isSchedulesPageEnabled } = useChecklistSchedulerContext();

  const { isEnabled: isSearchFeatureEnabled } = useFlag(
    UnleashKeys.INFIELD_SEARCH_EVERYTHING_IS_ENABLED
  );
  const { isEnabled: isAkerbpCustomCode } = useFlag(
    UnleashKeys.akerbp_custom_code,
    { forceRerender: true, fallback: false }
  );
  useEffect(() => {
    if (!isOnline && showSearch) {
      setShowSearch(false);
    }
  }, [isOnline, setShowSearch, showSearch]);

  const navigationItemsDesktop: NavigationItem[] = [
    isTemplatePageEnabled && {
      key: 'desktop-navigation-templates',
      name: t('TOP_BAR_NAVIGATION_TEMPLATES', 'Templates'),
      to: '/templates',
    },
    isSchedulesPageEnabled && {
      key: 'desktop-navigation-schedules',
      name: t('TOP_BAR_NAVIGATION_SCHEDULES', 'Schedules'),
      to: '/schedules',
      beta: true,
    },
    isOverviewPageEnabled && {
      key: 'desktop-navigation-overview',
      name: t('TOP_BAR_NAVIGATION_OVERVIEW', 'Overview'),
      to: '/overview',
    },
    isChecklistPageEnabled &&
      !isSearchFeatureEnabled && {
        key: 'desktop-navigation-checklists',
        name: t('TOP_BAR_NAVIGATION_CHECKLISTS', 'Checklists'),
        to: `/checklists`,
      },
    isObservationsFeatureEnabled && {
      key: 'desktop-navigation-observations',
      name: t('TOP_BAR_NAVIGATION_OBSERVATIONS', 'Observations'),
      to: `/observations`,
    },
  ].filter(truthy);

  if (isDesktop)
    return (
      <>
        <TopBarDesktop
          isAkerbpCustomCode={isAkerbpCustomCode}
          navigationItems={navigationItemsDesktop}
          onShowSearch={() => setShowSearch(!showSearch)}
        />
        {showSearch && (
          <DismissableContent onDismiss={() => setShowSearch(false)}>
            <S.DesktopSearchContainer>
              <TopBarSearch
                assetPageId={assetPageId}
                onClose={() => setShowSearch(false)}
                onResultSelected={() => setShowSearch(false)}
              />
            </S.DesktopSearchContainer>
          </DismissableContent>
        )}
      </>
    );

  return (
    <>
      <TopBarMobile
        darkMode={page === 'mobile-explore'}
        showSearch={page !== 'mobile-explore'}
      />
      {showSearch && (
        <S.MobileSearchContainer $searchOpened={showSearch}>
          <TopBarSearch
            assetPageId={assetPageId}
            onClose={() => setShowSearch(false)}
          />
        </S.MobileSearchContainer>
      )}
    </>
  );
};
