import type { UserProfileItem } from '@cognite/sdk';

export const createUserProfilesMap = (userProfiles?: UserProfileItem[]) => {
  return (
    userProfiles?.reduce((acc, profile) => {
      acc[profile.userIdentifier] = profile;
      return acc;
    }, {} as Record<string, UserProfileItem>) || {}
  );
};
