import mixpanel from 'mixpanel-browser';
import type { GetViewportParameters } from 'pdfjs-dist/types/src/display/api';
import { v4 as uuid } from 'uuid';

import { AnnotationType, InteractionMode, ToolConfig, ToolType } from '../..';
import { CommentAnnotation, Size } from '../annotations/types';
import { MIXPANEL_TOKEN } from '../constants';

// Don't identify users in MixPanel to avoid GDPR problems
const MIXPANEL_UNIQUE_ID = 'ufv-user';

export enum TrackedEventType {
  INIT = 'UFV.init',
  ERROR = 'UFV.error',
  SET_TOOL = 'UFV.setTool',
  DOCUMENT_EMBEDDED_IMAGE_SIZE = 'UFV.documentEmbeddedImageSize',
  CREATE_ELLIPSE = 'UFV.createEllipse',
  CREATE_POLYLINE = 'UFV.createPolyline',
  CREATE_LINE = 'UFV.createLine',
  CREATE_TEXT = 'UFV.createText',
  CREATE_COMMENT = 'UFV.createComment',
  CREATE_RECTANGLE = 'UFV.createRectangle',
  CREATE_IMAGE = 'UFV.createImage',
  CREATE_IMAGE_CONTAINER = 'UFV.createImageContainer',
  CREATE_DOCUMENT_CONTAINER = 'UFV.createDocumentContainer',
  CREATE_TEXT_CONTAINER = 'UFV.createTextContainer',
  IS_SUPPORTED_FILE_INFO = 'UFV.isSupportedFileInfo',
  CAUSE_MAP_ANCHOR_PRESSED = 'UFV.causeMapAnchorPressed',
  CAUSE_MAP_CREATED = 'UFV.causeMapCreated',
  CAUSE_MAP_UPDATED = 'UFV.causeMapUpdated',
  CAUSE_MAP_DELETED = 'UFV.causeMapDeleted',
  CAUSE_MAP_STATUS_CHANGED = 'UFV.causeMapStatusChanged',
  PERFORMANCE_DOCUMENT_RENDER = 'UFV.performanceDocumentRender',
  PERFORMANCE_REACT_CONTAINER_SCREENSHOT = 'UFV.performanceReactContainerScreenshot',
  PERFORMANCE_LOAD_ALL_NODES = 'UFV.performanceLoadAllNodes',
}

export type ContentContainerEventProps = {
  mimeType: string;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
};

export type ShapeEventProps = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  strokeScaleEnabled: boolean;
  userGenerated: boolean;
  name: string;
  source: ToolType;
  containerId: string;
  annotationType: AnnotationType;
};

export type PerformanceProps = {
  $duration: number;
};

export type TrackedEventMap = {
  [TrackedEventType.INIT]: {
    hostElementId: string;
    applicationId: string;
    defaultTool: ToolConfig;
    interactionMode: InteractionMode;
  };
  [TrackedEventType.ERROR]: {
    name: string;
    error: string | Error;
  };
  [TrackedEventType.SET_TOOL]: ToolConfig;
  [TrackedEventType.DOCUMENT_EMBEDDED_IMAGE_SIZE]: {
    width: number;
    height: number;
  };
  [TrackedEventType.CREATE_RECTANGLE]: ShapeEventProps;
  [TrackedEventType.CREATE_IMAGE]: ShapeEventProps;
  [TrackedEventType.CREATE_COMMENT]: Pick<CommentAnnotation, 'author'> &
    CommentAnnotation['style'];
  [TrackedEventType.CREATE_ELLIPSE]: ShapeEventProps & {
    radiusX: number;
    radiusY: number;
  };
  [TrackedEventType.CREATE_POLYLINE]: ShapeEventProps & { points: number[] };
  [TrackedEventType.CREATE_LINE]: ShapeEventProps & { points: number[] };
  [TrackedEventType.CREATE_TEXT]: ShapeEventProps & {
    text: string;
    fontSize: number;
  };
  [TrackedEventType.CREATE_IMAGE_CONTAINER]: ContentContainerEventProps;
  [TrackedEventType.CREATE_TEXT_CONTAINER]: ContentContainerEventProps;
  [TrackedEventType.CREATE_DOCUMENT_CONTAINER]: ContentContainerEventProps & {
    page: number;
    pageCount: number;
  };
  [TrackedEventType.IS_SUPPORTED_FILE_INFO]: {
    isSupported: boolean;
    mimeType: string | undefined;
    computedMimeType: string | undefined;
    fileNameExtension: string;
  };
  [TrackedEventType.CAUSE_MAP_ANCHOR_PRESSED]: {
    anchorName: string | undefined;
  };
  [TrackedEventType.CAUSE_MAP_CREATED]: {};
  [TrackedEventType.CAUSE_MAP_UPDATED]: {
    width: number;
    height: number;
    fontSize: number;
    fill: string;
  };
  [TrackedEventType.CAUSE_MAP_STATUS_CHANGED]: { currentStatus: string };
  [TrackedEventType.PERFORMANCE_DOCUMENT_RENDER]: Partial<
    ContentContainerEventProps &
      PerformanceProps & {
        currentScale: number;
        numOperations: number;
        viewport: GetViewportParameters;
        canvasSize: Size;
        isLinearized: boolean;
        contentLength?: number;
      }
  >;
  [TrackedEventType.PERFORMANCE_REACT_CONTAINER_SCREENSHOT]: Partial<
    ContentContainerEventProps &
      PerformanceProps & { reactContainerType: string; currentScale: number }
  >;
  [TrackedEventType.PERFORMANCE_LOAD_ALL_NODES]: Partial<
    PerformanceProps & Record<string, number>
  >;
};

type MetricsLoggerParams = {
  shouldLogMetrics: boolean;
  applicationId: string;
  eventProps: TrackedEventMap[TrackedEventType.INIT];
  project?: string; // TODO(FUS-000): should project be a required field?
};

class MetricsLogger {
  private readonly shouldLogMetrics: boolean;
  private readonly sessionProps: {
    project: string;
    application: string;
    sessionId: string;
  };

  public constructor({
    shouldLogMetrics,
    applicationId,
    eventProps,
    project,
  }: MetricsLoggerParams) {
    this.shouldLogMetrics = shouldLogMetrics;
    this.sessionProps = {
      project: project || 'unknown',
      application: applicationId || 'unknown',
      // Use a random identifier because we want to don't track users over
      // multiple sessions to not violate GDPR.
      sessionId: uuid(),
    };

    if (this.shouldLogMetrics) {
      mixpanel.init(MIXPANEL_TOKEN, {
        batch_requests: true,
        disable_cookie: true,
        disable_persistence: true,
        // Don't send IP which disables geolocation
        ip: false,
        // Avoid sending a bunch of properties that might help identifying a user
        property_blacklist: [
          // https://help.mixpanel.com/hc/en-us/articles/115004613766-Default-Properties-Collected-by-Mixpanel#profile-properties-javascript
          '$city',
          '$region',
          'mp_country_code',
          '$geo_source',
          '$timezone',
          'mp_lib',
          '$lib_version',
          '$device_id',
          '$user_id',
          '$current_url',
          '$screen_width',
          '$screen_height',
          '$referrer',
          '$referring_domain',
          '$initial_referrer',
          '$initial_referring_domain',
        ],
      });
      // Reset device ID (even if we don't send it)
      mixpanel.reset();

      mixpanel.identify(MIXPANEL_UNIQUE_ID);
      this.trackEvent(TrackedEventType.INIT, eventProps);
    }
  }

  public trackEvent<T extends keyof TrackedEventMap>(
    eventType: T,
    eventProps: TrackedEventMap[T]
  ): void {
    if (this.shouldLogMetrics) {
      const combined = { ...this.sessionProps, ...eventProps };
      mixpanel.track(eventType, combined);
    }
  }
}

let metricsLogger: MetricsLogger | null = null;
const getMetricsLogger = (
  initParams?: MetricsLoggerParams
): MetricsLogger | null => {
  if (metricsLogger === null) {
    if (initParams === undefined) {
      // What should we do when the logger is not initialized _and_ no init params is given?
      // For now, we just return null.
      // Another option would be to initialize the logger with default values so we still get some metrics,
      // and initialize it properly when the init params are given.
      return null;
    }
    metricsLogger = new MetricsLogger(initParams);
  }
  return metricsLogger;
};

export default getMetricsLogger;
