import React, { PropsWithChildren } from 'react';

import styled from 'styled-components';

import { MAX_PAGE_WIDTH_MOBILE } from '@fusion/ui-components';

import { Flex } from '@cognite/cogs-core';

const TabContentContainer = ({ children }: PropsWithChildren) => (
  <Flex direction="column" gap={24}>
    {children}
  </Flex>
);

const TabContentBody = ({
  children,
  compact,
}: PropsWithChildren<{ compact?: boolean }>) => (
  <TabContentBodyContainer $compact={compact}>
    {children}
  </TabContentBodyContainer>
);

export const TabContent = () => <></>;
TabContent.Container = TabContentContainer;
TabContent.Body = TabContentBody;

const TabContentBodyContainer = styled.div<{ $compact?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ $compact }) => ($compact ? 'max-width: 400px;' : '')};

  @container main (inline-size <= ${MAX_PAGE_WIDTH_MOBILE}px) {
    max-width: 100%;
  }
`;
