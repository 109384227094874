import type { UploadedMedia } from '@infield/features/media/types';
import type { FC } from 'react';

import * as S from './elements';
import { MediaPreview } from './media-preview';

type Props = {
  handleMediaClick: (media: UploadedMedia) => void;
  mediaFiles?: UploadedMedia[];
};

export const MultipleMediaContent: FC<Props> = ({
  mediaFiles,
  handleMediaClick,
}) => {
  return (
    <S.MediaGridWrapper>
      {mediaFiles?.map((media) => {
        return (
          <MediaPreview
            key={media?.file?.externalId}
            media={media}
            handleMediaClick={handleMediaClick}
          />
        );
      })}
    </S.MediaGridWrapper>
  );
};
