import type { TaskInterval, Template } from '@cognite/apm-client';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { dmsItemIngestionLimit } from '@infield/utils/dms-requests';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import chunk from 'lodash/chunk';

export const useIntervalsDelete = () => {
  const { intervalService } = useFDMServices();
  const queryClient = useQueryClient();
  const metrics = useMetrics(METRICS_NAMESPACES.auditSchedule);

  return useMutation<
    TaskInterval[],
    Error,
    {
      intervalsToDelete: TaskInterval[];
    }
  >(
    async ({ intervalsToDelete }) => {
      const batchedIntervals = chunk(
        intervalsToDelete.map(({ externalId }) => externalId),
        dmsItemIngestionLimit
      );

      await Promise.all(
        batchedIntervals.map((batch) => intervalService.deleteIntervals(batch))
      );

      return intervalsToDelete;
    },
    {
      onMutate: async ({ intervalsToDelete }) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        if (activeQuery.length > 0) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries([QueryKeys.TEMPLATE]);

          const [{ queryKey: activeQueryKey }] = activeQuery;

          // Snapshot the previous value
          const previousTemplate = queryClient.getQueryData(
            activeQueryKey
          ) as Template;

          const previousTemplateItems = previousTemplate.templateItems ?? [];

          const intervalsExternalIds = intervalsToDelete?.map(
            ({ externalId }) => externalId
          );

          // Optimistically update to the new value
          const nextTemplateItems = [
            ...previousTemplateItems.map((templateItem) => {
              const previousIntervals = [...(templateItem.schedules || [])];

              // Optimistically update to the new value
              const nextIntervals = previousIntervals.filter(
                ({ externalId }) => !intervalsExternalIds.includes(externalId)
              );

              return { ...templateItem, schedules: nextIntervals };
            }),
          ];

          const updatedTemplate = {
            ...previousTemplate,
            templateItems: nextTemplateItems,
          };

          queryClient.setQueryData(activeQueryKey, updatedTemplate);

          // Return a context object with the snapshotted value
          return previousTemplate;
        }
      },

      onError: (err, previousIntervals) => {
        const queryCache = queryClient.getQueryCache();

        // get the active queryKey
        const activeQuery = queryCache.findAll({
          type: 'active',
          queryKey: [QueryKeys.TEMPLATE],
        });

        // set cache back to its original state
        if (activeQuery.length > 0) {
          const [{ queryKey: activeQueryKey }] = activeQuery;

          queryClient.setQueryData(activeQueryKey, previousIntervals);
        }
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-intervals-delete-mutation',
          },
        });
      },
      onSuccess: async (intervalsToDelete) => {
        queryClient.invalidateQueries([QueryKeys.TEMPLATE]);
        intervalsToDelete.forEach(() => {
          metrics.track('Delete');
        });
      },
      mutationKey: ['useIntervalsDelete'],
    }
  );
};
