import type { Activity, Checklist } from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import { sleep } from '@cognite/lodashy';
import { useMetrics } from '@cognite/metrics';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const overviewTableQueryKeys = [
  QueryKeys.ACTIVITY_INFINITE_WITH_CHECKLIST,
  QueryKeys.OPERATION_CHECKLIST_LIST,
  QueryKeys.SEARCH_ACTIVITIES_WITH_CHECKLIST,
  QueryKeys.SEARCH_CHECKLISTS,
];

/**
 * Soft-deletes checklists.
 *
 * @param externalIds - Checklists to soft delete
 */
export const useChecklistsDelete = () => {
  const { checklistService } = useFDMServices();
  const queryClient = useQueryClient();
  const { t } = useTranslation(LOCIZE_NAMESPACES.checklist);
  const metrics = useMetrics(METRICS_NAMESPACES.auditChecklist);

  return useMutation(
    async (externalIds: string[]) => {
      return checklistService.softDeleteChecklists(externalIds);
    },
    {
      onMutate: async (externalIds) => {
        // Optimistically update overview table queries
        const queryCache = queryClient.getQueryCache();
        const activeAndEnabledQueries = queryCache
          .getAll()
          .filter(
            (cache) =>
              cache.isActive() &&
              !cache.isDisabled() &&
              overviewTableQueryKeys.includes((cache.queryKey as string[])?.[0])
          );

        if (activeAndEnabledQueries.length > 0) {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await Promise.all(
            activeAndEnabledQueries.map((query) => query.cancel())
          );

          let previousData;
          activeAndEnabledQueries.forEach((cache) => {
            switch (cache.queryKey[0]) {
              case QueryKeys.SEARCH_CHECKLISTS:
                // Snapshot the previous value
                previousData = queryClient.getQueryData(
                  cache.queryKey
                ) as Checklist[];

                // Optimistically update cache
                queryClient.setQueryData(
                  cache.queryKey,
                  previousData.filter((checklist) => {
                    return !externalIds.includes(checklist.externalId);
                  })
                );
                break;

              case QueryKeys.OPERATION_CHECKLIST_LIST:
                // Snapshot the previous value
                previousData = queryClient.getQueryData(cache.queryKey) as any;
                // Optimistically update cache
                queryClient.setQueryData(cache.queryKey, {
                  ...previousData,
                  // Only perform optimistic update for 1st page (50 checklists) for simplicity
                  // Pages beyond the first will still be updated, just not optimistically
                  pages: [
                    {
                      items: previousData.pages[0].items.filter(
                        (checklist: Checklist) => {
                          return !externalIds.includes(checklist.externalId);
                        }
                      ),

                      pageInfo: previousData.pages[0].pageInfo,
                    },
                  ],
                });
                break;

              case QueryKeys.ACTIVITY_INFINITE_WITH_CHECKLIST:
                previousData = queryClient.getQueryData(cache.queryKey) as any;
                // Optimistically update cache
                queryClient.setQueryData(cache.queryKey, {
                  ...previousData,
                  // Only perform optimistic update for 1st page (1000 checklists) for simplicity
                  pages: [
                    {
                      items: {
                        ...previousData.pages[0].items,
                        checklists:
                          previousData.pages[0].items.checklists.filter(
                            (checklist: Checklist) => {
                              return !externalIds.includes(
                                checklist.externalId
                              );
                            }
                          ),
                      },
                      pageInfo: previousData.pages[0].pageInfo,
                    },
                  ],
                });
                break;

              case QueryKeys.SEARCH_ACTIVITIES_WITH_CHECKLIST:
                // Snapshot the previous value
                previousData = queryClient.getQueryData(cache.queryKey) as {
                  activities: Activity[];
                  checklists: Checklist[];
                };
                // Optimistically update cache
                queryClient.setQueryData(cache.queryKey, {
                  ...previousData,
                  checklists: previousData.checklists.filter((checklist) => {
                    return !externalIds.includes(checklist.externalId);
                  }),
                });
                break;
            }
          });
        }
      },
      onError: (err) => {
        makeToast({
          body: t(
            'CHECKLIST_DELETE_NOTIFICATION_ERROR',
            'Failed to delete checklist'
          ),
          type: 'danger',
        });
        captureException(err, {
          level: 'error',
          tags: {
            mutationKey: 'use-checklist-delete-mutation',
          },
        });
      },
      onSuccess: async (externalIds) => {
        externalIds.forEach(() => {
          metrics.track('Update', {
            isArchived: true,
          });
        });
      },
      onSettled: async () => {
        // Invalidate list queries
        await Promise.all([
          queryClient.invalidateQueries([
            QueryKeys.ACTIVITY_INFINITE_WITH_CHECKLIST,
          ]),
          queryClient.invalidateQueries([QueryKeys.OPERATION_CHECKLIST_LIST]),
        ]);

        // Search is eventually consistent, needs some time to reflect the changes
        await sleep(1500);

        // Invalidate search queries
        await Promise.all([
          queryClient.invalidateQueries([
            QueryKeys.SEARCH_ACTIVITIES_WITH_CHECKLIST,
          ]),
          queryClient.invalidateQueries([QueryKeys.SEARCH_CHECKLISTS]),
        ]);
      },
    }
  );
};
