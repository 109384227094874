import {
  ActivityList,
  selectedActivityAtom,
} from '@infield/features/activities';
import { ChecklistComponent } from '@infield/features/checklist';
import { SplitViewWrapper } from '@infield/features/ui/split-view-wrapper/split-view-wrapper';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { Allotment, LayoutPriority } from 'allotment';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { AssetContainerContent } from './asset-container-content';
import * as S from './elements';

export const ActivityDesktop: FC = () => {
  const { isOnline } = useNetworkStatusContext();

  const [openedChecklistId, setOpenChecklistId] =
    useRecoilState(selectedActivityAtom);
  const [openedActivityDetailsId, setOpenedActivityDetailsId] = useState('');
  const [openedAssetDetailsId, setOpenedAssetDetailsId] = useState('');

  const openChecklist = (activityId: string) => {
    setOpenChecklistId(activityId);
  };

  const handleOnTitleClick = (activityExternalId: string) => {
    setOpenedActivityDetailsId(activityExternalId);
  };

  const handleOnAssetClick = (assetExternalId: string) => {
    setOpenedAssetDetailsId(assetExternalId);
  };

  useEffect(() => {
    if (!isOnline) {
      setOpenedAssetDetailsId('');
    }
  }, [isOnline]);

  useEffect(() => {
    setOpenedAssetDetailsId('');
  }, [openedChecklistId]);

  return (
    <>
      <S.Container>
        <S.ActivityListContainer>
          <ActivityList onSelectActivity={openChecklist} />
          {!isOnline && (
            <S.ChecklistOverlay data-testid="checklist-list-overlay" />
          )}
        </S.ActivityListContainer>
        <SplitViewWrapper>
          <Allotment.Pane
            visible={Boolean(openedChecklistId)}
            preferredSize={800}
            minSize={322}
          >
            <S.ChecklistContainer>
              <ChecklistComponent
                checklistId={openedChecklistId}
                onTitleClick={handleOnTitleClick}
                onAssetClick={handleOnAssetClick}
              />
              {!isOnline && (
                <S.ChecklistOverlay data-testid="checklist-item-overlay" />
              )}
            </S.ChecklistContainer>
          </Allotment.Pane>
          <Allotment.Pane priority={LayoutPriority.High} minSize={400}>
            <AssetContainerContent
              openedActivityDetailsId={openedActivityDetailsId}
              openedAssetDetailsId={openedAssetDetailsId}
              handleOnAssetClick={handleOnAssetClick}
              setOpenedActivityDetailsId={setOpenedActivityDetailsId}
              setOpenedAssetDetailsId={setOpenedAssetDetailsId}
            />
          </Allotment.Pane>
        </SplitViewWrapper>
      </S.Container>
    </>
  );
};
