/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
import uniq from 'lodash/uniq';

import type { Filters, Sort } from '@cognite/fdm-client';
import type {
  AggregationDefinition,
  CogniteClient,
  NodeWrite,
  ViewDefinition,
  ViewReference,
} from '@cognite/sdk';

import { QueryBuilder } from './query-builder';
import { QueryResultSetMerger } from './result-set-merger';
import type { DefaultCDFViewMigrator, PageInfo, PropertyTree } from './types';
import {
  createFilterWithSpaces,
  extractRelationCursors,
  isFurtherRelationPaginationNecessary,
  mapNodeDefinitionToNodeWithView,
  mergeInstancesResults,
  toDMSFilter,
} from './utils';
import { ViewCache } from './view-cache';

export class CDFView<
  ReadType extends Record<string, any>,
  WriteType = ReadType
> {
  cogniteSDK: CogniteClient;
  viewReference: ViewReference;
  defaultInstanceSpace: string;
  viewDetails: ViewDefinition | null;
  viewCache: ViewCache;

  migrator?: DefaultCDFViewMigrator;
  track?: (name: string, options: object) => void;

  constructor(
    cogniteSDK: CogniteClient,
    viewReference: Omit<ViewReference, 'type'>,
    defaultInstanceSpace: string,
    viewCache: ViewCache = new ViewCache(cogniteSDK),
    options?: {
      migrator?: DefaultCDFViewMigrator;
      track?: (name: string, options: object) => void;
    }
  ) {
    this.cogniteSDK = cogniteSDK;
    this.defaultInstanceSpace = defaultInstanceSpace;
    this.viewReference = { ...viewReference, type: 'view' };
    this.viewCache = viewCache;
    this.viewDetails = null;
    this.track = options?.track;
    this.migrator = options?.migrator;
  }

  /**
   * List items from the view
   * @param variables - Variables for the query
   * @returns List of items
   */
  list = async (
    variables: {
      spaces?: string[];
      nextCursor?: string;
      pageSize?: number;
      filters?: Filters;
      sort?: Sort;
      propertyTree?: PropertyTree;
      properties?: string[];
      autoPaginateRelations?: boolean;
      includeNullInDescSort?: boolean;
    } = {}
  ): Promise<{
    items: ReadType[];
    pageInfo: PageInfo;
  }> => {
    const timeStart = performance.now();
    const viewDefinition = await this.fetchViewDetails();
    let { filters, sort, properties } = variables;
    if (this.migrator?.mapFiltersInward && filters !== undefined) {
      filters = this.migrator.mapFiltersInward(filters);
    }
    if (this.migrator?.mapSortInward && sort !== undefined) {
      sort = this.migrator.mapSortInward(sort);
    }
    if (this.migrator?.mapPropertiesInward && properties !== undefined) {
      properties = this.migrator.mapPropertiesInward(properties);
    }
    // 1. Build and execute a CDF DM Query
    const queryBuilder = new QueryBuilder(
      this.cogniteSDK,
      this.viewReference,
      this.defaultInstanceSpace,
      this.viewCache
    );

    const result = await queryBuilder.build({
      ...variables,
      filters,
      sort,
      properties,
    });
    let { query } = result;
    const { cdfProperties } = result;

    const baseCursorKey = `${viewDefinition.externalId}/${viewDefinition.version}`;
    let queryInstances = await this.cogniteSDK.instances.query(query);
    const baseQueryCursor = queryInstances.nextCursor[baseCursorKey];
    let allQueryInstances = queryInstances.items;

    if (variables.autoPaginateRelations) {
      while (
        isFurtherRelationPaginationNecessary(
          query,
          queryInstances,
          baseCursorKey
        )
      ) {
        const relationCursors = extractRelationCursors(
          baseCursorKey,
          queryInstances.nextCursor
        );

        query = await queryBuilder
          .build({
            ...variables,
            relationCursors,
          })
          .then((res) => res.query);
        queryInstances = await this.cogniteSDK.instances.query(query);

        allQueryInstances = mergeInstancesResults(
          allQueryInstances,
          queryInstances.items
        );
      }
    }

    // 2. Take the result sets from the query, and merge them into a single core object.
    const resultSetMerger = new QueryResultSetMerger<ReadType>(
      this.cogniteSDK,
      this.viewReference,
      this.defaultInstanceSpace,
      this.viewCache
    );

    // 2a. Fetch additional CDF resources - files, timeseries, etc.
    const filesByQueryInstanceKey =
      await resultSetMerger.fetchCDFResourceResultSets(
        allQueryInstances,
        cdfProperties
      );

    // 2b. Connect the data together into a single resource (whatever the type T is)
    const connectedData = resultSetMerger.connectData(
      query,
      allQueryInstances,
      filesByQueryInstanceKey,
      this.viewReference
    );

    this.track?.(`list_${this.viewReference.externalId}`, {
      duration: performance.now() - timeStart,
      count: connectedData.length,
    });

    if (this.migrator?.mapDataOutward) {
      return {
        items: connectedData.map(this.migrator.mapDataOutward),
        pageInfo: {
          nextCursor: baseQueryCursor,
          hasNextPage: baseQueryCursor !== undefined,
        },
      };
    }

    return {
      items: connectedData as ReadType[],
      pageInfo: {
        nextCursor: baseQueryCursor,
        hasNextPage: baseQueryCursor !== undefined,
      },
    };
  };

  /**
   * Upsert items to the view
   * @param nodes - Nodes to upsert
   */
  async upsert(
    nodes: (Partial<WriteType> & { externalId: string; space?: string })[]
  ) {
    const timeStart = performance.now();
    if (this.migrator?.mapDataInward) {
      nodes = nodes.map(this.migrator.mapDataInward);
    }
    const parseData: NodeWrite[] = nodes.map((node) => {
      const properties: Partial<WriteType> & {
        externalId?: string;
        space?: string;
      } = {
        ...node,
        externalId: '',
        space: '',
      };
      delete properties.externalId;
      delete properties.space;
      return {
        instanceType: 'node',
        externalId: node.externalId,
        space: node.space || this.defaultInstanceSpace,
        sources: [{ source: this.viewReference, properties }],
      };
    });

    const upsertResult = await this.cogniteSDK.instances.upsert({
      autoCreateDirectRelations: true,
      items: parseData,
    });

    this.track?.(`upsert_${this.viewReference.externalId}`, {
      duration: performance.now() - timeStart,
      count: nodes.length,
    });

    return upsertResult;
  }

  /**
   * Aggregate items based on groupBy
   * @param variables - Variables for the query
   * @returns Aggregated items
   */
  async aggregate(variables: {
    spaces?: string[];
    groupBy?: string[];
    filters?: Filters;
    query?: string;
    aggregates?: AggregationDefinition[];
    properties?: string[];
    limit?: number;
  }) {
    const timeStart = performance.now();

    let { filters } = variables;
    const {
      groupBy,
      query,
      spaces = [this.defaultInstanceSpace],
      aggregates,
      properties,
      limit,
    } = variables;

    if (this.migrator?.mapFiltersInward && filters !== undefined) {
      filters = this.migrator.mapFiltersInward(filters);
    }

    const viewDefinition = await this.fetchViewDetails();

    const dmsFilters = await toDMSFilter(
      filters,
      viewDefinition,
      this.viewCache
    );

    const aggregateResult = await this.cogniteSDK.instances.aggregate({
      view: this.viewReference,
      groupBy,
      aggregates,
      filter: createFilterWithSpaces(dmsFilters, spaces),
      instanceType: 'node',
      query,
      properties,
      limit,
    });

    this.track?.(`aggregate_${this.viewReference.externalId}`, {
      duration: performance.now() - timeStart,
    });

    return aggregateResult;
  }

  /**
   * Search for items in the view
   * @param variables - Variables for the query
   * @returns List of items
   */
  async search(variables: {
    query: string;
    spaces?: string[];
    pageSize?: number;
    filters?: Filters;
    properties?: string[];
  }) {
    const timeStart = performance.now();

    let { filters, properties } = variables;
    const { pageSize, query, spaces = [this.defaultInstanceSpace] } = variables;

    if (this.migrator?.mapFiltersInward && filters !== undefined) {
      filters = this.migrator.mapFiltersInward(filters);
    }
    if (this.migrator?.mapPropertiesInward && properties !== undefined) {
      properties = this.migrator.mapPropertiesInward(properties);
    }

    const viewDefinition = await this.fetchViewDetails();

    const dmsFilters = await toDMSFilter(
      filters,
      viewDefinition,
      this.viewCache
    );

    const searchResults = await this.cogniteSDK.instances
      .search({
        view: this.viewReference,
        query,
        filter: createFilterWithSpaces(dmsFilters, spaces),
        limit: pageSize,
        properties,
      })
      .then((res) =>
        res.items.map((node) =>
          mapNodeDefinitionToNodeWithView<ReadType>(node, this.viewReference)
        )
      );

    this.track?.(`search_${this.viewReference.externalId}`, {
      duration: performance.now() - timeStart,
      count: searchResults.length,
    });

    if (this.migrator?.mapDataOutward) {
      return searchResults.map(this.migrator.mapDataOutward);
    }

    return searchResults;
  }

  /**
   * Deletes items based on external ID.
   * @param externalIds External IDs to delete
   * @returns
   */
  async delete(items: { externalId: string; space?: string }[]) {
    const timeStart = performance.now();
    const deleteResult = await this.cogniteSDK.instances.delete(
      items.map((item) => ({
        instanceType: 'node',
        externalId: item.externalId,
        space: item.space || this.defaultInstanceSpace,
      }))
    );
    this.track?.(`list_${this.viewReference.externalId}`, {
      duration: performance.now() - timeStart,
      count: items.length,
    });
    return deleteResult;
  }

  /**
   * Get a single item by external ID
   * @param externalId External ID to fetch
   * @param fields GraphQL fields to fetch for this query
   * @returns Item
   */
  async byId(
    references: { space?: string; externalId: string }[],
    options?: {
      propertyTree?: PropertyTree;
      properties?: string[];
      pageSize?: number;
    }
  ) {
    // Tracking is baked into the list function
    const spaces = uniq(
      references.filter(({ space }) => space).map(({ space }) => space!)
    );
    return this.list({
      propertyTree: options?.propertyTree,
      properties: options?.properties,
      pageSize: options?.pageSize || 100,
      filters: {
        in: {
          property: 'externalId',
          in: references.map((ref) => ref.externalId),
        },
      },
      spaces: spaces.length > 0 ? spaces : undefined,
    }).then((res) => res.items);
  }

  public async fetchViewDetails() {
    const viewDefinition = await this.viewCache.fetchViewDetails(
      this.viewReference
    );

    if (viewDefinition === undefined) {
      console.error(this.viewReference);
      throw new Error('Could not find view definition');
    }

    return viewDefinition;
  }
}
