import { NavigateFunction } from 'react-router-dom';

import { CogIdpProject, TokenInspect } from '../types';

// Used to return a never-resolving, never-rejecting promise.
// This is useful if you expect the caller to not do anything before
// an operation is fullfilled e.g. redirect to another page.
function noopPromise(): Promise<void> {
  return new Promise(() => {});
}

// we export this function for testing purposes only
export function generateRedirectToLoginUrl(
  href: string,
  keepDeepLink: boolean
) {
  const url = new URL(href);
  const ref = new URLSearchParams({
    path: url.pathname,
    search: url.search,
  });
  const redirectUrl = new URL('/', url.origin);
  if (keepDeepLink) {
    redirectUrl.search = ref.toString();
  }
  return redirectUrl.href;
}

type RedirectToLoginProps = {
  keepDeepLink?: boolean;
};
/**
 * Used to redirect to login page if user does not have access to project
 * And forwards the current path and search params to the login page
 */
export function redirectToLogin({
  keepDeepLink = true,
}: RedirectToLoginProps = {}): Promise<void> {
  const redirectUrl = generateRedirectToLoginUrl(
    window.location.href,
    keepDeepLink
  );
  redirectToSubpath(redirectUrl);
  return noopPromise();
}

export const parseRef = (search: string): [string, Record<string, string>] => {
  const searchParams = new URLSearchParams(search);

  const refPath = searchParams.get('path') ?? '';
  const refSearch = searchParams.get('search') ?? '';
  const extraParams: Record<string, string> = {};

  new URLSearchParams(refSearch).forEach((value, key) => {
    extraParams[key] = value;
  });

  return [refPath, extraParams];
};

export const selectProjectRoute = '/select-project';
export const goToSelectProject = (navigate: NavigateFunction) => {
  const [path, extraParams] = parseRef(window.location.search);
  if (path && Object.keys(extraParams).length > 0) {
    redirectToApp(path, extraParams);
    return;
  }
  navigate(selectProjectRoute);
};

export const redirectToSubpath = (url: string) => {
  const { host, protocol, origin } = window.location;
  const redirectUrl = new URL(url, origin);
  // make sure we don't redirect to antoher domain as we don't trust the input path
  // see https://kennel209.gitbooks.io/owasp-testing-guide-v4/content/en/web_application_security_testing/testing_for_client_side_url_redirect_otg-client-004.html
  redirectUrl.host = host;
  redirectUrl.protocol = protocol;
  window.location.href = redirectUrl.href;
};

export const redirectToApp = (
  path: string,
  extraParams: Record<string, string> = {}
) => {
  const urlSearchParams = new URLSearchParams(extraParams);
  redirectToSubpath(`${path}?${urlSearchParams.toString()}`);
};

export const getCogIdpProjects = async (
  cluster: string,
  accessToken: string,
  cogIdpProjects: CogIdpProject[]
) => {
  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const tokenInspectProjects = await fetch(
    `https://${cluster}/api/v1/token/inspect`,
    options
  ).then(async (r) => {
    if (r.status === 200) {
      const data: TokenInspect = await r.json();
      return data?.projects;
    } else {
      return [];
    }
  });

  // Condition that returns true if the project belongs to a cluster.
  const projectsPerCluster = (p: CogIdpProject) =>
    `https://${cluster}` === p.apiUrl;

  // Condition that returns true if the project has groups.
  const projectsWithGroups = (p: CogIdpProject) => {
    const found = tokenInspectProjects.find(
      (tp) => tp.projectUrlName === p.name
    );
    const groupsCount = found?.groups?.length ?? 0;
    return groupsCount > 0;
  };

  return cogIdpProjects
    .filter(projectsPerCluster)
    .filter(projectsWithGroups)
    .map((p) => p.name);
};
