import { VIEW_VERSIONS } from '@cognite/apm-client';
import type { ViewReference } from '@cognite/sdk';

// TODO(FUS-000): Comment the query for easier understanding
export const getAssetModelsQuery = (
  assetExternalId: string,
  assetViewReference: ViewReference
) => {
  // Here we assume that all of the core views are in the same space
  const { space } = assetViewReference;

  return {
    with: {
      assets: {
        limit: 50,
        nodes: {
          filter: {
            and: [
              {
                matchAll: {},
              },
              {
                hasData: [assetViewReference],
              },
              {
                or: [
                  {
                    equals: {
                      property: ['node', 'externalId'],
                      value: assetExternalId,
                    },
                  },
                ],
              },
            ],
          },
        },
      },
      object3d: {
        limit: 10000,
        nodes: {
          from: 'assets',
          through: {
            source: assetViewReference,
            identifier: 'object3D',
          },
          direction: 'outwards',
          filter: {
            and: [
              {
                matchAll: {},
              },
              {
                hasData: [
                  {
                    type: 'view',
                    space,
                    externalId: 'Cognite3DObject',
                    version: VIEW_VERSIONS.COGNITE_3D_OBJECT,
                  },
                ],
              },
            ],
          },
        },
      },
      cadNodes: {
        limit: 10000,
        nodes: {
          from: 'object3d',
          through: {
            source: {
              type: 'view',
              space,
              externalId: 'CogniteCADNode',
              version: VIEW_VERSIONS.COGNITE_CAD_NODE,
            },
            identifier: 'object3D',
          },
          direction: 'inwards',
          filter: {
            and: [
              {
                matchAll: {},
              },
              {
                hasData: [
                  {
                    type: 'view',
                    space,
                    externalId: 'CogniteCADNode',
                    version: VIEW_VERSIONS.COGNITE_CAD_NODE,
                  },
                ],
              },
            ],
          },
        },
      },
      model3D: {
        limit: 10000,
        nodes: {
          from: 'cadNodes',
          through: {
            source: {
              type: 'view',
              space,
              externalId: 'CogniteCADNode',
              version: VIEW_VERSIONS.COGNITE_CAD_NODE,
            },
            identifier: 'model3D',
          },
          direction: 'outwards',
        },
      },
      revisions: {
        limit: 10000,
        nodes: {
          from: 'cadNodes',
          through: {
            source: {
              type: 'view',
              space,
              externalId: 'CogniteCADNode',
              version: VIEW_VERSIONS.COGNITE_CAD_NODE,
            },
            identifier: 'revisions',
          },
          direction: 'outwards',
        },
      },
    },
    select: {
      model3D: {
        sources: [
          {
            source: {
              type: 'view',
              space,
              externalId: 'Cognite3DModel',
              version: VIEW_VERSIONS.COGNITE_3D_MODEL,
            },
            properties: ['*'],
          },
        ],
      },
      revisions: {
        sources: [
          {
            source: {
              type: 'view',
              space,
              externalId: 'CogniteCADRevision',
              version: VIEW_VERSIONS.COGNITE_CAD_REVISION,
            },
            properties: ['*'],
          },
        ],
      },
    },
  };
};
