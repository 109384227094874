import { Body, Heading, IconWrapper, LoaderIcon } from '@cognite/cogs.js-v10';
import { Asset, useClassicByIdOrAPMAssetQuery } from '@infield/features/asset';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { ComponentScrollableContent } from '@infield/features/ui';
import type { FC } from 'react';

import { AssetInfoError } from '../asset-info-error';
import type { AssetInfoBaseProps } from '../types';

import * as S from './elements';
import { EmptyStateSearchSad } from './EmptyStateSearchSad';

export const AssetInfoDesktop: FC<AssetInfoBaseProps> = ({
  isLoading,
  assetId,
  onClose,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.threeD);

  const {
    data: asset,
    error,
    isLoading: isLoadingAsset,
  } = useClassicByIdOrAPMAssetQuery(assetId);

  if (error) {
    return <AssetInfoError error={error} />;
  }

  if (isLoading || isLoadingAsset) {
    return (
      <S.LoaderWrapper data-test-id="asset-info-loading">
        <IconWrapper size={64}>
          <LoaderIcon className="cogs-icon--rotating" />
        </IconWrapper>
      </S.LoaderWrapper>
    );
  }

  if (!asset) {
    return (
      <S.EmptyState>
        <EmptyStateSearchSad />
        <Heading level={5}>
          {t(
            'THREE_D_ASSET_INFO_ERROR_NO_ASSET_FOUND',
            'Could not find associated asset'
          )}
        </Heading>
        <Body>
          {t(
            'THREE_D_ASSET_INFO_ERROR_SELECT_ANOTHER_ASSET',
            'Select another asset in the 3D model'
          )}
        </Body>
      </S.EmptyState>
    );
  }

  return (
    <S.AssetExplorationWrapper direction="column">
      <ComponentScrollableContent>
        <Asset
          onClose={onClose}
          hideThreeDCard
          assetExternalId={asset.externalId!}
        />
      </ComponentScrollableContent>
    </S.AssetExplorationWrapper>
  );
};
