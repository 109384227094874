import { Avatar, Tooltip } from '@cognite/cogs.js-v10';
import type { UserProfileItem } from '@cognite/sdk';
import RobotAvatarImg from '@infield/assets/robot-avatar.png';
import { useTranslation } from 'react-i18next';

import { LOCIZE_NAMESPACES } from '../i18n';

import { getUserName, isServicePrincipal } from './utils/user-profile-utils';

interface UserTableCellProps {
  user?: UserProfileItem;
}

export const UserTableCell: React.FC<UserTableCellProps> = ({
  user,
}: UserTableCellProps) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.user);

  const servicePrincipalName = t(
    'CHECKLIST_TABLE_CREATED_BY_AUTOMATIC',
    'Auto-generated'
  );

  const unknownName = t('CHECKLIST_TABLE_CREATED_BY_UNKNOWN', 'Unknown');

  const isUserServicePrincipal = isServicePrincipal(user);
  const userName = getUserName(user);

  return (
    <Tooltip
      content={
        isUserServicePrincipal ? servicePrincipalName : userName || unknownName
      }
      placement="top"
      data-testid="checklist-item-avatar-tooltip"
    >
      <Avatar
        tooltip={false}
        size="small"
        text={isUserServicePrincipal ? undefined : userName}
        data-testid="checklist-item-avatar"
        image={isUserServicePrincipal ? RobotAvatarImg : undefined}
        style={{ pointerEvents: 'initial' }}
      />
    </Tooltip>
  );
};
