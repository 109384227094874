import type { DateRangeType } from '@cognite/apm-observation';
import dayjs from 'dayjs';

export const isDateRangeFilterForScheduledChecklistsValid = (
  dateRange: DateRangeType
) => {
  const [startDate, endDate] = dateRange;

  if (!startDate || !endDate) return false;

  const today = dayjs().endOf('day');
  const maxDateRange = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

  const isStartDateValid = startDate.isBefore(today) || startDate.isSame(today);
  const isDateRangeValid = endDate.diff(startDate) <= maxDateRange;

  return isStartDateValid && isDateRangeValid;
};

// If the end date is in the future, update it to today
export const getDateRangeFilterWithoutFutureEndDate = (
  dateRange: DateRangeType
): DateRangeType => {
  const [startDate, endDate] = dateRange;
  if (!startDate || !endDate) return [null, null];

  const today = dayjs().endOf('day');

  if (endDate.isAfter(today)) {
    return [startDate, dayjs(today)];
  }

  return dateRange;
};

export const isChecklistSchedulerFeatureEnabled = (
  isFlagEnabled: boolean,
  isChecklistAdmin: boolean | undefined
): boolean => {
  return (isFlagEnabled && isChecklistAdmin) ?? false;
};
