import { useUserProfilesByIdsQuery } from '@infield/features/user/hooks/use-query/use-user-profiles-by-ids-query';
import { createContext } from 'react';

import { useChecklistsAggregate } from './use-query/use-checklists-aggregate';

/**
 * The context can be used to inject any kind of
 * dependency, but mainly hooks and components/containers.
 *
 * The main purpose is to facilitate testing
 * and storybooking without having to make complex mocks
 * - and to keep the components as simple as possible.
 */

export interface UseChecklistsCreatedByFilterOptionsContextInterface {
  useChecklistsAggregate: typeof useChecklistsAggregate;
  useUserProfilesByIdsQuery: typeof useUserProfilesByIdsQuery;
}

let context: React.Context<UseChecklistsCreatedByFilterOptionsContextInterface>;

export const getChecklistsCreatedByFilterOptionsContext = () => {
  if (!context) {
    context = createContext({
      useChecklistsAggregate,
      useUserProfilesByIdsQuery,
    });
  }

  return context;
};
