import { AuthClient } from '@cognite/auth-client';
import { CogIdPClient } from '@cognite/cogidp-sdk';

import {
  CogniteIdPResponse,
  CogIdpProject,
  ListCogIdpProjectsResponse,
  PublicOrgResponse,
} from '../types';

import {
  getApp,
  getBaseUrl,
  getRequiredOrganization,
  isPreviewDeployment,
  setRedirectCookieForPreviewDeployment,
} from './loginInfo';

export const cogIdpAuthority = 'https://auth.cognite.com';
export const cogIdpInternalId = 'ff16d970-0491-415a-ab4b-3ba9eb65ac4a';

export const cogIdpAsResponse = (
  projects: CogIdpProject[] = []
): CogniteIdPResponse => ({
  authority: cogIdpAuthority,
  internalId: cogIdpInternalId,
  type: 'COGNITE_IDP',
  clusters: [
    ...new Set(projects.map((project) => removeProtocol(project.apiUrl))),
  ],
  projects: projects,
  appConfiguration: {
    clientId: getClientId(),
  },
});

const removeProtocol = (url: string) => new URL(url).host;

const getRedirectUri = (): string => {
  if (isPreviewDeployment) {
    return 'https://oauth.preview.cogniteapp.com/signin/callback';
  }
  return `${getBaseUrl()}/signin/callback`;
};

export const getCogniteIdPUserManager = (params: {
  authority: string;
  client_id: string;
}) => {
  return new AuthClient({ clientId: params.client_id });
};

export const getCogIdpQueryKey = (
  cluster: string,
  type: 'token' | 'projects'
) => ['cognite_idp', type, cluster];

export const getCogniteIdPToken = async (
  userManager: AuthClient
): Promise<string> => {
  const accessToken = await userManager.getAccessToken();
  if (!accessToken) {
    throw new Error('No access token');
  }
  return accessToken;
};

export type CogIdPState = {
  pathname: string;
  search: string;
};

export const cogniteIdPSignInRedirect = async (
  userManager: AuthClient,
  organization: string
) => {
  if (isPreviewDeployment) {
    // We need to tell the Preview Server where to redirect to
    // when we use the preview server as the redirect URI for OAuth.
    // Normally, we store the org in a cookie, and redirect the user
    // to the org subdomain. But when using Preview Server, that isn't sufficient as
    // we also need to tell which app&version to redirect to.
    const redirectTo = new URL(window.location.href);
    redirectTo.pathname = `/`;
    setRedirectCookieForPreviewDeployment(redirectTo.href);
  }
  const state = generateCogIdPState();
  await userManager.signinRedirect({
    redirectUri: getRedirectUri(),
    state,
    organization,
  });
};

function filterQueryParams(
  search: string,
  predicate: (key: string, value: string) => boolean
) {
  const searchParams = new URLSearchParams(search);
  const filteredParams = Array.from(searchParams.entries()).filter(
    ([key, value]) => predicate(key, value)
  );
  return new URLSearchParams(filteredParams).toString();
}

// exporting just for testing.
export const generateCogIdPState = (): CogIdPState => {
  const { pathname, search } = window.location;

  // We want to filter out the code, state and scope query params from the URL.
  // If we keep those fields in the state, they would override
  // the new values we get after the OAuth flow.
  // We only care about the path '/' as that's where
  // those query params should only be present.
  if (pathname === '/') {
    const paramsToRemove = ['code', 'state', 'scope'];
    const filteredQuery = filterQueryParams(
      search,
      (key) => !paramsToRemove.includes(key)
    );
    return { pathname, search: `?${filteredQuery.toString()}` };
  }

  return { pathname, search };
};

export const getPublicOrg = async (
  options: { timeout?: number } = {}
): Promise<PublicOrgResponse | undefined> => {
  const organization = getRequiredOrganization();

  const controller = new AbortController();
  if (options.timeout) {
    setTimeout(() => controller.abort(), options.timeout);
  }

  const response = await fetch(
    `${cogIdpAuthority}/api/v0/orgs/${organization}/public`,
    {
      signal: controller.signal,
    }
  );
  if (!response.ok) {
    return undefined;
  }
  return await response.json();
};

export const getProjectsForCogIdpOrg = async (
  token?: string
): Promise<ListCogIdpProjectsResponse | undefined> => {
  if (!token) {
    return undefined;
  }
  const organization = getRequiredOrganization();

  const response = await fetch(
    `${cogIdpAuthority}/api/v0/orgs/${organization}/projects`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    return undefined;
  }
  return await response.json();
};

export const getClientId = () => {
  const app = getApp();
  if (app === 'fusion') {
    return '012c261c-0c5c-4ff6-9af1-20b9dfd81352';
  } else if (app === 'fusion-dev') {
    return 'e28859b5-1db0-44df-bbf3-d0a06758eecc';
  } else {
    throw new Error(`Unknown app: ${app}`);
  }
};

export async function handleSigninCallbackForCogIdP() {
  const clientId = getClientId();
  const state = new URLSearchParams(window.location.search).get('state');
  if (!state) {
    throw new Error('Missing OAuth state');
  }
  const { orgId } = await CogIdPClient.getSigninFromState(clientId, state);
  const redirectTo = new URL(window.location.href);
  redirectTo.pathname = '/';
  redirectTo.hostname = `${orgId}.${redirectTo.hostname}`;
  window.location.href = redirectTo.href;
}
