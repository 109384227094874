import { ContainerType } from '../containers/ContainerType';
import {
  DataGridColumn,
  DataGridContainerProps,
} from '../containers/DataGridContainer';
import { UnifiedViewer } from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';

import Tool from './Tool';
import { DataGridToolConfig, ToolType } from './types';

const DEFAULT_GRID_SIZE = 4;
const DEFAULT_WIDTH = 425;
const DEFAULT_HEIGHT = 280;
const DEFAULT_CELL_WIDTH = 100;
const DEFAULT_CELL_HEIGHT = 70;

export default class DataGridTool extends Tool {
  public readonly type = ToolType.DATA_GRID;
  public override readonly cursor = 'crosshair';
  private config: DataGridToolConfig;

  public constructor({
    unifiedViewer,
    config,
  }: {
    unifiedViewer: UnifiedViewer;
    config: DataGridToolConfig;
  }) {
    super(unifiedViewer);
    this.config = config;
    this.setConfig(config);
  }

  public setConfig = (config: DataGridToolConfig): void => {
    this.config = config;
  };

  private getDataGridContainer = (): DataGridContainerProps => {
    const translatedMousePosition =
      this.unifiedViewer.stage.getRelativePointerPosition();

    const columns = [...Array(this.config.gridSize ?? DEFAULT_GRID_SIZE)].map(
      (_, i): DataGridColumn => ({
        key: crypto.randomUUID(),
        title: `Column ${i}`,
        width: this.config.cellWidth ?? DEFAULT_CELL_WIDTH,
      })
    );
    const rows = columns.map(() => ({
      id: crypto.randomUUID(),
      height: this.config.cellHeight ?? DEFAULT_CELL_HEIGHT,
      cells: Object.fromEntries(columns.map(({ key }) => [key, { value: '' }])),
    }));

    const width = this.config.containerWidth ?? DEFAULT_WIDTH;
    const height = this.config.containerHeight ?? DEFAULT_HEIGHT;
    return {
      id: crypto.randomUUID(),
      type: ContainerType.DATA_GRID,
      shouldAutoSize: true,
      label: this.config.label,
      x: translatedMousePosition.x - width / 2,
      y: translatedMousePosition.y - height / 2,
      width,
      height,
      rows,
      columns,
      metadata: this.config.metadata,
    };
  };

  public override onMouseDown = (): void => {
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      annotations: [],
      containers: [this.getDataGridContainer()],
    });
  };

  public override onDestroy(): void {}
}
