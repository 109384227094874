import type {
  MeasurementType,
  Operation,
  TemplateItem,
  TemplateStatus,
} from '@cognite/apm-client';
import { makeToast } from '@cognite/cogs-lab';
import {
  AddLargeIcon,
  CalendarAddIcon,
  CheckmarkFilledIcon,
  ClearAllIcon,
  DeleteIcon,
  DuplicateIcon,
  EmptyState,
  ListsIllustration,
  Skeleton,
  WiFiIllustration,
} from '@cognite/cogs.js-v10';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import type { DropResult, ResponderProvided } from '@hello-pangea/dnd';
import { useIsTemplateAdminQuery } from '@infield/features/app-config';
import { Asset } from '@infield/features/asset/asset-exploration';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { useMeasurementDelete } from '@infield/features/measurements';
import { TaskForm } from '@infield/features/task';
import type { TemplateItemAndGroupList } from '@infield/features/template';
import {
  checkedTasksAtom,
  isNewTemplateAtom,
  selectedTaskAtom,
  selectedTemplateAtom,
  showAssetDetailsAtom,
  useTemplateDuplicate,
  useTemplateItemsDuplicate,
  useTemplateItemsReorderUpsert,
  useTemplateItemsUpdate,
  useTemplateQuery,
} from '@infield/features/template';
import { DeleteModal } from '@infield/features/template/template-detail/delete-modal';
import { TemplateMenu } from '@infield/features/template/template-menu';
import {
  getGroupLabel,
  getTaskGroupLabel,
  removeGroupLabel,
} from '@infield/features/template/utils';
import { ActionMenu, ComponentScrollableContent } from '@infield/features/ui';
import { SplitViewWrapper } from '@infield/features/ui/split-view-wrapper/split-view-wrapper';
import { EmptyStateWrapper } from '@infield/features/ui/table-empty-state/elements';
import { useNetworkStatusContext } from '@infield/providers/network-status-provider';
import { Allotment, LayoutPriority } from 'allotment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FC } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';

import { TEMPLATE_TASK_LIMIT } from '../constants';
import { TasksGroup } from '../tasks-group';
import {
  flattenTemplateItemsAndGroups,
  getTemplateItemsAndGroupsList,
} from '../utils';
import { orderTemplateItems } from '../utils/orderTemplateItems';
import { reorderItems } from '../utils/reorderItems';
import { sortTemplateItems } from '../utils/sortTemplateItems';

import * as S from './elements';
import { GroupModal } from './group-modal';
import { IntervalModal } from './interval-modal';
import { TaskListItem } from './task-list-item';

export type Group = { groupName: string; tasks: Operation[] };

type Props = {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
};

export const TemplateDetail: FC<Props> = ({ isCollapsed, setIsCollapsed }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.template);
  const { isOnline } = useNetworkStatusContext();
  const isNewTemplate = useRecoilValue(isNewTemplateAtom);
  const [selectedTemplate, setSelectedTemplate] =
    useRecoilState(selectedTemplateAtom);
  const [selectedTask, setSelectedTask] = useRecoilState(selectedTaskAtom);
  const [checkedTasks, setCheckedTasks] = useRecoilState(checkedTasksAtom);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showIntervalsModal, setShowIntervalsModal] = useState(false);

  const { data: isTemplateAdmin } = useIsTemplateAdminQuery();

  const [recentlyUngroupedTasks, setRecentlyUngroupedTasks] = useState<
    string[]
  >([]);

  const [renameGroup, setRenameGroup] = useState('');

  const [showAssetDetails, setShowAssetDetails] =
    useRecoilState(showAssetDetailsAtom);

  const { data: { templateItems } = {}, isInitialLoading } = useTemplateQuery(
    selectedTemplate?.externalId
  );

  const tasks = useMemo(() => templateItems || [], [templateItems]);
  // So the drag and drop updates immediately, we store a local version of the tasks to update immediately.
  const [localTasks, setLocalTasks] = useState<TemplateItem[]>([]);

  const intervals = tasks.flatMap((task) => task.schedules || []);

  const isNewTask = !tasks.find(
    (task) => task.externalId === selectedTask?.externalId
  );

  const { mutateAsync: reorderTemplateItems } = useTemplateItemsReorderUpsert();
  const { mutateAsync: duplicateTemplateItems } = useTemplateItemsDuplicate();
  const { mutateAsync: duplicateTemplate } = useTemplateDuplicate();
  const { mutateAsync: updateTemplateItems } = useTemplateItemsUpdate();

  const { mutateAsync: deleteMeasurement } = useMeasurementDelete();

  const isTemplateEditable =
    !selectedTemplate?.isArchived &&
    Boolean(isTemplateAdmin) &&
    selectedTemplate?.status === 'Draft';
  const isTaskLimitReached = tasks.length >= TEMPLATE_TASK_LIMIT;

  const isDuplicateTasksDisabled =
    checkedTasks.length + tasks.length > TEMPLATE_TASK_LIMIT;

  // cleanup on changing template
  useEffect(() => {
    setCheckedTasks([]);
    setRecentlyUngroupedTasks([]);
  }, [selectedTemplate?.externalId, setCheckedTasks]);

  useEffect(() => {
    // Always keep local tasks in sync with any task changes.
    setLocalTasks(tasks);
  }, [tasks]);

  // We set task and open task form by default for empty non deleted templates
  useEffect(() => {
    if (
      !isInitialLoading &&
      !selectedTask &&
      selectedTemplate &&
      !selectedTemplate.isArchived &&
      tasks.length === 0
    ) {
      setSelectedTask({
        externalId: uuid(),
        order: tasks.length,
      });
    }

    if (selectedTask) {
      const updatedTaskData = tasks.find(
        (task) => task.externalId === selectedTask.externalId
      );
      if (updatedTaskData) {
        setSelectedTask(updatedTaskData);
      }
    }
  }, [
    isInitialLoading,
    selectedTask,
    selectedTemplate,
    setSelectedTask,
    tasks,
  ]);

  const tasksAndGroupsList = useMemo(() => {
    return getTemplateItemsAndGroupsList(localTasks);
  }, [localTasks]);

  const handleAddTask = async () => {
    if (selectedTask) {
      await deleteMeasurement({
        taskExternalId: selectedTask.externalId,
        removeMeasurementsWithoutTimeseries: true,
      });
    }

    setSelectedTask({
      externalId: uuid(),
      order: tasks.length,
    });

    scrollToLastChild();
  };

  const onDragEndResponder = async (
    result: DropResult,
    _: ResponderProvided
  ) => {
    if (!result.destination) {
      return;
    }

    // Ultimately this function should handle following cases
    // as for now we are not supporting drag & drop from nonGroup->Group and group->nonGroup
    // case1 group->sameGroup
    // case2 group->otherGroup
    // case3 group->nonGroup
    // case4 nonGroup->group
    // case5 nonGroup->nonGroup(task or group)

    const sourceDropId = result.source.droppableId;
    const destinationDropId = result.destination.droppableId;
    const GROUP_PREFIX = '$infield-droppable-group-';

    let reorderedTasks: TemplateItem[];
    // this case handles
    // case1 group->sameGroup
    // case2 group->otherGroup
    if (sourceDropId.startsWith(GROUP_PREFIX)) {
      const sourceGroupName = sourceDropId.split(GROUP_PREFIX)[1];
      const destinationGroupName = destinationDropId.split(GROUP_PREFIX)[1];

      const sourceGroupIndex = tasksAndGroupsList.findIndex(
        (item) => 'groupName' in item && item.groupName === sourceGroupName
      );

      const destinationGroupIndex = tasksAndGroupsList.findIndex(
        (item) => 'groupName' in item && item.groupName === destinationGroupName
      );

      const itemsBeforeSourceGroup = flattenTemplateItemsAndGroups(
        tasksAndGroupsList.slice(0, sourceGroupIndex)
      ).length;

      const itemsBeforeDestinationGroup = flattenTemplateItemsAndGroups(
        tasksAndGroupsList.slice(0, destinationGroupIndex)
      ).length;

      const destinationIndex =
        result.destination.index +
        itemsBeforeDestinationGroup +
        (destinationGroupIndex > sourceGroupIndex ? -1 : 0);

      const sourceIndex = result.source.index + itemsBeforeSourceGroup;

      const { externalId } =
        tasks[result.source.index + itemsBeforeSourceGroup];

      reorderedTasks = reorderItems(tasks, sourceIndex, destinationIndex).map(
        (elem) => {
          if (elem.externalId === externalId) {
            return {
              ...elem,
              labels: [
                ...removeGroupLabel(elem.labels),
                getGroupLabel(destinationGroupName),
              ],
            };
          }

          return elem;
        }
      );
    } else {
      // this handles following cases
      // case5 nonGroup->nonGroup(task or group)
      reorderedTasks = reorderItems(
        tasksAndGroupsList,
        result.source.index,
        result.destination.index
      );
    }

    setLocalTasks(reorderedTasks);
    await reorderTemplateItems(
      {
        templateItems: reorderedTasks.map(({ externalId, order, labels }) => ({
          externalId,
          order,
          labels,
        })),
      },
      {
        onError: () => {
          makeToast({
            body: t(
              'TEMPLATE_TASK_LIST_UPSERT_REORDER_OPERATION_ERROR',
              'Failed to reorder operations'
            ),
            type: 'danger',
          });
        },
      }
    );
  };

  const getHasMeasurement = useCallback(
    (task: TemplateItem, measurementType: MeasurementType) =>
      task.measurements
        ?.map((measurement) => measurement.type)
        .includes(measurementType),
    []
  );

  const getHasCondition = useCallback((task: TemplateItem) => {
    const conditionsList = task.conditionalActions?.flatMap(
      (conditionalAction) => conditionalAction.conditions || []
    );
    return Boolean(conditionsList?.length && conditionsList?.length > 0);
  }, []);

  const handleReorderTemplateItems = (templateItems: TemplateItem[]) => {
    const orderedTemplateItems = orderTemplateItems(templateItems);

    reorderTemplateItems(
      {
        templateItems: orderedTemplateItems,
      },
      {
        onError: () => {
          makeToast({
            type: 'danger',
            body: t(
              'TEMPLATE_TASK_LIST_UPSERT_REORDER_OPERATION_ERROR',
              'Failed to reorder operations'
            ),
          });
        },
      }
    );
  };

  const handleCreateGroup = (groupName: string) => {
    // we first filter out existing group tasks so that they are ordered first within the group
    const existingGroupTasks = tasks.filter(
      (task) =>
        getTaskGroupLabel(task, groupName) &&
        !checkedTasks.includes(task.externalId)
    );

    const newGroupTasks = tasks.filter((task) =>
      checkedTasks.includes(task.externalId)
    );

    const groupedTasks = existingGroupTasks
      .concat(newGroupTasks)
      .map((task) => ({
        ...task,
        labels: [...removeGroupLabel(task.labels), getGroupLabel(groupName)],
      }));

    const otherTasks = tasks.filter(
      (task) =>
        !(
          checkedTasks.includes(task.externalId) ||
          getTaskGroupLabel(task, groupName)
        )
    );

    const allTasks = [...otherTasks, ...groupedTasks];

    handleReorderTemplateItems(allTasks);
    scrollToLastChild();
    setCheckedTasks([]);
    setShowGroupModal(false);
    setShowConfirmationModal(false);
  };

  const handleRenameGroup = (groupName: string) => {
    if (!renameGroup) return;

    const groupTasks = tasks.filter((task) =>
      getTaskGroupLabel(task, renameGroup)
    );

    const groupTasksWithUpdatedName: TemplateItem[] = groupTasks.map(
      (task) => ({
        externalId: task.externalId,
        labels: [...removeGroupLabel(task.labels), getGroupLabel(groupName)],
      })
    );

    updateTemplateItems({ templateItemsToUpsert: groupTasksWithUpdatedName });
    scrollToLastChild();
    setCheckedTasks([]);
    setShowGroupModal(false);
    setShowConfirmationModal(false);
    setRenameGroup('');
  };

  const handleUnGroupTasks = useCallback(
    (groupName: string) => {
      const tasksToUnGroup = tasks
        .filter((task) => getTaskGroupLabel(task, groupName))
        .map((task) => ({
          ...task,
          labels: [...removeGroupLabel(task.labels)],
        }));

      const otherTasks = tasks.filter(
        (task) =>
          !(
            checkedTasks.includes(task.externalId) ||
            getTaskGroupLabel(task, groupName)
          )
      );
      const allTasks = [...otherTasks, ...tasksToUnGroup];

      handleReorderTemplateItems(allTasks);
      scrollToLastChild();
      setRecentlyUngroupedTasks(tasksToUnGroup.map((task) => task.externalId));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedTasks, t, tasks, reorderTemplateItems]
  );

  const handleSelectTask = useCallback(
    async (task: TemplateItem) => {
      if (selectedTask) {
        await deleteMeasurement({
          taskExternalId: selectedTask.externalId,
          removeMeasurementsWithoutTimeseries: true,
        });
      }
      setSelectedTask(task);
    },
    [deleteMeasurement, selectedTask, setSelectedTask]
  );

  const handleCheckTask = useCallback(
    async (task: TemplateItem) => {
      if (checkedTasks.includes(task.externalId)) {
        setCheckedTasks((prevTask) => [
          ...prevTask.filter((externalId) => externalId !== task.externalId),
        ]);
      } else {
        setCheckedTasks((prevTask) => [...prevTask, task.externalId]);
      }
    },
    [checkedTasks, setCheckedTasks]
  );

  const handleDuplicateTasks = useCallback(async () => {
    const tasksToDuplicate = tasks.filter((task) =>
      checkedTasks.includes(task.externalId)
    );

    const { duplicatedTemplateItems } = await duplicateTemplateItems({
      templateItems: tasks,
      itemsToDuplicate: tasksToDuplicate,
      selectedTemplateId: selectedTemplate?.externalId,
    });

    const allTasks = [...tasks, ...duplicatedTemplateItems];

    const sortedTasks = sortTemplateItems(allTasks);

    handleReorderTemplateItems(sortedTasks);
    scrollToLastChild();
    setCheckedTasks([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkedTasks,
    duplicateTemplateItems,
    intervals,
    selectedTemplate?.externalId,
    setCheckedTasks,
    tasks,
  ]);

  const handleDuplicateTemplate = useCallback(async () => {
    const { duplicatedTemplate } = await duplicateTemplate({
      template: selectedTemplate,
      templateItems: tasks,
    });

    setSelectedTask(undefined);
    setCheckedTasks([]);
    setSelectedTemplate(duplicatedTemplate);
  }, [
    duplicateTemplate,
    selectedTemplate,
    tasks,
    setSelectedTask,
    setCheckedTasks,
    setSelectedTemplate,
  ]);

  const listRef = useRef<HTMLOListElement>(null);

  const scrollToLastChild = () => {
    listRef.current?.lastElementChild?.scrollIntoView();
  };

  const getTaskJSX = useCallback(
    (task: TemplateItem, index: number) => (
      <TaskListItem
        key={task.externalId}
        externalId={task.externalId}
        index={index}
        assetExternalId={task.asset?.externalId ?? undefined}
        assetLabel={task.asset?.title ?? undefined}
        title={task.title}
        isEditable={isTemplateEditable}
        isChecked={checkedTasks.includes(task.externalId)}
        onClick={() => handleSelectTask(task)}
        onClickCheck={() => handleCheckTask(task)}
        isSelected={selectedTask?.externalId === task.externalId}
        hasNumericalMeasurement={getHasMeasurement(task, 'numerical')}
        hasLabelMeasurement={getHasMeasurement(task, 'label')}
        hasIntervals={task.schedules && task.schedules.length > 0}
        hasMessage={getHasMeasurement(task, 'message')}
        hasCondition={getHasCondition(task)}
        fadeColor={recentlyUngroupedTasks.includes(task.externalId)}
      />
    ),
    [
      checkedTasks,
      getHasMeasurement,
      getHasCondition,
      handleCheckTask,
      handleSelectTask,
      isTemplateEditable,
      recentlyUngroupedTasks,
      selectedTask?.externalId,
    ]
  );

  const taskListJSX = useCallback(
    (tasks: TemplateItem[]) => tasks.map(getTaskJSX),
    [getTaskJSX]
  );

  const tasksAndGroupsJSX = useMemo(() => {
    return tasksAndGroupsList.map((listItem, index) => {
      if ('groupName' in listItem && listItem.groupName) {
        const { groupName } = listItem;
        return (
          <TasksGroup
            key={groupName}
            isEditable={isTemplateEditable}
            index={index}
            groupName={groupName}
            groupTaskList={taskListJSX(listItem.tasks)}
            onClickUnGroup={() => handleUnGroupTasks(groupName)}
            onClickRename={() => setRenameGroup(groupName)}
          />
        );
      }

      return getTaskJSX(listItem as TemplateItem, index);
    });
  }, [
    tasksAndGroupsList,
    getTaskJSX,
    isTemplateEditable,
    taskListJSX,
    handleUnGroupTasks,
  ]);

  if (!selectedTemplate) {
    if (!isOnline) {
      return (
        <EmptyStateWrapper>
          <EmptyState
            illustration={<WiFiIllustration />}
            title={t(
              'TEMPLATES_DESKTOP_PAGE_NO_NETWORK_CONNECTION_TITLE',
              "You're offline"
            )}
            description={t(
              'TEMPLATES_DESKTOP_PAGE_NO_NETWORK_CONNECTION_BODY',
              'Connect to your network to view template details.'
            )}
          />
        </EmptyStateWrapper>
      );
    }
    return (
      <EmptyStateWrapper>
        <EmptyState
          illustration={<ListsIllustration />}
          title={t(
            'TEMPLATES_PAGE_TASK_LIST_SELECT_A_TEMPLATE_TITLE',
            'Select a template'
          )}
          description={t(
            'TEMPLATES_PAGE_TASK_LIST_SELECT_A_TEMPLATE_BODY',
            'To view template details, select a template from the sidebar.'
          )}
        />
      </EmptyStateWrapper>
    );
  }

  return (
    <S.TemplateContainer>
      {(showGroupModal || renameGroup) && (
        <GroupModal
          tasks={tasks}
          groupName={renameGroup}
          onSubmit={renameGroup ? handleRenameGroup : handleCreateGroup}
          showGroupModal={showGroupModal || Boolean(renameGroup)}
          onCancel={() => {
            setShowGroupModal(false);
            setRenameGroup('');
          }}
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          tasks={tasks.filter((task) => checkedTasks.includes(task.externalId))}
          closeModal={() => setShowDeleteModal(false)}
          onSubmit={() => {
            setShowDeleteModal(false);
            setCheckedTasks([]);
          }}
        />
      )}
      {showIntervalsModal && (
        <IntervalModal
          tasks={tasks.filter((task) => checkedTasks.includes(task.externalId))}
          closeModal={() => setShowIntervalsModal(false)}
          onSubmit={() => {
            setShowIntervalsModal(false);
            setCheckedTasks([]);
          }}
        />
      )}
      <S.OverflowContainer>
        <TemplateMenu
          externalId={selectedTemplate?.externalId}
          name={selectedTemplate?.title}
          currentStatusValue={selectedTemplate?.status as TemplateStatus}
          isTaskListEmpty={tasks.length === 0}
          onAddTask={handleAddTask}
          isEditable={isTemplateEditable}
          isTaskLimitReached={isTaskLimitReached}
          handleDuplicateTemplate={handleDuplicateTemplate}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
        {!isNewTemplate && isInitialLoading && tasks.length === 0 ? (
          <Skeleton.List lines={4} borders />
        ) : (
          <S.TaskWrapper direction="row">
            <SplitViewWrapper>
              <Allotment.Pane priority={LayoutPriority.High} minSize={500}>
                <S.TaskListContainer data-testid="template-task-list">
                  {tasks.length === 0 ? (
                    <EmptyState
                      illustration={<ListsIllustration />}
                      title={t(
                        'TEMPLATES_PAGE_TASK_LIST_EMPTY_TITLE',
                        'This template is empty'
                      )}
                      description={
                        selectedTemplate.isArchived
                          ? ''
                          : t(
                              'TEMPLATES_PAGE_TASK_LIST_EMPTY_BODY',
                              'Add new tasks to build this template.'
                            )
                      }
                    />
                  ) : (
                    <DragDropContext onDragEnd={onDragEndResponder}>
                      <Droppable
                        droppableId="template-list-ungrouped"
                        type="ungrouped"
                      >
                        {(provided, snapshot) => (
                          <ComponentScrollableContent>
                            <S.TaskList
                              $isDraggedOver={snapshot.isDraggingOver}
                              $hasExtraMargin={checkedTasks.length > 0}
                              ref={(el) => {
                                provided.innerRef(el);
                                (listRef as any).current = el;
                              }}
                              {...provided.droppableProps}
                            >
                              {tasksAndGroupsJSX}
                              {provided.placeholder}
                            </S.TaskList>
                          </ComponentScrollableContent>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}

                  {checkedTasks.length > 0 && (
                    <ActionMenu
                      visible
                      subTitle={t('TEMPLATE_ACTION_MENU_TITLE', {
                        defaultValue: '{{taskLength}} tasks',
                        taskLength: checkedTasks.length,
                        count: checkedTasks.length,
                      })}
                      actions={[
                        {
                          key: 'Select all',
                          dataTestId: 'template-action-menu-select-all',
                          name:
                            checkedTasks.length === tasks.length
                              ? t(
                                  'TEMPLATE_ACTION_MENU_DESELECT_ALL',
                                  'Deselect all'
                                )
                              : t(
                                  'TEMPLATE_ACTION_MENU_SELECT_ALL',
                                  'Select all'
                                ),
                          icon:
                            checkedTasks.length === tasks.length ? (
                              <ClearAllIcon />
                            ) : (
                              <CheckmarkFilledIcon />
                            ),
                        },
                        {
                          key: 'Create Group',
                          dataTestId: 'template-action-menu-create-group',
                          name: t(
                            'TEMPLATE_ACTION_MENU_CREATE_GROUP',
                            'Create group'
                          ),
                          icon: <AddLargeIcon />,
                        },
                        {
                          key: 'Schedule',
                          dataTestId: 'template-action-menu-add-schedule',
                          name: t(
                            'TEMPLATE_ACTION_MENU_ADD_INTERVALS',
                            'Schedule'
                          ),
                          icon: <CalendarAddIcon />,
                        },
                        {
                          key: 'Duplicate',
                          dataTestId: 'template-action-menu-duplicate',
                          name: t(
                            'TEMPLATE_ACTION_MENU_DUPLICATE',
                            'Duplicate'
                          ),
                          icon: <DuplicateIcon />,
                          disabled: isDuplicateTasksDisabled,
                          tooltip: isDuplicateTasksDisabled
                            ? t(
                                'TEMPLATE_ACTION_MENU_DUPLICATE_TASKS_BUTTON_DISABLED_TOOLTIP',
                                'A template can have a maximum of {{taskLimit}} tasks.',
                                {
                                  taskLimit: TEMPLATE_TASK_LIMIT,
                                }
                              )
                            : undefined,
                        },
                        {
                          key: 'Delete',
                          dataTestId: 'template-action-menu-delete',
                          name: t(
                            'TEMPLATE_ACTION_MENU_DELETE_SELECTED',
                            'Delete {{count}} task',
                            { count: checkedTasks.length }
                          ),
                          icon: <DeleteIcon />,
                        },
                      ]}
                      onActionClick={(key) => {
                        switch (key) {
                          case 'Create Group':
                            setShowGroupModal(true);
                            break;
                          case 'Schedule':
                            setShowIntervalsModal(true);
                            break;
                          case 'Duplicate':
                            handleDuplicateTasks();
                            break;
                          case 'Select all':
                            setCheckedTasks(
                              checkedTasks.length === tasks.length
                                ? []
                                : tasks.map((task) => task.externalId)
                            );
                            break;
                          case 'Delete':
                            setShowDeleteModal(true);
                            break;
                        }
                      }}
                      onClose={() => setCheckedTasks([])}
                    />
                  )}
                </S.TaskListContainer>
              </Allotment.Pane>
              <Allotment.Pane
                visible={Boolean(selectedTask)}
                preferredSize={412}
                maxSize={912}
                minSize={380}
              >
                {selectedTask && (
                  <TaskForm
                    task={selectedTask}
                    isEditable={isTemplateEditable}
                    isNewtask={isNewTask}
                  />
                )}
              </Allotment.Pane>
              <Allotment.Pane
                visible={
                  showAssetDetails && Boolean(selectedTask?.asset?.externalId)
                }
                minSize={400}
              >
                {selectedTask?.asset?.externalId && (
                  <S.AssetExplorationWrapper direction="column">
                    <Asset
                      onClose={() => setShowAssetDetails(false)}
                      assetExternalId={selectedTask.asset.externalId}
                    />
                  </S.AssetExplorationWrapper>
                )}
              </Allotment.Pane>
            </SplitViewWrapper>
          </S.TaskWrapper>
        )}
      </S.OverflowContainer>
    </S.TemplateContainer>
  );
};
