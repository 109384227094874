import { cloneDeep } from 'lodash';

import type { Filters, Sort } from '@cognite/fdm-client';
import type {
  CDFExternalIdReference,
  FilterDefinition,
  NextCursorV3,
  NodeOrEdge,
  PrimitiveProperty,
  PropertySortV3,
  QueryRequest,
  QueryResponse,
  TextProperty,
  ThroughReference,
  ViewCorePropertyDefinition,
  ViewDefinition,
  ViewDefinitionProperty,
  ViewDirectNodeRelation,
  ViewPropertyDefinition,
  ViewPropertyReference,
  ViewReference,
} from '@cognite/sdk';

import type { DirectRelationship } from './types';
import type { ViewCache } from './view-cache';

export const VIEW_VERSIONS = {
  ACTION: 'v1',
  ANNOTATION: 'v1',
  ASSET: 'v3',
  CHECKLIST_ITEM: 'v4',
  CHECKLIST: 'v4',
  CONDITION: 'v1',
  CONDITIONAL_ACTION: 'v1',
  SCHEDULE: 'v4',
  MEASUREMENT_READING: 'v4',
  OBSERVATION: 'v3',
  TEMPLATE_ITEM: 'v5',
  TEMPLATE: 'v5',
  TIMESERIES: 'v1',
  CAD_MODEL: 'v1',
  COGNITE_3D_OBJECT: 'v1',
  COGNITE_CAD_NODE: 'v1',
  COGNITE_CAD_MODEL: 'v1',
  COGNITE_CAD_REVISION: 'v1',
  COGNITE_3D_MODEL: 'v1',
};

export const getDirectRelationship = (
  item?: { externalId?: string; space?: string } | null
): DirectRelationship | undefined => {
  if (item === null) {
    return null;
  }
  if (item && item.externalId && item.space) {
    return {
      space: item.space,
      externalId: item.externalId,
    };
  }
  if (item && item.externalId && !item.space) {
    throw new Error('ExternalId and space must be defined.');
  }
  return undefined;
};

export const toDMSSort = (
  view: ViewReference,
  sort?: Sort
): PropertySortV3[] => {
  if (!sort) return [];
  return sort.map((s) => ({
    direction: Object.values(s)[0] === 'ASC' ? 'ascending' : 'descending',
    nullsFirst: Object.values(s)[0] !== 'ASC',
    property: [
      view.space,
      `${view.externalId}/${view.version}`,
      Object.keys(s)[0],
    ],
  }));
};

/**
 * Based on the property type within the view definition, return the correct format of the value.
 * e.g. transforms a number into a valid ISO string if property type === timestamp
 * @param viewDefinition
 * @param property
 * @param value
 */
export const parseDMSPropertyValue = (
  viewDefinition: ViewDefinition,
  property: string,
  value?: number | string
) => {
  if (property === 'createdTime') {
    if (value === undefined) {
      return undefined;
    }
    return new Date(value).toISOString();
  }
  const propertyType = (
    viewDefinition.properties[property] as ViewPropertyDefinition
  ).type.type;

  if (
    propertyType === 'timestamp' &&
    typeof value === 'number' &&
    value !== undefined
  ) {
    return new Date(value).toISOString();
  }

  return value;
};

export const toDMSFilter = async (
  filter: Filters | undefined,
  viewDefinition: ViewDefinition,
  viewCache: ViewCache,
  directRelationshipProperty?: string
): Promise<FilterDefinition | undefined> => {
  if (filter === undefined) {
    return undefined;
  }
  const {
    and,
    or,
    not,
    equals,
    in: inRawFilter,
    isNull,
    range,
    containsAny,
    directRelationFilter,
    nested,
    directRelationInstanceSpace,
    prefix,
  } = filter;

  let andFilter: FilterDefinition | undefined;
  if (and && and.length > 0) {
    const andPromises = await Promise.all(
      and.map((f) =>
        toDMSFilter(f, viewDefinition, viewCache, directRelationshipProperty)
      )
    );
    const convertedAnd = andPromises.filter((f) =>
      Boolean(f)
    ) as FilterDefinition[];
    if (convertedAnd.length === 0) {
      andFilter = undefined;
    } else {
      andFilter = {
        and: convertedAnd,
      };
    }
  }

  let orFilter: FilterDefinition | undefined;
  if (or && or.length > 0) {
    const orPromises = await Promise.all(
      or.map((f) =>
        toDMSFilter(f, viewDefinition, viewCache, directRelationshipProperty)
      )
    );

    const convertedOr = orPromises.filter((f) =>
      Boolean(f)
    ) as FilterDefinition[];
    if (convertedOr.length === 0) {
      orFilter = undefined;
    } else {
      orFilter = {
        or: convertedOr,
      };
    }
  }

  let notFilter: FilterDefinition | undefined;
  if (not) {
    const convertedNotFilter = await toDMSFilter(
      not,
      viewDefinition,
      viewCache,
      directRelationshipProperty
    );
    if (convertedNotFilter === undefined) {
      notFilter = undefined;
    } else {
      notFilter = {
        not: convertedNotFilter,
      };
    }
  }

  const isDirectRelationFilter = directRelationshipProperty !== undefined;

  const getPropertyPath = (property: string) => {
    if (
      [
        'space',
        'externalId',
        'createdTime',
        'lastUpdatedTime',
        'deletedTime',
        'node_id',
        'edge_id',
      ].includes(property)
    ) {
      return ['node'];
    }
    return [
      viewDefinition.space,
      `${viewDefinition.externalId}/${viewDefinition.version}`,
    ];
  };

  const directRelationPath = isDirectRelationFilter
    ? [
        ...getPropertyPath(directRelationshipProperty),
        directRelationshipProperty,
      ]
    : [];

  let equalsFilter: FilterDefinition | undefined;
  if (equals) {
    equalsFilter = {
      equals: isDirectRelationFilter
        ? {
            property: directRelationPath,
            value: {
              [equals.property]: equals.eq,
              space: directRelationInstanceSpace,
            },
          }
        : {
            property: [...getPropertyPath(equals.property), equals.property],
            value: equals.eq,
          },
    };
  }

  let prefixFilter: FilterDefinition | undefined;
  if (prefix) {
    prefixFilter = {
      prefix: {
        property: [...getPropertyPath(prefix.property), prefix.property],
        value: prefix.prefix,
      },
    };
  }

  let existsFilter: FilterDefinition | undefined;
  if (isNull) {
    if (isNull.isNull) {
      existsFilter = {
        not: {
          exists: {
            property: isDirectRelationFilter
              ? [directRelationshipProperty, isNull.property]
              : [...getPropertyPath(isNull.property), isNull.property],
          },
        },
      };
    } else {
      existsFilter = {
        exists: {
          property: isDirectRelationFilter
            ? [directRelationshipProperty, isNull.property]
            : [...getPropertyPath(isNull.property), isNull.property],
        },
      };
    }
  }
  let rangeFilter: FilterDefinition | undefined;
  if (range) {
    rangeFilter = {
      range: {
        property: isDirectRelationFilter
          ? [directRelationshipProperty, range.property]
          : [...getPropertyPath(range.property), range.property],
        gt: parseDMSPropertyValue(viewDefinition, range.property, range.gt),
        lt: parseDMSPropertyValue(viewDefinition, range.property, range.lt),
        gte: parseDMSPropertyValue(viewDefinition, range.property, range.gte),
        lte: parseDMSPropertyValue(viewDefinition, range.property, range.lte),
      },
    };
  }

  let inFilter: FilterDefinition | undefined;
  if (inRawFilter) {
    inFilter = {
      in: isDirectRelationFilter
        ? {
            property: directRelationPath,
            values: inRawFilter.in.map((item) => ({
              [inRawFilter.property]: item,
              space: directRelationInstanceSpace,
            })),
          }
        : {
            property: [
              ...getPropertyPath(inRawFilter.property),
              inRawFilter.property,
            ],
            values: inRawFilter.in,
          },
    };
  }

  let containsAnyFilter: FilterDefinition | undefined;
  if (containsAny) {
    containsAnyFilter = {
      containsAny: isDirectRelationFilter
        ? {
            property: directRelationPath,
            values: containsAny.containsAny.map((ca) => ({
              [containsAny.property]: ca,
              space: directRelationInstanceSpace,
            })),
          }
        : {
            property: [
              ...getPropertyPath(containsAny.property),
              containsAny.property,
            ],
            values: containsAny.containsAny,
          },
    };
  }

  let directRelationDmsFilter: FilterDefinition | undefined;
  if (directRelationFilter) {
    if (!directRelationInstanceSpace) {
      throw new Error(
        'Target instanceSpace is required when using directRelationFilter with DMS endpoints'
      );
    }
    const directRelationshipProperties = Object.keys(directRelationFilter);
    const directRelationshipFilterPromises = await Promise.all(
      directRelationshipProperties.map((property) =>
        toDMSFilter(
          { ...directRelationFilter[property], directRelationInstanceSpace },
          viewDefinition,
          viewCache,
          property
        )
      )
    );
    const directRelationshipFilters = directRelationshipFilterPromises.filter(
      (f) => f!
    ) as FilterDefinition[];
    if (directRelationshipFilters.length === 0) {
      directRelationDmsFilter = undefined;
    }
    if (directRelationshipFilters.length === 1) {
      [directRelationDmsFilter] = directRelationshipFilters;
    } else {
      directRelationDmsFilter = {
        and: directRelationshipFilters,
      };
    }
  }

  let nestedFilter: FilterDefinition | undefined;
  if (nested) {
    const { scope, filters: nestedFilterRef } = nested;
    const nestedViewReference = (
      (viewDefinition.properties[scope] as ViewPropertyDefinition)
        .type as ViewDirectNodeRelation
    ).source;
    const nestedViewDefinition = await viewCache.fetchViewDetails(
      nestedViewReference
    );
    if (nestedViewDefinition && filter) {
      const nestedFilterDef = await toDMSFilter(
        nestedFilterRef,
        nestedViewDefinition,
        viewCache,
        directRelationshipProperty
      );
      if (nestedFilterDef) {
        nestedFilter = {
          nested: {
            scope: [...getPropertyPath(''), scope],
            filter: nestedFilterDef,
          },
        };
      }
    }
  }

  const allFilters = [
    andFilter,
    orFilter,
    notFilter,
    equalsFilter,
    inFilter,
    existsFilter,
    rangeFilter,
    containsAnyFilter,
    nestedFilter,
    directRelationDmsFilter,
    prefixFilter,
  ]
    .filter((f) => Boolean(f))
    .map((f) => f!);
  if (allFilters.length === 0) {
    return undefined;
  }
  if (allFilters.length === 1) {
    return allFilters[0];
  }
  return {
    and: allFilters,
  };
};

export const isViewPropertyDefinition = (
  property: ViewDefinitionProperty
): property is ViewPropertyDefinition => {
  return (property as ViewPropertyDefinition).container !== undefined;
};

export const isAndFilter = (
  filter?: FilterDefinition
): filter is {
  and: FilterDefinition[];
} => {
  return (filter as any)?.and !== undefined;
};

export const isList = (property: ViewCorePropertyDefinition): boolean => {
  return Boolean(
    (
      (property as ViewPropertyDefinition)?.type as
        | TextProperty
        | PrimitiveProperty
        | CDFExternalIdReference
    )?.list
  );
};

export const createFilterWithSpaces = (
  filters?: FilterDefinition,
  spaces: string[] = [],
  hasData: ViewReference[] = []
): FilterDefinition => {
  const filterDefinition: FilterDefinition = { and: [] };

  if (spaces.length > 0) {
    filterDefinition.and.push({
      in: {
        property: ['node', 'space'],
        values: spaces,
      },
    });
  }
  if (hasData?.length > 0) {
    filterDefinition.and.push({
      hasData,
    });
  }

  if (filters) {
    filterDefinition.and.push(filters);
  }

  return filterDefinition;
};

export const mapNodeDefinitionToNodeWithView = <T extends Record<string, any>>(
  node: NodeOrEdge,
  viewReference: ViewReference
) => {
  return {
    ...node.properties?.[viewReference.space][
      `${viewReference.externalId}/${viewReference.version}`
    ],
    externalId: node.externalId,
    space: node.space,
    createdTime: node.createdTime,
    lastUpdatedTime: node.lastUpdatedTime,
  } as unknown as T;
};

export const extractRelationCursors = (
  baseCursor: string,
  cursors: Record<string, NextCursorV3>
) => {
  return Object.entries(cursors)
    .filter(([key]) => key !== baseCursor)
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Record<string, NextCursorV3>);
};

export const isFurtherRelationPaginationNecessary = (
  previousQueryRequest: QueryRequest,
  previousQueryResult: QueryResponse,
  baseQueryKey: string
) => {
  return Object.keys(previousQueryResult.nextCursor)
    .filter((key) => key !== baseQueryKey)
    .some((key) => {
      const previousQueryLimit = previousQueryRequest.with[key]?.limit ?? 1000;
      const previousQueryResultCount =
        previousQueryResult.items[key].length ?? 0;
      const previousQueryCursor = previousQueryResult?.nextCursor?.[key];
      return (
        previousQueryCursor &&
        previousQueryResultCount !== 0 &&
        // We can assume in DMS that there are only more results if the count is equal to the limit
        previousQueryResultCount === previousQueryLimit
      );
    });
};

export const mergeInstancesResults = (
  firstInstances: Record<string, NodeOrEdge[]>,
  secondInstances: Record<string, NodeOrEdge[]>
) => {
  const duplicateInstanceLookup: Set<string> = new Set();
  return Object.keys(firstInstances).reduce((acc, queryKey) => {
    const firstInstancesSet = firstInstances[queryKey];
    const secondInstancesSet = secondInstances[queryKey] ?? [];
    acc[queryKey] = [];
    [...firstInstancesSet, ...secondInstancesSet].forEach((instance) => {
      const instanceKey = `${instance.externalId}/${instance.space}`;
      if (duplicateInstanceLookup.has(instanceKey)) {
        return;
      }
      duplicateInstanceLookup.add(instanceKey);
      acc[queryKey].push(instance);
    });
    return acc;
  }, {} as Record<string, NodeOrEdge[]>);
};

export const convertThroughReferenceToViewPropertyRefence = (
  through?: ThroughReference
): ViewPropertyReference | undefined => {
  if (!through) {
    return undefined;
  }

  return {
    identifier: through.identifier,
    view: through.source as ViewReference,
  };
};

export const mapFiltersInwardHelper = (
  filters: Filters,
  space: string
): Filters => {
  const newFilters = cloneDeep(filters);
  Object.keys(filters).forEach((key) => {
    if (key === 'and') {
      newFilters.and = filters.and!.map((f: Filters) =>
        mapFiltersInwardHelper(f, space)
      );
    }
    if (key === 'or') {
      newFilters.or = filters.or!.map((f: Filters) =>
        mapFiltersInwardHelper(f, space)
      );
    }
    if (key === 'not') {
      newFilters.not = mapFiltersInwardHelper(newFilters.not!, space);
    }

    if (key === 'range') {
      if (filters.range?.property === 'startTime') {
        newFilters.range = {
          ...filters.range,
          property: 'scheduledStartTime',
        };
        console.log(
          `Replaced startTime with scheduledStartTime to transition to IDM`
        );
      }

      if (filters.range?.property === 'endTime') {
        newFilters.range = {
          ...filters.range,
          property: 'scheduledEndTime',
        };
        console.log(
          `Replaced endTime with scheduledEndTime to transition to IDM`
        );
      }
    }

    if (key === 'in') {
      if (filters.in?.property === 'id') {
        newFilters.in = {
          property: 'sourceId',
          in: filters.in.in,
        };
        newFilters.directRelationInstanceSpace = space;
      }
      if (filters.in?.property === 'assetExternalId') {
        newFilters.in = undefined;
        newFilters.directRelationFilter = {
          mainAsset: {
            in: { property: 'externalId', in: filters.in.in },
          },
        };
        newFilters.directRelationInstanceSpace = space;
      }
      if (filters.in?.property === 'cloneOf') {
        newFilters.in = undefined;
        console.log(
          'Removed filter for "cloneOf" since the property does not exist on CogniteMaintenanceOrder data model'
        );
      }
    }

    if (key === 'equals') {
      if (filters.equals?.property === 'isInApp') {
        newFilters.equals = undefined;
        console.log(
          `Removed filter for "isInApp" since it doesn't exist in IDM`
        );
      }
      if (filters.equals?.property === 'hasBeenMutated') {
        newFilters.equals = undefined;
        console.log(
          `Removed filter for "hasBeenMutated" since it doesn't exist in IDM`
        );
      }
      if (filters.equals?.property === 'mutation') {
        newFilters.equals = undefined;
        console.log(
          `Removed filter for "mutation" since it doesn't exist in IDM`
        );
      }
      if (filters.equals?.property === 'assetExternalId') {
        newFilters.equals = undefined;
        newFilters.directRelationFilter = {
          mainAsset: {
            equals: { property: 'externalId', eq: String(filters.equals.eq) },
          },
        };
        newFilters.directRelationInstanceSpace = space;
      }
      if (filters.equals?.property === 'source') {
        newFilters.equals = undefined;
        console.log(
          `Removed filter for "source" since the since CogniteMaintenanceOrder has source as CogniteSourceSystem and we don't know how to filter on that yet`
        );
      }
    }
    if (key === 'isNull') {
      if (filters.isNull?.property === 'cloneOf') {
        newFilters.isNull = undefined;
        console.log(
          'Removed filter for "cloneOf" since the property does not exist on CogniteMaintenanceOrder data model'
        );
      }
    }
  });
  return newFilters;
};
