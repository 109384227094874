import type { FdmTimeSeries } from '@cognite/fdm-client';
import type { TimeseriesFilter } from '@cognite/sdk';

export const getTimeseriesFilter = (
  timeseriesDataSet?: number
): TimeseriesFilter | undefined => {
  // We shall normally look for timeseries created by infield in the configured dataset
  // If the dataset is not configured, we shall look for all timeseries
  if (timeseriesDataSet) {
    return {
      dataSetIds: [
        {
          id: timeseriesDataSet,
        },
      ],
    };
  }
  return undefined;
};

export function isFdmTimeSeries(resource: any): resource is FdmTimeSeries {
  if (!resource) {
    return false;
  }
  return (
    typeof resource.id === 'number' &&
    'isString' in resource &&
    typeof resource.isString === 'boolean'
  );
}
