import React, { useEffect, useState } from 'react';

import { IRect } from 'konva/cmj/types';

import { TooltipAnchorPosition, TooltipPositionsOrder } from './types';

const TOOLTIP_CONTAINER_MARGIN = 10;

type NodeTooltipProps = {
  targetRect: IRect;
  anchorTo?: TooltipAnchorPosition;
  shouldPositionStrictly?: boolean;
};

const getTopLeftTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: Math.max(
      targetRect.x,
      Math.min(targetRect.x + targetRect.width, TOOLTIP_CONTAINER_MARGIN)
    ),
    y: targetRect.y - tooltipContainerRect.height,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getTopCenterToolTipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x + targetRect.width / 2 - tooltipContainerRect.width / 2,
    y: targetRect.y - tooltipContainerRect.height,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getTopRightTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x + targetRect.width - tooltipContainerRect.width,
    y: targetRect.y - tooltipContainerRect.height,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getLeftTopTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x - tooltipContainerRect.width,
    y: targetRect.y,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getLeftCenterTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x - tooltipContainerRect.width,
    y: targetRect.y + targetRect.height / 2 - tooltipContainerRect.height / 2,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getLeftBottomTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x - tooltipContainerRect.width,
    y: targetRect.y + targetRect.height - tooltipContainerRect.height,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getBottomRightTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x + targetRect.width - tooltipContainerRect.width,
    y: targetRect.y + targetRect.height,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getBottomCenterTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x + targetRect.width / 2 - tooltipContainerRect.width / 2,
    y: targetRect.y + targetRect.height,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getBottomLeftTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: Math.max(
      targetRect.x,
      Math.min(targetRect.x + targetRect.width, TOOLTIP_CONTAINER_MARGIN)
    ),
    y: targetRect.y + targetRect.height,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getRightBottomTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x + targetRect.width,
    y: targetRect.y + targetRect.height - tooltipContainerRect.height,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getRightCenterTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x + targetRect.width,
    y: targetRect.y + targetRect.height / 2 - tooltipContainerRect.height / 2,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getRightTopTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x + targetRect.width,
    y: targetRect.y,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const getCenterTooltipPosition = (
  targetRect: IRect,
  tooltipContainerRect: IRect
) => {
  return {
    x: targetRect.x + targetRect.width / 2 - tooltipContainerRect.width / 2,
    y: targetRect.y + targetRect.height / 2 - tooltipContainerRect.height / 2,
    width: tooltipContainerRect.width,
    height: tooltipContainerRect.height,
  };
};

const isRectInViewport = (rect: IRect, parentRect: IRect) => {
  if (rect.x < 0 || rect.x + rect.width > parentRect.width) {
    return false;
  }

  if (rect.y < 0 || rect.y + rect.height > parentRect.height) {
    return false;
  }

  return true;
};

type LazyAnchoredPosition = {
  anchorTo: TooltipAnchorPosition;
  getRect: () => IRect;
};

const getFirstVisiblePositionOrFirst = (
  positions: LazyAnchoredPosition[],
  defaultPosition: LazyAnchoredPosition,
  parentContainerRect: IRect
): TooltipAnchorPosition => {
  const firstPositionInViewPort = positions.find((position) =>
    isRectInViewport(position.getRect(), parentContainerRect)
  );
  const pickedPosition = firstPositionInViewPort ?? defaultPosition;
  return pickedPosition.anchorTo;
};

const getTooltipAnchorPrecedence = (
  anchorTo: TooltipAnchorPosition
): [TooltipAnchorPosition, ...TooltipAnchorPosition[]] => {
  const anchorToIndex = TooltipPositionsOrder.indexOf(anchorTo);

  if (anchorToIndex === -1) {
    throw new Error(`Invalid anchorTo position: ${anchorTo}`);
  }

  return [
    ...TooltipPositionsOrder.slice(anchorToIndex),
    ...TooltipPositionsOrder.slice(0, anchorToIndex),
  ] as [TooltipAnchorPosition, ...TooltipAnchorPosition[]];
};

const getOptimalAnchorPosition = (
  anchorTo: TooltipAnchorPosition,
  targetRect: IRect,
  tooltipContainerRect: IRect,
  parentContainerRect: IRect,
  shouldPositionStrictly: boolean
): TooltipAnchorPosition => {
  if (shouldPositionStrictly) {
    return anchorTo;
  }

  const precedenceList = getTooltipAnchorPrecedence(anchorTo);
  const anchoredPositions = precedenceList.map<LazyAnchoredPosition>(
    (anchor) => ({
      anchorTo: anchor,
      getRect: () =>
        getTooltipPosition(anchor, targetRect, tooltipContainerRect),
    })
  );

  return getFirstVisiblePositionOrFirst(
    anchoredPositions,
    anchoredPositions[0],
    parentContainerRect
  );
};

const getTooltipPosition = (
  anchorTo: TooltipAnchorPosition,
  targetRect: IRect,
  tooltipContainerRect: IRect
): IRect => {
  switch (anchorTo) {
    case TooltipAnchorPosition.TOP_LEFT:
      return getTopLeftTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.TOP_CENTER:
      return getTopCenterToolTipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.TOP_RIGHT:
      return getTopRightTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.RIGHT_TOP:
      return getRightTopTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.RIGHT_CENTER:
      return getRightCenterTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.RIGHT_BOTTOM:
      return getRightBottomTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.BOTTOM_RIGHT:
      return getBottomRightTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.BOTTOM_CENTER:
      return getBottomCenterTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.BOTTOM_LEFT:
      return getBottomLeftTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.LEFT_BOTTOM:
      return getLeftBottomTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.LEFT_CENTER:
      return getLeftCenterTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.LEFT_TOP:
      return getLeftTopTooltipPosition(targetRect, tooltipContainerRect);
    case TooltipAnchorPosition.CENTER:
      return getCenterTooltipPosition(targetRect, tooltipContainerRect);
  }
};

const mapXYToTopLeft = (rect: IRect): { top: number; left: number } => ({
  top: rect.y,
  left: rect.x,
});

const NodeTooltip: React.FC<React.PropsWithChildren<NodeTooltipProps>> = ({
  targetRect,
  anchorTo = TooltipAnchorPosition.TOP_LEFT,
  shouldPositionStrictly = false,
  children,
}) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [tooltipContainerRect, setTooltipContainerRect] = React.useState<
    IRect | undefined
  >(undefined);
  const [optimalTooltipAnchor, setOptimalTooltipAnchor] =
    React.useState<TooltipAnchorPosition>(anchorTo);

  useEffect(() => {
    const ref = containerRef;
    if (ref === null) {
      return;
    }

    const parentContainerRect =
      containerRef?.parentElement?.getBoundingClientRect();
    if (parentContainerRect === undefined) {
      // eslint-disable-next-line no-console
      console.warn('Parent container was not set - this should never happen');
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setTooltipContainerRect(ref.getBoundingClientRect());
    });

    resizeObserver.observe(ref);

    const currentTooltipContainerRect = ref.getBoundingClientRect();
    setTooltipContainerRect(currentTooltipContainerRect);

    setOptimalTooltipAnchor(
      getOptimalAnchorPosition(
        anchorTo,
        targetRect,
        currentTooltipContainerRect,
        parentContainerRect,
        shouldPositionStrictly
      )
    );

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, anchorTo, targetRect, shouldPositionStrictly]);

  const parentContainerRect =
    containerRef?.parentElement?.getBoundingClientRect();

  const tooltipPosition =
    tooltipContainerRect !== undefined && parentContainerRect !== undefined
      ? getTooltipPosition(
          optimalTooltipAnchor,
          targetRect,
          tooltipContainerRect
        )
      : undefined;

  return (
    <div
      ref={setContainerRef}
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        ...(tooltipPosition !== undefined && parentContainerRect !== undefined
          ? mapXYToTopLeft(tooltipPosition)
          : { visibility: 'hidden' }),
      }}
    >
      {children}
    </div>
  );
};

export default NodeTooltip;
