import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { styled } from 'styled-components';

import { cloneDeep, debounce, isEqual, memoize } from 'lodash-es';
import { createRoot } from 'react-dom/client';
import {
  CellComponent,
  OptionsGeneral,
  TabulatorFull as Tabulator,
} from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css';

import { Colors, DragHandleVerticalIcon } from '@cognite/cogs.js-v10';

import CanvasTooltip from '../../react/NodePopup/CanvasTooltip';
import { LoadingStatus } from '../asyncContainerUtils';
import { ReactContainerRenderContentProps } from '../ReactContainer';

import DataGridContextMenu from './DataGridContextMenu';
import getTableData, { TableData } from './getTableData';
import getTabulatorColumns from './getTabulatorColumns';
import type {
  DataGridCellStyle,
  DataGridColumn,
  DataGridContainerProps,
  DataGridRow,
} from './types';
import updateTable from './updateTable';
import useAttachEventListeners from './useAttachEventListeners';
import useAutoResizeDataGrid from './useAutoResizeDataGrid';

export const DATA_GRID_CONTEXT_MENU_KEY = 'data-grid-context-menu';

// Shameful because we don't have a good way of knowing when the initial load / render is completed
// so we just delay it by some time and hope that it's enough.
const SHAMEFUL_ON_INITIAL_LOAD_COMPLETED_DELAY_MS = 500;
const ON_RESIZE_COMPLETED_DELAY_MS = 200;

const ROW_HEADER_CONFIG: OptionsGeneral['rowHeader'] = {
  headerSort: false,
  resizable: false,
  minWidth: 25,
  width: 25,
  rowHandle: true,
  // @ts-expect-error: Tabulator actually supports functions for the formatter property
  formatter: memoize(() => {
    const domNode = document.createElement('div');
    createRoot(domNode).render(
      <div style={{ cursor: 'grab', padding: '1px 2px' }}>
        <DragHandleVerticalIcon />
      </div>
    );
    return domNode;
  }),
};

type DataGridContentProps = Pick<
  ReactContainerRenderContentProps,
  | 'width'
  | 'height'
  | 'unscaledWidth'
  | 'unscaledHeight'
  | 'setLoadingStatus'
  | 'onContentSizeChange'
  | 'shouldAutoSize'
> &
  Pick<DataGridContainerProps, 'columns' | 'rows' | 'onCellClick'> & {
    containerPadding: number;
    onRowsUpdated: (nextRows: DataGridRow[]) => void;
    onColumnUpdated: (nextColumns: DataGridColumn[]) => void;
  };

type ContextMenuState = { x: number; y: number; cell: CellComponent };

const getScaleAndOffset = (element: HTMLElement) => {
  const clientRect = element.getBoundingClientRect();
  const unscaledWidth = element.offsetWidth;
  return {
    scale: clientRect.width / unscaledWidth,
    offsetX: clientRect.left,
    offsetY: clientRect.top,
  };
};

const getDataGridRow = (data: TableData): DataGridRow => {
  return {
    id: data.id,
    cells: Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, { value }])
    ),
  };
};

const DataGridContent: React.FC<DataGridContentProps> = ({
  width,
  height,
  unscaledWidth,
  unscaledHeight,
  setLoadingStatus,
  columns,
  rows,
  shouldAutoSize,
  containerPadding,
  onCellClick,
  onContentSizeChange,
  onRowsUpdated,
  onColumnUpdated,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [contextMenuState, setContextMenuState] =
    useState<ContextMenuState | null>(null);
  const cellStyles = useRef<DataGridCellStyle[]>([]);
  const tableRef = useRef<Tabulator | null>(null);
  const [forceUpdate, setForceUpdate] = useState(0);

  const debouncedSetForceUpdate = useCallback(
    debounce(setForceUpdate, ON_RESIZE_COMPLETED_DELAY_MS),
    [setForceUpdate]
  );

  // Hide context menu on click outside
  useEffect(() => {
    const hideContextMenu = (e: MouseEvent) => {
      // Let the context menu handle its own events
      if (
        e.target instanceof HTMLElement &&
        e.target.closest(`#${DATA_GRID_CONTEXT_MENU_KEY}`) !== null
      ) {
        return;
      }
      setContextMenuState(null);
    };
    document.body.addEventListener('mousedown', hideContextMenu, {
      capture: true,
    });
    return () => {
      document.body.removeEventListener('mousedown', hideContextMenu, {
        capture: true,
      });
    };
  }, []);

  const onCellContextMenu = useCallback(
    (event: UIEvent, cell: CellComponent) => {
      const container = containerRef.current;
      if (container === null || !(event instanceof MouseEvent)) {
        return;
      }
      const { scale, offsetX, offsetY } = getScaleAndOffset(container);
      setContextMenuState({
        cell,
        x: (event.clientX - offsetX) / scale,
        y: (event.clientY - offsetY) / scale,
      });
    },
    [setContextMenuState]
  );

  const handleCellClick = useCallback(
    (cell: CellComponent) => {
      if (onCellClick === undefined) {
        return;
      }
      const rowId = cell.getData().id;
      const columnKey = cell.getColumn().getField();
      const selectedCell = rows.find((r) => r.id === rowId)?.cells[columnKey];
      if (selectedCell !== undefined) {
        onCellClick({ cell: selectedCell, rowId, columnKey });
      }
    },
    [onCellClick, rows]
  );

  const onCellUpdated = useCallback(() => {
    if (tableRef.current === null) {
      return;
    }
    const data: TableData[] = tableRef.current.getData();
    const rowsById = new Map(rows.map((r) => [r.id, r]));
    const nextRows = data.map((d) => {
      const row = rowsById.get(d.id);
      if (row === undefined) {
        return getDataGridRow(d);
      }
      return {
        ...row,
        cells: Object.fromEntries(
          Object.entries(row.cells).map(([key, cell]) => [
            key,
            { ...cell, value: d[key] },
          ])
        ),
      };
    });
    onRowsUpdated(nextRows);
  }, [rows, onRowsUpdated]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const nextCellStyles = rows
      .flatMap((row) => Object.values(row.cells))
      .map((cell) => cell.style)
      .filter((style) => style !== undefined);
    const hasCellStylesChanged = !isEqual(cellStyles.current, nextCellStyles);
    if (hasCellStylesChanged) {
      cellStyles.current = nextCellStyles;
    }

    // NOTE: we have to trigger a full table-re-render when the styling is
    // changed, otherwise the table will not update the style
    if (tableRef.current !== null && !hasCellStylesChanged) {
      updateTable({
        table: tableRef.current,
        rows,
        columns,
        onCellContextMenu,
        onCellUpdated,
        onCellClick: handleCellClick,
      });
      return;
    }

    const table = new Tabulator(containerRef.current, {
      // NOTE: We need to clone the rows and columns as apparently
      // tabulator apparently mutates the provided data
      data: getTableData(rows),
      columns: getTabulatorColumns(
        cloneDeep(columns),
        rows,
        onCellUpdated,
        handleCellClick,
        onCellContextMenu
      ),
      rowFormatter: (row) => {
        const rowHeight = row.getData().height;
        if (typeof rowHeight === 'number') {
          row.getElement().style.height = `${rowHeight}px`;
        }
      },
      columnDefaults: { tooltip: true },
      renderVertical: 'basic',
      // TODO(FUS-000): Expose this prop to the user. Currently, we always hide the
      // header since the sizing of the container gets messed up when the
      // header is visible
      headerVisible: false,
      resizableRows: true,
      movableRows: true,
      movableColumns: true,
      rowHeader: ROW_HEADER_CONFIG,
    });
    tableRef.current = table;
  }, [rows, columns, handleCellClick, onCellContextMenu, onCellUpdated]);

  useAttachEventListeners({
    tableRef,
    rows,
    columns,
    onRowsUpdated,
    onColumnsUpdated: onColumnUpdated,
    setForceUpdate: debouncedSetForceUpdate,
  });

  useEffect(() => {
    setLoadingStatus(LoadingStatus.LOADING);
    // Here we're guessing that the initial load is completed after some time
    // and then we set the loading status to success
    const timeoutId = setTimeout(() => {
      setLoadingStatus(LoadingStatus.SUCCESS);
    }, SHAMEFUL_ON_INITIAL_LOAD_COMPLETED_DELAY_MS);
    return () => clearTimeout(timeoutId);
  }, [setLoadingStatus]);

  useAutoResizeDataGrid({
    gridContainerRef: containerRef,
    shouldAutoSize,
    containerPadding,
    onContentSizeChange,
    unscaledWidth,
    unscaledHeight,
    width,
    height,
    rows,
    columns,
    forceUpdate,
  });

  // Scale calculation
  const scale = Math.min(width / unscaledWidth, height / unscaledHeight);
  return (
    <>
      {contextMenuState !== null &&
        createPortal(
          <CanvasTooltip
            id={DATA_GRID_CONTEXT_MENU_KEY}
            position={{ x: contextMenuState.x, y: contextMenuState.y }}
          >
            <DataGridContextMenu
              columns={columns}
              selectedCell={contextMenuState.cell}
              rows={rows}
              onColumnUpdated={onColumnUpdated}
              onRowsUpdated={onRowsUpdated}
              onContextMenuItemClick={() => setContextMenuState(null)}
            />
          </CanvasTooltip>,
          containerRef.current?.parentElement ?? document.body
        )}
      <div
        onKeyDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
        }}
      >
        <Container
          ref={containerRef}
          style={{
            width: unscaledWidth,
            height: unscaledHeight,
          }}
          width={unscaledWidth}
          height={unscaledHeight}
        />
      </div>
    </>
  );
};

// Wrapper around the Tabulator container to prevent the table from overflowing
const Container = styled.div<{ width: number; height: number }>`
  && .tabulator-tableholder {
    overflow: hidden;
  }

  .tabulator-row .tabulator-cell {
    padding: 12px 2px;
  }

  box-sizing: border-box;
  border: 1px solid ${Colors['border--muted']};
`;

export default DataGridContent;
