import type { Filters } from '@cognite/fdm-client';
import { useMetrics } from '@cognite/metrics';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

type Props = {
  field: string;
  isDirectRelation: boolean;
  isDisabled: boolean;
  filter?: Filters;
};

export const useChecklistsAggregate = ({
  field,
  isDirectRelation,
  isDisabled,
  filter,
}: Props) => {
  const { checklistService } = useFDMServices();

  const sliTimerStartTime = useRef<number | undefined>(undefined);
  const sliMetrics = useMetrics(METRICS_NAMESPACES.checklists);

  const trackMetrics = (status: 'error' | 'success') => {
    const sliTimerEndTime = Date.now();
    sliMetrics.track(QueryKeys.CHECKLIST_AGGREGATE, {
      sliTimerMilliseconds: sliTimerStartTime.current
        ? sliTimerEndTime - sliTimerStartTime.current
        : undefined,
      status,
      networkSpeedMbps: navigator.connection?.downlink,
    });
  };

  const queryResult = useQuery(
    [
      QueryKeys.CHECKLIST_AGGREGATE,
      field,
      isDirectRelation,
      filter,
      isDisabled,
    ],
    async () => {
      sliTimerStartTime.current = Date.now();
      return await checklistService.aggregate(field, isDirectRelation, filter);
    },
    {
      onSuccess: () => {
        trackMetrics('success');
      },
      onError: (err) => {
        captureException(err, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.CHECKLIST,
          },
        });
        trackMetrics('error');
      },
      refetchOnWindowFocus: false,
      enabled: !isDisabled,
    }
  );

  return {
    data: queryResult.data,
  };
};
