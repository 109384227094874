import { useFlag, useVariant } from '@cognite/react-feature-flags';
import { createContext } from 'react';

export interface UseIsChecklistDataCorruptContextInterface {
  useFlag: typeof useFlag;
  useVariant: typeof useVariant;
}

let context: React.Context<UseIsChecklistDataCorruptContextInterface>;

export const getIsChecklistDataCorruptContext = () => {
  if (!context) {
    context = createContext({
      useFlag,
      useVariant,
    });
  }

  return context;
};
