import Konva from 'konva';
import { throttle } from 'lodash';

import {
  isCauseMapNodeAnnotationNode,
  type Position,
} from '../annotations/types';
import { Container } from '../containers/Container';
import LayerContainer from '../containers/LayerContainer';
import ConnectionHelper from '../tools/ConnectionHelper';
import { ContainerAttachmentHelper } from '../tools/ContainerAttachmentHelper';
import { UnifiedViewer } from '../UnifiedViewer';
import UnifiedViewerEventType from '../UnifiedViewerEventType';
import { ANCHOR_STYLE } from '../UnifiedViewerTransformer';

import configureCauseMapConnection from './configureCauseMapConnection';
import getNodeIdsByType from './getNodeIdsByType';
import getNodeRectRelativeToStage from './getNodeRectRelativeToStage';
import isSingleDocumentAnnotationNode from './isSingleDocumentAnnotationNode';
import { isLineSegment } from './mathUtils';
import { isResizableNode } from './nodeUtils';
import { isPointInRect } from './rectUtils';

type LineAnchors = {
  startAnchor: Konva.Circle;
  endAnchor: Konva.Circle;
};
const MINIMUM_ANCHOR_SIZE = 10;
const SCALE_ANCHOR_THROTTLE_MS = 30;
const MIN_ANCHOR_SCALE = 0.07;

export const shouldNodeUseLineTransformer = (
  node: Konva.Node
): node is Konva.Arrow => isLineSegment(node) && isResizableNode(node);

const scaleLineAnchors = (lineAnchors: LineAnchors, scale: number): void => {
  const clampedScale = Math.max(scale, MIN_ANCHOR_SCALE);
  const newScale = { x: 1 / clampedScale, y: 1 / clampedScale };
  lineAnchors.startAnchor.scale(newScale);
  lineAnchors.endAnchor.scale(newScale);
};

const getLineOffset = (line: Konva.Arrow, viewer: UnifiedViewer): Position => {
  const container = isSingleDocumentAnnotationNode(line)
    ? viewer?.getContainerById(line.attrs.containerId)
    : undefined;
  return container?.getNode().position() ?? { x: 0, y: 0 };
};

const getAnchorPositionsFromLine = (
  line: Konva.Arrow,
  viewer: UnifiedViewer
): { start: Position; end: Position } => {
  const { x: lineX, y: lineY } = line.position();
  const [startX, startY, endX, endY] = line.points();
  const { x: offsetX, y: offsetY } = getLineOffset(line, viewer);
  return {
    start: {
      x: offsetX + lineX + startX,
      y: offsetY + lineY + startY,
    },
    end: {
      x: offsetX + lineX + endX,
      y: offsetY + lineY + endY,
    },
  };
};

const createLineAnchor = (
  line: Konva.Arrow,
  viewer: UnifiedViewer,
  initialScale: number
): LineAnchors => {
  const anchorPositions = getAnchorPositionsFromLine(line, viewer);
  const startAnchor = new Konva.Circle({
    name: `${line.name()}_anchor`,
    ...anchorPositions.start,
    // TODO(FUS-000): find a better heuristic for the line anchor size?
    radius: Math.max(line.strokeWidth() / 2, MINIMUM_ANCHOR_SIZE),
    fill: ANCHOR_STYLE.anchorFill,
    stroke: ANCHOR_STYLE.anchorStroke,
    strokeWidth: ANCHOR_STYLE.anchorStrokeWidth,
    draggable: true,
  });
  const endAnchor = startAnchor.clone({ ...anchorPositions.end });
  const lineAnchors = { startAnchor, endAnchor };
  scaleLineAnchors(lineAnchors, initialScale);

  return lineAnchors;
};

class LineTransformer {
  private isAnchorDragging: boolean = false;
  private shouldGenerateConnections: boolean = true;
  private lines: Konva.Arrow[] = [];
  private anchorsPerLine: Map<string, LineAnchors> = new Map();
  private layer: LayerContainer | undefined = undefined;
  private eventListeners: Record<
    string | number,
    Konva.KonvaEventListener<Konva.Node, any>[]
  > = {};
  private unifiedViewer: UnifiedViewer;
  private connectionHelper: ConnectionHelper;
  private containerAttachmentHelper: ContainerAttachmentHelper;

  public constructor(unifiedViewer: UnifiedViewer) {
    this.unifiedViewer = unifiedViewer;
    this.connectionHelper = new ConnectionHelper(this.unifiedViewer);
    this.containerAttachmentHelper = new ContainerAttachmentHelper(
      this.unifiedViewer
    );
    this.setShouldGenerateConnections(this.shouldGenerateConnections);

    this.unifiedViewer.addEventListener(
      UnifiedViewerEventType.ON_ZOOM_CHANGE,
      this.applyScaleToLineAnchors
    );
    this.unifiedViewer.addEventListener(
      UnifiedViewerEventType.ON_ZOOM_END,
      this.applyScaleToLineAnchors
    );
  }

  private applyScaleToLineAnchors = throttle((scale: number): void => {
    this.anchorsPerLine.forEach((lineAnchors) => {
      scaleLineAnchors(lineAnchors, scale);
    });
  }, SCALE_ANCHOR_THROTTLE_MS);

  public setTransformerLayer = (layer: LayerContainer): void => {
    this.layer = layer;
  };

  private refreshLineDragMoveEventListener = (line: Konva.Arrow): void => {
    line.off('dragmove');
    line.on('dragmove', () => {
      const anchorPositions = getAnchorPositionsFromLine(
        line,
        this.unifiedViewer
      );
      const cachedAnchors = this.anchorsPerLine.get(line.id());
      cachedAnchors?.startAnchor.position(anchorPositions.start);
      cachedAnchors?.endAnchor.position(anchorPositions.end);
      this.connectionHelper.reset();
    });
  };

  private clearLines = (): void => {
    this.lines.forEach((line) => {
      const anchors = this.anchorsPerLine.get(line.id());
      anchors?.startAnchor.destroy();
      anchors?.endAnchor.destroy();
      this.anchorsPerLine.delete(line.id());
    });
    this.lines = [];
    this.isAnchorDragging = false;
    this.containerAttachmentHelper.end();
  };

  private startAnchorPosition: Konva.Vector2d | undefined = undefined;
  private endAnchorPosition: Konva.Vector2d | undefined = undefined;

  public setShouldGenerateConnections = (
    shouldGenerateConnections: boolean
  ): void => {
    if (shouldGenerateConnections === this.shouldGenerateConnections) {
      return;
    }
    if (shouldGenerateConnections) {
      this.connectionHelper.enable();
      this.connectionHelper.updateHoverEffect();
    } else {
      this.connectionHelper.disable();
    }
    this.shouldGenerateConnections = shouldGenerateConnections;
  };

  private handleStartAnchorPositionUpdate = (pos: Konva.Vector2d): void => {
    this.connectionHelper.updateHoverEffect();
    this.startAnchorPosition = pos;
  };

  private handleEndAnchorPositionUpdate = (pos: Konva.Vector2d): void => {
    this.connectionHelper.updateHoverEffect();
    this.endAnchorPosition = pos;
  };

  private setLinesListening = (isListening: boolean): void => {
    // We need to selectively disable listening, otherwise we will sometimes detect
    // the line as being the "target" shape, rather than for instance the stage or another shape
    this.lines.forEach((line) => {
      line.listening(isListening);
    });
  };

  private get isConnectionLine(): boolean {
    const line = this.getLineNodes()[0];
    if (line === undefined) {
      return false;
    }
    return (
      this.shouldGenerateConnections ||
      line.attrs.fromId !== undefined ||
      line.attrs.toId !== undefined
    );
  }

  private handleStartContainerAttachmentInteraction = (): void => {
    // TODO(AH-1616): Add support for connecting to "hot spots" inside a container
    if (this.isConnectionLine) {
      return;
    }
    this.containerAttachmentHelper.start();
  };

  private handleEndContainerAttachmentInteraction = (): void => {
    this.containerAttachmentHelper.end((targetContainer) =>
      this.getLinesToAttachOrDetachFromContainer(targetContainer)
    );
    this.syncLineWithAnchors(this.getLineNodes()[0]);
  };

  private shouldAttachOrDetachLineFromContainer = (
    targetContainer: Container | null
  ): boolean => {
    if (
      this.startAnchorPosition === undefined ||
      this.endAnchorPosition === undefined
    ) {
      return false;
    }

    // TODO(AH-1616): Add support for connecting to "hot spots" inside a container
    if (this.isConnectionLine) {
      return false;
    }

    if (targetContainer === null) {
      const currentlyAttachedContainerId =
        this.getLineNodes()[0].attrs.containerId;
      const currentContainer = this.unifiedViewer.getContainerById(
        currentlyAttachedContainerId
      );
      if (currentContainer === undefined) {
        return false;
      }
      const currentContainerRect = getNodeRectRelativeToStage(
        currentContainer.getContentNode()
      );
      if (currentContainerRect === null) {
        return false;
      }
      // Detach the line from the container if both anchors are outside the container
      return (
        !isPointInRect(this.startAnchorPosition, currentContainerRect) &&
        !isPointInRect(this.endAnchorPosition, currentContainerRect)
      );
    }

    const targetContainerRect = getNodeRectRelativeToStage(
      targetContainer.getContentNode()
    );
    if (targetContainerRect === null) {
      return false;
    }
    // Attach the line to the drop target/container if at least one anchor is inside it
    return (
      isPointInRect(this.startAnchorPosition, targetContainerRect) ||
      isPointInRect(this.endAnchorPosition, targetContainerRect)
    );
  };

  private getLinesToAttachOrDetachFromContainer = (
    targetContainer: Container | null
  ): Konva.Arrow[] =>
    this.shouldAttachOrDetachLineFromContainer(targetContainer)
      ? [this.getLineNodes()[0]]
      : [];

  private handleStartAnchorDragEnd = (): void => {
    this.isAnchorDragging = false;
    this.setLinesListening(true);

    const lineNodes = this.getLineNodes();
    const line = lineNodes[0];

    this.handleEndContainerAttachmentInteraction();

    this.unifiedViewer.emit(
      UnifiedViewerEventType.ON_TRANSFORM_END,
      getNodeIdsByType(lineNodes)
    );

    const { mouseUpNode } = this.connectionHelper.getConnectionEndpointNodes();
    if (lineNodes.length > 1) {
      return;
    }
    if (mouseUpNode !== undefined && !this.shouldGenerateConnections) {
      return;
    }

    line.attrs.fromId = mouseUpNode?.id();
    this.initializeCauseMapConnection(line);
    this.connectionHelper.reset();
  };

  private initializeCauseMapConnection = (connection: Konva.Arrow): void => {
    const fromId = connection.attrs.fromId;
    const toId = connection.attrs.toId;
    if (fromId === undefined || toId === undefined) {
      return;
    }

    const fromNodes = this.unifiedViewer.layers.main.layer.find(
      (n: Konva.Node) => n.id() === fromId
    );
    if (fromNodes === undefined || fromNodes.length !== 1) {
      return;
    }

    const toNodes = this.unifiedViewer.layers.main.layer.find(
      (n: Konva.Node) => n.id() === toId
    );
    if (toNodes === undefined || toNodes.length !== 1) {
      return;
    }

    const [fromNode, toNode] = [fromNodes[0], toNodes[0]];
    if (
      !isCauseMapNodeAnnotationNode(fromNode) ||
      !isCauseMapNodeAnnotationNode(toNode)
    ) {
      return;
    }
    configureCauseMapConnection({ connection, fromNode, toNode });
  };

  private handleEndAnchorDragEnd = () => {
    this.isAnchorDragging = false;
    this.setLinesListening(true);

    this.handleEndContainerAttachmentInteraction();

    this.unifiedViewer.emit(
      UnifiedViewerEventType.ON_TRANSFORM_END,
      getNodeIdsByType(this.getLineNodes())
    );

    const { mouseUpNode } = this.connectionHelper.getConnectionEndpointNodes();
    const lineNodes = this.getLineNodes();
    if (lineNodes.length > 1) {
      return;
    }
    if (mouseUpNode !== undefined && !this.shouldGenerateConnections) {
      return;
    }

    const line = lineNodes[0];
    line.attrs.toId = mouseUpNode?.id();
    this.initializeCauseMapConnection(line);
    this.connectionHelper.reset();
  };

  private syncLineWithAnchors = (line: Konva.Arrow) => {
    if (
      this.startAnchorPosition === undefined ||
      this.endAnchorPosition === undefined
    ) {
      return;
    }

    const { x: offsetX, y: offsetY } = getLineOffset(line, this.unifiedViewer);
    line.position({
      x: this.startAnchorPosition.x - offsetX,
      y: this.startAnchorPosition.y - offsetY,
    });
    line.points([
      0,
      0,
      this.endAnchorPosition.x - this.startAnchorPosition.x,
      this.endAnchorPosition.y - this.startAnchorPosition.y,
    ]);
  };

  private attachEventListeners = (
    line: Konva.Arrow,
    anchors: LineAnchors
  ): void => {
    const { startAnchor, endAnchor } = anchors;

    startAnchor.on('dragstart', () => {
      this.setLinesListening(false);
      this.isAnchorDragging = true;
      this.handleStartContainerAttachmentInteraction();
      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_TRANSFORM_START,
        getNodeIdsByType(this.getLineNodes())
      );
    });

    endAnchor.on('dragstart', () => {
      this.setLinesListening(false);
      this.isAnchorDragging = true;
      this.handleStartContainerAttachmentInteraction();
      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_TRANSFORM_START,
        getNodeIdsByType(this.getLineNodes())
      );
    });

    startAnchor.on('dragmove', () => {
      this.handleStartAnchorPositionUpdate(startAnchor.position());
      this.syncLineWithAnchors(this.getLineNodes()[0]);
      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_TRANSFORM_CHANGE,
        getNodeIdsByType(this.getLineNodes())
      );
    });
    endAnchor.on('dragmove', () => {
      this.handleEndAnchorPositionUpdate(endAnchor.position());
      this.syncLineWithAnchors(this.getLineNodes()[0]);
      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_TRANSFORM_CHANGE,
        getNodeIdsByType(this.getLineNodes())
      );
    });

    startAnchor.on('dragend', this.handleStartAnchorDragEnd);
    endAnchor.on('dragend', this.handleEndAnchorDragEnd);

    Object.entries(this.eventListeners).forEach(([evtStr, handlers]) => {
      handlers.forEach((handler) => {
        startAnchor.on(evtStr, handler);
        endAnchor.on(evtStr, handler);
      });
    });

    this.refreshLineDragMoveEventListener(line);
  };

  private updateLineAnchors = (line: Konva.Arrow): void => {
    const anchorPositions = getAnchorPositionsFromLine(
      line,
      this.unifiedViewer
    );

    this.startAnchorPosition = anchorPositions.start;
    this.endAnchorPosition = anchorPositions.end;

    const lineAnchors = this.anchorsPerLine.get(line.id());
    lineAnchors?.startAnchor.position(this.startAnchorPosition);
    lineAnchors?.endAnchor.position(this.endAnchorPosition);

    this.refreshLineDragMoveEventListener(line);
  };

  private createLineAnchors = (line: Konva.Arrow): void => {
    const initialScale = this.unifiedViewer.getScale();
    const lineAnchors = createLineAnchor(
      line,
      this.unifiedViewer,
      initialScale
    );
    this.attachEventListeners(line, lineAnchors);
    this.startAnchorPosition = lineAnchors.startAnchor.position();
    this.endAnchorPosition = lineAnchors.endAnchor.position();
    this.layer?.add(lineAnchors.startAnchor, lineAnchors.endAnchor);
    this.anchorsPerLine.set(line.id(), lineAnchors);
  };

  public setLineNodes = (lines: Konva.Arrow[]): void => {
    if (this.isAnchorDragging) {
      return;
    }

    // Delete the anchors of the removed lines
    const prevLines = this.lines;
    const newLineIds = lines.map((l) => l.id());
    prevLines.forEach((line) => {
      if (newLineIds.includes(line.id())) {
        return;
      }
      const anchors = this.anchorsPerLine.get(line.id());
      anchors?.startAnchor.destroy();
      anchors?.endAnchor.destroy();
      this.anchorsPerLine.delete(line.id());
    });

    this.lines = lines;
    this.lines.forEach((line) => {
      line.listening(true);
      if (this.anchorsPerLine.has(line.id())) {
        this.updateLineAnchors(line);
        return;
      }
      this.createLineAnchors(line);
    });

    if (!this.shouldGenerateConnections) {
      return;
    }

    if (this.lines.length > 0) {
      this.connectionHelper.enable();
    }

    if (this.lines.length === 0) {
      this.connectionHelper.disable();
    }
  };

  public getLineNodes = (): Konva.Arrow[] => this.lines;

  // This method maps the Konva transformer event string to the corresponding
  // line transformer event string. In general, transform event maps to drag
  // events for the line anchors
  private getAnchorEvtStr = (evtStr: string | number): string | number => {
    if (evtStr === 'transformstart') {
      return 'dragstart';
    }
    if (evtStr === 'transformend') {
      return 'dragend';
    }
    if (evtStr === 'transform') {
      return 'dragmove';
    }
    return evtStr;
  };

  public on = (
    evtStr: string | number,
    handler: Konva.KonvaEventListener<any, any>
  ): void => {
    const anchorEvtStr = this.getAnchorEvtStr(evtStr);
    if (this.eventListeners[anchorEvtStr] === undefined) {
      this.eventListeners[anchorEvtStr] = [];
    }
    this.eventListeners[anchorEvtStr].push(handler);
  };

  public onDestroy = (): void => {
    this.clearLines();
    this.connectionHelper.onDestroy();
    this.containerAttachmentHelper.onDestroy();
    this.unifiedViewer.removeEventListener(
      UnifiedViewerEventType.ON_ZOOM_CHANGE,
      this.applyScaleToLineAnchors
    );
    this.unifiedViewer.removeEventListener(
      UnifiedViewerEventType.ON_ZOOM_END,
      this.applyScaleToLineAnchors
    );
  };
}

export default LineTransformer;
