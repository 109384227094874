import Konva from 'konva';
import cloneDeep from 'lodash/cloneDeep';

import { Container } from '../containers';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import type { UnifiedViewer } from '../UnifiedViewer';
import UnifiedViewerRenderer from '../UnifiedViewerRenderer';
import getInitials from '../utils/getInitials';
import shamefulSafeKonvaScale from '../utils/shamefulSafeKonvaScale';

import {
  COMMENT_FONT_SIZE_PX,
  COMMENT_PADDING_PX,
  DEFAULT_COMMENT_BACKGROUND_COLOR,
  DEFAULT_COMMENT_CORNER_RADIUS,
  DEFAULT_COMMENT_STROKE,
  DEFAULT_COMMENT_STROKE_WIDTH,
  DEFAULT_COMMENT_TEXT_COLOR,
} from './constants';
import getNormalizedDimensionsFromNode from './getNormalizedDimensionsFromNode';
import setAnnotationNodeEventHandlers from './setAnnotationNodeEventHandlers';
import shouldBeDraggable from './shouldBeDraggable';
import shouldBeSelectable from './shouldBeSelectable';
import {
  AnnotationSerializer,
  AnnotationType,
  CommentAnnotation,
  CommentStyleProperties,
  Scale,
} from './types';

// NOTE: We assume that the comment is a square with the same area as the font size
export const getApproximateCommentAnnotationSize = (
  annotation: CommentAnnotation
): number =>
  2 * COMMENT_FONT_SIZE_PX +
  COMMENT_PADDING_PX +
  (annotation.style?.borderWidth ?? 0);

const isValidCommentAnnotation = (node: Konva.Node): node is Konva.Label =>
  node instanceof Konva.Label;

type CommentDimensions = { x: number; y: number; fontSize: number };

const getDimensions = (
  annotation: CommentAnnotation,
  getContainerById: (id: string) => Container | undefined
): CommentDimensions | undefined => {
  if (annotation.containerId === undefined) {
    return { x: annotation.x, y: annotation.y, fontSize: COMMENT_FONT_SIZE_PX };
  }

  const container = getContainerById(annotation.containerId);
  if (container === undefined) {
    return undefined;
  }

  const containerRect = container.getContentNode().size();
  return {
    x: annotation.x * containerRect.width,
    y: annotation.y * containerRect.height,
    fontSize: COMMENT_FONT_SIZE_PX,
  };
};

type CommentNodeProperties<MetadataType> = {
  [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION;
  annotationType: AnnotationType.COMMENT;
  id: string;
  author: string;
  containerId?: string;
  isSelectable: boolean;
  isDraggable: boolean;
  isResizable: false;
  metadata?: MetadataType;
  x: number;
  y: number;
  scale: Scale;
} & CommentStyleProperties;

type TagNodeProperties = {
  fill: string | undefined;
  stroke: string | undefined;
  strokeWidth: number | undefined;
  cornerRadius: [number, number, number, number];
};
type TextNodeProperties = {
  text: string;
  fill: string;
  fontSize: number;
  padding: number;
};
type NodeProperties<MetadataType> = {
  commentNodeProperties: CommentNodeProperties<MetadataType>;
  tagNodeProperties: TagNodeProperties;
  textNodeProperties: TextNodeProperties;
};

const getNodePropertiesFromAnnotation = <MetadataType>(
  annotation: CommentAnnotation<MetadataType>,
  unifiedViewer: UnifiedViewer,
  unifiedViewerRenderer: UnifiedViewerRenderer
): NodeProperties<MetadataType> | undefined => {
  const dimensions = getDimensions(
    annotation,
    unifiedViewerRenderer.getContainerById
  );
  if (dimensions === undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      `Could not get dimensions for annotation ${annotation.id}. If it has a container, maybe the container hasn't loaded yet?`
    );
    return undefined;
  }

  const currentScale = unifiedViewer.getScale();
  const commentNodeProperties: CommentNodeProperties<MetadataType> = {
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.ANNOTATION,
    annotationType: AnnotationType.COMMENT,
    id: annotation.id,
    containerId: annotation.containerId,
    isSelectable: shouldBeSelectable(annotation.isSelectable),
    isDraggable: shouldBeDraggable(annotation.isDraggable),
    isResizable: false,
    author: annotation.author,
    metadata: annotation.metadata,
    x: dimensions.x,
    y: dimensions.y,
    // Scale the node by the inverse of the current stage scale to keep the same size on the screen
    scale: { x: 1.0 / currentScale, y: 1.0 / currentScale },
  };

  const tagNodeProperties: TagNodeProperties = {
    fill: annotation.style?.backgroundColor ?? DEFAULT_COMMENT_BACKGROUND_COLOR,
    stroke: annotation.style?.borderColor ?? DEFAULT_COMMENT_STROKE,
    strokeWidth: annotation.style?.borderWidth ?? DEFAULT_COMMENT_STROKE_WIDTH,
    cornerRadius: DEFAULT_COMMENT_CORNER_RADIUS,
  };

  const textNodeProperties: TextNodeProperties = {
    text: getInitials(annotation.author),
    padding: COMMENT_PADDING_PX,
    fill: annotation.style?.color ?? DEFAULT_COMMENT_TEXT_COLOR,
    fontSize: dimensions.fontSize,
  };

  return { commentNodeProperties, tagNodeProperties, textNodeProperties };
};

type TransformationProperties = {
  scale: Scale;
  Text: { fontSize: number; padding: number };
};

const CommentSerializer: AnnotationSerializer<
  CommentAnnotation,
  TransformationProperties
> = {
  isSelectable: (node: Konva.Node) =>
    shouldBeSelectable(node.attrs.isSelectable),

  isDraggable: (node: Konva.Node) => shouldBeDraggable(node.attrs.isDraggable),

  isResizable: () => false,

  getTransformationPropertiesFromNode: (node: Konva.Node) => {
    if (!isValidCommentAnnotation(node)) {
      throw new Error('Expected node to be a Konva.Label');
    }
    return {
      scale: shamefulSafeKonvaScale(node.scale()),
      Text: {
        fontSize: node.getText().fontSize(),
        padding: node.getText().padding(),
      },
    };
  },

  normalize: ({ Text }) => ({ Text, scale: { x: 1, y: 1 } }),

  serialize: (node, unifiedViewerRenderer) => {
    if (!isValidCommentAnnotation(node)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    const tag = node.getTag();
    const text = node.getText();
    return {
      id: node.id(),
      type: AnnotationType.COMMENT,
      containerId: node.attrs.containerId,
      author: node.attrs.author,
      isSelectable: shouldBeSelectable(node.attrs.isSelectable),
      isDraggable: shouldBeDraggable(node.attrs.isDraggable),
      isResizable: false,
      ...getNormalizedDimensionsFromNode(
        node,
        unifiedViewerRenderer.getContainerRectRelativeToStageById
      ),
      style: {
        color: text.fill(),
        backgroundColor: tag.fill(),
        borderColor: tag.stroke(),
        borderWidth: tag.strokeWidth(),
        borderRadius: tag.cornerRadius(),
      },
      metadata: cloneDeep(node.attrs.metadata),
    };
  },

  deserialize: (annotation, unifiedViewer, unifiedViewerRenderer) => {
    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewer,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return undefined;
    }

    const label = new Konva.Label(nodeProperties.commentNodeProperties);
    label.add(new Konva.Tag(nodeProperties.tagNodeProperties));
    label.add(new Konva.Text(nodeProperties.textNodeProperties));
    label.offset({ x: label.width() / 2, y: label.height() / 2 });

    return setAnnotationNodeEventHandlers(
      label,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },

  updateNode(node, annotation, unifiedViewer, unifiedViewerRenderer): void {
    if (!isValidCommentAnnotation(node)) {
      throw new Error('Expected node to be a Konva.Label');
    }

    const nodeProperties = getNodePropertiesFromAnnotation(
      annotation,
      unifiedViewer,
      unifiedViewerRenderer
    );
    if (nodeProperties === undefined) {
      return;
    }

    node.setAttrs(nodeProperties.commentNodeProperties);
    node.getChildren().forEach((child) => {
      if (child instanceof Konva.Tag) {
        child.setAttrs(nodeProperties.tagNodeProperties);
      }

      if (child instanceof Konva.Text) {
        child.setAttrs(nodeProperties.textNodeProperties);
      }
    });
    node.offset({ x: node.width() / 2, y: node.height() / 2 });

    setAnnotationNodeEventHandlers(
      node,
      annotation,
      [
        { event: 'click', handler: annotation.onClick },
        { event: 'mousedown', handler: annotation.onMouseDown },
        { event: 'mouseup', handler: annotation.onMouseUp },
        { event: 'mouseover', handler: annotation.onMouseOver },
        { event: 'mouseout', handler: annotation.onMouseOut },
      ],
      unifiedViewer
    );
  },
};

export default CommentSerializer;
