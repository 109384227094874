import omit from 'lodash/omit';

import { Data } from '@cognite/plotting-components';
import { Aggregate, CogniteClient } from '@cognite/sdk';

import calculateGranularity from './calculateGranularity';
import { getDataFetchMode } from './getDataFetchMode';
import { CHART_POINTS_PER_SERIES } from './getTimeseriesChartData';
import { mapToChartData } from './mapToChartData';
import { DateRange } from './types';

type CdmDatapointResposeType = {
  id: string;
  instanceId: {
    externalId: string;
    space: string;
  };
  isString: boolean;
  isStep: boolean;
  unitExternalId: string;
  datapoints: {
    timestamp: Date;
    value: number;
  }[];
};

export type TimeseriesCdmDatapoints = Omit<
  CdmDatapointResposeType,
  'datapoints'
> & {
  data: Data;
};

const fetchDatapoints = async (
  sdk: CogniteClient,
  query: {
    instanceId: {
      externalId: string;
      space: string;
    };
    start: number;
    end: number;
    limit: number;
    granularity?: string;
    aggregates?: Aggregate[];
  }
) => {
  const { instanceId, start, end, limit, granularity, aggregates } = query;

  const response = await sdk.post<{ items: CdmDatapointResposeType[] }>(
    `api/v1/projects/${sdk.project}/timeseries/data/list`,
    {
      data: {
        items: [
          {
            instanceId,
          },
        ],
        start,
        end,
        limit,
        granularity,
        aggregates,
      },
      headers: {
        'cdf-version': 'alpha',
      },
    }
  );

  return response.data.items[0];
};

export const getTimeseriesCdmDatapoints = async (
  sdk: CogniteClient,
  {
    query,
  }: {
    query: {
      instanceId: string;
      instanceSpace: string;
      dateRange: DateRange;
      numberOfPoints?: number;
    };
  }
): Promise<TimeseriesCdmDatapoints> => {
  const {
    instanceId,
    instanceSpace,
    dateRange: [startDate, endDate],
    numberOfPoints: limit = CHART_POINTS_PER_SERIES,
  } = query;

  const start = startDate.getTime();
  const end = endDate.getTime();
  const granularity = calculateGranularity([startDate, endDate], limit);

  const countedDatapoints = await fetchDatapoints(sdk, {
    aggregates: ['count'],
    instanceId: {
      externalId: instanceId,
      space: instanceSpace,
    },
    start,
    end,
    limit,
    granularity,
  });

  const dataFetchMode = getDataFetchMode({
    numberOfPoints: countedDatapoints.datapoints.length,
    isString: countedDatapoints.isString,
  });

  const adjustedDatapoints = await fetchDatapoints(sdk, {
    start,
    end,
    limit,
    instanceId: {
      externalId: instanceId,
      space: instanceSpace,
    },
    ...(dataFetchMode === 'aggregate'
      ? {
          aggregates: ['average', 'min', 'max', 'count'],
          granularity,
        }
      : {}),
  });

  const mappedToChartData = mapToChartData({
    datapoints: adjustedDatapoints.datapoints,
    metadata: {
      isStep: adjustedDatapoints.isStep,
      isString: adjustedDatapoints.isString,
      unit: adjustedDatapoints.unitExternalId,
      dataFetchMode,
      numberOfPoints: adjustedDatapoints.datapoints.length,
    },
  });

  return { data: mappedToChartData, ...omit(adjustedDatapoints, 'datapoints') };
};
