import type { InstanceId } from '@cognite/apm-client/src/file/types';

export const valueAsInstanceId = (
  value: string | number | boolean | InstanceId | undefined
): InstanceId | undefined => {
  if (
    value &&
    typeof value === 'object' &&
    'externalId' in value &&
    'space' in value
  ) {
    return value satisfies InstanceId;
  }
  return undefined;
};
