import { type FC, type ReactNode } from 'react';

import { ArrowLeftIcon, type StatusChipProps } from '@cognite/cogs.js-v10';
import { Button, Heading, StatusChip } from '@cognite/cogs.js-v10';

import * as S from './elements';

type Props = {
  title: string | JSX.Element;
  chipProps?: StatusChipProps;
  children?: ReactNode;
  titleTestId?: string;
  onClickBack?: () => void;
};

export const Header: FC<Props> = ({
  title,
  chipProps,
  children,
  titleTestId,
  onClickBack,
}) => (
  <S.Container>
    <S.LeftSideWrapper>
      {onClickBack && (
        <Button
          data-testid="header-back-button"
          icon={<ArrowLeftIcon />}
          onClick={onClickBack}
        />
      )}
      <Heading data-testid={titleTestId} level={5}>
        {title}
      </Heading>
      {chipProps && (
        <StatusChip {...chipProps} variant="outline" size="small" />
      )}
    </S.LeftSideWrapper>
    <S.RightSideWrapper>{children}</S.RightSideWrapper>
  </S.Container>
);
