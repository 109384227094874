import { AuthClient, User } from '@cognite/auth-client';
import {
  cogniteIdPSignInRedirect,
  getBaseUrl,
  getCogniteIdPUserManager,
  getRequiredOrganization,
  isPreviewDeployment,
  setRedirectCookieForPreviewDeployment,
} from '@cognite/login-utils';

import { UserInfo } from './types';

export const getUser = async (
  userManager: AuthClient,
  signInIfUnAuthenticated: boolean
): Promise<User> => {
  const user = await userManager.getUser();
  if (!user) {
    const organization = getRequiredOrganization();
    if (signInIfUnAuthenticated) {
      await cogniteIdPSignInRedirect(userManager, organization);
    }
    throw new Error('User not found');
  }
  return user;
};

/**
 * This is set up to avoid parallel token refresh requests.
 * See the keycloak implementation for more details.
 */
let getAccessTokenPromise: Promise<string> | undefined;
export const getAccessToken = async (params: {
  authority: string;
  clientId: string;
}) => {
  if (getAccessTokenPromise) {
    return getAccessTokenPromise;
  }
  getAccessTokenPromise = new Promise<string>((resolve, reject) => {
    const userManager = getCogniteIdPUserManager({
      authority: params.authority,
      client_id: params.clientId,
    });
    userManager
      .getAccessToken()
      .then((token) => {
        if (!token) {
          throw new Error('No token found');
        }
        resolve(token);
      })
      .catch(reject);
  });

  getAccessTokenPromise.then(() => {
    getAccessTokenPromise = undefined;
  });
  return getAccessTokenPromise;
};

export const getUserInfo = async (params: {
  authority: string;
  clientId: string;
}): Promise<UserInfo> => {
  const userManager = getCogniteIdPUserManager({
    authority: params.authority,
    client_id: params.clientId,
  });
  const user = await getUser(userManager, false);
  return {
    id: user.id,
    displayName: user.name,
    mail: user.email,
    userPrincipalName: user.id,
    profilePicture: user.picture,
  };
};

export const logout = async (params: {
  authority: string;
  clientId: string;
}): Promise<void> => {
  const userManager = getCogniteIdPUserManager({
    authority: params.authority,
    client_id: params.clientId,
  });

  const redirectUri = getBaseUrl();

  if (isPreviewDeployment) {
    setRedirectCookieForPreviewDeployment(redirectUri);
    await userManager.signoutRedirect({
      redirectUri: 'https://oauth.preview.cogniteapp.com/signout/callback',
    });
  } else {
    await userManager.signoutRedirect({ redirectUri });
  }
};
