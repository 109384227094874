import type { APMClient } from '@cognite/apm-client';
import { FDMClient } from '@cognite/fdm-client';
import type { Filters, Sort } from '@cognite/fdm-client/src/types';
import type { CogniteClient } from '@cognite/sdk';

export class NotificationService extends FDMClient {
  apmClient: APMClient;
  modelSpace: string;
  modelName: string;
  modelVersion: string;
  constructor(client: CogniteClient, apmClient: APMClient) {
    super(client);
    this.apmClient = apmClient;
    this.modelSpace = apmClient.sourceDataModelSpace;
    this.modelName = apmClient.sourceDataModelName;
    this.modelVersion = apmClient.sourceDataModelVersion;
  }

  async getNotificationDetails(filters: Filters) {
    const {
      items: [notification],
    } = await this.apmClient.notifications.list({ filters });
    return notification;
  }

  async getNotifications(filters?: Filters, sort?: Sort, pageSize = 200) {
    const { items: notifications } = await this.apmClient.notifications.list({
      filters,
      sort,
      pageSize,
      includeNullInDescSort: true,
    });
    return notifications;
  }
}
