import Konva from 'konva';
import { cloneDeep, isEqual, pick } from 'lodash';
import ReactDOMClient from 'react-dom/client';
import { v4 as uuid } from 'uuid';

import html2canvas from '../../../vendor/html2canvas/src';
import shouldBeDraggable from '../../annotations/shouldBeDraggable';
import shouldBeResizable from '../../annotations/shouldBeResizable';
import shouldBeSelectable from '../../annotations/shouldBeSelectable';
import type { Size } from '../../annotations/types';
import { LoadingSpinner } from '../../components';
import {
  IdsByType,
  UNIFIED_VIEWER_NODE_TYPE_KEY,
  UnifiedViewerNodeType,
} from '../../types';
import { UnifiedViewer, UpdateRequestSource } from '../../UnifiedViewer';
import UnifiedViewerEventType from '../../UnifiedViewerEventType';
import assertNever from '../../utils/assertNever';
import delayMs from '../../utils/delay';
import { AbortError } from '../../utils/errors';
import EventEmitter from '../../utils/EventEmitter';
import { isPrimaryMouseButtonPressed } from '../../utils/eventUtils';
import getMetricsLogger, {
  TrackedEventType,
} from '../../utils/getMetricsLogger';
import isEmptyCanvas, { WHITE_PIXEL_VALUE } from '../../utils/isEmptyCanvas';
import { isMobileOrTablet } from '../../utils/isMobileOrTablet';
import shamefulSafeKonvaScale from '../../utils/shamefulSafeKonvaScale';
import {
  LoadingStatus,
  TLoadingStatus,
  defaultLoadingStatus,
} from '../asyncContainerUtils';
import BoundaryRect from '../BoundaryRect';
import { CONTAINER_REF_KEY } from '../constants';
import { Container } from '../Container';
import { ContainerEventType } from '../ContainerEventType';
import { ContainerType } from '../ContainerType';
import { EventListenerMap } from '../EventListenerMap';
import isSingularlySelected from '../isSingularlySelected';
import getQueuedScreenshotTaskRunner from '../ScreenshotTaskRunner';
import setContainerNodeEventHandlers from '../setContainerNodeEventHandlers';
import shouldEnterActiveMode from '../shouldEnterActiveMode';
import { ContainerConfig } from '../types';
import { getHeaderGroup } from '../utils';

import { ReactContainerProps, ReactContainerRenderContentProps } from './types';

import DD = Konva.DD;

const callIfAffectedContainer =
  (fn: () => void, containerId: string) => (ids: IdsByType) => {
    if (ids.containerIds.includes(containerId)) {
      fn();
    }
  };

// This is shameful because this is going to break for different types of components and timings
// Essentially we want to know when the component has finished rendering, but we don't have a
// good way to do that
const SHAMEFUL_DELAY_SCREENSHOT_CAPTURE_MS = 300;
const SHOULD_RESCALE = true;
const HTML_NODE_ID_ATTRIBUTE_NAME = 'data-ufv-container-node-id';
const EMPTY_CANVAS_REATTEMPT_DELAY_MS = 500;
const SHAMEFUL_ACTIVE_MODE_ENCFORCEMENT_INTERVAL_MS = 500;

// This cannot be reliably checked at runtime without crashing mobile devices
const SAFE_MAX_CANVAS_DIMENSION = isMobileOrTablet ? 4096 : 8192;

// By default, we'll upscale the screenshots by this factor to ensure that the quality is sufficent
const DEFAULT_UPSCALING_FACTOR =
  2 * (document.defaultView?.devicePixelRatio ?? 1);

// The outputted canvas size can be slightly larger than the actual size of the DOM node
// due to borders etc, so we'll add a margin to the maximum canvas size to ensure that we
// don't go over the limit.
const MAX_CANVAS_SIZE_MARGIN_FACTOR = 0.99;

export default abstract class ReactContainer<
    MetadataType,
    ContainerPropType extends ReactContainerProps<MetadataType>
  >
  extends EventEmitter<EventListenerMap>
  implements Container<MetadataType>
{
  public abstract readonly type: ContainerType;
  public readonly id: string;
  private isReady: boolean = false;
  private group: Konva.Group = new Konva.Group({
    [CONTAINER_REF_KEY]: this,
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.CONTAINER_GROUP,
  });
  private headerGroup: Konva.Group = new Konva.Group();
  private contentGroup: Konva.Group = new Konva.Group({
    id: uuid(),
    [UNIFIED_VIEWER_NODE_TYPE_KEY]:
      UnifiedViewerNodeType.CONTAINER_CONTENT_GROUP,
  });
  private boundaryRect?: BoundaryRect;

  protected props: ContainerPropType;
  protected readonly shouldRefreshScreenshotWhileLoading: boolean = false;
  protected readonly shouldRefreshScreenshotWhileInActiveMode: boolean = true;
  protected readonly domNode: HTMLDivElement;
  private readonly domNodeRoot: ReactDOMClient.Root;
  protected unifiedViewer: UnifiedViewer;
  protected isHidden: boolean = true;
  private domNodeImage: Konva.Image = new Konva.Image({
    image: undefined,
    width: 0,
    height: 0,
    [UNIFIED_VIEWER_NODE_TYPE_KEY]:
      UnifiedViewerNodeType.CONTAINER_DOM_IMAGE_NODE,
  });
  private removeListenerFns: (() => void)[] = [];
  private isSelected: boolean = false;
  private shouldBeActive: boolean = false;
  // For a period of time, the rendered dates might be different from the serialized date,
  // hence they are kept here until we have notified the application layer.
  protected loadingStatus: TLoadingStatus = defaultLoadingStatus;
  protected loadingSpinner?: LoadingSpinner;

  private unscaledWidth: number;
  private unscaledHeight: number;

  public constructor(props: ContainerPropType, unifiedViewer: UnifiedViewer) {
    super();
    this.id = props.id ?? uuid();
    this.getNode().id(this.id);
    this.getNode().add(this.headerGroup);
    this.getNode().add(this.contentGroup);
    this.boundaryRect = new BoundaryRect({
      width: 0,
      height: 0,
      fill: '#ffffff',
    });
    this.unifiedViewer = unifiedViewer;
    this.getContentNode().add(this.boundaryRect.getNode());
    this.getContentNode().add(this.domNodeImage);
    this.recreateLoadingSpinner();

    this.domNode = document.createElement('div');
    this.domNode.id = this.id;
    this.domNode.setAttribute(HTML_NODE_ID_ATTRIBUTE_NAME, this.id);
    this.domNodeRoot = ReactDOMClient.createRoot(this.domNode);
    this.unifiedViewer.host.appendChild(this.domNode);

    // The first width and height is used as a reference for the unscaled width and height
    // in the near future, this might be intrinsic to the rendered component instead, which
    // is why it was not exposed via props. Also, it's possible to do some weird stuff
    // by changing the width and height to not have the same proportions as the unscaled
    // dimensions which makes the expected behaviour difficult to determine.
    this.unscaledWidth = props.unscaledWidth ?? props.width;
    this.unscaledHeight = props.unscaledHeight ?? props.height;
    this.setContainerDimensions(this.unscaledWidth, this.unscaledHeight);

    this.props = props;

    this.attachDomNodeFocusModeListeners();
    this.attachEventListeners(this.unifiedViewer);

    // The constructor must complete before the inherited method renderContent is available
    setTimeout(() => {
      // TODO(FUS-000): There's a bug in the code that keeps track of the ready state
      // https://cognitedata.atlassian.net/browse/UFV-433
      this.setIsReady(true);

      this.props = { ...props };
      this.setProps(props);
      this.refreshDomNodeScreenshotNextTick();
    }, 0);

    if (this.unifiedViewer.options.shamefulShouldUseAlwaysActiveMode) {
      setInterval(() => {
        if (this.isHidden) {
          this.showDomNode();
        }
      }, SHAMEFUL_ACTIVE_MODE_ENCFORCEMENT_INTERVAL_MS);
    }
  }

  private getUnscaledSize = (): Size => {
    return {
      width: this.unscaledWidth ?? this.props.width,
      height: this.unscaledHeight ?? this.props.height,
    };
  };

  private getUnscaledContentSize = () => {
    const { height: nodeHeight } = this.getNode().getClientRect({
      skipTransform: true,
    });

    const { height: headerNodeHeight } = this.getHeaderNodeDimensions();

    const { width, height } = this.getUnscaledSize();
    const scaleY = nodeHeight / height;

    return { width, height: height - headerNodeHeight / scaleY };
  };

  private attachDomNodeFocusModeListeners = (): void => {
    this.getNode().on('mousedown', (event) => {
      if (!isPrimaryMouseButtonPressed(event.evt)) {
        return;
      }
      if (this.isSelected) {
        this.shouldBeActive = true;
      }
    });

    this.getNode().on('mouseup', (event) => {
      if (
        shouldEnterActiveMode(
          DD.justDragged,
          this.shouldBeActive,
          this.isHidden,
          this.unifiedViewer
        )
      ) {
        event.cancelBubble = true;
        this.showDomNode();
      }
    });
  };

  private attachEventListeners = (unifiedViewer: UnifiedViewer): void => {
    this.removeListenerFns = [
      ...this.removeListenerFns,
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_PAN_MOVE,
        this.handleConditionalSyncing()
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_PAN_END,
        this.handleConditionalSyncing()
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_ZOOM_START,
        this.handleConditionalSyncing()
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_ZOOM_CHANGE,
        this.handleConditionalSyncing()
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_ZOOM_END,
        this.handleConditionalSyncing()
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_DRAG_START,
        callIfAffectedContainer(this.handleConditionalSyncing(false), this.id)
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_DRAG_MOVE,
        callIfAffectedContainer(this.handleConditionalSyncing(false), this.id)
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_DRAG_END,
        callIfAffectedContainer(this.handleConditionalSyncing(true), this.id)
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_TRANSFORM_CHANGE,
        callIfAffectedContainer(this.handleConditionalSyncing(true), this.id)
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_TRANSFORM_END,
        callIfAffectedContainer(this.handleConditionalSyncing(true), this.id)
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_SELECT,
        (idsByType) => {
          this.setIsSelected(isSingularlySelected(this.id, idsByType));
          return this.isSelected;
        }
      ),
      unifiedViewer.addEventListener(
        UnifiedViewerEventType.ON_CONTEXT_MENU,
        (_, idsByType) => {
          this.hideDomNode();
          this.shouldBeActive = false;
          this.isSelected = isSingularlySelected(this.id, idsByType);
        }
      ),
    ];
  };

  private handleConditionalSyncing =
    (shouldHideDomNode: boolean = false) =>
    () => {
      if (shouldHideDomNode) {
        this.hideDomNode();
      }

      this.rerenderContent();
    };

  private syncActiveModeSelection = () => {
    const transformer = this.unifiedViewer.getTransformer();

    if (transformer === undefined) {
      return;
    }

    if (this.isHidden) {
      transformer.unregisterActiveModeForNodeId(this.getNode().id());
      return;
    }

    transformer.registerActiveModeForNodeId(this.getNode().id());
  };

  /**
   *
   * @param shouldRescale if true, the content will rescale in size, otherwise the content
   * scale will be maintained and the container will resize.
   * @returns the width and height of the content, should be passed to the content node
   */
  private syncDomNode(shouldRescale: boolean = SHOULD_RESCALE): Size {
    this.syncActiveModeSelection();
    const { x, y } = this.getContentNode().getClientRect({});
    const { width, height } = this.getContentNode().getClientRect({
      skipTransform: true,
    });

    const nodeScale = shamefulSafeKonvaScale(this.getNode().scale());
    const stageScale = this.unifiedViewer.stage.scaleX();
    this.domNode.style.position = 'absolute';
    this.domNode.style.width = `${width}px`;
    this.domNode.style.height = `${height}px`;

    this.domNode.style.transform = `translate(${x}px, ${y}px) scale(${
      shouldRescale ? stageScale * nodeScale.x : stageScale
    })`;

    this.domNode.style.transformOrigin = 'top left';
    this.domNode.style.zIndex = this.isHidden ? '-1' : 'auto';
    this.domNode.tabIndex = 0;

    return {
      width: shouldRescale ? width : width * nodeScale.x,
      height: shouldRescale ? height : height * nodeScale.y,
    };
  }

  private hideDomNodeInternal = async (): Promise<boolean> => {
    if (this.isHidden) {
      // Poyan 29/1-2024: Not sure why, but the domNodeImage is not always visible when the
      // container is hidden. This is an additional check to make sure it is visible.
      if (!this.domNodeImage.visible()) {
        this.domNodeImage.show();
      }
      return false;
    }

    await this.refreshDomNodeScreenshot();
    this.isHidden = true;
    this.syncDomNode();
    this.domNodeImage.show();
    return true;
  };

  public async hideDomNode(): Promise<boolean> {
    const didHide = await this.hideDomNodeInternal();
    if (didHide) {
      this.onDomNodeHidden();
    }
    return didHide;
  }

  protected onDomNodeHidden = (): void => {};

  private refreshDomNodeScreenshotNextTick = () => {
    if (
      !this.shouldRefreshScreenshotWhileLoading &&
      this.loadingStatus.isLoading
    ) {
      return;
    }
    this.rerenderContent(true);

    if (!this.shouldRefreshScreenshotWhileInActiveMode && this.shouldBeActive) {
      return;
    }
    // When the DOM-node has received new props that requires it to re-render, we delay the
    // capturing of the updated screenshot to allow for this to happen first.
    setTimeout(() => {
      this.refreshDomNodeScreenshot();
    }, SHAMEFUL_DELAY_SCREENSHOT_CAPTURE_MS);
  };

  protected async refreshDomNodeScreenshot(): Promise<void> {
    try {
      const nextDomNodeImage = await this.captureDomNodeScreenshot();
      this.replaceDomNodeScreenshot(nextDomNodeImage);
    } catch (e) {
      if (e instanceof AbortError) {
        // eslint-disable-next-line no-console
        console.debug(`Aborting screenshot capture for container ${this.id}`);
        return;
      }
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  public showDomNode = (): boolean => {
    if (!this.isHidden) {
      return false;
    }

    this.isHidden = false;
    this.domNodeImage.hide();

    this.syncDomNode();
    return true;
  };

  public replaceDomNodeScreenshot = (
    nextImageNode: Konva.Image | undefined
  ): void => {
    if (nextImageNode === undefined) {
      return;
    }

    const { width, height } = this.getContentNode().getClientRect({
      skipTransform: true,
    });
    this.domNodeImage.image(nextImageNode.image());
    this.domNodeImage.size({ width, height });
    this.domNodeImage.setAttr(
      UNIFIED_VIEWER_NODE_TYPE_KEY,
      UnifiedViewerNodeType.CONTAINER_DOM_IMAGE_NODE
    );
  };

  private setIsSelected = (nextIsSelected: boolean): void => {
    if (!nextIsSelected) {
      this.hideDomNode();
      this.shouldBeActive = false;
    }

    this.isSelected = nextIsSelected;
  };

  /**
   * Determines what scale we need to render the screenshot at to ensure that we
   * don't go beyond supported canvas dimensions limits of the browser.
   */
  protected getCanvasOutputScale = async (): Promise<number> => {
    const computedStyle = getComputedStyle(this.domNode);
    const domNodeHeight = parseInt(computedStyle.getPropertyValue('height'));
    const domNodeWidth = parseInt(computedStyle.getPropertyValue('width'));

    const scaledDomNodeWidth = domNodeWidth * DEFAULT_UPSCALING_FACTOR;
    const scaledDomNodeHeight = domNodeHeight * DEFAULT_UPSCALING_FACTOR;

    const shouldDownScale =
      scaledDomNodeWidth > SAFE_MAX_CANVAS_DIMENSION ||
      scaledDomNodeHeight > SAFE_MAX_CANVAS_DIMENSION;

    return shouldDownScale
      ? Math.min(
          SAFE_MAX_CANVAS_DIMENSION / scaledDomNodeWidth,
          SAFE_MAX_CANVAS_DIMENSION / scaledDomNodeHeight
        ) * MAX_CANVAS_SIZE_MARGIN_FACTOR
      : DEFAULT_UPSCALING_FACTOR;
  };

  protected async captureFn(): Promise<HTMLCanvasElement | undefined> {
    return html2canvas(this.domNode, {
      logging: false,
      scale: await this.getCanvasOutputScale(),
      cacheKey: 'ReactContainer',
      cacheElementReplaceSelector: `#${CSS.escape(this.id)}`,
      removeContainer: false,
      shouldForceSkipStyleCloning: this.shouldForceSkipStyleCloning,
      onclone: (_, clonedDomNode) => {
        // Remove added transformations to avoid inadvertently going beyond the maximum
        // canvas size
        clonedDomNode.style.transform = '';
        this.onClone(clonedDomNode);
      },
    });
  }

  protected async isCapturedCanvasEmpty(
    canvas: HTMLCanvasElement
  ): Promise<boolean> {
    return isEmptyCanvas(canvas, WHITE_PIXEL_VALUE);
  }

  protected async safelyCaptureScreenshot(
    attempt = 0,
    maxAttempts = 10
  ): Promise<HTMLCanvasElement> {
    const capturedScreenshot = await this.captureFn();

    if (
      capturedScreenshot !== undefined &&
      !(await this.isCapturedCanvasEmpty(capturedScreenshot))
    ) {
      return capturedScreenshot;
    }

    if (attempt >= maxAttempts) {
      throw new Error(`Failed to capture screenshot after ${attempt} attempts`);
    }

    await delayMs(EMPTY_CANVAS_REATTEMPT_DELAY_MS);
    return this.safelyCaptureScreenshot(attempt + 1, maxAttempts);
  }

  protected async captureDomNodeScreenshot(): Promise<Konva.Image | undefined> {
    return getQueuedScreenshotTaskRunner().schedule(
      async () => {
        try {
          const t1 = performance.now();
          const canvas = await this.safelyCaptureScreenshot();
          const t2 = performance.now();

          getMetricsLogger()?.trackEvent(
            TrackedEventType.PERFORMANCE_REACT_CONTAINER_SCREENSHOT,
            {
              width: this.props.width,
              height: this.props.height,
              maxWidth: this.props.maxWidth,
              maxHeight: this.props.maxHeight,
              $duration: parseFloat(((t2 - t1) / 1000).toFixed(3)),
              currentScale: this.unifiedViewer.getScale(),
              reactContainerType: this.props.type,
            }
          );

          if (canvas.width === 0 || canvas.height === 0) {
            // Nothing returned from DOM-node screenshot. Since html2canvas doesn't
            // understand everything, this can happen even if content is being rendered.
            return undefined;
          }

          const { width, height } = this.getContentNode().getClientRect({
            skipTransform: true,
          });
          const image = new Konva.Image({
            width,
            height,
            image: canvas,
          });
          return image;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          return undefined;
        }
      },
      {
        key: this.id,
      }
    );
  }

  // By default, the container can be dragged while active
  public canBeDraggedWhileActive(): boolean {
    return true;
  }

  protected abstract renderContent: (
    renderProps: ReactContainerRenderContentProps
  ) => JSX.Element;

  protected setLoadingStatus = (status: LoadingStatus): void => {
    const prevLoadingStatus = { ...this.loadingStatus };
    this.loadingStatus = { ...defaultLoadingStatus, [status]: true };

    if (!isEqual(prevLoadingStatus, this.loadingStatus)) {
      this.onLoadingStatusChange(status);
      this.refreshDomNodeScreenshotNextTick();
    }
  };

  private getContentScale = (): number => {
    const { width: contentWidth, height: contentHeight } =
      this.getContentNode().getClientRect({
        skipTransform: true,
      });
    const { width: unscaledWidth, height: unscaledHeight } =
      this.getUnscaledContentSize();
    return Math.min(
      contentWidth / unscaledWidth,
      contentHeight / unscaledHeight
    );
  };

  protected onContentSizeChange = ({ width, height }: Size): void => {
    const scale = this.getContentScale();
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [
        {
          ...this.serialize(),
          width: width * scale,
          height: height * scale,
          unscaledWidth: width,
          unscaledHeight: height,
        } as ContainerConfig,
      ],
      annotations: [],
      source: UpdateRequestSource.AUTO_RESIZE,
    });
  };

  protected rerenderContent(shouldRerenderWhileHidden = false): void {
    if (!shouldRerenderWhileHidden && this.isHidden) {
      return;
    }

    const { width, height } = this.syncDomNode();
    const { width: unscaledWidth, height: unscaledHeight } =
      this.getUnscaledContentSize();

    this.domNodeRoot.render(
      this.renderContent({
        unscaledWidth,
        unscaledHeight,
        width,
        height,
        shouldAutoSize: this.props.shouldAutoSize === true,
        setLoadingStatus: this.setLoadingStatus,
        onContentSizeChange: this.onContentSizeChange,
      })
    );
  }

  // TODO(FUS-000): Improve naming
  protected abstract getPropsRequiringUpdate(): string[];

  public setProps(props: ContainerPropType): void {
    const prevProps = { ...this.props };
    this.props = props;
    const width = props.width;
    const height = props.height;
    if (
      props.unscaledWidth !== undefined &&
      props.unscaledHeight !== undefined
    ) {
      this.unscaledWidth = props.unscaledWidth;
      this.unscaledHeight = props.unscaledHeight;
    }
    this.setLabel({ width, height });
    this.setContainerDimensions(width, height);

    const propsRequiringUpdate = [
      'width',
      'height',
      ...this.getPropsRequiringUpdate(),
    ];

    if (
      !isEqual(
        pick(prevProps, propsRequiringUpdate),
        pick(props, propsRequiringUpdate)
      )
    ) {
      this.loadingStatus = defaultLoadingStatus;
      this.refreshDomNodeScreenshotNextTick();
    }

    setContainerNodeEventHandlers(
      this.getNode(),
      this.props as unknown as ContainerConfig<any>,
      this.unifiedViewer
    );

    this.recreateLoadingSpinner();
  }

  public getChildren(): Container[] {
    return [];
  }

  public getContentNode(): Konva.Group {
    return this.contentGroup;
  }

  public addToContentNode(node: Konva.Group | Konva.Shape): void {
    this.getContentNode().add(node);
  }

  public setHeight(height: number): void {
    this.getNode().height(height);
  }

  public setWidth(width: number): void {
    this.getNode().width(width);
  }

  public getWidth(): number {
    return this.getContentNode().width();
  }

  public getHeight(): number {
    return (
      this.getContentNode().height() + this.getHeaderNodeDimensions().height
    );
  }

  public setContainerDimensions(width: number, height: number): void {
    this.setWidth(width);
    this.setHeight(height);

    const contentNodeSize = {
      width,
      height: height - this.getHeaderNodeDimensions().height,
    };

    this.getContentNode().size(contentNodeSize);
    this.boundaryRect?.setDimensions(contentNodeSize);
    this.domNodeImage.size(contentNodeSize);
    this.recreateLoadingSpinner();

    this.onContentNodeSizeChange?.(contentNodeSize);
    this.emit(ContainerEventType.ON_LAYOUT_CHANGE);
  }

  public setIsReady(isReady: boolean): void {
    if (this.isReady !== isReady) {
      this.isReady = isReady;
      this.emit(ContainerEventType.ON_READY_STATE_CHANGE, this as Container);
    }
  }

  public getIsReady(): boolean {
    return this.isReady;
  }

  public addChild(_child: Container): void {
    throw new Error(
      'The default addChild method is not implemented for ReactContainer'
    );
  }

  public setLabel({ width, height }: Size): void {
    if (this.props.label !== undefined) {
      this.setHeader(getHeaderGroup(this.props.label, { width, height }));
    }
  }

  public getNode(): Konva.Group {
    return this.group;
  }

  public setNode(node: Konva.Group): void {
    this.group = node;

    const contentContainerGroup = node.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_CONTENT_GROUP
    );

    this.contentGroup = contentContainerGroup as Konva.Group;
    const headerGroup = node.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_HEADER_GROUP
    );

    this.headerGroup = headerGroup as Konva.Group;

    this.boundaryRect?.setNode(
      this.contentGroup.children?.find(
        (n) =>
          n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
          UnifiedViewerNodeType.CONTAINER_BOUNDARY_RECT
      ) as Konva.Rect
    );

    this.domNodeImage = this.contentGroup.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_DOM_IMAGE_NODE
    ) as Konva.Image;
  }

  public getHeaderNodeDimensions(): Size {
    if (this.headerGroup === undefined) {
      return {
        width: 0,
        height: 0,
      };
    }
    // NOTE: we ignore the transform when computing the client rect for the
    // header group. This is to fix the issue that the header group seems to get
    // transformed on update (given that the container id's are the same across
    // the update). The behavior for transforming containers (with headers)
    // should be monitored.
    const { width, height } = this.headerGroup.getClientRect({
      skipTransform: true,
    });
    return { width, height };
  }

  public setHeader(header: Konva.Group): void {
    this.headerGroup.destroy();
    this.headerGroup = header;
    this.getNode().add(this.headerGroup);
    this.recalculateLayout();
  }

  private recalculateLayout(): void {
    const { height } = this.getHeaderNodeDimensions();
    this.headerGroup.y(-height);
  }

  protected serializeBaseContainerProps(): Omit<ReactContainerProps, 'type'> {
    const scaleX = this.getNode().scaleX();
    const scaleY = this.getNode().scaleY();
    return {
      id: this.props.id,
      label: this.props.label,
      width: Math.round(this.getWidth() * scaleX),
      height: Math.round(this.getHeight() * scaleY),
      unscaledWidth: this.unscaledWidth,
      unscaledHeight: this.unscaledHeight,
      x: this.getNode().x(),
      y: this.getNode().y(),
      isSelectable: this.props.isSelectable,
      isDraggable: this.props.isDraggable,
      metadata: cloneDeep(this.props.metadata),
    };
  }

  public abstract serialize: () => ContainerConfig;

  protected onClone = (_: HTMLElement): void => {};
  protected shouldForceSkipStyleCloning = (_: HTMLElement): boolean => false;

  public isSelectable = (): boolean =>
    shouldBeSelectable(this.props.isSelectable);

  public isDraggable = (): boolean => shouldBeDraggable(this.props.isDraggable);

  public isResizable = (): boolean => shouldBeResizable(this.props.isResizable);

  public onDestroy(): void {
    this.removeListenerFns.forEach((fn) => fn());
    // Unmount the root node asynchronously using setTimeout to avoid a race
    // condition between synchronous mount and asynchronous unmount. Otherwise,
    // it can happen that the component is unmounted right after it has been
    // mounted from a previous render.
    setTimeout(() => this.domNodeRoot.unmount());
    this.domNode.remove();
    this.loadingSpinner?.destroy();
  }

  private removeLoadingSpinners = (): void => {
    const existingLoadingSpinners = this.getContentNode().find(
      (n: Konva.Node) => n instanceof LoadingSpinner
    ) as LoadingSpinner[];
    existingLoadingSpinners.forEach((loadingSpinner) => {
      loadingSpinner?.destroy();
    });
  };

  private recreateLoadingSpinner = (): void => {
    // To avoid getting the loading spinner into a bogus state,
    // re-create it when/if our content node has a loading spinner defined
    this.removeLoadingSpinners();

    if (this.loadingStatus.isLoading) {
      this.loadingSpinner = new LoadingSpinner(this.unifiedViewer);
      this.getContentNode().add(this.loadingSpinner);
      if (this.boundaryRect !== undefined) {
        this.loadingSpinner.centerInsideBoundaryRect(this.boundaryRect);
      }
      this.loadingSpinner.recalculateSize(this.getWidth());
    }
  };

  private onLoadingStatusChange(status: LoadingStatus) {
    if (status === LoadingStatus.ERROR) {
      this.removeLoadingSpinners();
      // eslint-disable-next-line no-console
      console.error(`Load status: ${status}`);
      return;
    }
    if (status === LoadingStatus.LOADING) {
      this.recreateLoadingSpinner();
      return;
    }
    if (status === LoadingStatus.SUCCESS) {
      this.removeLoadingSpinners();
      return;
    }
    assertNever(status, `Unimplemented loading status ${status}`);
  }

  protected onContentNodeSizeChange(_dimensions: Size): void {}
}
