import { useMetrics } from '@cognite/metrics';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { captureException } from '@sentry/browser';
import { useContext, useEffect, useState } from 'react';

import { getIsChecklistDataCorruptContext } from './use-is-checklist-data-corrupt.context';
/**
 * Incident INC-1323 caused some checklists to be corrupted, and we want to lock these down
 * to prevent further corruption. After consulting with Robert Collins, we decided
 * that the most pragmatic approach is to store the list of corrupted checklist externalIds
 * in Unleash, and then check against this list when a checklist is loaded.
 *
 * @param checklistExternalId - The checklist to check for corruption
 * @returns Boolean indicating whether the checklist is corrupted or not.
 */
export const useIsChecklistDataCorrupt = (
  checklistExternalId: string
): boolean => {
  const metrics = useMetrics(METRICS_NAMESPACES.checklists);

  const { useFlag, useVariant } = useContext(
    getIsChecklistDataCorruptContext()
  );
  const { isEnabled: corruptedChecklistsEnabled, isClientReady } = useFlag(
    UnleashKeys.celanese_corrupted_readings,
    { forceRerender: true, fallback: false }
  );

  const corruptedChecklists = useVariant(
    UnleashKeys.celanese_corrupted_readings
  );

  const [isCorrupted, setIsCorrupted] = useState<boolean>(false);

  useEffect(() => {
    if (
      corruptedChecklistsEnabled &&
      isClientReady &&
      corruptedChecklists !== ''
    ) {
      try {
        const parsedChecklists = JSON.parse(corruptedChecklists) as string[];

        // Check if type is array of strings
        if (!Array.isArray(parsedChecklists)) {
          throw new Error('Invalid type');
        }
        for (const checklist of parsedChecklists) {
          if (typeof checklist !== 'string') {
            throw new Error('Invalid type');
          }
        }

        const isCorrupted = parsedChecklists.includes(checklistExternalId);
        setIsCorrupted(isCorrupted);
      } catch (error) {
        captureException(error);
      }
    }
  }, [
    checklistExternalId,
    corruptedChecklists,
    corruptedChecklistsEnabled,
    isClientReady,
  ]);

  // Track in mixpanel if corrupted checklist is opened
  useEffect(() => {
    if (isCorrupted) {
      metrics.track('corrupted-checklist-opened');
    }
  }, [isCorrupted, metrics]);

  return isCorrupted;
};
