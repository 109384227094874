import type { Checklist } from '@cognite/apm-client';
import { useFlag } from '@cognite/react-feature-flags';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import {
  dateFilterAtom,
  selectedOverviewTableAtom,
} from '@infield/features/activities/activity-planning/state';
import { useIsChecklistAdminQuery } from '@infield/features/app-config';
import { useCreatePlannedChecklistsMutation } from '@infield/features/checklist/checklist-scheduler/hooks/use-create-planned-checklists-mutation';
import { useFetchPlannedChecklistsQuery } from '@infield/features/checklist/checklist-scheduler/hooks/use-fetch-planned-checklists-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  getDateRangeFilterWithoutFutureEndDate,
  isChecklistSchedulerFeatureEnabled,
  isDateRangeFilterForScheduledChecklistsValid,
} from '../../features/checklist/checklist-scheduler/utils/utils';

import { ChecklistSchedulerContext } from './checklist-scheduler-context';
import { MissingChecklistsState } from './types';

export const ChecklistSchedulerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isEnabled: isChecklistSchedulerFlagEnabled } = useFlag(
    UnleashKeys.checklist_scheduler,
    { forceRerender: true, fallback: false }
  );
  const { isEnabled: isSchedulesTabEnabled } = useFlag('INFIELD.schedule_tab', {
    forceRerender: true,
    fallback: false,
  });

  const { data: isChecklistAdmin } = useIsChecklistAdminQuery();

  const setSelectedOverviewTable = useSetRecoilState(selectedOverviewTableAtom);
  const selectedDateFilter = useRecoilValue(dateFilterAtom);
  const [missingChecklistsState, setMissingChecklistsState] =
    useState<MissingChecklistsState>(MissingChecklistsState.NONE);
  const [numPlannedChecklists, setNumPlannedChecklists] = useState<number>(0);
  const [numCreatedChecklists, setNumCreatedChecklists] = useState<number>(0);
  const plannedChecklistsRef = useRef<Checklist[]>([]);

  // The date range needs to be maximum 7 days and the start date should be no later than today
  const isDateFilterValid =
    isDateRangeFilterForScheduledChecklistsValid(selectedDateFilter);

  const dateFilterWithoutFutureDays =
    getDateRangeFilterWithoutFutureEndDate(selectedDateFilter);

  const { refetch: refetchPlannedChecklists } = useFetchPlannedChecklistsQuery(
    dateFilterWithoutFutureDays
  );
  const { mutateAsync: createPlannedChecklists } =
    useCreatePlannedChecklistsMutation();

  const isChecklistSchedulerEnabled = isChecklistSchedulerFeatureEnabled(
    isChecklistSchedulerFlagEnabled,
    isChecklistAdmin
  );

  const isSchedulesPageEnabled = isChecklistSchedulerFeatureEnabled(
    isSchedulesTabEnabled,
    isChecklistAdmin
  );

  const resetStates = useCallback(() => {
    plannedChecklistsRef.current = [];
    setNumPlannedChecklists(0);
    setNumCreatedChecklists(0);
  }, []);

  const resetAndCloseBanner = useCallback(() => {
    setMissingChecklistsState(MissingChecklistsState.NONE);
    resetStates();
  }, [resetStates]);

  const fetchPlannedChecklists = useCallback(async (): Promise<void> => {
    try {
      resetStates();
      setMissingChecklistsState(
        MissingChecklistsState.SEARCHING_MISSING_CHECKLISTS
      );
      const { data: fetchedPlannedChecklists } = await refetchPlannedChecklists(
        { throwOnError: true }
      );

      setNumPlannedChecklists(fetchedPlannedChecklists?.length ?? 0);
      plannedChecklistsRef.current = fetchedPlannedChecklists ?? [];
      if (fetchedPlannedChecklists === undefined) {
        setMissingChecklistsState(MissingChecklistsState.NONE);
      } else if (fetchedPlannedChecklists.length === 0) {
        setMissingChecklistsState(MissingChecklistsState.NO_MISSING_CHECKLISTS);
      } else {
        setMissingChecklistsState(MissingChecklistsState.MISSING_CHECKLISTS);
      }
    } catch (error) {
      resetAndCloseBanner();
    }
  }, [
    refetchPlannedChecklists,
    setNumPlannedChecklists,
    resetStates,
    resetAndCloseBanner,
  ]);

  const createChecklists = useCallback(async (): Promise<void> => {
    setMissingChecklistsState(MissingChecklistsState.CREATING_CHECKLISTS);

    let totalCreated = 0;
    let totalFailed = 0;

    for (const checklist of plannedChecklistsRef.current) {
      try {
        const createdChecklist = await createPlannedChecklists([checklist]);
        if (createdChecklist.length === 0) totalFailed++;
        else totalCreated++;
        setNumCreatedChecklists(totalCreated);
      } catch (error) {
        totalFailed++;
      }
    }

    setMissingChecklistsState(
      totalFailed > 0
        ? MissingChecklistsState.FAILED_TO_CREATE_CHECKLISTS
        : MissingChecklistsState.CHECKLISTS_CREATED
    );
  }, [createPlannedChecklists]);

  const retryFetchAndCreateChecklists = useCallback(async (): Promise<void> => {
    await fetchPlannedChecklists();
    await createChecklists();
  }, [fetchPlannedChecklists, createChecklists]);

  const viewCreatedChecklists = useCallback(() => {
    setSelectedOverviewTable('checklists-table');

    // TODO: Set date filter
    // TODO: Filter on created by current user
    // TODO: Close banner?
  }, [setSelectedOverviewTable]);

  useEffect(() => {
    resetAndCloseBanner();
  }, [selectedDateFilter, resetAndCloseBanner]);

  const value = useMemo(
    () => ({
      isDateFilterValid,
      numPlannedChecklists,
      fetchPlannedChecklists,
      createChecklists,
      missingChecklistsState,
      resetAndCloseBanner,
      numCreatedChecklists,
      isChecklistSchedulerEnabled,
      isSchedulesPageEnabled,
      viewCreatedChecklists,
      dateFilterWithoutFutureDays,
      retryFetchAndCreateChecklists,
    }),
    [
      numPlannedChecklists,
      fetchPlannedChecklists,
      createChecklists,
      isDateFilterValid,
      missingChecklistsState,
      resetAndCloseBanner,
      numCreatedChecklists,
      isChecklistSchedulerEnabled,
      isSchedulesPageEnabled,
      viewCreatedChecklists,
      dateFilterWithoutFutureDays,
      retryFetchAndCreateChecklists,
    ]
  );

  return (
    <ChecklistSchedulerContext.Provider value={value}>
      {children}
    </ChecklistSchedulerContext.Provider>
  );
};
