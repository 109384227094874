import { AppConfigClient, AppConfigClientV2 } from '@cognite/apm-config';
import { useAuthContext } from '@cognite/e2e-auth';
import { useMetrics } from '@cognite/metrics';
import { useFlag } from '@cognite/react-feature-flags';
import { UnleashKeys } from '@infield/constants/unleash-keys';
import { defaultAppConfig } from '@infield/features/app-config/default-config';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import type { AppConfig } from '../../types';

export const useAppConfigQuery = () => {
  const coreAuth = useAuthContext();
  const metrics = useMetrics(METRICS_NAMESPACES.configVersion);

  const { isEnabled: isUsingNewConfigSetup } = useFlag(
    UnleashKeys.INAPP_New_Config_Setup,
    { forceRerender: true }
  );

  const appConfigClientV2 = new AppConfigClientV2(coreAuth.client, {
    externalId: 'CDF_AppLocationConfig',
    space: 'cdf_apps_shared',
    version: 'v1',
  });

  const schemaVersionOverride =
    coreAuth.authState?.status === 'AUTHENTICATED'
      ? coreAuth.authState.projectInfo?.configurationSchemaVersion
      : undefined;
  const appConfigClient = new AppConfigClient(
    coreAuth.client,
    schemaVersionOverride
  );

  return useQuery<AppConfig | undefined, Error>(
    [QueryKeys.APP_CONFIG, isUsingNewConfigSetup],
    async () => {
      if (!coreAuth.client.project) throw new Error('No project');

      if (isUsingNewConfigSetup) {
        return appConfigClientV2.list().then((configs) => configs[0]);
      }

      let appConfig = await appConfigClient.getAPM_ConfigV2();
      let appConfigVersion = 'v2';
      if (appConfig === undefined) {
        appConfig = await appConfigClient.getAPM_Config();
        appConfigVersion = 'v1';
      }

      if (!appConfig) {
        // still no appConfig, then it is likely an idm project
        appConfigVersion = 'idm';
      }

      metrics.track('AppConfigVersion', {
        appConfigVersion,
        project: coreAuth.client.project,
      });

      return appConfig || defaultAppConfig;
    },
    {
      onError: (err) =>
        captureException(err, {
          level: 'fatal',
          tags: {
            queryKey: QueryKeys.APP_CONFIG,
          },
        }),
      staleTime: 86400000, // 1 day
      cacheTime: 86400000, // 1 day
    }
  );
};
