import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { useFDMServices } from '@infield/providers/fdm-services';
import { QueryKeys } from '@infield/utils/queryKeys';
import { captureException } from '@sentry/react';
import { useQueries } from '@tanstack/react-query';

import type { UploadedMedia } from '../types';

export const useFetchMediaByExternalIds = (
  fileInstances?: InstanceOrExternalId[]
) => {
  const { mediaService } = useFDMServices();

  const queries = useQueries({
    queries: (fileInstances ?? []).map(({ externalId, space }) => ({
      queryKey: [QueryKeys.MEDIA_ITEMS, externalId, space],
      queryFn: async () =>
        mediaService.getMediaByFileExternalId(externalId, space),
      enabled: Boolean(externalId),
      onError: (error: any) =>
        captureException(error, {
          level: 'error',
          tags: {
            queryKey: QueryKeys.MEDIA_ITEMS,
          },
        }),
    })),
  });

  // Return both mediaFiles and their corresponding loading states
  const mediaFiles = queries.map((query) => {
    const media: UploadedMedia = {
      file: query.data,
      isError: query.isError,
      isLoading: query.isLoading,
    };
    return media;
  });

  return { mediaFiles };
};
