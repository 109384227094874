import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Metrics } from '@cognite/metrics';
import { CogniteClient } from '@cognite/sdk';

import type {
  AuthState,
  AuthStateUser,
  Customer,
  CustomerProject,
} from '../types';
import { hasAuthStateProjectInfo } from '../types';

import AzureADFlow from './flows/azure-ad-flow';
import type { AppID } from './flows/cogidp-flow';
import CogIDPFlow from './flows/cogidp-flow';
import { getInitialAuthState } from './utils';

/**
 * Note: Do not rely on hot reloading when editing this component
 */

type Props = {
  appId: AppID;
  supportedCustomers: Record<string, Customer>;
  useProductionAadApp?: boolean;
  redirect: boolean;
};

export const useCoreAuth = ({
  appId,
  supportedCustomers,
  useProductionAadApp = false,
  redirect = true,
}: Props) => {
  const [client, setClient] = useState<CogniteClient>(
    new CogniteClient({
      appId,
      project: '',
      oidcTokenProvider: async () => '',
    })
  );
  const [logoutFunc, setLogoutFunc] = useState<(() => Promise<void>) | null>(
    null
  );
  const [_, setSearchParams] = useSearchParams();
  const [project, setProject] = useState<string>();
  const [authState, setAuthState] = useState<AuthState>(getInitialAuthState());

  const storeAuthState = (state: AuthState) => {
    if (state.status === 'UNAUTHENTICATED') {
      localStorage.removeItem('APP_AUTH_STATE');
    }
    localStorage.setItem('APP_AUTH_STATE', JSON.stringify(state));

    setAuthState(state);
  };

  const findCustomerConfig = (customerId: string): Customer | undefined => {
    return Object.values(supportedCustomers).find(
      (customer) =>
        customer.id === customerId.toLowerCase() ||
        (customer.aliases || []).includes(customerId.toLowerCase())
    );
  };

  const loginWithCustomerId = async (customerId: string) => {
    const customerConfig = findCustomerConfig(customerId);
    if (!customerConfig) {
      const nextAuthState: AuthState = {
        status: 'AUTHENTICATING',
        projectInfo: {
          authType: 'cogidp',
          org: customerId,
          id: '',
          cluster: {
            name: 'ew1',
            apiBaseURL: '',
          },
        },
      };
      storeAuthState(nextAuthState);
      return;
    }
    const nextAuthState: AuthState = {
      status: 'AUTHENTICATING',
      projectInfo: customerConfig.projects[0],
    };
    storeAuthState(nextAuthState);
  };

  const loginWithProjectInfo = async (
    org: string,
    project: { name: string; apiUrl: string }
  ) => {
    const nextAuthState: AuthState = {
      status: 'AUTHENTICATING',
      projectInfo: {
        authType: 'cogidp',
        cluster: {
          apiBaseURL: project.apiUrl,
          name: 'ew1',
        },
        id: project.name,
        org,
      },
    };
    storeAuthState(nextAuthState);
  };

  const onAttemptLogin = useCallback(
    async (customerProject: CustomerProject, redirect = true) => {
      let getToken: (() => Promise<string>) | null = null;

      let user: AuthStateUser = {
        id: '',
        cdfId: '',
        name: '',
        email: '',
      };

      // Allows cypress to bypass auth with own token
      // E2E Token could be used to bypass auth with own token same will replace Cypress token
      if (localStorage.getItem('CY_TOKEN')) {
        getToken = async () => String(localStorage.getItem('CY_TOKEN'));
        user = JSON.parse(
          localStorage.getItem('CY_USER_STATE') || '{}'
        ) as AuthStateUser;
      } else if (localStorage.getItem('E2E_TOKEN')) {
        getToken = async () => String(localStorage.getItem('E2E_TOKEN'));
        user = JSON.parse(
          localStorage.getItem('E2E_USER_STATE') || '{}'
        ) as AuthStateUser;
      } else if (customerProject.authType === 'azureAD') {
        const azureADFlow = new AzureADFlow(
          customerProject,
          useProductionAadApp
        );
        try {
          getToken = await azureADFlow.getTokenFactory(redirect);
          const adUserInfo = await azureADFlow.getUserState();
          user = { ...adUserInfo, cdfId: '' };
        } catch (e) {
          const nextAuthState: AuthState = {
            status: 'ERROR',
            message: (e as Error).message,
          };
          storeAuthState(nextAuthState);
          throw e;
        }
      } else if (customerProject.authType === 'cogidp') {
        const cogidpFlow = new CogIDPFlow({
          isProduction: useProductionAadApp,
          isPreview: window.location.host.endsWith(
            'industry-apps.preview.cogniteapp.com'
          ),
          projectInfo: customerProject,
          app: appId,
        });
        try {
          getToken = await cogidpFlow.getTokenFactory();
          user = await cogidpFlow.getUserState();
          const logoutFunc = await cogidpFlow.getLogoutFunction();
          setLogoutFunc(() => logoutFunc);
        } catch (e) {
          const nextAuthState: AuthState = {
            status: 'ERROR',
            message: (e as Error).message,
          };
          storeAuthState(nextAuthState);
          // If a redirect contains an error, we remove that error from the search params
          // Otherwise, on the next attempt, cogidp will send in these search params, and result in a
          // "State mistmatch" error
          setSearchParams('');

          throw e;
        }
      }

      if (!getToken) {
        throw new Error('Unable to determine method to get token for user');
      }

      const nextClient = new CogniteClient({
        appId,
        project: customerProject.id || 'unknown',
        getToken,
        baseUrl: customerProject.cluster.apiBaseURL,
      });

      setClient(nextClient);

      if (!customerProject.id) {
        storeAuthState({
          status: 'AUTHENTICATED',
          projectInfo: customerProject,
          user,
        });
        return;
      }

      setProject(customerProject.id);

      const res = await nextClient.authenticate();
      // If we have no access token after authenticating, set back to idle
      if (!res) {
        storeAuthState({ status: 'UNAUTHENTICATED' });
      } else {
        if (customerProject.authType === 'azureAD') {
          const cdfUser = await nextClient.profiles.me();
          user.cdfId = cdfUser.userIdentifier;
        }

        storeAuthState({
          status: 'AUTHENTICATED',
          projectInfo: customerProject,
          user,
        });
        Metrics.identify(String(user.id) || 'UNKNOWN');
        Metrics.people({ name: user.name, email: user.email });
        Metrics.props({ projectId: customerProject.id, applicationId: appId });
      }
    },
    [appId, setSearchParams, useProductionAadApp]
  );

  useEffect(() => {
    // If localStorage says we're authenticated, autorun login once
    if (authState.status === 'AUTHENTICATED') {
      try {
        onAttemptLogin(authState.projectInfo, redirect);
      } catch (e) {
        throw new Error('failed');
      }
    }
    if (authState.status === 'ERROR') {
      storeAuthState({ status: 'UNAUTHENTICATED' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authState.status !== 'AUTHENTICATING') return;
    try {
      onAttemptLogin(authState.projectInfo, redirect);
    } catch (e) {
      throw new Error('failed');
    }
  }, [authState, onAttemptLogin, redirect]);

  const logout = async () => {
    storeAuthState({ status: 'UNAUTHENTICATED' });
    sessionStorage.clear();
    if (logoutFunc) {
      await logoutFunc();
    }
    window.location.replace('/');
  };

  const switchProject = (projectName: string) => {
    if (
      hasAuthStateProjectInfo(authState) &&
      authState.projectInfo.id !== projectName &&
      authState.projectInfo.authType === 'cogidp'
    ) {
      const updateAuthState: AuthState = {
        ...authState,
        projectInfo: {
          ...authState.projectInfo,
          ...findCustomerConfig(projectName)?.projects[0],
          id: projectName,
        },
      };
      storeAuthState(updateAuthState);
      // refresh page to refresh all data
      window.location.reload();
    } else {
      console.log(
        "Can't switch project, you are already on project or not cogidp auth"
      );
    }
  };

  return {
    authState,
    client,
    project,
    findCustomerConfig,
    loginWithCustomerId,
    logout,
    loginWithProjectInfo,
    switchProject,
  };
};
