import { Container, ContainerType, DataGridContainer } from '../../containers';
import ChartsContainer from '../../containers/ChartsContainer';
import DocumentContainer from '../../containers/DocumentContainer';
import ImageContainer from '../../containers/ImageContainer';
import PropertyListContainer from '../../containers/PropertyListContainer';
import RevealContainer from '../../containers/RevealContainer';
import TableContainer from '../../containers/TableContainer';
import TextContainer from '../../containers/TextContainer';
import TimeseriesContainer from '../../containers/TimeseriesContainer';
import { ContainerConfig } from '../../containers/types';
import VideoContainer from '../../containers/VideoContainer';
import { UnifiedViewer } from '../../UnifiedViewer';
import assertNever from '../../utils/assertNever';

const getContainerByContainerConfig = <MetadataType>(
  containerConfig: ContainerConfig<MetadataType>,
  unifiedViewer: UnifiedViewer
): Container<MetadataType> => {
  const containerType = containerConfig.type;

  if (containerType === ContainerType.DOCUMENT) {
    return new DocumentContainer<MetadataType>(containerConfig, unifiedViewer);
  }

  if (containerType === ContainerType.IMAGE) {
    return new ImageContainer<MetadataType>(containerConfig, unifiedViewer);
  }

  if (containerType === ContainerType.TEXT) {
    return new TextContainer<MetadataType>(containerConfig, unifiedViewer);
  }

  if (containerType === ContainerType.TIMESERIES) {
    return new TimeseriesContainer<MetadataType>(
      containerConfig,
      unifiedViewer
    );
  }

  if (containerType === ContainerType.CHARTS) {
    return new ChartsContainer<MetadataType>(containerConfig, unifiedViewer);
  }

  if (containerType === ContainerType.TABLE) {
    return new TableContainer<MetadataType>(containerConfig, unifiedViewer);
  }

  if (containerType === ContainerType.DATA_GRID) {
    return new DataGridContainer<MetadataType>(containerConfig, unifiedViewer);
  }

  if (containerType === ContainerType.PROPERTY_LIST) {
    return new PropertyListContainer<MetadataType>(
      containerConfig,
      unifiedViewer
    );
  }

  if (containerType === ContainerType.VIDEO) {
    return new VideoContainer<MetadataType>(containerConfig, unifiedViewer);
  }

  if (containerType === ContainerType.REVEAL) {
    return new RevealContainer<MetadataType>(containerConfig, unifiedViewer);
  }

  assertNever(containerType, 'Container type not supported ' + containerType);
};

export default getContainerByContainerConfig;
