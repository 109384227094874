import { IDPType, redirectToLogin } from '@cognite/login-utils';
import { CogniteClient } from '@cognite/sdk';

import { FusionTokenProvider, TokenRetreivalOpts } from './fusionTokenProvider';
import { UserInfo } from './types';
import { getBaseUrl, getCluster, getProject, getUrl } from './utils';

export { getIDP } from './utils';

export type AuthenticatedUser = {
  authenticated: boolean;
  username?: string;
  project?: string;
};

export type { UserInfo };

const urlCluster = getCluster();
const project = getProject();

const getSdkBaseUrl = () => {
  return urlCluster ? getUrl(urlCluster) : undefined;
};

const generateGlobalInstances = () => {
  // Fusion doesn't have a single singleton (yes, it's confusing).
  // We have a shared SDK instance for sub-apps (i.e. everything loaded through Single-SPA)
  // and we have a SDK instance for fusion-shell (login, landing page, navigation, etc.).
  // This causes problem with authentication as we have multiple OIDC clients operating in parallel.
  // And we have seen issues where two OIDC clients trying to do a refresh token grant at the same time,
  // with the same refresh token which causes an invalidation of the user's auth session.
  // We are enforcing a single SDK instance by using a global variable (memoization).
  if (!window.cogniteGlobalSdkSingleton) {
    const sdkTokenProvider = new FusionTokenProvider();
    const sdkSingleton = new CogniteClient({
      appId: sdkTokenProvider.getAppId(),
      baseUrl: getSdkBaseUrl(),
      project,
      getToken,
    });
    window.cogniteGlobalSdkSingleton = { sdkSingleton, sdkTokenProvider };
  }
  return window.cogniteGlobalSdkSingleton;
};

const { sdkSingleton, sdkTokenProvider } = generateGlobalInstances();

const ensureCorrectBaseUrlP = urlCluster
  ? Promise.resolve()
  : getBaseUrl()
      .then((baseUrl) => {
        if (baseUrl && baseUrl !== sdkSingleton.getBaseUrl()) {
          // @ts-ignore
          sdkSingleton.httpClient.setBaseUrl(getUrl(baseUrl));
        }
      })
      .catch((err) => {
        console.warn(err);
      });

export async function getUserInformation(): Promise<UserInfo> {
  return sdkTokenProvider.getUserInformation();
}

export function getFlow(): { flow: IDPType | undefined } {
  return { flow: 'COGNITE_IDP' };
}

export function setTokenRetreivalOpts(opts: TokenRetreivalOpts) {
  sdkTokenProvider.setTokenRetreivalOpts(opts);
}

export async function getToken() {
  // cyToken is used for e2e-tests
  const cyToken = localStorage.getItem('CY_TOKEN');
  if (cyToken) {
    return cyToken;
  }

  await ensureCorrectBaseUrlP;
  return sdkTokenProvider.getToken();
}

/**
 * Logout from the frontend only, login session will still be active on the IDP
 */
export async function logout() {
  return sdkTokenProvider.logout();
}

/**
 * Returns whether the user signed in through CogIdP. Since all users
 * are migrated, this is always true.
 */
export const isCogniteIdPUsedToSignIn = () => true;

export default sdkSingleton;

let authInit: Promise<void> | undefined;
export function loginAndAuthIfNeeded(): Promise<void> {
  if (!authInit) {
    authInit = sdkSingleton
      .authenticate()
      .then(async (token) => {
        if (token === undefined) {
          await redirectToLogin();
        }
      })
      .catch(() => redirectToLogin());
  }
  return authInit;
}
