import type { Activity, Checklist, Operation } from '@cognite/apm-client';
import { Body, DeleteIcon, Modal } from '@cognite/cogs.js-v10';
import type { Filters } from '@cognite/fdm-client/src/types';
import {
  ActivityCreateChecklistModal,
  useActivityCreateChecklist,
} from '@infield/features/activities';
import {
  dateFilterAtom,
  selectedOverviewTableAtom,
} from '@infield/features/activities/activity-planning/state';
import { useMultipleChecklistsUpsertMutation } from '@infield/features/checklist';
import { ChecklistSchedulerDesktopBanner } from '@infield/features/checklist/checklist-scheduler/checklist-scheduler-desktop-banner/checklist-scheduler-desktop-banner';
import { useChecklistsDelete } from '@infield/features/checklist/hooks/use-mutation/use-checklists-delete-mutation';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { AssignModal } from '@infield/features/ui';
import { useChecklistSchedulerContext } from '@infield/providers/checklist-scheduler-provider/checklist-scheduler-context';
import { useMemo, useState } from 'react';
import type { FC } from 'react';
import { useRecoilValue } from 'recoil';

import type { PanelViews } from '../types';

import { ActivityPlanningChecklistsView } from './activity-planning-checklists-view';
import { ActivityPlanningMaintenanceView } from './activity-planning-maintenance-view';

export const ActivityPlanningView: FC = () => {
  const { isChecklistSchedulerEnabled } = useChecklistSchedulerContext();

  const selectedDateFilter = useRecoilValue(dateFilterAtom);
  const selectedTable = useRecoilValue(selectedOverviewTableAtom);

  const [showCreateAndAssignModal, setShowCreateAndAssignModal] =
    useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const [selectedActivity, setSelectedActivity] = useState<
    Activity | undefined
  >();
  const [selectedChecklists, setSelectedChecklists] = useState<Checklist[]>([]);
  const [selectedOperations, setSelectedOperations] = useState<Operation[]>([]);

  const { mutateAsync: createChecklist, isLoading: isCreateChecklistLoading } =
    useActivityCreateChecklist();
  const { mutateAsync: updateChecklists } =
    useMultipleChecklistsUpsertMutation();
  const { mutateAsync: deleteChecklists } = useChecklistsDelete();

  const [panelView, setPanelView] = useState<PanelViews>('none');

  const handleCreateAndAssignClick = () => {
    if (selectedActivity && selectedChecklists.length === 0) {
      setShowCreateAndAssignModal(true);
    } else {
      setShowReassignModal(true);
    }
  };

  const handleCreateChecklist = async (
    startTime: string,
    endTime: string,
    assignedTo?: string[]
  ) => {
    if (selectedActivity) {
      const { createdChecklist } = await createChecklist({
        activityExternalId: selectedActivity.externalId,
        operations: selectedOperations,
        plannedStartTime: startTime,
        plannedEndTime: endTime,
        assignees: assignedTo,
      });
      setSelectedChecklists((prevChecklists) => [
        ...prevChecklists,
        createdChecklist,
      ]);
    }

    setShowCreateAndAssignModal(false);
  };

  const handleDeleteChecklistButtonClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteChecklists = async () => {
    deleteChecklists(
      selectedChecklists?.map((checklist) => checklist.externalId) || []
    );
    setShowDeleteModal(false);
  };

  const handleUpdateAssignees = async (assignedTo?: string[]) => {
    if (selectedChecklists) {
      const updatedChecklists: Checklist[] = selectedChecklists.map(
        (checklist) => ({
          externalId: checklist.externalId,
          startTime: checklist.startTime,
          endTime: checklist.endTime,
          assignedTo,
        })
      );
      await updateChecklists(updatedChecklists);
      setSelectedChecklists(updatedChecklists);
    }

    setShowReassignModal(false);
  };

  const dateFilters: Filters | undefined = useMemo(() => {
    if (selectedDateFilter && selectedDateFilter[0] && selectedDateFilter[1]) {
      return {
        or: [
          {
            range: {
              property: 'startTime',
              gte: selectedDateFilter[0].valueOf(),
              lte: selectedDateFilter[1].valueOf(),
            },
          },
          {
            range: {
              property: 'endTime',
              gte: selectedDateFilter[0].valueOf(),
              lte: selectedDateFilter[1].valueOf(),
            },
          },
        ],
      };
    }
  }, [selectedDateFilter]);

  const createAndAssignModalTitle =
    selectedChecklists.length > 0
      ? t('ACTIVITY_DIALOGUE_OPEN_AS_CHECKLIST_TITLE_EDIT', 'Edit checklist')
      : t(
          'ACTIVITY_DIALOGUE_OPEN_AS_CHECKLIST_TITLE_OPEN',
          'Open as checklist?'
        );

  const createAndAssignModalSaveDescription =
    selectedChecklists.length > 0
      ? t(
          'ACTIVITY_DIALOGUE_OPEN_AS_CHECKLIST_BUTTON_SAVE_CHECKLIST',
          'Save checklist'
        )
      : t(
          'ACTIVITY_DIALOGUE_OPEN_AS_CHECKLIST_BUTTON_CREATE_NEW_CHECKLIST',
          'Create new checklist'
        );

  const deleteChecklistModalTitle =
    selectedChecklists.length === 1
      ? t(
          'OVERVIEW_TABLE_DELETE_CHECKLIST_CONFIRMATION_MODAL_TITLE',
          'Delete "{{ checklistTitle }}"?',
          { checklistTitle: selectedChecklists[0].title }
        )
      : t(
          'OVERVIEW_TABLE_DELETE_MULTIPLE_CHECKLISTS_CONFIRMATION_MODAL_TITLE',
          'Delete {{ numChecklists }} checklists?',
          { numChecklists: selectedChecklists.length }
        );

  const selectedAssignees = useMemo(
    () => selectedChecklists.flatMap((checklist) => checklist.assignedTo || []),
    [selectedChecklists]
  );
  return (
    <>
      {isChecklistSchedulerEnabled && <ChecklistSchedulerDesktopBanner />}
      {selectedTable === 'maintenance-table' ? (
        <ActivityPlanningMaintenanceView
          panelView={panelView}
          dateFilters={dateFilters}
          selectedActivity={selectedActivity}
          selectedOperations={selectedOperations}
          selectedChecklists={selectedChecklists}
          handleCreateAndAssignClick={handleCreateAndAssignClick}
          setPanelView={setPanelView}
          setSelectedActivity={setSelectedActivity}
          setSelectedOperations={setSelectedOperations}
          setSelectedChecklists={setSelectedChecklists}
        />
      ) : (
        <ActivityPlanningChecklistsView
          panelView={panelView}
          dateFilters={dateFilters}
          selectedChecklists={selectedChecklists}
          handleCreateAndAssignClick={handleCreateAndAssignClick}
          onDeleteChecklists={handleDeleteChecklistButtonClick}
          setPanelView={setPanelView}
          setSelectedChecklists={setSelectedChecklists}
        />
      )}
      <ActivityCreateChecklistModal
        visible={showCreateAndAssignModal}
        title={createAndAssignModalTitle}
        subtitle={selectedActivity?.title}
        saveDescription={createAndAssignModalSaveDescription}
        assignees={[]}
        isCreateChecklistLoading={isCreateChecklistLoading}
        onSave={handleCreateChecklist}
        onClose={() => setShowCreateAndAssignModal(false)}
      />
      <AssignModal
        visible={showReassignModal}
        title={t('ACTIVITY_DIALOGUE_ASSIGN', 'Assign')}
        assignedTo={selectedAssignees}
        onAssign={handleUpdateAssignees}
        onClose={() => setShowReassignModal(false)}
      />
      <Modal
        visible={showDeleteModal}
        title={deleteChecklistModalTitle}
        icon={<DeleteIcon />}
        onCancel={() => setShowDeleteModal(false)}
        onOk={handleDeleteChecklists}
        cancelText={t(
          'OVERVIEW_TABLE_DELETE_CHECKLIST_MODAL_CANCEL_BUTTON_TEXT',
          'Cancel'
        )}
        okText={t(
          'OVERVIEW_TABLE_DELETE_CHECKLIST_CONFIRMATION_MODAL_BUTTON_TEXT',
          'Delete'
        )}
        destructive
      >
        <Body>
          {t(
            'OVERVIEW_TABLE_DELETE_CHECKLIST_CONFIRMATION_MODAL_BODY',
            'You’ll lose all progress and data collected except for numerical readings. If you need to restore a checklist, contact your admin.'
          )}
        </Body>
      </Modal>
    </>
  );
};
