import { Button, Flex, Input } from '@cognite/cogs-core';
import { CopyIcon, LoaderIcon } from '@cognite/cogs-icons';

import { UserInfo } from '../../common/types';
import { TabContent } from '../tab-content/TabContent';

export type PersonalInfoTabProps = {
  userInfo?: UserInfo;
  isUserInfoLoading?: boolean;
  userIdFieldLabel?: string;
  nameFieldLabel?: string;
  nameFieldHelpText?: string;
  emailFieldLabel?: string;
  emailFieldHelpText?: string;
};

export const PersonalInfoTab = ({
  userInfo,
  isUserInfoLoading,
  userIdFieldLabel = 'User ID',
  nameFieldLabel = 'Name',
  nameFieldHelpText = 'Contact your administrator if you want to change your name',
  emailFieldLabel = 'Email address',
  emailFieldHelpText = 'Contact your administrator if you want to change your name or email address',
}: PersonalInfoTabProps): JSX.Element => {
  const userId = userInfo?.id ?? '';
  const name = userInfo?.name ?? '';
  const email = userInfo?.email ?? '';

  return (
    <TabContent.Container>
      <TabContent.Body compact>
        <Flex gap={8}>
          <Input
            disabled
            fullWidth
            icon={isUserInfoLoading ? <LoaderIcon /> : undefined}
            label={{
              info: undefined,
              required: false,
              text: userIdFieldLabel,
            }}
            size="large"
            value={userId}
          />
          <Button
            icon={<CopyIcon />}
            aria-label="Copy user ID"
            onClick={() => {
              navigator.clipboard.writeText(userId);
            }}
            style={{ alignSelf: 'flex-end', height: 40, width: 40 }}
          />
        </Flex>
        <Input
          disabled
          fullWidth
          helpText={!email ? nameFieldHelpText : undefined}
          icon={isUserInfoLoading ? <LoaderIcon /> : undefined}
          label={{
            info: undefined,
            required: false,
            text: nameFieldLabel,
          }}
          size="large"
          value={name}
        />
        {!!email && (
          <Input
            disabled
            fullWidth
            helpText={emailFieldHelpText}
            icon={isUserInfoLoading ? <LoaderIcon /> : undefined}
            label={{
              info: undefined,
              required: false,
              text: emailFieldLabel,
            }}
            size="large"
            value={email}
          />
        )}
      </TabContent.Body>
    </TabContent.Container>
  );
};
