import { EmptyState, Flex } from '@cognite/cogs-core';
import { ImageIllustration, LoaderIcon } from '@cognite/cogs-icons';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { UploadedMedia } from '@infield/features/media/types';
import type { FC } from 'react';

import * as S from './elements';
import { ImageItem } from './image-item';
import { getIsVideo, getMediaSrc } from './utils';
import { VideoItem } from './video-item';

type Props = {
  media: UploadedMedia;
  isFullScreen?: boolean;
};

export const MediaItem: FC<Props> = ({ media, isFullScreen }) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.fileViewer);

  if (media.isLoading) {
    return (
      <S.PlaceholderFlexContainer justifyContent="center">
        <LoaderIcon className="cogs-icon--rotating" />
      </S.PlaceholderFlexContainer>
    );
  }

  if (media.isError || !media.file) {
    return (
      <S.PlaceholderFlexContainer justifyContent="center">
        <EmptyState
          error={t('MEDIA_ITEM_ERROR', 'Media removed or not found')}
          illustration={<ImageIllustration />}
          size={isFullScreen ? 'medium' : 'small'}
        />
      </S.PlaceholderFlexContainer>
    );
  }

  const isVideo = media && getIsVideo(media.file);
  const mediaSrc = media && getMediaSrc(media.file);

  if (isVideo) {
    return <VideoItem mediaSrc={mediaSrc} isFullScreen={isFullScreen} />;
  }

  return (
    <ImageItem
      mediaName={media.file.name}
      mediaSrc={mediaSrc}
      isFullScreen={isFullScreen}
    />
  );
};
