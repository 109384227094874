import { IDPResponse } from '../types';

export const getLoginFlowsByCluster = (idp?: IDPResponse) => {
  const loginFlowsByCluster: Record<string, { idp?: IDPResponse }> = {};

  if (idp) {
    idp.clusters.forEach((cluster) => {
      loginFlowsByCluster[cluster] = { idp };
    });
    return loginFlowsByCluster;
  }
  return loginFlowsByCluster;
};

export const sortIDPsByLabel = (idps?: IDPResponse[]) => {
  return (idps ?? []).sort((idpA: IDPResponse, idpB: IDPResponse) => {
    const idpLabelA = (idpA.label ?? '').toLocaleLowerCase();
    const idpLabelB = (idpB.label ?? '').toLocaleLowerCase();
    if (idpLabelA < idpLabelB) return -1;
    if (idpLabelA > idpLabelB) return 1;
    return 0;
  });
};

type FusionDomain = {
  baseHostname: string; // the hostname without the org subdomain, but not neccessary the _direct_ parent domain
  orgPattern?: RegExp; // regex to match to extract the organization. default: first subdomain
};

const fusionAppHosts: FusionDomain[] = [
  { baseHostname: 'fusion.cognite.com' },
  { baseHostname: 'staging.fusion.cognite.com' },
  { baseHostname: 'next-release.fusion.cognite.com' },
];

const fusionDevAppHosts: FusionDomain[] = [
  { baseHostname: 'dev.fusion.cogniteapp.com' },
  { baseHostname: 'fusion-pr-preview.cogniteapp.com' },
  { baseHostname: 'local.cognite.ai' },
  { baseHostname: 'localhost' },
  { baseHostname: 'fusion.cognitedata-development.cognite.ai' },
  {
    baseHostname: 'fusion-preview.preview.cogniteapp.com',
    orgPattern:
      /([^.]+)\.fusion-shell-[\d]+\.fusion-preview\.preview\.cogniteapp\.com/,
  },
];

const whitelistedHosts = [...fusionAppHosts, ...fusionDevAppHosts];

const isAnyOfHosts = (hostname: string, baseHostnames: string[]) => {
  return baseHostnames.some((_host) => hostname.endsWith(_host));
};

const getBaseHostnameFromDomain = ({ baseHostname }: FusionDomain) =>
  baseHostname;

export const getApp = () => {
  const hostname = window.location.hostname;

  if (isAnyOfHosts(hostname, fusionAppHosts.map(getBaseHostnameFromDomain))) {
    return 'fusion';
  }

  return 'fusion-dev';
};

// Make apps explicitly require to be specified in order to use the
// dlc-service api directly instead of hardcoded responses.
// See the variable [hardcodedDlcResponses].
export const isAllowlistedHost = () => {
  const hostname = window.location.hostname;
  return isAnyOfHosts(
    hostname,
    whitelistedHosts.map(getBaseHostnameFromDomain)
  );
};

export const isPreviewDeployment = window.location.hostname.endsWith(
  '.preview.cogniteapp.com'
);

export const getOrganization = (): string | null => {
  const hostname = window.location.hostname;

  // ensure e.g. next-release.fusion.cognite.com isn't
  // interpreted as the app fusion.cognite.com with
  // the organization "next-release"
  if (whitelistedHosts.map(getBaseHostnameFromDomain).includes(hostname)) {
    return null;
  }

  for (const { baseHostname, orgPattern } of whitelistedHosts) {
    if (!hostname.endsWith(baseHostname)) {
      continue;
    }
    if (orgPattern) {
      return hostname.match(orgPattern)?.[1] ?? null;
    }
    return hostname.split('.')[0];
  }
  return null;
};

export const getRequiredOrganization = (): string => {
  const org = getOrganization();
  if (!org) {
    throw new Error('Organization not found');
  }
  return org;
};

export const getBaseHostname = (): string => {
  const noOrganizationSpecified = !getOrganization();
  if (noOrganizationSpecified) {
    return window.location.hostname;
  } else {
    return window.location.hostname.split('.').slice(1).join('.');
  }
};

export const getBaseUrl = (): string => {
  const baseHostnameWithProtocol = `${
    window.location.protocol
  }//${getBaseHostname()}`;
  const { port } = window.location;
  if (port) {
    return `${baseHostnameWithProtocol}:${port}`;
  }
  return baseHostnameWithProtocol;
};

const getOrgCookieName = (hostname: string) =>
  `loginOrg-${encodeURIComponent(hostname)}`;

export function setLoginOrganizationCookie(org: string) {
  const baseHostname = getBaseHostname();
  const expiresInMinutes = 10;
  setCookie(
    getOrgCookieName(baseHostname),
    org,
    baseHostname,
    expiresInMinutes
  );
}

export function setRedirectCookieForPreviewDeployment(redirectTo: string) {
  const expiresInMinutes = 10;
  setCookie(
    'cogRedirectTo',
    redirectTo,
    'preview.cogniteapp.com',
    expiresInMinutes
  );
}

function setCookie(
  name: string,
  value: string,
  domain: string,
  expiresInMinutes: number
) {
  const expires = new Date(Date.now() + expiresInMinutes * 60 * 1000);
  document.cookie = `${name}=${value};domain=${domain};expires=${expires.toUTCString()}`;
}

function getCookieValue(name: string): string {
  return (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
  );
}

function getOrganizationCookie(): string | null {
  const baseHostname = getBaseHostname();
  const org = getCookieValue(getOrgCookieName(baseHostname));
  return org !== '' ? org : null;
}

export function handleSigninCallbackForAad() {
  const org = getOrganizationCookie();
  if (!org) {
    throw new Error('No organization found');
  }
  const redirectTo = new URL(window.location.href);
  redirectTo.pathname = '/';
  redirectTo.hostname = `${org}.${redirectTo.hostname}`;
  window.location.href = redirectTo.href;
}
