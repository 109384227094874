import { Flex } from '@cognite/cogs.js-v10';
import styled from 'styled-components';

export const Container = styled(Flex).attrs({
  direction: 'column',
})`
  height: 100%;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme['surface--muted']};
`;

export const Header = styled(Flex).attrs({
  alignItems: 'center',
  wrap: 'wrap',
})`
  height: auto;
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme['border--muted']};
  gap: 8px;
`;

export const ActivityList = styled(Flex).attrs({
  direction: 'column',
})`
  user-select: 'none';
`;

export const EmptyStateContainer = styled(Flex)`
  justify-content: center;
  margin: 16px;
`;

export const ActivityListWrapper = styled(Flex).attrs({
  direction: 'column',
})`
  overflow-y: auto;
  width: 100%;
  user-select: 'none';

  ::-webkit-scrollbar {
    display: none;
  }
`;
