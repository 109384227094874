import { Flex } from '@cognite/cogs.js-v10';
import {
  ACTION_MENU_HEIGHT,
  ACTION_MENU_MARGIN,
} from '@infield/features/ui/action-menu/elements';
import styled from 'styled-components';

export const ClickableText = styled.span`
  color: ${({ theme }) => theme['border--interactive--toggled-default']};
`;

export const MultiLineText = styled.div`
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const InfoboxWrapper = styled(Flex)`
  flex: 1;
  max-height: 80px;
`;

export const Container = styled.div<{ $hasExtraMargin?: boolean }>`
  display: contents;
  margin-bottom: ${({ $hasExtraMargin }) =>
    $hasExtraMargin ? `${ACTION_MENU_HEIGHT + ACTION_MENU_MARGIN * 2}px` : 0};
  .MuiDataGrid-virtualScrollerContent {
    margin-bottom: ${({ $hasExtraMargin }) =>
      $hasExtraMargin ? `${ACTION_MENU_HEIGHT + ACTION_MENU_MARGIN * 2}px` : 0};
  }
`;
