import { Select } from '@cognite/cogs-lab';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';

import * as S from './elements';

interface StatusFilterProps {
  handleStatusFilterClick: (value: string) => void;
  currentStatusFilter: string;
}

export const StatusFilter = ({
  handleStatusFilterClick,
  currentStatusFilter,
}: StatusFilterProps) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const filterOptions = [
    {
      label: t('CHECKLIST_LIST_FILTER_READY', 'Ready'),
      value: 'Ready',
    },
    {
      label: t('CHECKLIST_LIST_FILTER_IN_PROGRESS', 'In progress'),
      value: 'In progress',
    },
    {
      label: t('CHECKLIST_LIST_FILTER_DONE', 'Done'),
      value: 'Done',
    },
  ];

  return (
    <Select
      size="large"
      options={filterOptions}
      value={currentStatusFilter}
      onChange={(_, value) =>
        value ? handleStatusFilterClick(value) : undefined
      }
      variant="solid"
      renderOption={(option) => <S.SelectOption>{option.label}</S.SelectOption>}
    />
  );
};
