import React, {
  createContext,
  useContext,
  memo,
  useEffect,
  useState,
  useMemo,
} from 'react';

import { RecoilRoot } from 'recoil';

import { CogIdPClient, useCogIdPClient } from '@cognite/cogidp-sdk';
import { CogniteClient } from '@cognite/sdk';
import { CogniteClient as CogniteClientBeta } from '@cognite/sdk-beta';
import { useSDK } from '@cognite/sdk-provider';

import { getChartsStorageService } from '../services';
import {
  ChartsStorageServiceInterface,
  shamefulDummyChartsStorageService,
} from '../services/storage/ChartsStorageServiceInterface';
import { UserInfo } from '../types';
import { BetaSDKProvider } from '../utils';

// TODO(FUS-000): use this type in UFV, and delete the local 'ChartsContextType' from UFV
export type ChartsContextType = {
  getToken: () => Promise<string>;
  getUserInformation: () => Promise<UserInfo>;
  isProduction: () => boolean;
  getProject: () => string;
  getCluster: () => string | null;
  getRequiredOrganization: () => string;
  cogIdPClient: CogIdPClient;
  chartsStorageService: ChartsStorageServiceInterface;
};

export const ChartsContext = createContext<ChartsContextType>({
  getToken: () => {
    throw new Error('Could not initialize getToken in ChartsContext');
  },
  getUserInformation: () => {
    throw new Error('Could not initialize getUserInformation in ChartsContext');
  },
  isProduction: () => {
    throw new Error('Could not initialize isProduction in ChartsContext');
  },
  getProject: () => {
    throw new Error('Could not initialize getProject in ChartsContext');
  },
  getCluster: () => {
    throw new Error('Could not initialize getCluster in ChartsContext');
  },
  getRequiredOrganization: () => {
    throw new Error('Could not initialize getCluster in ChartsContext');
  },
  cogIdPClient: new CogIdPClient('Invalid organization', () => {
    throw new Error('Could not initialize getToken in cogIDPClient');
  }),
  chartsStorageService: shamefulDummyChartsStorageService,
});

export type ChartsContextProps = React.PropsWithChildren<
  Omit<ChartsContextType, 'chartsStorageService' | 'cogIdPClient'>
>;

let betaClientSingleton: CogniteClientBeta | null = null;
const getBetaClient = async (
  stable: CogniteClient,
  getProject: () => string,
  getToken: () => Promise<string>
) => {
  if (!betaClientSingleton) {
    betaClientSingleton = new CogniteClientBeta({
      appId: 'fusion.cognite.com',
      project: getProject(),
      getToken,
      baseUrl: stable.getBaseUrl(),
    });
    await betaClientSingleton.authenticate();
  }
  return betaClientSingleton;
};

export const ChartsProvider: React.FC<ChartsContextProps> = memo(
  ({
    children,
    getToken,
    getUserInformation,
    isProduction,
    getProject,
    getCluster,
    getRequiredOrganization,
  }) => {
    const sdk = useSDK();
    const [betaClient, setBetaClient] = useState<CogniteClientBeta | null>(
      null
    );
    const cogIdPClient = useCogIdPClient(getRequiredOrganization(), getToken);

    const contextValue = useMemo(
      () => ({
        getToken,
        getUserInformation,
        isProduction,
        getProject,
        getCluster,
        getRequiredOrganization,
        cogIdPClient,
        chartsStorageService: getChartsStorageService(sdk),
      }),
      [
        getToken,
        getUserInformation,
        isProduction,
        getProject,
        getCluster,
        getRequiredOrganization,
        cogIdPClient,
        sdk,
      ]
    );

    useEffect(() => {
      getBetaClient(sdk, getProject, getToken).then(setBetaClient);
    }, []);

    if (!betaClient || !sdk || !cogIdPClient) {
      return null;
    }

    return (
      <ChartsContext.Provider value={contextValue}>
        <BetaSDKProvider betaClient={betaClient}>
          <RecoilRoot>{children}</RecoilRoot>
        </BetaSDKProvider>
      </ChartsContext.Provider>
    );
  }
);

export const useChartsContext = (): ChartsContextType =>
  useContext(ChartsContext);

export const useChartsStorageService = () =>
  useContext(ChartsContext).chartsStorageService;
