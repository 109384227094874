import { Button, Input, SearchIcon } from '@cognite/cogs.js-v10';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { SortType } from '../types';
import { getSortIcon } from '../utils';

import * as S from './elements';
import { SortMenu } from './sort-menu';

interface Props {
  searchInput: string;
  searchPlaceholder?: string;
  checklistOptionsMenu?: JSX.Element;
  sortType?: SortType;
  isSortDisabled?: boolean;
  setSearchInput: (searchInput: string) => void;
  setSortType?: (sortType: SortType) => void;
}

export const SearchFilterSortHeader: FC<Props> = ({
  searchInput,
  searchPlaceholder,
  checklistOptionsMenu,
  sortType,
  isSortDisabled = false,
  setSearchInput,
  setSortType,
}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSortMenu, setShowSortMenu] = useState(false);
  const [isComposing, setIsComposing] = useState<boolean>(false);
  const showSortButton = sortType && setSortType && !isSortDisabled;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // `isComposing` state is controlled with `onCompositionStart` and
    // `onCompositionEnd` event handlers - which is updated when user starts,
    // completes or cancels a composition session. Composition sessions are
    // created by input method editors that are used enter Chinese, Japanese,
    // or Korean using a Latin keyboard - so we don't want to trigger any
    // search action while the user is in a composition session.

    if (isComposing) {
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      (e.target as any).blur();
    }
  };

  return (
    <S.SearchFilterSortContainer>
      {/* Search */}
      <Input
        ref={searchRef}
        data-testid="search-filter-sort-header-search"
        type="search"
        size="large"
        variant="solid"
        fullWidth
        placeholder={searchPlaceholder}
        clearable
        icon={<SearchIcon />}
        value={searchInput}
        onCompositionStart={() => setIsComposing(true)}
        onCompositionEnd={() => setIsComposing(false)}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setSearchInput(e.target.value);
          if (e.target.value) {
            searchParams.set('search', e.target.value);
          } else {
            searchParams.delete('search');
          }
          setSearchParams(searchParams, { replace: true });
        }}
      />
      {/* Filter - TODO(FUS-000): different for mobile and desktop - also have to wait for SelectPanel cogs component for checkbox capability */}
      {/* {handleFilterButtonClick ? (
        <Button
          icon={<FilterIcon />}
          onClick={handleFilterButtonClick}
        />
      ) : (
        <Dropdown
          content={
            <Menu hasCheckbox>
              <Menu.Item>Select all</Menu.Item>
              <Menu.Item>Draft</Menu.Item>
              <Menu.Item>Ready</Menu.Item>
              <Menu.Item disabled>Deleted</Menu.Item>
            </Menu>
          }
        >
          <Button icon={<FilterIcon />} />
        </Dropdown>
      )} */}

      {/* Sort */}
      {showSortButton && (
        <Button
          icon={getSortIcon(sortType)}
          toggled={sortType !== 'orderCreated'}
          size="large"
          data-testid="search-filter-sort-header-sort"
          onClick={() => setShowSortMenu(true)}
          aria-label="search-filter-sort-header-sort"
        />
      )}
      {/* Sort Popup Menu */}
      {showSortMenu && showSortButton && (
        <SortMenu
          sortType={sortType}
          setSortType={setSortType}
          onClose={() => setShowSortMenu(false)}
        />
      )}

      {/* Checklist actions menu */}
      {checklistOptionsMenu}
    </S.SearchFilterSortContainer>
  );
};
