import type { ViewConfigEntities } from '@cognite/apm-config';
import type { ViewReference } from '@cognite/sdk';

export const APM_Activity = 'APM_Activity';
export const APM_Notification = 'APM_Notification';
export const APM_Operation = 'APM_Operation';

export const baseNotificationViewReference: ViewReference = {
  externalId: APM_Notification,
  space: 'APM_SourceData',
  version: '1',
  type: 'view',
};

export const baseIdmNotificationViewReference: ViewReference = {
  externalId: 'CogniteNotification',
  space: 'cdf_idm',
  version: 'v1',
  type: 'view',
};

export const baseAssetViewReference: ViewReference = {
  externalId: 'Asset',
  space: 'cdf_core',
  version: 'v1',
  type: 'view',
};

export const baseIdmAssetViewReference: ViewReference = {
  externalId: 'CogniteAsset',
  space: 'cdf_cdm',
  version: 'v1',
  type: 'view',
};

export const baseActivityViewReference: ViewReference = {
  externalId: APM_Activity,
  space: 'APM_SourceData',
  version: '1',
  type: 'view',
};

export const baseIdmActivityViewReference: ViewReference = {
  externalId: 'CogniteMaintenanceOrder',
  space: 'cdf_idm',
  version: 'v1',
  type: 'view',
};

export const baseOperationViewReference: ViewReference = {
  externalId: APM_Operation,
  space: 'APM_SourceData',
  version: '1',
  type: 'view',
};

export const baseIdmOperationViewReference: ViewReference = {
  externalId: 'CogniteOperation',
  space: 'cdf_idm',
  version: 'v1',
  type: 'view',
};

export const baseIdmViewDictionary: Record<ViewConfigEntities, ViewReference> =
  {
    MAINTENANCE_ORDER: baseIdmActivityViewReference,
    OPERATION: baseIdmOperationViewReference,
    NOTIFICATION: baseIdmNotificationViewReference,
    ASSET: baseIdmAssetViewReference,
  };
