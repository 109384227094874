import { UnifiedViewer } from '../UnifiedViewer';
import assertNever from '../utils/assertNever';

import CauseMapTool from './CauseMapTool';
import CommentTool from './CommentTool';
import DataGridTool from './DataGridTool';
import EllipseTool from './EllipseTool';
import ImageTool from './ImageTool';
import LineTool from './LineTool';
import PanTool from './PanTool';
import PolylineTool from './PolylineTool';
import RectangleTool from './RectangleTool';
import SelectTool from './SelectTool';
import StickyTool from './StickyTool';
import TextTool from './TextTool';
import Tool from './Tool';
import {
  ToolConfig,
  isCauseMapToolConfig,
  isCommentToolConfig,
  isDataGridToolConfig,
  isEllipseToolConfig,
  isImageToolConfig,
  isLineToolConfig,
  isPanToolConfig,
  isPolylineToolConfig,
  isRectangleToolConfig,
  isSelectToolConfig,
  isStickyToolConfig,
  isTextToolConfig,
} from './types';

const getToolByToolConfig = (
  config: ToolConfig,
  unifiedViewer: UnifiedViewer
): Tool => {
  if (isEllipseToolConfig(config)) {
    return new EllipseTool({ unifiedViewer, config });
  }

  if (isImageToolConfig(config)) {
    return new ImageTool({ unifiedViewer, config });
  }

  if (isLineToolConfig(config)) {
    return new LineTool({ unifiedViewer, config });
  }

  if (isPanToolConfig(config)) {
    return new PanTool(unifiedViewer);
  }

  if (isPolylineToolConfig(config)) {
    return new PolylineTool({ unifiedViewer, config });
  }

  if (isRectangleToolConfig(config)) {
    return new RectangleTool({ unifiedViewer, config });
  }

  if (isSelectToolConfig(config)) {
    return new SelectTool({ unifiedViewer, config });
  }

  if (isStickyToolConfig(config)) {
    return new StickyTool({ unifiedViewer, config });
  }

  if (isTextToolConfig(config)) {
    return new TextTool({ unifiedViewer, config });
  }

  if (isCauseMapToolConfig(config)) {
    return new CauseMapTool({ unifiedViewer, config });
  }

  if (isCommentToolConfig(config)) {
    return new CommentTool({ unifiedViewer, config });
  }

  if (isDataGridToolConfig(config)) {
    return new DataGridTool({ unifiedViewer, config });
  }

  assertNever(
    config,
    'Invalid tool config in getToolByToolConfig: ' + JSON.stringify(config)
  );
};

export default getToolByToolConfig;
