import { Button, DeleteIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import type { UploadedMedia } from '@infield/features/media/types';
import type { FC } from 'react';

import * as S from './elements';
import { MediaItem } from './media-item';

type Props = {
  media: UploadedMedia;
  handleDeleteMedia: (media: UploadedMedia) => void;
  isDeletingMedia: boolean;
  hideDeleteButton: boolean;
};

export const SingleMediaContent: FC<Props> = ({
  media,
  handleDeleteMedia,
  isDeletingMedia,
  hideDeleteButton,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.fileViewer);

  const showDeleteButton =
    media.file?.externalId &&
    !media.isLoading &&
    !media.isError &&
    !hideDeleteButton;

  return (
    <S.SingleMediaContainer data-testid="single-media-container">
      <MediaItem isFullScreen media={media} />
      {showDeleteButton && (
        <Button
          data-testid="delete-media-button"
          icon={<DeleteIcon />}
          type="ghost-destructive"
          onClick={() => handleDeleteMedia(media)}
          loading={isDeletingMedia}
        >
          {t('DELETE_IMAGE', 'Delete')}
        </Button>
      )}
    </S.SingleMediaContainer>
  );
};
