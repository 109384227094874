import { FallbackContent } from '@infield/providers/error-boundary-provider/fallback-content';
import { captureException } from '@sentry/browser';
import type { FC, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import * as S from './elements';

interface ComponentErrorBoundaryProps {
  children?: ReactNode;
  sentryTag?: string;
  FallbackComponent?: FC<{ error: Error; resetErrorBoundary: () => void }>;
  onError?: () => void;
}

export const ComponentErrorBoundary: FC<ComponentErrorBoundaryProps> = ({
  children,
  sentryTag,
  FallbackComponent = FallbackContent,
  onError,
  ...props
}) => (
  <ErrorBoundary
    onError={(error) => {
      captureException(error, {
        level: 'warning',
        tags: { errorBoundary: sentryTag },
      });
      if (onError) onError();
    }}
    // Only apply styling when an error actually occurs
    FallbackComponent={({ error, resetErrorBoundary }) => (
      <S.ComponentErrorBoundary {...props}>
        <FallbackComponent
          error={error}
          resetErrorBoundary={resetErrorBoundary}
        />
      </S.ComponentErrorBoundary>
    )}
  >
    {/* No wrapper here => no extra styling if no error */}
    {children}
  </ErrorBoundary>
);
