import { ContainerType } from '../../containers/ContainerType';
import {
  DocumentMimeType,
  ImageMimeType,
  TextMimeType,
  VideoMimeType,
} from '../../containers/types';
import getFileExtension from '../getFileExtension';

import { NativelySupportedMimeType } from './isNativelySupportedMimeType';

const recordContainsValue = (
  record: Record<string, string>,
  value: string
): boolean => {
  return Object.values(record).includes(value);
};

/**
 * We are not including VIDEO here because of issues with conflicting props
 * https://cognitedata.atlassian.net/browse/AH-2685
 */
export const getShamefulContainerTypeFromMimeType = (
  mimeType: string
): ContainerType.DOCUMENT | ContainerType.IMAGE | ContainerType.TEXT => {
  if (recordContainsValue(DocumentMimeType, mimeType)) {
    return ContainerType.DOCUMENT;
  }
  if (recordContainsValue(ImageMimeType, mimeType)) {
    return ContainerType.IMAGE;
  }
  if (recordContainsValue(TextMimeType, mimeType)) {
    return ContainerType.TEXT;
  }

  // TODO(FUS-000): Should be possible to add a `assertNever` here
  throw Error(`Url with unsupported mime type '${mimeType}' was provided`);
};

export const getContainerTypeFromMimeType = (
  mimeType: string
):
  | ContainerType.DOCUMENT
  | ContainerType.IMAGE
  | ContainerType.TEXT
  | ContainerType.VIDEO => {
  if (recordContainsValue(DocumentMimeType, mimeType)) {
    return ContainerType.DOCUMENT;
  }
  if (recordContainsValue(ImageMimeType, mimeType)) {
    return ContainerType.IMAGE;
  }
  if (recordContainsValue(TextMimeType, mimeType)) {
    return ContainerType.TEXT;
  }
  if (recordContainsValue(VideoMimeType, mimeType)) {
    return ContainerType.VIDEO;
  }

  // TODO(FUS-000): Should be possible to add a `assertNever` here
  throw Error(`Url with unsupported mime type '${mimeType}' was provided`);
};

const getSupportedMimeTypeFromUrl = (
  url: string
): NativelySupportedMimeType | undefined => {
  // TODO(FUS-000): Improve typing. Ideally we would like this function to not compile if we don't check for all supported mime types in `SupportedMimeTypes`
  const fileExtension = getFileExtension(url);

  // Document types
  if (fileExtension === 'pdf') {
    return DocumentMimeType.PDF;
  }

  // Image types
  if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
    return ImageMimeType.JPEG;
  }
  if (fileExtension === 'png') {
    return ImageMimeType.PNG;
  }
  if (fileExtension === 'svg') {
    return ImageMimeType.SVG;
  }
  if (fileExtension === 'tif' || fileExtension === 'tiff') {
    return ImageMimeType.TIFF;
  }
  if (fileExtension === 'webp') {
    return ImageMimeType.WEBP;
  }

  // Text types
  if (fileExtension === 'txt') {
    return TextMimeType.TXT;
  }

  if (fileExtension === 'csv') {
    return TextMimeType.CSV;
  }

  if (fileExtension === 'json') {
    return TextMimeType.JSON;
  }

  return undefined;
};

export default getSupportedMimeTypeFromUrl;
