import type { FC, PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { configureScope } from '@sentry/react';

import sdk, { loginAndAuthIfNeeded } from '@cognite/cdf-sdk-singleton';
import { AuthContainer } from '@cognite/cdf-utilities';
import { CogniteClient } from '@cognite/sdk';

import CUSTOMERS from '../customers';
import { LoginScreen } from '../login';
import ProjectSelectionScreen from '../login/project-selection-screen';
import type {
  AuthState,
  AuthStateAuthenticated,
  AuthStateUser,
  Customer,
  CustomerProject,
} from '../types';
import { isFusionHosted } from '../utils';

import type { AppID } from './flows/cogidp-flow';
import { useCoreAuth } from './use-core-auth';
import { useProjectsQuery } from './use-projects-query';

export type AuthContextType = {
  client: CogniteClient;
  authState: AuthStateAuthenticated;
  logout: () => void;
  loginWithCustomerId: (customerId: string) => void;
  findCustomerConfig: (customerId: string) => Customer | undefined;
  switchCustomerProject: (projectName: string) => void;
  projectsForOrg?: { name: string; apiUrl: string }[] | null | undefined;
};

const AuthContext = createContext<AuthContextType | null>(null);

type Props = {
  appName: string;
  appId?: AppID;
  useProductionAadApp?: boolean;
  redirect?: boolean;
  mock?: {
    client?: CogniteClient;
    user?: AuthStateUser;
    projectInfo?: CustomerProject;
  };
};

export const AuthProvider: FC<PropsWithChildren<Props>> = ({
  children,
  mock,
  appName,
  appId = 'MAINTAIN',
  useProductionAadApp = false,
  redirect = true,
}) => {
  const {
    loginWithCustomerId,
    authState,
    client,
    logout,
    findCustomerConfig,
    project,
    loginWithProjectInfo,
    switchProject,
  } = useCoreAuth({
    appId,
    supportedCustomers: CUSTOMERS,
    useProductionAadApp,
    redirect,
  });

  const { data: projectsForOrg, isLoading: isLoadingProjects } =
    useProjectsQuery(client, authState);

  const [error, setError] = useState('');

  const onCustomerSelect = async (customerId: string) => {
    setError('');
    try {
      await loginWithCustomerId(customerId);
    } catch (e: any) {
      setError((e as Error).message);
    }
  };

  const switchCustomerProject = useCallback(
    // TODO(FUS-000): Disabling so we can enable linting
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (project: string) => {
      if (
        projectsForOrg?.map((projectItem) => projectItem.name).includes(project)
      ) {
        switchProject(project);
      } else {
        console.log('Project not found');
      }
    },
    [projectsForOrg, switchProject]
  );

  useEffect(() => {
    if (authState?.status === 'AUTHENTICATED') {
      configureScope((scope) => {
        scope.setTag('cluster', authState.projectInfo.cluster.name);
        scope.setTag('organisation', authState.projectInfo.org);
        scope.setTag('project', authState.projectInfo.id);
      });
    }
  }, [authState]);

  const value: Omit<AuthContextType, 'authState'> & { authState: AuthState } =
    useMemo(
      () => ({
        client,
        authState,
        logout,
        loginWithCustomerId,
        findCustomerConfig,
        projectsForOrg,
        switchCustomerProject,
      }),
      [
        client,
        authState,
        logout,
        loginWithCustomerId,
        findCustomerConfig,
        projectsForOrg,
        switchCustomerProject,
      ]
    );
  if (isFusionHosted()) {
    return (
      <AuthContainer title="Maintain" sdk={sdk} login={loginAndAuthIfNeeded}>
        <AuthContext.Provider
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          value={{
            client: sdk,
            authState: {
              status: 'AUTHENTICATED',
              user: {
                name: '',
              },
              projectInfo: {
                id: sdk.project,
              },
            } as AuthStateAuthenticated,
            logout: () => null,
            loginWithCustomerId: async () => null,
            findCustomerConfig,
            switchCustomerProject: () => null,
          }}
        >
          {children}
        </AuthContext.Provider>
      </AuthContainer>
    );
  }

  if (mock) {
    const mockClient =
      mock.client ||
      new CogniteClient({
        appId: '',
        project: 'fusion',
        oidcTokenProvider: async () => '',
      });
    const mockUser = mock.user || {
      name: 'Test User',
      email: 'test@cognite.com',
    };
    return (
      <AuthContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          client: mockClient,
          authState: {
            status: 'AUTHENTICATED',
            user: mockUser,
            projectInfo: mock.projectInfo || {
              id: 'test-project',
              authType: 'azureAD',
              cluster: {
                name: 'ew1',
                aadAppId: 1,
                stagingAadAppId: 5,
                apiBaseURL: 'http://locahost:9999',
              },
            },
          } as AuthStateAuthenticated,
          logout: () => null,
          loginWithCustomerId: async () => null,
          findCustomerConfig,
          switchCustomerProject: () => null,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
  if (value.authState.status === 'AUTHENTICATED' && project === undefined) {
    if (isLoadingProjects) {
      return <ProjectSelectionScreen isLoading onLogout={() => logout()} />;
    }

    return (
      <ProjectSelectionScreen
        onLogout={() => logout()}
        projects={projectsForOrg || []}
        // TODO(FUS-000): Disabling so we can enable linting
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onProjectSelect={(project) => {
          const org =
            authState?.status === 'AUTHENTICATED' &&
            authState.projectInfo.id === '' &&
            authState.projectInfo.org;
          if (org) {
            loginWithProjectInfo(org, project);
          }
        }}
      />
    );
  }

  if (
    value.authState.status === 'UNAUTHENTICATED' ||
    value.authState.status === 'AUTHENTICATING'
  ) {
    return (
      <LoginScreen
        onSubmit={onCustomerSelect}
        error={error}
        isLoading={authState.status === 'AUTHENTICATING'}
        appName={appName}
      />
    );
  }
  if (value.authState.status === 'ERROR') {
    return (
      <LoginScreen
        onSubmit={onCustomerSelect}
        error={value.authState.message || error}
      />
    );
  }
  return (
    <AuthContext.Provider value={value as AuthContextType}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context) {
    // we never even render context if it is not for already authenticated user, so we do manual type narrowing here
    return context;
  }
  throw new Error('useAuthContext must be used within an AuthProvider');
};
