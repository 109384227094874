import styled from 'styled-components';

import { Flex } from '@cognite/cogs.js-v10';

export const Container = styled(Flex).attrs({
  alignItems: 'center',
  gap: 12,
})`
  padding: 10px 16px;
  width: 100%;
  background-color: var(--cogs-surface--muted);
  border-bottom: 1px solid var(--cogs-border--muted);
`;

export const LeftSideWrapper = styled(Flex)`
  align-items: center;
  gap: 10px;
  overflow: hidden;
`;

export const RightSideWrapper = styled(Flex)`
  flex: 1;
  gap: 10px;
  justify-content: end;
`;
