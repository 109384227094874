import { Infobox, Skeleton } from '@cognite/cogs.js-v10';
import {
  useAppConfigQuery,
  useFeatureToggleConfig,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import {
  AssetExplorationViews,
  useClassicOrAPMAssetQuery,
} from '@infield/features/asset';
import { Asset3dView, AssetTreedCard } from '@infield/features/asset/asset-3d';
import {
  AssetDocumentsCard,
  AssetDocumentsView,
} from '@infield/features/asset/asset-documents';
import { AssetHeader } from '@infield/features/asset/asset-header/asset-header';
import { AssetMediaCard } from '@infield/features/asset/asset-media-card/asset-media-card';
import {
  AssetPropertiesCard,
  AssetPropertiesView,
} from '@infield/features/asset/asset-properties';
import {
  AssetTrendCard,
  AssetTrendsView,
} from '@infield/features/asset/asset-trends';
import { selectedAssetViewAtom } from '@infield/features/asset/state';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { ComponentScrollableContent } from '@infield/features/ui';
import { useIsDesktop } from '@infield/hooks/useIsDesktop';
import { type FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  AssetActivitiesCard,
  AssetActivitiesView,
  selectedActivityInPanelAtom,
} from '../asset-activities';
import {
  AssetNotificationsCard,
  AssetNotificationsView,
  selectedNotificationInPanelAtom,
} from '../asset-notifications';

import * as S from './elements';

interface Props {
  assetExternalId: string;
  hideThreeDCard?: boolean;
  onClose?: () => void;
}

export const Asset: FC<Props> = ({
  assetExternalId,
  hideThreeDCard,
  onClose,
}) => {
  const {
    data: assetData,
    isInitialLoading,
    isSuccess,
  } = useClassicOrAPMAssetQuery(assetExternalId);
  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);
  const { data: appConfig } = useAppConfigQuery();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const setSelectedActivity = useSetRecoilState(selectedActivityInPanelAtom);
  const setSelectedNotification = useSetRecoilState(
    selectedNotificationInPanelAtom
  );

  const [selectedAssetView, setSelectedAssetView] = useRecoilState(
    selectedAssetViewAtom
  );
  const isActiveView = (view: string) => selectedAssetView === view;
  if (hideThreeDCard && isActiveView(AssetExplorationViews.THREED)) {
    setSelectedAssetView('');
  }

  const propertyConfiguration =
    appConfig?.featureConfiguration?.assetPageConfiguration?.propertyCard;

  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );

  const handleOnActivityClick = (activityExternalId: string) => {
    if (isDesktop) {
      setSelectedActivity(activityExternalId);

      setSelectedAssetView(AssetExplorationViews.ACTIVITIES);
    } else {
      navigate(`/activity/${encodeURIComponent(activityExternalId)}/details`);
    }
  };

  const handleOnNotificationClick = (notificationExternalId: string) => {
    if (isDesktop) {
      setSelectedNotification(notificationExternalId);

      setSelectedAssetView(AssetExplorationViews.NOTIFICATIONS);
    } else {
      navigate(
        `/notification/${encodeURIComponent(notificationExternalId)}/details`
      );
    }
  };

  useEffect(() => {
    setSelectedAssetView('');
  }, [setSelectedAssetView]);

  if (isInitialLoading) {
    return <Skeleton.List lines={4} borders />;
  }
  if (isSuccess && assetData)
    return (
      <S.Container>
        <AssetHeader onClose={onClose} assetData={assetData} />
        <ComponentScrollableContent>
          {isActiveView(AssetExplorationViews.THREED) && (
            <Asset3dView assetData={assetData} />
          )}
          {isActiveView(AssetExplorationViews.TRENDS) && (
            <AssetTrendsView assetExternalId={assetExternalId} />
          )}
          {isActiveView(AssetExplorationViews.DOCUMENTS) && (
            <AssetDocumentsView assetExternalId={assetExternalId} />
          )}
          {isActiveView(AssetExplorationViews.PROPERTIES) && (
            <AssetPropertiesView
              asset={assetData}
              isLoading={isInitialLoading}
            />
          )}

          {isActiveView(AssetExplorationViews.ACTIVITIES) && (
            <AssetActivitiesView assetExternalId={assetExternalId} />
          )}

          {isActiveView(AssetExplorationViews.NOTIFICATIONS) && (
            <AssetNotificationsView assetExternalId={assetExternalId} />
          )}
          {!selectedAssetView && (
            <S.CardContainer $isDesktop={isDesktop}>
              {!hideThreeDCard && rootLocationFeatureToggles?.threeD && (
                <AssetTreedCard
                  assetId={'id' in assetData ? assetData?.id : undefined}
                  assetExternalId={assetExternalId}
                  onOpen={() =>
                    setSelectedAssetView(AssetExplorationViews.THREED)
                  }
                />
              )}
              {rootLocationFeatureToggles?.trends && (
                <AssetTrendCard
                  assetExternalId={assetExternalId}
                  onOpen={() =>
                    setSelectedAssetView(AssetExplorationViews.TRENDS)
                  }
                />
              )}
              {rootLocationFeatureToggles?.documents && 'id' in assetData && (
                <AssetDocumentsCard
                  assetExternalId={
                    assetData.externalId || 'missingAssetExternalId'
                  }
                  onOpen={() =>
                    setSelectedAssetView(AssetExplorationViews.DOCUMENTS)
                  }
                />
              )}
              {rootLocationFeatureToggles?.workorders && (
                <AssetActivitiesCard
                  assetExternalId={assetExternalId}
                  onOpen={() =>
                    setSelectedAssetView(AssetExplorationViews.ACTIVITIES)
                  }
                  onActivityClick={handleOnActivityClick}
                />
              )}
              {rootLocationFeatureToggles?.notifications && (
                <AssetNotificationsCard
                  assetExternalId={assetExternalId}
                  onOpen={() =>
                    setSelectedAssetView(AssetExplorationViews.NOTIFICATIONS)
                  }
                  onNotificationClick={handleOnNotificationClick}
                />
              )}
              <AssetPropertiesCard
                assetMetadata={assetData.metadata}
                config={propertyConfiguration}
                onOpen={() =>
                  setSelectedAssetView(AssetExplorationViews.PROPERTIES)
                }
              />
              {rootLocationFeatureToggles?.media && 'id' in assetData && (
                <AssetMediaCard assetExternalId={assetExternalId} />
              )}
            </S.CardContainer>
          )}
        </ComponentScrollableContent>
      </S.Container>
    );
  return (
    <Infobox status="warning">
      {t(
        'noDataforAssetId',
        'Did not find any data for the given assetExternalId'
      )}
    </Infobox>
  );
};
