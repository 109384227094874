import { sleep } from '@cognite/lodashy';
import type { CogniteClient } from '@cognite/sdk';

export const getBaseUrlFromClient = (client: CogniteClient) => {
  return `${client.getBaseUrl()}/api/v1/projects/${client.project}`;
};

const retrieveFunctionId = async (
  client: CogniteClient,
  functionExtId: string
) => {
  const response = await client.post<{ items: { id: string }[] }>(
    `${getBaseUrlFromClient(client)}/functions/byids`,
    {
      data: {
        items: [{ externalId: functionExtId }],
      },
    }
  );
  if (!response?.data?.items?.[0]) {
    throw new Error(`No function found with external id '${functionExtId}'`);
  }

  return response.data.items[0].id;
};

const createSession = (client: CogniteClient) => {
  return client
    .post<{ items: { id: string; status: string; nonce: string }[] }>(
      `${getBaseUrlFromClient(client)}/sessions`,
      {
        data: {
          items: [{ tokenExchange: true }],
        },
      }
    )
    .then((response) => {
      return response.data.items[0];
    });
};

const retrieveCallStatus = async (
  client: CogniteClient,
  functionId: string,
  callId: string
) => {
  const res = await client.get<{
    status: 'Running' | 'Completed' | 'Failed' | 'Timeout';
  }>(`${getBaseUrlFromClient(client)}/functions/${functionId}/calls/${callId}`);
  return res;
};

const retrieveCallResponse = async <Response>(
  client: CogniteClient,
  functionId: string,
  callId: string
): Promise<Response> => {
  const res = await client.get<{ response?: Response }>(
    `${getBaseUrlFromClient(
      client
    )}/functions/${functionId}/calls/${callId}/response`
  );

  if (!res.data.response) {
    throw new Error(`Function call '${callId}' did not have a response`);
  }
  return res.data.response;
};

const callFunction = async (
  client: CogniteClient,
  functionId: string,
  data?: any,
  prevNonce?: string
) => {
  const nonce = prevNonce || (await createSession(client)).nonce;
  const res = await client.post(
    `${getBaseUrlFromClient(client)}/functions/${functionId}/call`,
    {
      data: {
        data,
        nonce,
      },
    }
  );
  return res;
};

const waitForFunctionResult = async <Response>(
  client: CogniteClient,
  functionId: string,
  callId: string,
  numberOfTries = 15
): Promise<Response> => {
  if (numberOfTries < 1) {
    throw new Error(`Function call '${callId}' exceeded retry limit`);
  }
  await sleep(4000);
  const res = await retrieveCallStatus(client, functionId, callId);
  if (res.data.status === 'Running') {
    return waitForFunctionResult<Response>(
      client,
      functionId,
      callId,
      numberOfTries - 1
    );
  } else if (res.data.status === 'Completed') {
    return await retrieveCallResponse<Response>(client, functionId, callId);
  } else if (res.data.status === 'Failed') {
    throw new Error(`Function call '${callId}' failed`);
  } else {
    throw new Error(`Function call '${callId}' timed out`);
  }
};

export const callCogniteFunctionAndAwaitResponse = async (
  client: CogniteClient,
  functionExtId: string,
  data: any[] = []
) => {
  const functionId = await retrieveFunctionId(client, functionExtId);
  const nonces: { nonce: string }[] = await Promise.all(
    data.map(() => createSession(client))
  );
  const calls: any[] = await Promise.all(
    data.map((dataItem, i) =>
      callFunction(client, functionId, dataItem, nonces[i].nonce)
    )
  );
  const responces = await Promise.all(
    calls.map((call) => waitForFunctionResult(client, functionId, call.data.id))
  );

  return responces;
};
