import { useQuery } from '@tanstack/react-query';

import type { CogniteClient } from '@cognite/sdk';

import type { AuthState } from '..';

export const useProjectsQuery = (
  client: CogniteClient,
  authState: AuthState
) => {
  const org =
    authState?.status === 'AUTHENTICATED' && authState.projectInfo.org;

  // Check this to avoid running the query in E2E tests because the E2E testing auth token
  // is using legacy auth and the query is using the new auth (cogidp), even though the project is
  // set up to use cogidp.
  // This is a temporary solution until we have a better way to handle this with a real user login.
  const authTokenForE2ETests = localStorage.getItem('E2E_TOKEN');

  return useQuery(['projects', org], async () => {
    await client.authenticate();
    if (org && !authTokenForE2ETests) {
      return client
        .get<{ items: { name: string; apiUrl: string }[] }>(
          `https://auth.cognite.com/api/v0/orgs/${org}/projects`,
          {
            withCredentials: true,
            headers: {
              Authorization: String(
                client.getDefaultRequestHeaders().Authorization
              ),
            },
          }
        )
        .then((res) => res.data.items);
    }
    return null;
  });
};
