/* eslint-disable no-param-reassign */
import cloneDeep from 'lodash/cloneDeep';

import type { Filters, Sort } from '@cognite/fdm-client';
import type {
  CogniteClient,
  NextCursorV3,
  QueryRequest,
  ReverseDirectRelationConnection,
  ViewDefinition,
  ViewDirectNodeRelation,
  ViewPropertyDefinition,
  ViewReference,
} from '@cognite/sdk';
// eslint-disable-next-line @cognite/no-sdk-submodule-imports
import type { EdgeConnection } from '@cognite/sdk/dist/src/api/views/types.gen';

import type { CdfProperty, PropertyTree } from './types';
import {
  convertThroughReferenceToViewPropertyRefence,
  createFilterWithSpaces,
  isAndFilter,
  isViewPropertyDefinition,
  toDMSFilter,
  toDMSSort,
} from './utils';
import type { ViewCache } from './view-cache';

export type QueryBuilderBuildParams = {
  spaces?: string[];
  nextCursor?: string;
  pageSize?: number;
  filters?: Filters;
  sort?: Sort;
  propertyTree?: PropertyTree;
  properties?: string[];
  relationCursors?: Record<string, NextCursorV3>;
  includeNullInDescSort?: boolean;
};

export class QueryBuilder {
  cogniteSDK: CogniteClient;
  viewReference: ViewReference;
  defaultInstanceSpace: string;
  viewDetails: ViewDefinition | null;
  viewCache: ViewCache;

  rootQuery: QueryRequest = { select: {}, with: {} };

  constructor(
    cogniteSDK: CogniteClient,
    viewReference: Omit<ViewReference, 'type'>,
    defaultInstanceSpace: string,
    viewCache: ViewCache
  ) {
    this.cogniteSDK = cogniteSDK;
    this.defaultInstanceSpace = defaultInstanceSpace;
    this.viewReference = { ...viewReference, type: 'view' };
    this.viewCache = viewCache;
    this.viewDetails = null;
  }

  /**
   * List items from the view
   * @param variables - Variables for the query
   * @returns List of items
   */
  build = async (
    variables: QueryBuilderBuildParams
  ): Promise<{ query: QueryRequest; cdfProperties: CdfProperty[] }> => {
    const {
      sort,
      filters = {},
      nextCursor,
      pageSize,
      spaces = [this.defaultInstanceSpace],
      propertyTree = {},
      properties = ['*'],
      includeNullInDescSort = false,
    } = variables;

    const viewDefinition = await this.fetchViewDetails();

    const clonedFilters = cloneDeep(filters);

    // Add null filters for DESC sort items
    sort?.forEach((s) => {
      if (clonedFilters.and === undefined) {
        clonedFilters.and = [];
      }
      if (Object.values(s)[0] === 'DESC' && !includeNullInDescSort) {
        clonedFilters.and.push({
          isNull: {
            property: Object.keys(s)[0],
            isNull: false,
          },
        });
      }
    });
    const dmsFilters = await toDMSFilter(
      clonedFilters,
      viewDefinition,
      this.viewCache
    );

    this.rootQuery = {
      with: {
        [`${viewDefinition.externalId}/${viewDefinition.version}`]: {
          sort: [...toDMSSort(this.viewReference, sort)],
          limit: pageSize,
          nodes: {
            filter: createFilterWithSpaces(dmsFilters, spaces, [
              this.viewReference,
            ]),
          },
        },
      },
      select: {
        [`${viewDefinition.externalId}/${viewDefinition.version}`]: {
          sources: [
            {
              source: this.viewReference,
              properties: [...properties, ...Object.keys(propertyTree)],
            },
          ],
        },
      },
    };
    if (nextCursor) {
      this.rootQuery.cursors = {
        [`${viewDefinition.externalId}/${viewDefinition.version}`]: nextCursor,
      };
    }

    const { query, nextCdfProperties } = await this.appendRelationsToWithQuery(
      `${viewDefinition.externalId}/${viewDefinition.version}`,
      propertyTree,
      variables.relationCursors
    );

    return { query, cdfProperties: nextCdfProperties };
  };

  async appendRelationsToWithQuery(
    from: string,
    propertyTree: PropertyTree,
    relationCursors: Record<string, NextCursorV3> | undefined,
    level = 0
  ) {
    const viewDefinition = await this.fetchViewDetails();

    // Grab CDF properties (files, timeseries), for later use.
    const nextCdfProperties = Object.entries(viewDefinition.properties)
      .filter(
        ([_, value]) =>
          isViewPropertyDefinition(value) &&
          (value.type.type === 'file' || value.type.type === 'timeseries')
      )
      .map(([key, value]) => ({
        viewDefinition,
        property: key,
        type: isViewPropertyDefinition(value) ? value.type.type : '',
        key: from,
        queryInstanceKey: `${viewDefinition.externalId}/${viewDefinition.version}_${key}_${level}`,
      }));

    await this.appendDirectRelationsToQuery(
      viewDefinition,
      propertyTree,
      from,
      relationCursors,
      level
    );

    await this.appendMultiReverseDirectRelationsToQuery(
      viewDefinition,
      propertyTree,
      from,
      relationCursors,
      level
    );

    await this.appendEdgesToQuery(
      viewDefinition,
      propertyTree,
      from,
      relationCursors,
      level
    );

    return { query: this.rootQuery, nextCdfProperties };
  }

  mergeQueries(query1: QueryRequest, query2: QueryRequest): QueryRequest {
    return {
      select: {
        ...query1.select,
        ...query2.select,
      },
      with: {
        ...query1.with,
        ...query2.with,
      },
      cursors: {
        ...query1.cursors,
        ...query2.cursors,
      },
    };
  }

  async fetchViewDetails() {
    const viewDefinition = await this.viewCache.fetchViewDetails(
      this.viewReference
    );

    if (viewDefinition === undefined) {
      console.error(this.viewReference);
      throw new Error('Could not find view definition');
    }

    return viewDefinition;
  }

  private async appendDirectRelationsToQuery(
    viewDefinition: ViewDefinition,
    propertyTree: PropertyTree,
    from: string,
    relationCursors: Record<string, NextCursorV3> | undefined,
    level: number
  ) {
    // Collect direct relation properties from view, that are within the property tree
    const directRelations = Object.entries(viewDefinition.properties)
      .filter(([key, value]) => {
        return (
          isViewPropertyDefinition(value) &&
          value.type.type === 'direct' &&
          Object.keys(propertyTree).includes(key)
        );
      })
      .map(([key, value]) => {
        const viewPropDef = value as ViewPropertyDefinition;
        const view = (viewPropDef.type as ViewDirectNodeRelation).source!;

        return {
          key,
          view,
          queryBuilder: new QueryBuilder(
            this.cogniteSDK,
            {
              externalId: view.externalId,
              space: view.space,
              version: view.version,
            },
            this.defaultInstanceSpace,
            this.viewCache
          ),
        };
      });

    // For each direct relation, build the query to fetch them, and append to the root query
    await Promise.all(
      directRelations.map(async ({ key, view, queryBuilder }) => {
        const filter = await toDMSFilter(
          propertyTree[key].filters,
          viewDefinition,
          this.viewCache
        );
        const queryKey = `${viewDefinition.externalId}/${viewDefinition.version}_${key}_${level}`;
        const limit = propertyTree[key].limit || 1000;
        this.rootQuery.with[queryKey] = {
          limit,
          sort: toDMSSort(view, propertyTree[key].sort),
          nodes: {
            from,
            filter,
            through: {
              view: this.viewReference,
              identifier: key,
            },
            direction: 'outwards',
          },
        };

        this.rootQuery.select[queryKey] = {
          sources: [{ source: view, properties: ['*'] }],
        };

        const previousQueryCursor = relationCursors?.[queryKey];
        if (previousQueryCursor) {
          if (!this.rootQuery.cursors) {
            this.rootQuery.cursors = {};
          }
          this.rootQuery.cursors[queryKey] = previousQueryCursor;
        }

        if (Object.keys(propertyTree[key] || {}).length > 0) {
          const nextQuery = await queryBuilder.appendRelationsToWithQuery(
            `${viewDefinition.externalId}/${viewDefinition.version}_${key}_${level}`,
            propertyTree[key].propertyTree || {},
            relationCursors,
            level + 1
          );

          this.rootQuery = this.mergeQueries(this.rootQuery, nextQuery.query);
        }
      })
    );
  }

  private async appendMultiReverseDirectRelationsToQuery(
    viewDefinition: ViewDefinition,
    propertyTree: PropertyTree,
    from: string,
    relationCursors: Record<string, NextCursorV3> | undefined,
    level: number
  ) {
    // Append reverse direct relations
    const reverseDirectRelationsProperties = Object.entries(
      viewDefinition.properties
    )
      .filter(([key, value]) => {
        return (
          !isViewPropertyDefinition(value) &&
          value.connectionType === 'multi_reverse_direct_relation' &&
          Object.keys(propertyTree).includes(key)
        );
      })
      .map(([key, value]) => {
        const viewPropDef = value as ReverseDirectRelationConnection;
        const view = viewPropDef.source;

        return {
          key,
          viewPropDef,
          view,
          queryBuilder: new QueryBuilder(
            this.cogniteSDK,
            {
              externalId: view.externalId,
              space: view.space,
              version: view.version,
            },
            this.defaultInstanceSpace,
            this.viewCache
          ),
        };
      });

    await Promise.all(
      reverseDirectRelationsProperties.map(
        async ({ key, viewPropDef, view, queryBuilder }) => {
          const queryKey = `${viewDefinition.externalId}/${viewDefinition.version}_${key}:${viewPropDef.through.identifier}:inwards_${level}`;

          const filter = await toDMSFilter(
            propertyTree[key].filters,
            viewDefinition,
            this.viewCache
          );

          this.rootQuery.with[queryKey] = {
            limit: propertyTree[key].limit || 1000,
            sort: toDMSSort(view, propertyTree[key].sort),
            nodes: {
              from,
              through: convertThroughReferenceToViewPropertyRefence(
                viewPropDef.through
              ),
              direction: 'inwards',
              filter: {
                and: isAndFilter(filter)
                  ? [...filter.and, { hasData: [view] }]
                  : [{ hasData: [view] }],
              },
            },
          };

          this.rootQuery.select[queryKey] = {
            sources: [{ source: view, properties: ['*'] }],
          };

          const previousQueryCursor = relationCursors?.[queryKey];
          if (previousQueryCursor) {
            if (!this.rootQuery.cursors) {
              this.rootQuery.cursors = {};
            }
            this.rootQuery.cursors[queryKey] = previousQueryCursor;
          }

          if (Object.keys(propertyTree[key] || {}).length > 0) {
            const nextQuery = await queryBuilder.appendRelationsToWithQuery(
              queryKey,
              propertyTree[key].propertyTree || {},
              relationCursors,
              level + 1
            );
            this.rootQuery = this.mergeQueries(this.rootQuery, nextQuery.query);
          }
        }
      )
    );
  }

  private async appendEdgesToQuery(
    viewDefinition: ViewDefinition,
    propertyTree: PropertyTree,
    from: string,
    relationCursors: Record<string, NextCursorV3> | undefined,
    level: number
  ) {
    // Append edges
    const edgeProperties = Object.entries(viewDefinition.properties)
      .filter(([key, value]) => {
        return (
          !isViewPropertyDefinition(value) &&
          value.connectionType === 'multi_edge_connection' &&
          Object.keys(propertyTree).includes(key)
        );
      })
      .map(([key, value]) => {
        const viewPropDef = value as EdgeConnection;
        const view = viewPropDef.source;

        return {
          key,
          viewPropDef,
          view,
          queryBuilder: new QueryBuilder(
            this.cogniteSDK,
            {
              externalId: view.externalId,
              space: view.space,
              version: view.version,
            },
            this.defaultInstanceSpace,
            this.viewCache
          ),
        };
      });

    await Promise.all(
      edgeProperties.map(async ({ key, viewPropDef, view, queryBuilder }) => {
        const queryKey = `${viewDefinition.externalId}/${viewDefinition.version}_${key}_${level}`;
        const edgesQueryKey = `${viewDefinition.externalId}/${viewDefinition.version}_${key}_edges_${level}`;

        this.rootQuery.with[edgesQueryKey] = {
          limit: propertyTree[key].limit || 1000,
          edges: {
            from,
            direction: 'outwards',
            filter: {
              equals: {
                property: ['edge', 'type'],
                value: viewPropDef.type,
              },
            },
          },
        };

        const filter = await toDMSFilter(
          propertyTree[key].filters,
          viewDefinition,
          this.viewCache
        );

        this.rootQuery.with[queryKey] = {
          limit: propertyTree[key].limit || 1000,
          sort: toDMSSort(view, propertyTree[key].sort),
          nodes: {
            from: edgesQueryKey,
            direction: 'outwards',
            filter: {
              and: isAndFilter(filter)
                ? [...filter.and, { hasData: [view] }]
                : [{ hasData: [view] }],
            },
          },
        };

        this.rootQuery.select[edgesQueryKey] = {};
        this.rootQuery.select[queryKey] = {
          sources: [{ source: view, properties: ['*'] }],
        };
        const previousEdgesQueryCursor = relationCursors?.[edgesQueryKey];
        if (previousEdgesQueryCursor) {
          if (!this.rootQuery.cursors) {
            this.rootQuery.cursors = {};
          }
          this.rootQuery.cursors[edgesQueryKey] = previousEdgesQueryCursor;
        }
        if (Object.keys(propertyTree[key] || {}).length > 0) {
          const nextQuery = await queryBuilder.appendRelationsToWithQuery(
            queryKey,
            propertyTree[key].propertyTree || {},
            relationCursors,
            level + 1
          );
          this.rootQuery = this.mergeQueries(this.rootQuery, nextQuery.query);
        }
      })
    );
  }
}
