// This is the *publicly* exposed interface container types.
export enum ContainerType {
  PROPERTY_LIST = 'propertyListContainer',
  CHARTS = 'chartsContainer',
  DOCUMENT = 'documentContainer',
  IMAGE = 'imageContainer',
  REVEAL = 'revealContainer',
  TABLE = 'tableContainer',
  TEXT = 'textContainer',
  TIMESERIES = 'timeseriesContainer',
  VIDEO = 'videoContainer',
  DATA_GRID = 'dataGridContainer',
}
