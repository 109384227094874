import { isEqual } from 'lodash-es';

import UnifiedViewerEventType from '../../UnifiedViewerEventType';
import { ContainerType } from '../ContainerType';
import ReactContainer, {
  ReactContainerRenderContentProps,
} from '../ReactContainer';
import ErrorContent from '../TimeseriesContainer/ErrorContent';

import DataGridContent, { DATA_GRID_CONTEXT_MENU_KEY } from './DataGridContent';
import type {
  DataGridColumn,
  DataGridContainerProps,
  DataGridRow,
} from './types';

export default class DataGridContainer<MetadataType> extends ReactContainer<
  MetadataType,
  DataGridContainerProps<MetadataType>
> {
  public readonly type = ContainerType.DATA_GRID;
  protected override readonly shouldRefreshScreenshotWhileInActiveMode: boolean =
    true;

  protected getPropsRequiringUpdate(): Array<keyof DataGridContainerProps> {
    return ['columns', 'rows'];
  }

  // We don't want the container to be draggable when the data grid container is in active mode.
  // Otherwise, the container gets dragged while the user is e.g. trying to resize the columns/rows.
  public override canBeDraggedWhileActive(): boolean {
    return false;
  }

  protected renderContent = ({
    width,
    height,
    unscaledWidth,
    unscaledHeight,
    shouldAutoSize,
    setLoadingStatus,
    onContentSizeChange,
  }: ReactContainerRenderContentProps): JSX.Element => {
    if (this.loadingStatus.isError) {
      return <ErrorContent width={width} height={height} />;
    }
    return (
      <DataGridContent
        width={width}
        height={height}
        rows={this.props.rows}
        columns={this.props.columns}
        unscaledWidth={unscaledWidth}
        unscaledHeight={unscaledHeight}
        shouldAutoSize={shouldAutoSize}
        setLoadingStatus={setLoadingStatus}
        onContentSizeChange={onContentSizeChange}
        onRowsUpdated={this.onRowsUpdated}
        onColumnUpdated={this.onColumnUpdated}
        containerPadding={this.getHeaderNodeDimensions().height}
        onCellClick={this.props.onCellClick}
      />
    );
  };

  private onColumnUpdated = (nextColumns: DataGridColumn[]): void => {
    if (isEqual(nextColumns, this.props.columns)) {
      return;
    }
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [{ ...this.serialize(), columns: nextColumns }],
      annotations: [],
    });
  };

  private onRowsUpdated = (nextRows: DataGridRow[]): void => {
    if (isEqual(nextRows, this.props.rows)) {
      return;
    }
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [{ ...this.serialize(), rows: nextRows }],
      annotations: [],
    });
  };

  // On clone, we want to remove the input elements from the cloned dom node and
  // replace them with the text content. Moreover, we want to remove the border
  // from the grid cells that are in editing mode.
  protected override onClone = (clonedDomNode: HTMLElement): void => {
    // Remove context menu from the cloned dom node
    clonedDomNode.querySelector(`#${DATA_GRID_CONTEXT_MENU_KEY}`)?.remove();

    // Make sure we're not taking screenshot of the data grid while it's in editing mode
    clonedDomNode.querySelectorAll('.tabulator-editing').forEach((node) => {
      if (!(node instanceof HTMLElement) || node.role !== 'gridcell') {
        return;
      }
      const inputElement = Array.from(node.children).find(
        (child): child is HTMLInputElement => child.tagName === 'INPUT'
      );
      const inputTextContent = inputElement?.value ?? '';
      node.textContent = inputTextContent;
      node.style.border = 'none'; // Remove the border
      inputElement?.remove();
    });
  };

  public serialize = (): DataGridContainerProps => {
    return {
      ...this.serializeBaseContainerProps(),
      rows: this.props.rows,
      columns: this.props.columns,
      type: this.type,
    };
  };
}
