import { useEffect } from 'react';

import { Size } from '../../annotations/types';

import { DataGridColumn, DataGridRow } from './types';

const DOWNSIZE_CONTENT_PERCENTAGE = 0.95;
const UPSIZE_MARGIN_PX = 5;

type UseAutoResizeDataGridProps = {
  gridContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  shouldAutoSize: boolean;
  containerPadding: number;
  onContentSizeChange: (newSize: Size) => void;
  unscaledWidth: number;
  unscaledHeight: number;
  width: number;
  height: number;
  rows: DataGridRow[];
  columns: DataGridColumn[];
  forceUpdate?: number;
};

// This hook is responsible for resizing the grid based on its content. In
// short, the resizing will occur when/if the underlying HTML content overflows
// the boundaries defined by unscaledWidth and unscaledHeight (i.e., the UFV
// container boundaries). In particular, through the onContentSizeChange
// callback, this hook will increase the height of the UFV container if the
// content overflows, decrease it if the content covers less than a given
// percentage of the table, and increase the width if the table overflows.
// Note that, we mainly re-run the hook when the the data, columns, and/or size
// of the container changes.
const useAutoResizeDataGrid = ({
  gridContainerRef,
  shouldAutoSize,
  containerPadding,
  onContentSizeChange,
  unscaledWidth,
  unscaledHeight,
  width,
  height,
  rows,
  columns,
  forceUpdate,
}: UseAutoResizeDataGridProps) => {
  useEffect(
    () => {
      // We need to wait for the next tick to ensure the grid has been rendered
      const timerId = setTimeout(() => {
        const containerElement = gridContainerRef.current;
        if (containerElement === null || shouldAutoSize !== true) {
          return;
        }
        const tableElement = containerElement.querySelector('.tabulator-table');
        if (tableElement === null) {
          return;
        }

        // Increase the width if the table content overflows the container
        if (
          tableElement.clientWidth - containerElement.clientWidth >
          UPSIZE_MARGIN_PX
        ) {
          onContentSizeChange({
            width: tableElement.clientWidth,
            height: unscaledHeight,
          });
          return;
        }

        if (
          tableElement.clientHeight - containerElement.clientHeight >
          UPSIZE_MARGIN_PX
        ) {
          onContentSizeChange({
            width: unscaledWidth,
            height: tableElement.clientHeight + containerPadding,
          });
          return;
        }

        // Decrease the height if the table content covers less than a given percentage
        const tableContentHeight = tableElement.clientHeight;
        if (
          tableContentHeight / containerElement.clientHeight <
            DOWNSIZE_CONTENT_PERCENTAGE &&
          tableContentHeight < containerElement.clientHeight
        ) {
          onContentSizeChange({
            width: unscaledWidth,
            height: tableContentHeight,
          });
          return;
        }

        // Decrease the width if the table content covers less than a given percentage
        const tableContentWidth = tableElement.clientWidth;
        if (
          tableContentWidth / containerElement.clientWidth <
            DOWNSIZE_CONTENT_PERCENTAGE &&
          tableContentWidth < containerElement.clientWidth
        ) {
          onContentSizeChange({
            width: tableContentWidth,
            height: unscaledHeight,
          });
          return;
        }
      }, 0);
      return () => clearTimeout(timerId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      rows,
      columns,
      gridContainerRef.current,
      shouldAutoSize,
      width,
      height,
      unscaledWidth,
      unscaledHeight,
      onContentSizeChange,
      containerPadding,
      forceUpdate,
    ]
  );
};

export default useAutoResizeDataGrid;
