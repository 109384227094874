import { makeToast, Menu } from '@cognite/cogs-lab';
import { Button, EllipsisVerticalIcon } from '@cognite/cogs.js-v10';

interface Props {
  isDateFilterValid: boolean;
  translationFunction: any;
  handleGetMissingChecklistsButtonClick: () => Promise<void>;
}

export const OverviewTableExtraOptionsUi = ({
  isDateFilterValid,
  translationFunction: t,
  handleGetMissingChecklistsButtonClick,
}: Props) => {
  const onButtonClick = async () => {
    await handleGetMissingChecklistsButtonClick();
    if (!isDateFilterValid) {
      makeToast(
        {
          body: t(
            'FETCH_PLANNED_CHECKLISTS_TOAST_ERROR_INVALID_RANGE',
            'The date range must be 7 days or less and not be a future date'
          ),
          type: 'warning',
        },
        { autoClose: 10000 }
      );
      return;
    }
  };

  return (
    <>
      <Menu
        renderTrigger={(props) => (
          <Button
            {...props}
            data-testid="overview-table-extra-options-menu"
            type="ghost"
            icon={<EllipsisVerticalIcon />}
            aria-label={t('button.ariaLabel.extraOptions', 'Options')}
          />
        )}
      >
        <Menu.ItemAction
          data-testid="get-missing-checklists-button"
          onClick={onButtonClick}
          label={t(
            'CHECK_FOR_MISSING_CHECKLISTS_BUTTON_TEXT',
            'Find missing checklists'
          )}
        />
      </Menu>
    </>
  );
};
