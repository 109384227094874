import type { Checklist, Operation, UserFilter } from '@cognite/apm-client';
import type { GridFilterModel } from '@cognite/apm-observation';
import {
  Button,
  ChecklistIcon,
  DeleteIcon,
  Input,
  SearchIcon,
} from '@cognite/cogs.js-v10';
import type { Filters } from '@cognite/fdm-client';
import { setUserPreferredColumnsToSelected } from '@infield/features/activities/utils';
import {
  useFeatureToggleConfig,
  useIsChecklistAdminQuery,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { OverviewTableExtraOptions } from '@infield/features/checklist/checklist-scheduler/overview-table-extra-options';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { DateRangePicker } from '@infield/features/ui';
import {
  useUpsertCurrentUserPreferences,
  useUserPreferencesDelete,
} from '@infield/features/user';
import { useDebounce } from '@infield/hooks/use-debounce';
import { useChecklistSchedulerContext } from '@infield/providers/checklist-scheduler-provider/checklist-scheduler-context';
import { FDMFilterToGridFilter } from '@infield/utils/grid-filter-to-fdm-filter';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { FilterMenuContainer } from '../filter-menu';
import {
  dateFilterAtom,
  overviewTableSearchQueryAtom,
  selectedOverviewTableAtom,
} from '../state';
import type { FilterTableType, SelectedColumnOption } from '../types';

import { ActivityTableHiddenColumns } from './activity-table-hidden-columns';
import { ActivityTableSelectionControl } from './activity-table-selection-control';
import * as S from './elements';
import type { PickerShortcut } from './types';

interface Props {
  allFilters: UserFilter[];
  filterType: FilterTableType;
  allColumns: SelectedColumnOption[];
  savedColumnState: SelectedColumnOption[];
  setColumns: (columns: SelectedColumnOption[]) => void;
  loadingColumns?: boolean;
  selectedOperations?: Operation[];
  selectedChecklists: Checklist[];
  onCreateAndAssign: () => void;
  activitiesFilter?: Filters;
  checklistFilter?: Filters;
  checklistTableFdmFilter?: Filters;
  selectedFilter?: string;
  setActivityFilterModel?: (activityFilter: GridFilterModel) => void;
  setChecklistFilterModel?: (checklistFilter: GridFilterModel) => void;
  setChecklistTableFdmFilterModel?: (
    checklistTableFdmFilter: GridFilterModel
  ) => void;
  setSelectedFilter: (filterExternalId?: string) => void;
  onDeleteChecklists?: (externalIds: string[]) => void;
}

export const ActivityPlanningToolbar: FC<Props> = ({
  allFilters,
  filterType,
  allColumns,
  savedColumnState,
  setColumns,
  loadingColumns = false,
  selectedOperations,
  selectedChecklists,
  onCreateAndAssign,
  activitiesFilter,
  checklistFilter,
  checklistTableFdmFilter,
  setActivityFilterModel,
  setChecklistFilterModel,
  setChecklistTableFdmFilterModel,
  selectedFilter,
  setSelectedFilter,
  onDeleteChecklists,
}) => {
  const { isChecklistSchedulerEnabled } = useChecklistSchedulerContext();
  const { mutateAsync: upsertUserPreferences } =
    useUpsertCurrentUserPreferences();
  const { mutateAsync: deleteUserPreferences } = useUserPreferencesDelete();
  const { data: isChecklistAdmin } = useIsChecklistAdminQuery();
  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );

  const [dateFilter, setDateFilter] = useRecoilState(dateFilterAtom);
  const setOverviewTableSearchQuery = useSetRecoilState(
    overviewTableSearchQueryAtom
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedQuery = useDebounce(searchQuery, 500);
  const [selectedOverviewTable, setSelectedOverviewTable] = useRecoilState(
    selectedOverviewTableAtom
  );
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const [unsavedChanges, setUnsavedChanges] =
    useState<SelectedColumnOption[]>(savedColumnState);

  useEffect(() => {
    if (unsavedChanges !== savedColumnState) {
      setUnsavedChanges(savedColumnState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedColumnState]);

  useEffect(() => {
    setOverviewTableSearchQuery(debouncedQuery);
  }, [debouncedQuery, setOverviewTableSearchQuery]);

  useEffect(() => {
    const { workorderChecklistFlow, templateChecklistFlow } =
      rootLocationFeatureToggles || {};

    if (!workorderChecklistFlow) {
      setSelectedOverviewTable('checklists-table');
    }
    if (!templateChecklistFlow) {
      setSelectedOverviewTable('maintenance-table');
    }
  }, [rootLocationFeatureToggles, setSelectedOverviewTable]);

  const isChecklistsTableSelected =
    selectedOverviewTable === 'checklists-table';

  const getCreateAndAssignTitle = () => {
    if (!isChecklistsTableSelected && selectedChecklists.length === 0) {
      return t(
        'ACTIVITY_OVERVIEW_TOOLBAR_ACTION_CREATE_AND_ASSIGN',
        'Create & assign'
      );
    }
    const hasChecklistWithAssignees = selectedChecklists.some(
      (checklist) => checklist.assignedTo && checklist.assignedTo.length > 0
    );
    if (hasChecklistWithAssignees) {
      return t('ACTIVITY_OVERVIEW_TOOLBAR_ACTION_REASSIGN', 'Reassign');
    }
    return t('ACTIVITY_OVERVIEW_TOOLBAR_ACTION_ASSIGN', 'Assign');
  };

  const handleApplyFilters = (filterExternalId: string) => {
    handleClearFilters();
    // Find filter
    const newSelectedFilter = allFilters.find(
      (filter) => filter.externalId === filterExternalId
    );

    if (newSelectedFilter?.columns) {
      const newColumns = setUserPreferredColumnsToSelected(
        newSelectedFilter.columns,
        allColumns
      );

      setColumns(newColumns);
    } else {
      setColumns(
        allColumns.map((col) => {
          return {
            ...col,
            selected: false,
          };
        })
      );
    }

    // Apply filters
    if (newSelectedFilter?.filters?.activityFilter) {
      const activityFilterFormatted = FDMFilterToGridFilter(
        newSelectedFilter.filters.activityFilter
      );
      if (setActivityFilterModel)
        setActivityFilterModel(activityFilterFormatted || []);
    }
    if (newSelectedFilter?.filters?.checklistFilter) {
      const checklistFilterFormatted = FDMFilterToGridFilter(
        newSelectedFilter.filters.checklistFilter,
        { status: 'checklistStatus' }
      );
      if (setChecklistFilterModel)
        setChecklistFilterModel(checklistFilterFormatted || []);
    }
    if (newSelectedFilter?.filters?.checklistTableFdmFilter) {
      const checklistsTableFilterFormatted = FDMFilterToGridFilter(
        newSelectedFilter.filters.checklistTableFdmFilter
      );
      if (setChecklistTableFdmFilterModel)
        setChecklistTableFdmFilterModel(checklistsTableFilterFormatted || []);
    }

    setSelectedFilter(newSelectedFilter?.externalId);
  };

  const handleSetDefault = (filterExternalId: string) => {
    // Find filter
    const selectedFilter = allFilters.find(
      (filter) => filter.externalId === filterExternalId
    );

    if (!selectedFilter) return;

    // Find current default filter
    const currentDefaultFilter = allFilters.find(
      (filter) => filter.default === true
    );

    // Unset current default
    if (currentDefaultFilter) {
      upsertUserPreferences({
        preference: filterType,
        data: {
          ...currentDefaultFilter,
          default: false,
        },
      });
    }

    if (currentDefaultFilter !== selectedFilter) {
      // Set desired default
      upsertUserPreferences({
        preference: filterType,
        data: {
          ...selectedFilter,
          default: true,
        },
      });
    }
  };

  const handleRename = (filterExternalId: string, name: string) => {
    // Find filter
    const selectedFilter = allFilters.find(
      (filter) => filter.externalId === filterExternalId
    );

    if (!selectedFilter) return;

    // Set new name
    upsertUserPreferences({
      preference: filterType,
      data: {
        ...selectedFilter,
        name,
      },
    });
  };

  const handleDelete = (filterExternalId: string) => {
    if (selectedFilter === filterExternalId) {
      handleClearFilters();
    }
    deleteUserPreferences({ externalIds: [filterExternalId] });
  };

  const handleClearFilters = () => {
    setColumns(allColumns);
    if (setActivityFilterModel) setActivityFilterModel([]);
    if (setChecklistFilterModel) setChecklistFilterModel([]);
    if (setChecklistTableFdmFilterModel) setChecklistTableFdmFilterModel([]);
    setSelectedFilter(undefined);
  };

  const shortcutsContent: PickerShortcut[] = [
    {
      label: t('ACTIVITY_OVERVIEW_TOOLBAR_TIMEFILTER_TODAY', 'Today'),
      getValue: () => {
        const today = dayjs();
        return [today.startOf('day'), today.endOf('day')];
      },
    },
    {
      label: t('ACTIVITY_OVERVIEW_TOOLBAR_TIMEFILTER_THIS_WEEK', 'This week'),
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: t('ACTIVITY_OVERVIEW_TOOLBAR_TIMEFILTER_THIS_MONTH', 'This month'),
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    {
      label: t(
        'ACTIVITY_OVERVIEW_TOOLBAR_TIMEFILTER_LAST_2_WEEKS',
        'Last 2 weeks'
      ),
      getValue: () => {
        const today = dayjs();
        return [today.subtract(2, 'weeks').startOf('day'), today.endOf('day')];
      },
    },
    {
      label: t(
        'ACTIVITY_OVERVIEW_TOOLBAR_TIMEFILTER_NEXT_2_WEEKS',
        'Next 2 weeks'
      ),
      getValue: () => {
        const today = dayjs();
        return [today.startOf('day'), today.add(2, 'weeks').endOf('day')];
      },
    },
    {
      label: t('ACTIVITY_OVERVIEW_TOOLBAR_TIMEFILTER_RESET', 'Reset'),
      getValue: () => [null, null],
    },
  ];

  const isCreateChecklistDisabled =
    (!isChecklistsTableSelected &&
      selectedOperations &&
      selectedOperations.length === 0) ||
    (isChecklistsTableSelected && selectedChecklists.length === 0);

  const isDeleteChecklistVisible =
    onDeleteChecklists && selectedChecklists.length > 0 && isChecklistAdmin;

  return (
    <S.PanelWrapper>
      <S.LeftContainer>
        <Input
          data-testid="activity-planning-toolbar-search-table"
          placeholder={t(
            'ACTIVITY_OVERVIEW_TOOLBAR_SEARCH_INPUT_PLACEHOLDER',
            'Search table'
          )}
          icon={<SearchIcon />}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <FilterMenuContainer
          filterType={filterType}
          columns={savedColumnState}
          selectedFilter={selectedFilter}
          activitiesFilter={activitiesFilter}
          checklistFilter={checklistFilter}
          checklistTableFdmFilter={checklistTableFdmFilter}
          onApply={handleApplyFilters}
          onSetDefault={handleSetDefault}
          setSelectedFilter={setSelectedFilter}
          onRename={handleRename}
          onDelete={handleDelete}
          onClearFilters={handleClearFilters}
        />
        <ActivityTableHiddenColumns
          allColumns={allColumns}
          savedColumnState={savedColumnState}
          loadingColumns={loadingColumns}
          unsavedChanges={unsavedChanges}
          onChange={setColumns}
          setSelectedFilter={setSelectedFilter}
          setUnsavedChanges={setUnsavedChanges}
        />
        <DateRangePicker
          defaultValue={dateFilter}
          displayWeekNumber
          calendars={2}
          onChange={setDateFilter}
          shortcuts={shortcutsContent}
          data-testid="activity-planning-toolbar-date-range-picker"
        />
      </S.LeftContainer>
      <S.RightContainer>
        <Button
          type="primary"
          icon={<ChecklistIcon />}
          disabled={isCreateChecklistDisabled}
          onClick={onCreateAndAssign}
        >
          {getCreateAndAssignTitle()}
        </Button>

        {isDeleteChecklistVisible && (
          <Button
            type="ghost-destructive"
            icon={<DeleteIcon />}
            onClick={() =>
              onDeleteChecklists(
                selectedChecklists.map((checklist) => checklist.externalId)
              )
            }
          >
            {t(
              'OVERVIEW_TABLE_DELETE_CHECKLIST_BUTTON_TEXT',
              'Delete checklist'
            )}
          </Button>
        )}
        <div data-chmln="infield-overview-6596913836fcc30017322431-step-1">
          <ActivityTableSelectionControl />
        </div>
        {isChecklistSchedulerEnabled && <OverviewTableExtraOptions />}
      </S.RightContainer>
    </S.PanelWrapper>
  );
};
