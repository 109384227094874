import type { ImageSize, Size } from '../annotations/types';

const isImageSizeASizeObject = (size: ImageSize): size is Size => {
  if (typeof size === 'object' && 'width' in size && 'height' in size) {
    return true;
  }
  return false;
};

export default isImageSizeASizeObject;
