import type { APMClient } from '@cognite/apm-client';
import type { ViewDefinition } from '@cognite/sdk';
import { ActivityIDMMigrator } from '@infield/features/activities/activity-idm-migrator';
import { OperationIDMMigrator } from '@infield/features/activities/operation-idm-migrator';
import { AssetIDMMigrator } from '@infield/features/asset/asset-idm-migrator';
import { FileIDMMigrator } from '@infield/features/media/file-idm-migrator';
import { NotificationIDMMigrator } from '@infield/features/notifications/notification-idm-migrator';
import { TimeseriesIDMMigrator } from '@infield/features/timeseries/timeseries-idm-migrator';

export const setIdmMigrators = (
  apmClient: APMClient,
  baseViewDefinition?: ViewDefinition,
  customerViewDefinition?: ViewDefinition
) => {
  apmClient.activities.migrator = new ActivityIDMMigrator(
    apmClient.sourceDataInstanceSpace
  );
  apmClient.operations.migrator = new OperationIDMMigrator(
    apmClient.sourceDataInstanceSpace
  );
  apmClient.notifications.migrator = new NotificationIDMMigrator(
    apmClient.sourceDataInstanceSpace
  );
  apmClient.assets.migrator = baseViewDefinition
    ? new AssetIDMMigrator(
        apmClient.sourceDataInstanceSpace,
        baseViewDefinition,
        customerViewDefinition || baseViewDefinition
      )
    : undefined;
  if (apmClient.files && apmClient.options?.filesView?.space) {
    apmClient.files.migrator = new FileIDMMigrator(
      apmClient.options?.filesView?.space
    );
  }
  if (apmClient.timeseries && apmClient.options?.timeseriesView?.space) {
    apmClient.timeseries.migrator = new TimeseriesIDMMigrator();
  }
};
