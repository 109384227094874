// PDFJS is asynchronously loaded and configured to load its worker script and
// resources from a hosted location.

import type { DocumentInitParameters } from 'pdfjs-dist/types/src/display/api';

export type {
  PDFDocumentLoadingTask,
  PDFDocumentProxy,
  PDFDataRangeTransport,
  DocumentInitParameters,
} from 'pdfjs-dist/types/src/display/api';

// This is the base URL for the PDFJS assets. It is configured based on
// fusion-shell's setup. It can be provided by hosting the `pdfjs-dist`
// directory from node_modules, although only a subset of the files are needed.
let PDFJS_BASE_URL = '/dependencies/pdfjs-dist@4.8.69';
export const setPDFJSBaseURL = (url: string) => {
  PDFJS_BASE_URL = url;
};

let pdfjsPromise: Promise<typeof import('pdfjs-dist')> | null = null;

export const importPDFJS = async (): Promise<typeof import('pdfjs-dist')> => {
  if (pdfjsPromise === null) {
    const cMapUrl = `${PDFJS_BASE_URL}/cmaps/`;
    const workerUrl = `${PDFJS_BASE_URL}/build/pdf.worker.min.mjs`;
    pdfjsPromise = Promise.all([import('pdfjs-dist'), fetch(workerUrl)]).then(
      async ([PDFJS, workerRes]) => {
        const workerBlob = new Blob([await workerRes.text()], {
          type: 'text/javascript',
        });
        PDFJS.GlobalWorkerOptions.workerPort = new Worker(
          URL.createObjectURL(workerBlob),
          { type: 'module' }
        );

        return new Proxy(PDFJS, {
          get(target, prop: keyof typeof PDFJS) {
            if (prop === 'getDocument') {
              return (docParams: DocumentInitParameters) =>
                target.getDocument({ ...docParams, cMapUrl });
            }
            return target[prop];
          },
        });
      }
    );
  }
  return pdfjsPromise;
};
