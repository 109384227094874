import Konva from 'konva';
import { Node } from 'konva/lib/Node';

import { Container } from '../../containers';
import { UnifiedViewer } from '../../UnifiedViewer';
import { UnifiedViewerPointerEvent } from '../../UnifiedViewerRenderer';

import { attachOrDetachAnnotations } from './attachOrDetachAnnotations';
import createTargetIndicatorForContainer from './createTargetIndicatorForContainer';
import getContainerUnderMouse from './getContainerUnderMouse';

/**
 * Type of the interaction callbacks returned from start. Optionally pass
 * annotations to attach or detach when ending the interaction. If information
 * about the target is required to determine which annotations to attach or
 * detach, pass a function that will be called with the target container.
 */
export type ActiveInteraction = {
  lockTarget: () => void;
  end: (
    annotationsToAttachOrDetach?:
      | Node[]
      | ((targetContainer: Container | null) => Node[])
  ) => void;
};

/**
 * Starts an interaction where the user can attach annotations to a container.
 * This function will subscribe to mouse events, managing a visual indicator
 * that highlights a container when it is under the mouse.
 *
 * It returns callbacks to control the interaction.
 *
 * `lockTarget` can optionally be called to set the final attachment target to
 * what is currently under the mouse, useful when you want to set the target on
 * the mouse down of a drag interaction rather than mouse up. If not called, the
 * target will be set when `end` is called.
 *
 * `end` accepts an array of annotations which will be attached to the container
 * under the mouse, or detached from any previous container if there is no
 * container under the mouse.
 *
 * The end interaction function must be called to clean up event listeners and
 * the target indicator, even if no annotations are attached or detached.
 */
export const startContainerAttachmentInteraction = (
  unifiedViewer: UnifiedViewer,
  annotationsToKeepOnTop?: Node[]
): ActiveInteraction => {
  let targetIndicator: Konva.Rect | null = null;

  // Final target. When locked, moves from undefined to a container or null.
  let target: Container | null | undefined = undefined;

  const destroyTargetIndicator = () => {
    targetIndicator?.destroy();
    targetIndicator = null;
  };

  const updateTargetIndicator = () => {
    if (target !== undefined) {
      return;
    }

    const container = getContainerUnderMouse(unifiedViewer.getContainers());
    // The current drop target container is tracked as an attribute on the
    // visual indicator rect, check to see if we're over the same one.
    if (container?.id !== targetIndicator?.attrs.container.id) {
      destroyTargetIndicator();
      if (container === null) {
        return;
      }
      targetIndicator = createTargetIndicatorForContainer(container);
      unifiedViewer.layers.main.add(targetIndicator);
      targetIndicator.moveToBottom();

      // If we're over a container, move the dragged annotations to the top of
      // the stack so they can be seen.
      //
      // TODO(FUS-000): If the annotations are currently attached to a different
      // container, their z-index is within that container and we can't bring
      // them on top of other containers.
      annotationsToKeepOnTop?.forEach((node) => {
        node.moveToTop();
      });
    }
  };

  // Event listeners
  const { eventHandler, stage } = unifiedViewer;
  const onMouseMove = updateTargetIndicator;
  const onMouseOut = (e: UnifiedViewerPointerEvent) => {
    if (e.target === stage) {
      destroyTargetIndicator();
    }
  };

  // Start interaction
  updateTargetIndicator();
  eventHandler.addEventListener(stage, 'mousemove', onMouseMove);
  eventHandler.addEventListener(stage, 'mouseout', onMouseOut);

  const lockTarget = (): void => {
    if (target === undefined) {
      target = targetIndicator?.getAttr('container') ?? null;
    }
  };

  const end: ActiveInteraction['end'] = (annotationsToAttachOrDetach) => {
    eventHandler.removeEventListener(stage, 'mousemove', onMouseMove);
    eventHandler.removeEventListener(stage, 'mouseout', onMouseOut);

    lockTarget();
    destroyTargetIndicator();

    if (annotationsToAttachOrDetach !== undefined) {
      attachOrDetachAnnotations(
        typeof annotationsToAttachOrDetach === 'function'
          ? annotationsToAttachOrDetach(target ?? null)
          : annotationsToAttachOrDetach,
        target ?? null
      );
    }
  };

  return { lockTarget, end };
};
