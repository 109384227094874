// TODO(FUS-000): common module?

export const getItem = (key: string) => {
  const string = localStorage.getItem(key);
  try {
    return JSON.parse(string!);
  } catch (err) {
    console.warn(err, string); // eslint-disable-line no-console
    return string;
  }
};

export const setItem = (key: string, data: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (err) {
    console.warn(err, key); // eslint-disable-line no-console
  }
};
