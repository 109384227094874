import { repeat } from './repeat';

/**
 * Generates a random string of the specified length using alphanumeric characters.
 *
 * @param {number} length - The length of the random string to generate. Must be greater than 0.
 * @returns {string} A random string of the specified length.
 * @throws {Error} If the length is less than 1.
 */
export function randomString(length: number): string {
  if (length < 1) {
    throw new Error('Length must be greater than 0');
  }
  const validCharacters = [
    ...'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
  ];
  let result = '';
  repeat(() => {
    result += randomElement(validCharacters);
  }, length);
  return result;
}

/**
 * Returns a random element from the given array.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The array from which to select a random element.
 * @returns {T} A random element from the array.
 */
export function randomElement<T>(array: readonly T[]): T {
  const index = randomInteger(0, array.length);
  return array[index];
}

/**
 * Returns a random integer between minInclusive and maxExclusive.
 *
 * @param minInclusive
 * @param maxExclusive
 * @returns
 */
export function randomInteger(
  minInclusive: number,
  maxExclusive: number
): number {
  return (
    Math.floor(Math.random() * (maxExclusive - minInclusive)) + minInclusive
  );
}
