import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';

import { UnifiedViewer, UnifiedViewerRenderer } from '../../index';
import {
  Metadata,
  UNIFIED_VIEWER_NODE_TYPE_KEY,
  UnifiedViewerNodeType,
} from '../types';

import { NodeEventMap } from './setAnnotationNodeEventHandlers';

type AnnotationClickHandler = (
  event: KonvaEventObject<NodeEventMap['click']>,
  annotation: Annotation
) => void;

type AnnotationMouseDownHandler = (
  event: KonvaEventObject<NodeEventMap['mousedown']>,
  annotation: Annotation
) => void;

type AnnotationMouseUpHandler = (
  event: KonvaEventObject<NodeEventMap['mouseup']>,
  annotation: Annotation
) => void;

type AnnotationMouseOverHandler = (
  event: KonvaEventObject<NodeEventMap['mouseover']>,
  annotation: Annotation
) => void;

type AnnotationMouseOutHandler = (
  event: KonvaEventObject<NodeEventMap['mouseout']>,
  annotation: Annotation
) => void;

export enum AnnotationType {
  RECTANGLE = 'rectangleAnnotation',
  ELLIPSE = 'ellipseAnnotation',
  POLYLINE = 'polylineAnnotation',
  SVG = 'svgAnnotation',
  TEXT = 'textAnnotation',
  IMAGE = 'imageAnnotation',
  LABEL = 'labelAnnotation',
  STICKY = 'stickyAnnotation',
  CAUSE_MAP_NODE = 'causeMapNodeAnnotation',
  COMMENT = 'commentAnnotation',
}

export type Size = {
  width: number;
  height: number;
};

export type Position = {
  x: number;
  y: number;
};

export type Vector = Position;

export type BoundingBox = Position & Size;

export type PixelUnit = `${number}px`;
export type FontSize = number | PixelUnit;
export type ImageSize = FontSize | Size;
export type SvgSize = FontSize;

export type Scale = {
  x: number;
  y: number;
};

export type BaseStyleProperties = {
  // TODO(FUS-000): any other?
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  shouldEnableStrokeScale?: boolean;
  opacity?: number;
  dash?: number[];
};

type BaseAnnotation<MetadataType = any> = {
  id: string;
  type: AnnotationType;
  onClick?: AnnotationClickHandler;
  onMouseDown?: AnnotationMouseDownHandler;
  onMouseUp?: AnnotationMouseUpHandler;
  onMouseOver?: AnnotationMouseOverHandler;
  onMouseOut?: AnnotationMouseOutHandler;
  isSelectable?: boolean;
  isDraggable?: boolean;
  isResizable?: boolean;
} & Metadata<MetadataType>;

type WorkspaceAnnotation<MetadataType = any> = BaseAnnotation<MetadataType> & {
  containerId?: never;
};

export type SingleDocumentAnnotation<MetadataType = any> =
  BaseAnnotation<MetadataType> & { containerId: string };

export type RectangleAnnotation<MetadataType = any> = (
  | SingleDocumentAnnotation<MetadataType>
  | WorkspaceAnnotation<MetadataType>
) & {
  type: AnnotationType.RECTANGLE;
  x: number;
  y: number;
  width: number;
  height: number;
  data?: string; // data is the Konva property for storing SVG paths.
  style?: RectangleStyleProperties;
};

export type ImageStyleProperties = { color?: string };

export type Rotation = { rotation?: number }; // rotation in degrees

export type ImageAnnotation<MetadataType = any> = (
  | SingleDocumentAnnotation<MetadataType>
  | WorkspaceAnnotation<MetadataType>
) & {
  type: AnnotationType.IMAGE;
  url: string;
  x: number;
  y: number;
  size: ImageSize;
  style?: ImageStyleProperties;
} & Rotation;

export type EllipseStyleProperties = BaseStyleProperties;

export type EllipseAnnotation<MetadataType = any> = (
  | SingleDocumentAnnotation<MetadataType>
  | WorkspaceAnnotation<MetadataType>
) & {
  type: AnnotationType.ELLIPSE;
  x: number;
  y: number;
  radius: { x: number; y: number } | number;
  style?: EllipseStyleProperties;
};

export enum PolylineEndType {
  NONE = 'none',
  ARROW = 'arrow',
}

export enum ShapeAnchorPoint {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export type PolylineAnnotation<MetadataType = any> = (
  | SingleDocumentAnnotation<MetadataType>
  | WorkspaceAnnotation<MetadataType>
) & {
  type: AnnotationType.POLYLINE;
  startEndType?: PolylineEndType;
  endEndType?: PolylineEndType;
  // anchorStartTo and anchorStartEnd denotes _where_ in the bounding box of the
  // endpoint shapes the start and end points of the connection (i.e., if fromId
  // or toId is defined), respectively, should go from and to.
  // If these are not defined, the library decides, based on shortest distance
  // heuristics, where the connections should be anchored from and to
  anchorStartTo?: ShapeAnchorPoint;
  anchorEndTo?: ShapeAnchorPoint;
  style?: LineStyleProperties;
  vertices?: Vertex[];
  fromId?: string;
  toId?: string;
};

export type ConnectionPolylineAnnotation = PolylineAnnotation &
  (
    | {
        fromId: string;
        toId: string | undefined;
        vertices: Vertex[];
      }
    | {
        fromId: string | undefined;
        toId: string;
        vertices: Vertex[];
      }
    | {
        fromId: string;
        toId: string;
      }
  );

export type SvgStyleProperties = BaseStyleProperties & {
  lineJoin?: 'bevel' | 'round' | 'miter';
  lineCap?: 'butt' | 'round' | 'square';
};

export type SvgPath = {
  d: string;
  style?: SvgStyleProperties;
};

export type SvgAnnotation<MetadataType = any> = (
  | SingleDocumentAnnotation<MetadataType>
  | WorkspaceAnnotation<MetadataType>
) & {
  type: AnnotationType.SVG;
  paths: SvgPath[];
  x: number;
  y: number;
  size: SvgSize;
  style: SvgStyleProperties;
};

export type TextAnnotation<MetadataType = any> = (
  | SingleDocumentAnnotation<MetadataType>
  | WorkspaceAnnotation<MetadataType>
) & {
  type: AnnotationType.TEXT;
  text: string;
  x: number;
  y: number;
  // TODO(FUS-000): The non-optionality of this differs from the other annotations
  // This is due to fontSize being a required property of Konva.Text
  // Might want to solve another way to be consistent
  style: TextStyleProperties;
};

type TextStyleProperties = {
  fontSize: FontSize;
  fill?: string;
  lineHeight?: number;
};

export type LabelStyleProperties = TextStyleProperties & {
  padding?: number;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  borderRadius?: number | number[];
};

export type LabelAnnotation<MetadataType = any> = (
  | SingleDocumentAnnotation<MetadataType>
  | WorkspaceAnnotation<MetadataType>
) & {
  type: AnnotationType.LABEL;
  text: string;
  x: number;
  y: number;
  style: LabelStyleProperties;
};

export type StickyAnnotation<MetadataType = any> = (
  | SingleDocumentAnnotation<MetadataType>
  | WorkspaceAnnotation<MetadataType>
) & {
  type: AnnotationType.STICKY;
  text: string;
  x: number;
  y: number;
  width: number;
  height: number;
  // TODO(FUS-000): see why we are including fill for the label annotation, seems like a text annotation
  // only thing.
  style: Omit<LabelStyleProperties, 'fill' | 'fontSize'>;
};

export type CauseMapNodeStyleProperties = Omit<
  LabelStyleProperties,
  'fill' | 'fontSize'
> & { fontSize?: FontSize };

export enum CauseMapNodeStatusType {
  // A checkmark will be rendered for accepted nodes
  ACCEPTED = 'accepted',
  // An X will be rendered for rejected nodes
  REJECTED = 'rejected',
  // A question mark will be rendered for possible nodes
  POSSIBLE = 'possible',
}

export type CauseMapNodeAnnotation<MetadataType = any> =
  WorkspaceAnnotation<MetadataType> & {
    type: AnnotationType.CAUSE_MAP_NODE;
    text: string;
    x: number;
    y: number;
    width: number;
    height: number;
    status?: CauseMapNodeStatusType;
    style: CauseMapNodeStyleProperties;
  };

export type CommentStyleProperties = Pick<
  LabelStyleProperties,
  'color' | 'backgroundColor' | 'borderColor' | 'borderWidth' | 'borderRadius'
>;

export type CommentAnnotation<MetadataType = any> = (
  | SingleDocumentAnnotation<MetadataType>
  | WorkspaceAnnotation<MetadataType>
) & {
  type: AnnotationType.COMMENT;
  x: number;
  y: number;
  author: string;
  style?: CommentStyleProperties;
};

export type Vertex = { x: number; y: number };

// Note about RIGHT_ANGLES vs ELBOWED:
// RIGHT_ANGLES:
// - Are only supported with RectangleAnnotations with containerId as endpoints for now
// - Route around other nodes
// - Stick close to nodes with obstaclePadding
// - Can have many segments
// ELBOWED:
// - Does not avoid obstacles
// - Always bends halfway between the nodes
// - Max 3 segments
export enum LineType {
  RIGHT_ANGLES = 'rightAngles',
  STRAIGHT = 'straight',
  ELBOWED = 'elbowed',
}

export type LineStyleProperties = BaseStyleProperties & {
  dashEnabled?: boolean;
  lineType?: LineType;
};

export type Annotation<MetadataType = any> =
  | RectangleAnnotation<MetadataType>
  | ImageAnnotation<MetadataType>
  | TextAnnotation<MetadataType>
  | PolylineAnnotation<MetadataType>
  | SvgAnnotation<MetadataType>
  | EllipseAnnotation<MetadataType>
  | StickyAnnotation<MetadataType>
  | LabelAnnotation<MetadataType>
  | CauseMapNodeAnnotation<MetadataType>
  | CommentAnnotation<MetadataType>;

export type RectangleStyleProperties = BaseStyleProperties & {
  shouldApplyCloudTransform?: boolean;
  cornerRadius?: number | number[];
};

// The name AnnotationSerializer can probably be improved. AnnotationManager? AnnotationHandler?
// How to name to capture that it's a center point for.... managing a specific type of Annotation
// but that it's a wider set of methods that are included.
export type AnnotationSerializer<AnnotationType, TransformationProperties> = {
  // Something smart here.
  getTransformationPropertiesFromNode: (
    node: Konva.Node
  ) => TransformationProperties;

  // Take a number of properties, and return the same properties of same type
  normalize: (properties: TransformationProperties) => TransformationProperties;

  isDraggable: (node: Konva.Node) => boolean;

  isSelectable: (node: Konva.Node) => boolean;

  isResizable: (node: Konva.Node) => boolean;

  serialize: (
    node: Konva.Node,
    unifiedViewerRenderer: UnifiedViewerRenderer
  ) => AnnotationType;

  deserialize: (
    annotation: AnnotationType,
    unifiedViewer: UnifiedViewer,
    unifiedViewerRenderer: UnifiedViewerRenderer
  ) => Konva.Node | undefined;

  updateNode: (
    node: Konva.Node,
    annotation: AnnotationType,
    unifiedViewer: UnifiedViewer,
    unifiedViewerRenderer: UnifiedViewerRenderer
  ) => void;
};

export const isSingleDocumentAnnotation = (
  annotation: SingleDocumentAnnotation | WorkspaceAnnotation
): annotation is SingleDocumentAnnotation => {
  return annotation.containerId !== undefined;
};

export const isRectangleAnnotation = (
  annotation: Annotation
): annotation is RectangleAnnotation => {
  return annotation.type === AnnotationType.RECTANGLE;
};

export const isCloudAnnotation = (
  annotation: Annotation
): annotation is RectangleAnnotation => {
  return (
    annotation.type === AnnotationType.RECTANGLE &&
    annotation.style?.shouldApplyCloudTransform === true
  );
};

export const isEllipseAnnotation = (
  annotation: Annotation
): annotation is EllipseAnnotation => {
  return annotation.type === AnnotationType.ELLIPSE;
};

export const isConnectionPolylineAnnotation = (
  annotation: Annotation
): annotation is ConnectionPolylineAnnotation => {
  if (!isPolylineAnnotation(annotation)) {
    return false;
  }

  if (annotation.fromId === undefined && annotation.toId === undefined) {
    return false;
  }

  if (
    (annotation.fromId === undefined || annotation.toId === undefined) &&
    annotation.vertices === undefined
  ) {
    return false;
  }

  return true;
};

export const isPolylineAnnotation = (
  annotation: Annotation
): annotation is PolylineAnnotation => {
  return annotation.type === AnnotationType.POLYLINE;
};

export const isSvgAnnotation = (
  annotation: Annotation
): annotation is SvgAnnotation => {
  return annotation.type === AnnotationType.SVG;
};

export const isTextAnnotation = (
  annotation: Annotation
): annotation is TextAnnotation => {
  return annotation.type === AnnotationType.TEXT;
};

export const isImageAnnotation = (
  annotation: Annotation
): annotation is ImageAnnotation => {
  return annotation.type === AnnotationType.IMAGE;
};

export const isLabelAnnotation = (
  annotation: Annotation
): annotation is LabelAnnotation => {
  return annotation.type === AnnotationType.LABEL;
};

export const isStickyAnnotation = (
  annotation: Annotation
): annotation is StickyAnnotation => {
  return annotation.type === AnnotationType.STICKY;
};

export const isCauseMapNodeAnnotation = (
  annotation: Annotation
): annotation is CauseMapNodeAnnotation => {
  return annotation.type === AnnotationType.CAUSE_MAP_NODE;
};

export const isCauseMapNodeAnnotationNode = (
  node: Konva.Node
): node is Konva.Label =>
  node.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
    UnifiedViewerNodeType.ANNOTATION &&
  node.attrs.annotationType === AnnotationType.CAUSE_MAP_NODE;

export const isCommentAnnotation = (
  annotation: Annotation
): annotation is CommentAnnotation => {
  return annotation.type === AnnotationType.COMMENT;
};

export const isCommentAnnotationNode = (
  node: Konva.Node
): node is Konva.Label =>
  node.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
    UnifiedViewerNodeType.ANNOTATION &&
  node.attrs.annotationType === AnnotationType.COMMENT;

export const isLabelNode = (
  node: Konva.Label | unknown
): node is Konva.Label => {
  return node instanceof Konva.Label;
};

export const isTextNode = (node: Konva.Text | unknown): node is Konva.Text => {
  return node instanceof Konva.Text;
};

export const isPolylineNode = (
  node: Konva.Arrow | unknown
): node is Konva.Arrow => {
  return node instanceof Konva.Arrow;
};

export const isStickyAnnotationNode = (node: Konva.Node): node is Konva.Label =>
  node.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
    UnifiedViewerNodeType.ANNOTATION &&
  node.attrs.annotationType === AnnotationType.STICKY;

export const isCloudNode = (node: Konva.Node): node is Konva.Path =>
  node.attrs.annotationType === AnnotationType.RECTANGLE &&
  node instanceof Konva.Path &&
  node.attrs.data !== undefined;
