import Konva from 'konva';

const findNearestAncestor = <ExpectedReturnType>(
  conditionFn: (node: any) => boolean
): ((node: Konva.Node) => ExpectedReturnType | undefined) => {
  const fn = (node: Konva.Node): ExpectedReturnType | undefined => {
    if (conditionFn(node)) {
      // TODO(FUS-000): Fix typing here, previously Konva.Group was returned, but not generic enough
      return node as unknown as ExpectedReturnType;
    }

    if (node.parent === null) {
      return undefined;
    }

    return fn(node.parent);
  };

  return fn;
};

export default findNearestAncestor;
