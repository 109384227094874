import createAutoExpiringPdfCache from './createAutoExpiringPdfCache';
import { DEFAULT_CACHE_AUTO_EXPIRE_MS as EXTENDED_CACHE_EXPIRE_TIME_MS } from './getContainerConfigFromFileInfo';
let autoExpiringPdfCache: ReturnType<typeof createAutoExpiringPdfCache>;
const getPdfCache = (): typeof autoExpiringPdfCache => {
  if (!autoExpiringPdfCache) {
    autoExpiringPdfCache = createAutoExpiringPdfCache(
      EXTENDED_CACHE_EXPIRE_TIME_MS
    );
  }
  return autoExpiringPdfCache;
};

export default getPdfCache;
