import type { DateRangeType } from '@cognite/apm-observation';
import { Banner } from '@cognite/cogs-lab';
import { Button, CloseIcon } from '@cognite/cogs.js-v10';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import i18n from '@infield/features/i18n/i18n';
import { getCorrectDateFormat } from '@infield/features/ui/date-range-picker/utils';
import { MissingChecklistsState } from '@infield/providers/checklist-scheduler-provider/types';
import type { TFunction } from 'i18next';
import type { FC } from 'react';
import { useCallback } from 'react';

interface Props {
  numPlannedChecklists: number;
  createChecklists: () => Promise<void>;
  missingChecklistsState: MissingChecklistsState;
  resetAndCloseBanner: () => void;
  numCreatedChecklists: number;
  viewCreatedChecklists: () => void;
  dateRangeFilter: DateRangeType;
  retryFetchAndCreateChecklists: () => Promise<void>;
}

export const ChecklistSchedulerDesktopBannerUi: FC<Props> = ({
  numPlannedChecklists,
  createChecklists,
  missingChecklistsState,
  resetAndCloseBanner,
  numCreatedChecklists,
  viewCreatedChecklists,
  dateRangeFilter,
  retryFetchAndCreateChecklists,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.activity);

  const dateFormat = getCorrectDateFormat(i18n.language);
  const startDate = dateRangeFilter[0]?.format(dateFormat);
  const endDate = dateRangeFilter[1]?.format(dateFormat);

  const bannerStatusType =
    missingChecklistsState === MissingChecklistsState.CHECKLISTS_CREATED ||
    missingChecklistsState === MissingChecklistsState.NO_MISSING_CHECKLISTS
      ? 'success'
      : 'warning';

  const showActionButton = [
    MissingChecklistsState.MISSING_CHECKLISTS,
    MissingChecklistsState.CREATING_CHECKLISTS,
    MissingChecklistsState.CHECKLISTS_CREATED,
    MissingChecklistsState.FAILED_TO_CREATE_CHECKLISTS,
  ].includes(missingChecklistsState);

  const showCloseButton =
    missingChecklistsState !== MissingChecklistsState.CREATING_CHECKLISTS;

  const onBannerButtonClick = useCallback(async (): Promise<void> => {
    switch (missingChecklistsState) {
      case MissingChecklistsState.FAILED_TO_CREATE_CHECKLISTS:
        await retryFetchAndCreateChecklists();
        break;
      case MissingChecklistsState.MISSING_CHECKLISTS:
        await createChecklists();
        break;
      case MissingChecklistsState.CHECKLISTS_CREATED:
        viewCreatedChecklists();
        break;
    }
  }, [
    createChecklists,
    viewCreatedChecklists,
    missingChecklistsState,
    retryFetchAndCreateChecklists,
  ]);

  const onCloseButtonClick = useCallback((): void => {
    resetAndCloseBanner();
  }, [resetAndCloseBanner]);

  return (
    <>
      {missingChecklistsState !== MissingChecklistsState.NONE && (
        <Banner
          data-testid="checklist-scheduler-banner"
          status={bannerStatusType}
          actions={
            <>
              {showActionButton && (
                <Button
                  size="small"
                  onClick={onBannerButtonClick}
                  type="tertiary"
                  loading={
                    missingChecklistsState ===
                    MissingChecklistsState.CREATING_CHECKLISTS
                  }
                >
                  {getCreateButtonText(
                    missingChecklistsState,
                    numPlannedChecklists,
                    t
                  )}
                </Button>
              )}
              {showCloseButton && (
                <Button
                  data-testid="close-scheduled-checklist-banner-button"
                  aria-label={t(
                    'SCHEDULE_BANNER_CLOSE_BUTTON_ARIA_LABEL',
                    'Close scheduled checklist banner'
                  )}
                  size="small"
                  onClick={onCloseButtonClick}
                  icon={<CloseIcon />}
                  type="ghost"
                />
              )}
            </>
          }
        >
          {getBannerText(
            missingChecklistsState,
            numCreatedChecklists,
            numPlannedChecklists,
            t,
            startDate,
            endDate
          )}
        </Banner>
      )}
    </>
  );
};

const getCreateButtonText = (
  missingChecklistsState: MissingChecklistsState,
  numPlannedChecklists: number,
  translationFunction: TFunction<string, undefined>
): string => {
  if (
    missingChecklistsState ===
    MissingChecklistsState.FAILED_TO_CREATE_CHECKLISTS
  ) {
    return translationFunction('SCHEDULE_BANNER_BUTTON_RETRY', 'Retry');
  }
  if (missingChecklistsState === MissingChecklistsState.CHECKLISTS_CREATED) {
    return translationFunction(
      'SCHEDULE_BANNER_BUTTON_VIEW',
      'View checklists'
    );
  }
  if (numPlannedChecklists === 1) {
    return translationFunction(
      'SCHEDULE_BANNER_BUTTON_SINGLE',
      'Create checklist'
    );
  }
  return translationFunction(
    'SCHEDULE_BANNER_BUTTON_MULTIPLE',
    'Create checklists'
  );
};

const getBannerText = (
  missingChecklistsState: MissingChecklistsState,
  numCreatedChecklists: number,
  numPlannedChecklists: number,
  translationFunction: TFunction<string, undefined>,
  startDate?: string,
  endDate?: string
): string => {
  if (
    missingChecklistsState ===
    MissingChecklistsState.FAILED_TO_CREATE_CHECKLISTS
  ) {
    if (numCreatedChecklists === 0) {
      return translationFunction(
        'SCHEDULE_BANNER_TEXT_FAILED_ALL',
        '{{numCreatedChecklists}}/{{numPlannedChecklists}} checklists created. Try again.',
        { numCreatedChecklists, numPlannedChecklists }
      );
    } else {
      return translationFunction(
        'SCHEDULE_BANNER_TEXT_FAILED_PARTIAL',
        "{{numCreatedChecklists}}/{{numPlannedChecklists}} checklists created. Some checklists couldn't be created.",
        { numCreatedChecklists, numPlannedChecklists }
      );
    }
  }
  if (missingChecklistsState === MissingChecklistsState.CREATING_CHECKLISTS) {
    return translationFunction(
      'SCHEDULE_BANNER_TEXT_CREATING',
      'Creating checklists... {{numCreatedChecklists}}/{{numPlannedChecklists}}',
      { numCreatedChecklists, numPlannedChecklists }
    );
  }

  if (
    missingChecklistsState ===
    MissingChecklistsState.SEARCHING_MISSING_CHECKLISTS
  ) {
    return translationFunction('SCHEDULE_BANNER_TEXT_LOADING', 'Searching...');
  }
  if (missingChecklistsState === MissingChecklistsState.CHECKLISTS_CREATED) {
    if (numCreatedChecklists === 1) {
      return translationFunction(
        'SCHEDULE_BANNER_TEXT_SUCCESS_WITH_CREATED_SINGLE',
        '1 checklist was created'
      );
    }
    return translationFunction(
      'SCHEDULE_BANNER_TEXT_SUCCESS_WITH_CREATED',
      '{{numCreatedChecklists}} checklists were created',
      { numCreatedChecklists }
    );
  }
  if (missingChecklistsState === MissingChecklistsState.NO_MISSING_CHECKLISTS) {
    return translationFunction(
      'SCHEDULE_BANNER_TEXT_SUCCESS',
      "You don't have any missing checklists between {{startDate}} and {{endDate}}",
      { startDate, endDate }
    );
  }

  return numPlannedChecklists === 1
    ? translationFunction(
        'SCHEDULE_BANNER_TEXT_SINGLE',
        "1 scheduled checklist wasn't created between {{startDate}} and {{endDate}}. You can create it manually.",
        {
          startDate,
          endDate,
        }
      )
    : translationFunction(
        'SCHEDULE_BANNER_TEXT_MULTIPLE',
        "{{numPlannedChecklists}} scheduled checklists weren't created between {{startDate}} and {{endDate}}. You can create them manually.",
        {
          numPlannedChecklists,
          startDate,
          endDate,
        }
      );
};
