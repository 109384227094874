import type { InstanceOrExternalId } from '@cognite/apm-client/src/file/types';
import { Modal } from '@cognite/cogs.js-v10';
import type { UploadedMedia } from '@infield/features/media/types';
import { type FC, useEffect, useState } from 'react';

import { MultipleMediaContent } from './multiple-media-content';
import { SingleMediaContent } from './single-media-content';

type Props = {
  modalTitle: string;
  onClose: () => void;
  mediaFiles: UploadedMedia[] | undefined;
  deleteMedia: (params: {
    fileInstanceIds: InstanceOrExternalId[];
  }) => Promise<Record<string, never> | undefined>;
  isDeletingMedia: boolean;
  isDeleteMediaError: boolean;
  hideDeleteButton: boolean;
};

export const MediaCollectionViewerUi: FC<Props> = ({
  modalTitle,
  onClose,
  mediaFiles,
  deleteMedia,
  isDeletingMedia,
  isDeleteMediaError,
  hideDeleteButton,
}) => {
  const [selectedMedia, setSelectedMedia] = useState<UploadedMedia>();

  useEffect(() => {
    if (mediaFiles?.length === 1) {
      setSelectedMedia(mediaFiles[0]);
    }
  }, [mediaFiles]);

  const handleMediaClick = (media: UploadedMedia): void => {
    setSelectedMedia(media);
  };

  const handleDeleteMedia = async (media: UploadedMedia): Promise<void> => {
    if (!media.file?.externalId) return;
    await deleteMedia({
      fileInstanceIds: [
        { externalId: media.file.externalId, space: media.file.space },
      ],
    });
    if (isDeleteMediaError) return;
    setSelectedMedia(undefined);
    onClose();
  };

  return (
    <Modal
      title={modalTitle}
      hideFooter
      size="large"
      visible
      onCancel={onClose}
    >
      {selectedMedia ? (
        <SingleMediaContent
          media={selectedMedia}
          handleDeleteMedia={handleDeleteMedia}
          isDeletingMedia={isDeletingMedia}
          hideDeleteButton={hideDeleteButton}
        />
      ) : (
        <MultipleMediaContent
          mediaFiles={mediaFiles}
          handleMediaClick={handleMediaClick}
        />
      )}
    </Modal>
  );
};
