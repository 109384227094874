import type { APMAsset, Observation } from '@cognite/apm-client';
import { Button } from '@cognite/cogs.js-v10';
import { useMetrics } from '@cognite/metrics';
import type { Asset } from '@cognite/sdk';
import { useSelectedRootAsset } from '@infield/features/asset';
import { LOCIZE_NAMESPACES, useTranslation } from '@infield/features/i18n';
import { useUpdateMedia } from '@infield/features/media';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import { MediaCollectionViewer } from '@infield/features/ui/media-collection-viewer';
import { useQueryClient } from '@tanstack/react-query';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { DEFAULT_STATUS } from '../constants';
import { useObservationFields } from '../hooks/use-observation-fields';
import type { Step } from '../types';
import {
  getCompletedStepsNumber,
  getContextualizedAssetsForMedia,
  getDefaultObservation,
  getIsAllRequiredFieldsFilled,
  getIsObservationLocked,
  invalidateMediaQueries,
  isFieldValueSet,
  prepareMediaUpdates,
} from '../utils';

import * as S from './elements';
import { ObservationDetailsOverview } from './observation-details-overview';
import { ObservationDetailsStepAsset } from './observation-details-step-asset';
import { ObservationDetailsStepPriority } from './observation-details-step-priority';
import { ObservationDetailsStepProblem } from './observation-details-step-problem';
import { ObservationDetailsStepWrapper } from './observation-details-step-wrapper';
import { ObservationDetailsStepMedia } from './observation-step-media';
import { ObservationDetailsStepTroubleshoot } from './observation-step-troubleshoot';
import { ObservationDetailsStepType } from './observation-step-type';

interface Props {
  observation?: Observation;
  checklistItemAsset?: APMAsset;
  showBackToChecklistDescription?: boolean;
  resetSelectedStep?: boolean;
  onUpdate: (observation: Observation) => void;
  onCancel: () => void;
}

export const ObservationDetails: FC<Props> = ({
  observation,
  checklistItemAsset,
  showBackToChecklistDescription,
  resetSelectedStep,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const metrics = useMetrics(METRICS_NAMESPACES.observation);

  const [selectedStep, setSelectedStep] = useState<Step | null>(null);
  const [nextOnLastStep, setNextOnLastStep] = useState<boolean>(false);
  const [defaultObservation, setDefaultObservation] = useState(
    getDefaultObservation(checklistItemAsset)
  );
  const [isImageCollectionModalVisible, setIsImageCollectionModalVisible] =
    useState(false);

  const { observationFields: steps } = useObservationFields();
  const { data: rootAsset } = useSelectedRootAsset();
  const { mutateAsync: updateMedia } = useUpdateMedia();

  const asset = observation ? observation?.asset : defaultObservation.asset;

  const isLocked = getIsObservationLocked(observation);

  const isMediaFileUploaded =
    observation?.files && observation.files.length > 0;
  const imageFilesInstanceIds =
    observation?.files
      ?.filter((file) => file?.externalId)
      .map((file) => ({
        externalId: file!.externalId,
        space: file!.space,
      })) || [];

  const queryClient = useQueryClient();

  useEffect(() => {
    if (resetSelectedStep) {
      setSelectedStep(null);
    }
  }, [observation?.externalId, resetSelectedStep]);

  const selectedStepIndex = steps.findIndex(
    (step) => step.key === selectedStep?.key
  );

  const completedStepsNumber = getCompletedStepsNumber(
    observation || defaultObservation,
    steps
  );

  const isObservationCompleted = getIsAllRequiredFieldsFilled(
    observation || defaultObservation,
    steps
  );

  const hasNext = (): boolean => {
    const numberOfSteps = steps.length - 1;
    const observationToCheck = observation || defaultObservation;

    const isStepCompleted =
      selectedStep &&
      observationToCheck &&
      (!selectedStep.isRequired ||
        isFieldValueSet(observationToCheck[selectedStep.key]));
    return (
      (selectedStepIndex < numberOfSteps && isStepCompleted) ||
      isObservationCompleted
    );
  };

  const handleOnNext = () => {
    const nextStep = steps[selectedStepIndex + 1];
    setSelectedStep(nextStep);
    setNextOnLastStep(!nextStep);
  };

  const handleOnPrevious = () => {
    const previousStep = steps[selectedStepIndex - 1];
    if (selectedStepIndex === 0) {
      setSelectedStep(null);
    } else {
      setSelectedStep(previousStep);
    }
  };

  const handleObservationUpdate = (data: Partial<Observation>) => {
    if (!observation) {
      onUpdate({
        ...defaultObservation,
        ...data,
      });
      metrics.track('observationCreated');
    } else {
      const isFieldToBeUpdatedEmpty = Object.values(data).some(
        (fieldValue) => !isFieldValueSet(fieldValue)
      );
      const statusUpdate =
        isFieldToBeUpdatedEmpty && observation.status === 'Completed'
          ? { status: DEFAULT_STATUS }
          : {};
      onUpdate({
        ...observation,
        ...data,
        ...statusUpdate,
      });
    }
  };

  const handleAssetChange = (asset?: Asset, apmAsset?: APMAsset) => {
    if (!apmAsset && !observation) {
      setDefaultObservation((prevState) => ({
        ...prevState,
        asset: undefined,
      }));
    } else {
      const previousAssetExternalId = observation?.asset?.externalId;
      handleObservationUpdate({
        asset: apmAsset ?? null,
      });
      if (observation?.files && observation?.files.length > 0) {
        const contextualizedAssets = getContextualizedAssetsForMedia(
          asset,
          apmAsset,
          rootAsset
        );
        const mediaUpdates = prepareMediaUpdates(
          observation?.files,
          contextualizedAssets
        );
        updateMedia({ items: mediaUpdates }).then(() => {
          invalidateMediaQueries(
            queryClient,
            previousAssetExternalId,
            asset?.externalId
          );
        });
      }
    }
  };

  function renderStep() {
    switch (selectedStep?.key) {
      case 'files':
        return (
          <>
            {isMediaFileUploaded && isImageCollectionModalVisible && (
              <MediaCollectionViewer
                fileInstanceIds={imageFilesInstanceIds}
                onClose={() => setIsImageCollectionModalVisible(false)}
                hideDeleteButton={isLocked}
              />
            )}
            <S.Container alignItems="center" justifyContent="center">
              <S.MediaContentWrapper>
                <S.StepContentWrapper>
                  <ObservationDetailsStepMedia
                    step={selectedStep}
                    fileInstanceIds={observation?.files
                      ?.filter((file) => file && file.externalId)
                      .map((file) => ({
                        externalId: file!.externalId,
                        space: file!.space,
                      }))}
                    observationExternalId={
                      observation?.externalId || defaultObservation.externalId
                    }
                    assetExternalId={asset?.externalId ?? undefined}
                    isLocked={isLocked}
                    onMediaSave={(fileExternalIds) =>
                      handleObservationUpdate({
                        files: fileExternalIds.map((externalId) => ({
                          externalId,
                        })),
                      })
                    }
                  />
                </S.StepContentWrapper>
                {isMediaFileUploaded && (
                  <div>
                    <Button
                      size="medium"
                      onClick={() => setIsImageCollectionModalVisible(true)}
                    >
                      {t(
                        'OBSERVATION_STEP_MEDIA_UPLOADED_CHIP',
                        '{{count}} images uploaded',
                        { count: observation?.files?.length }
                      )}
                    </Button>
                  </div>
                )}
              </S.MediaContentWrapper>
            </S.Container>
          </>
        );
      case 'description':
        return (
          <S.StepContentWrapper>
            <ObservationDetailsStepProblem
              step={selectedStep}
              value={observation?.description}
              isLocked={isLocked}
              onChange={(value) =>
                handleObservationUpdate({ [selectedStep?.key]: value })
              }
            />
          </S.StepContentWrapper>
        );
      case 'asset':
        return (
          <S.StepContentWrapper>
            <ObservationDetailsStepAsset
              step={selectedStep}
              asset={asset ?? undefined}
              isLocked={isLocked}
              onChange={handleAssetChange}
            />
          </S.StepContentWrapper>
        );
      case 'priority':
        return (
          <S.StepContentWrapper>
            <ObservationDetailsStepPriority
              step={selectedStep}
              value={observation?.priority}
              isLocked={isLocked}
              onChange={(selectedPriority) =>
                handleObservationUpdate({
                  [selectedStep?.key]: selectedPriority,
                })
              }
            />
          </S.StepContentWrapper>
        );
      case 'type':
        return (
          <S.StepContentWrapper>
            <ObservationDetailsStepType
              step={selectedStep}
              value={observation?.type}
              isLocked={isLocked}
              onChange={(selectedType) =>
                handleObservationUpdate({
                  [selectedStep?.key]: selectedType,
                })
              }
            />
          </S.StepContentWrapper>
        );
      case 'troubleshooting':
        return (
          <S.StepContentWrapper>
            <ObservationDetailsStepTroubleshoot
              step={selectedStep}
              value={observation?.troubleshooting}
              isLocked={isLocked}
              onChange={(value) =>
                handleObservationUpdate({ [selectedStep?.key]: value })
              }
            />
          </S.StepContentWrapper>
        );
      default:
        throw new Error(`No markup exists for step ${selectedStep?.key}`);
    }
  }

  return (
    <S.Container>
      {selectedStep ? (
        <ObservationDetailsStepWrapper
          asset={asset ?? undefined}
          totalSteps={steps.length}
          completedSteps={completedStepsNumber}
          hasNext={hasNext()}
          onBack={() => setSelectedStep(null)}
          onPrevious={handleOnPrevious}
          onNext={handleOnNext}
        >
          {renderStep()}
        </ObservationDetailsStepWrapper>
      ) : (
        <ObservationDetailsOverview
          observation={observation || defaultObservation}
          steps={steps}
          isCompleted={isObservationCompleted}
          nextOnLastStep={nextOnLastStep}
          onStepClick={setSelectedStep}
          showBackToChecklistDescription={showBackToChecklistDescription}
          onStart={() => setSelectedStep(steps[0])}
          onCancel={onCancel}
          updateObservation={handleObservationUpdate}
        />
      )}
    </S.Container>
  );
};
