import { useChecklistSchedulerContext } from '@infield/providers/checklist-scheduler-provider/checklist-scheduler-context';
import type { FC } from 'react';

import { ChecklistSchedulerDesktopBannerUi } from './checklist-scheduler-desktop-banner-ui';

export const ChecklistSchedulerDesktopBanner: FC = () => {
  const {
    numPlannedChecklists,
    createChecklists,
    missingChecklistsState,
    resetAndCloseBanner,
    numCreatedChecklists,
    viewCreatedChecklists,
    dateFilterWithoutFutureDays,
    retryFetchAndCreateChecklists,
  } = useChecklistSchedulerContext();

  return (
    <ChecklistSchedulerDesktopBannerUi
      numPlannedChecklists={numPlannedChecklists}
      createChecklists={createChecklists}
      missingChecklistsState={missingChecklistsState}
      resetAndCloseBanner={resetAndCloseBanner}
      numCreatedChecklists={numCreatedChecklists}
      viewCreatedChecklists={viewCreatedChecklists}
      dateRangeFilter={dateFilterWithoutFutureDays}
      retryFetchAndCreateChecklists={retryFetchAndCreateChecklists}
    />
  );
};
