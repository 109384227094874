import Konva from 'konva';
import { cloneDeep, isEqual, pick } from 'lodash';
import { v4 as uuid } from 'uuid';

import { UnifiedViewerEventType } from '../../index';
import shouldBeDraggable from '../annotations/shouldBeDraggable';
import shouldBeResizable from '../annotations/shouldBeResizable';
import shouldBeSelectable from '../annotations/shouldBeSelectable';
import { Size } from '../annotations/types';
import { LoadingSpinner } from '../components';
import {
  DEFAULT_CONTAINER_HEIGHT,
  DEFAULT_CONTAINER_WIDTH,
} from '../constants';
import { UNIFIED_VIEWER_NODE_TYPE_KEY, UnifiedViewerNodeType } from '../types';
import { UnifiedViewer } from '../UnifiedViewer';
import EventEmitter from '../utils/EventEmitter';
import getMetricsLogger, { TrackedEventType } from '../utils/getMetricsLogger';
import getResponseCache from '../utils/getResponseCache';
import { NativelySupportedMimeType } from '../utils/mimeTypes/isNativelySupportedMimeType';

import {
  defaultLoadingStatus,
  LoadingStatus,
  TLoadingStatus,
} from './asyncContainerUtils';
import BoundaryRect from './BoundaryRect';
import { CONTAINER_REF_KEY } from './constants';
import { Container, ContainerProps, EventableContainer } from './Container';
import { ContainerEventType } from './ContainerEventType';
import { ContainerType } from './ContainerType';
import { EventListenerMap } from './EventListenerMap';
import getDimensionsFromContainerProps from './getDimensionsFromContainerProps';
import setContainerNodeEventHandlers from './setContainerNodeEventHandlers';
import { ContainerConfig, TextMimeType } from './types';
import { getHeaderGroup } from './utils';

const DEFAULT_FONT_SIZE = 12;
const PADDING = 20;
const PRETTIFY_INDENTATION_LEVEL = 2;
const MAX_NUMBER_OF_CHARACTERS = 10_000;

export type TextContainerProps<MetadataType = any> = {
  id: string;
  url: string;
  type: ContainerType.TEXT;
  mimeType?: NativelySupportedMimeType;
  shouldPrettify?: boolean;
  fontSize?: number;
  dataCacheKey?: string;
} & ContainerProps<MetadataType> &
  EventableContainer;

export default class TextContainer<MetadataType>
  extends EventEmitter<EventListenerMap>
  implements Container<MetadataType>
{
  public readonly type = ContainerType.TEXT;
  public readonly id: string;
  public loadingStatus: TLoadingStatus = defaultLoadingStatus;
  private isReady: boolean = false;
  protected loadingSpinner?: LoadingSpinner;
  private group: Konva.Group = new Konva.Group({
    [CONTAINER_REF_KEY]: this,
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.CONTAINER_GROUP,
  });
  private headerGroup: Konva.Group = new Konva.Group();
  private contentGroup: Konva.Group = new Konva.Group({
    id: uuid(),
    [UNIFIED_VIEWER_NODE_TYPE_KEY]:
      UnifiedViewerNodeType.CONTAINER_CONTENT_GROUP,
  });
  private boundaryRect: BoundaryRect = new BoundaryRect({
    width: 0,
    height: 0,
  });
  private text: Konva.Text = new Konva.Text({
    padding: PADDING,
    fontFamily: 'courier',
    [UNIFIED_VIEWER_NODE_TYPE_KEY]: UnifiedViewerNodeType.CONTAINER_TEXT,
  });
  private props: TextContainerProps<MetadataType>;
  private contentType: string = '';
  private unifiedViewer: UnifiedViewer;

  public constructor(
    props: TextContainerProps<MetadataType>,
    unifiedViewer: UnifiedViewer
  ) {
    super();
    this.id = props.id;
    this.getNode().id(this.id);
    this.getNode().add(this.headerGroup);
    this.getNode().add(this.contentGroup);
    this.boundaryRect = new BoundaryRect({
      width: this.getContentNode().width(),
      height: this.getContentNode().height(),
    });
    this.getContentNode().add(this.boundaryRect.getNode());
    this.getContentNode().add(this.text);
    this.recreateLoadingSpinner();

    this.unifiedViewer = unifiedViewer;
    this.props = { ...props };
    this.setProps(props, true);
  }

  public setProps(
    props: TextContainerProps<MetadataType>,
    forceUpdate: boolean = false
  ): void {
    const prevProps = { ...this.props };
    this.props = props;

    if (this.loadingStatus.isLoading) {
      this.recreateLoadingSpinner();
    }

    const propsRequiringUpdate = [
      'width',
      'height',
      'maxWidth',
      'maxHeight',
      'url',
      'label',
      'shouldPrettify',
      'fontSize',
    ];
    if (
      !forceUpdate &&
      isEqual(
        pick(prevProps, propsRequiringUpdate),
        pick(props, propsRequiringUpdate)
      )
    ) {
      return;
    }

    this.text.fontSize(props.fontSize ?? DEFAULT_FONT_SIZE);

    setContainerNodeEventHandlers(
      this.getNode(),
      this.props as ContainerConfig<any>,
      this.unifiedViewer
    );

    const { width, height, maxWidth, maxHeight } =
      getDimensionsFromContainerProps(props);
    this.setContainerDimensions(
      width ?? maxWidth ?? DEFAULT_CONTAINER_WIDTH,
      height ?? maxHeight ?? DEFAULT_CONTAINER_HEIGHT
    );
    this.loadingSpinner?.centerInsideBoundaryRect(this.boundaryRect);

    this.loadTextAndSetContainerDimensions().then(() => {
      getMetricsLogger()?.trackEvent(TrackedEventType.CREATE_TEXT_CONTAINER, {
        ...getDimensionsFromContainerProps(this.props),
        mimeType: this.contentType,
      });
    });
  }

  public setLoadingStatus(status: LoadingStatus): void {
    this.loadingStatus = { ...defaultLoadingStatus, [status]: true };
    this.onLoadingStatusChange(status);
  }

  public getChildren(): Container[] {
    return [];
  }

  public getContentNode(): Konva.Group {
    return this.contentGroup;
  }

  public addToContentNode(node: Konva.Group | Konva.Shape): void {
    this.getContentNode().add(node);
  }

  public setHeight(height: number): void {
    this.getNode().height(height);
  }

  public setWidth(width: number): void {
    this.getNode().width(width);
  }

  public getWidth(): number {
    return this.getNode().width();
  }

  public getHeight(): number {
    return this.getNode().height();
  }

  public setContainerDimensions(width: number, height: number): void {
    this.setWidth(width);
    this.setHeight(height);

    const contentNodeDimensions = {
      width,
      height: height - this.getHeaderNodeDimensions().height,
    };
    this.getContentNode().size(contentNodeDimensions);
    this.boundaryRect.setDimensions(contentNodeDimensions);

    this.emit(ContainerEventType.ON_LAYOUT_CHANGE);
  }

  public setIsReady(isReady: boolean): void {
    if (this.isReady !== isReady) {
      this.isReady = isReady;
      this.emit(ContainerEventType.ON_READY_STATE_CHANGE, this as Container);
    }
  }

  public getIsReady(): boolean {
    return this.isReady;
  }

  public addChild(_child: Container): void {
    throw new Error('The default addChild method is not implemented');
  }

  private setLabel(containerDimensions: Size): void {
    if (this.props.label !== undefined) {
      this.setHeader(getHeaderGroup(this.props.label, containerDimensions));
    }
  }

  public getNode(): Konva.Group {
    return this.group;
  }

  public setNode(node: Konva.Group): void {
    this.group = node;

    const contentContainerGroup = node.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_CONTENT_GROUP
    );

    this.contentGroup = contentContainerGroup as Konva.Group;
    const headerGroup = node.children?.find(
      (n) =>
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_HEADER_GROUP
    );

    this.headerGroup = headerGroup as Konva.Group;

    this.text = this.contentGroup.children?.find((n) => {
      return (
        n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
        UnifiedViewerNodeType.CONTAINER_TEXT
      );
    }) as Konva.Text;

    this.boundaryRect?.setNode(
      this.contentGroup.children?.find(
        (n) =>
          n.attrs[UNIFIED_VIEWER_NODE_TYPE_KEY] ===
          UnifiedViewerNodeType.CONTAINER_BOUNDARY_RECT
      ) as Konva.Rect
    );
  }

  public getHeaderNodeDimensions(): { width: number; height: number } {
    if (this.headerGroup === undefined) {
      return {
        width: 0,
        height: 0,
      };
    }
    // NOTE: we ignore the transform when computing the client rect for the
    // header group. This is to fix the issue that the header group seems to get
    // transformed on update (given that the container ids are the same across
    // the update). The behavior for transforming containers (with headers)
    // should be monitored.
    const { width, height } = this.headerGroup.getClientRect({
      skipTransform: true,
    });
    return { width, height };
  }

  public setHeader(header: Konva.Group): void {
    this.headerGroup.destroy();
    this.headerGroup = header;
    this.getNode().add(this.headerGroup);
    this.recalculateLayout();
  }

  private getPrettifiedText(text: string): string {
    const shouldPrettify = this.props.shouldPrettify ?? true;
    if (!shouldPrettify) {
      return text;
    }

    try {
      if (this.contentType === TextMimeType.JSON) {
        return JSON.stringify(
          JSON.parse(text),
          null,
          PRETTIFY_INDENTATION_LEVEL
        );
      }
    } catch {
      return text;
    }

    return text;
  }

  private getComputedContainerWidth = (): number => {
    if (this.props.width !== undefined) {
      return this.props.width;
    }
    const textWidth = this.text.width();
    if (this.props.maxWidth !== undefined) {
      return Math.min(textWidth, this.props.maxWidth);
    }
    return textWidth;
  };

  private getComputedContainerHeight = (): number => {
    if (this.props.height !== undefined) {
      return this.props.height;
    }
    const textHeight = this.text.height();
    if (this.props.maxHeight !== undefined) {
      return Math.min(textHeight, this.props.maxHeight);
    }
    return textHeight;
  };

  private shamefulResetTextDimensions = (): void => {
    this.text.width(null as any);
    this.text.height(null as any);
  };

  public loadTextAndSetContainerDimensions = async (): Promise<void> => {
    this.setIsReady(false);
    this.setLoadingStatus(LoadingStatus.LOADING);
    try {
      const data = await getResponseCache().fetch(this.props.url, {
        key: this.props.dataCacheKey,
      });
      this.contentType = this.props.mimeType ?? data?.contentType ?? 'unknown';
      const text = new TextDecoder().decode(data.data);

      // TODO(FUS-000): Should we support this through pagination
      const truncatedText =
        text.length > MAX_NUMBER_OF_CHARACTERS
          ? text.slice(0, MAX_NUMBER_OF_CHARACTERS) +
            `\n\n\n...only the first ${MAX_NUMBER_OF_CHARACTERS} characters are displayed...`
          : text;

      // NOTE: We need to reset the text dimensions before setting the text since the previous set width/height
      //       would override the intrinsic width/height of the text node.
      this.shamefulResetTextDimensions();
      this.text.setText(this.getPrettifiedText(truncatedText));
      this.text.width(this.getComputedContainerWidth());
      this.text.height(this.getComputedContainerHeight());

      const { width, height } = this.text.getClientRect({
        skipTransform: true,
      });

      this.setContainerDimensions(width, height);

      this.setLabel({ width, height });

      this.setLoadingStatus(LoadingStatus.SUCCESS);
      this.setIsReady(true);
    } catch (e) {
      this.setLoadingStatus(LoadingStatus.ERROR);
    }
  };

  private recreateLoadingSpinner = (): void => {
    // To avoid getting the loading spinner into a bogus state,
    // re-create it when our content node already has one.
    const existingLoadingSpinner = this.getContentNode().findOne(
      (n: Konva.Node) => n instanceof LoadingSpinner
    );
    if (existingLoadingSpinner !== undefined) {
      existingLoadingSpinner.destroy();
      existingLoadingSpinner.remove();
      this.loadingSpinner = new LoadingSpinner(this.unifiedViewer);
      this.getContentNode().add(this.loadingSpinner);
    }
    this.loadingSpinner?.centerInsideBoundaryRect(this.boundaryRect);
    this.loadingSpinner?.recalculateSize(this.getWidth());
  };

  private onLoadingStatusChange(status: LoadingStatus) {
    if (status === LoadingStatus.ERROR) {
      // eslint-disable-next-line no-console
      console.error(`Load status: ${status}`);
    }
    if (status === LoadingStatus.LOADING) {
      this.recreateLoadingSpinner();
    } else {
      this.loadingSpinner?.destroy();
    }
  }

  private recalculateLayout(): void {
    const { height } = this.getHeaderNodeDimensions();
    this.headerGroup.y(-height);
    this.text.height(this.text.height() - height);
    this.onUpdate();
  }

  public serialize = (): ContainerConfig => {
    const scaleX = this.getNode().scaleX();
    const scaleY = this.getNode().scaleY();

    return {
      id: this.props.id,
      type: this.type,
      url: this.props.url,
      label: this.props.label,
      width: Math.round(this.getWidth() * scaleX),
      height: Math.round(this.getHeight() * scaleY),
      maxWidth: this.props.maxWidth
        ? Math.round(this.props.maxWidth * scaleX)
        : undefined,
      maxHeight: this.props.maxHeight
        ? Math.round(this.props.maxHeight * scaleY)
        : undefined,
      x: this.getNode().x(),
      y: this.getNode().y(),
      isSelectable: this.props.isSelectable,
      isDraggable: this.props.isDraggable,
      metadata: cloneDeep(this.props.metadata),
    };
  };

  public isSelectable = (): boolean =>
    shouldBeSelectable(this.props.isSelectable);

  public isDraggable = (): boolean => shouldBeDraggable(this.props.isDraggable);

  public isResizable = (): boolean => shouldBeResizable(this.props.isResizable);

  private onUpdate = () => {
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_UPDATE_REQUEST, {
      containers: [this.serialize()],
      annotations: [],
    });
  };

  public onDestroy(): void {
    this.loadingSpinner?.destroy();
  }
}
