import { DataGridContainerProps } from '../../containers/DataGridContainer';
import { AnchorName } from '../Anchor/constants';

const getNewRow = ({ columns }: DataGridContainerProps) => ({
  id: crypto.randomUUID(),
  height: 45,
  cells: Object.fromEntries(
    columns.map(({ key }) => [key, { value: undefined }])
  ),
});

const getNewColumn = ({ rows }: DataGridContainerProps) => ({
  key: crypto.randomUUID(),
  title: `Column ${rows.length + 1}`,
  width: 150,
});

// Depending on the anchor name, append or prepend a new row or column to the data grid container
const getUpdatedDataGridContainer = (
  props: DataGridContainerProps,
  anchorName: AnchorName
): DataGridContainerProps => {
  // Append new row
  if (anchorName === 'bottom-center') {
    return { ...props, rows: [...props.rows, getNewRow(props)] };
  }

  // Prepend new row
  if (anchorName === 'top-center') {
    return { ...props, rows: [getNewRow(props), ...props.rows] };
  }

  // Append new column
  if (anchorName === 'middle-right') {
    const newColumn = getNewColumn(props);
    return {
      ...props,
      columns: [...props.columns, newColumn],
      rows: props.rows.map((row) => ({
        ...row,
        cells: {
          ...row.cells,
          [newColumn.key]: { value: undefined },
        },
      })),
    };
  }

  // Prepend new column
  if (anchorName === 'middle-left') {
    const newColumn = getNewColumn(props);
    return {
      ...props,
      columns: [newColumn, ...props.columns],
      rows: props.rows.map((row) => ({
        ...row,
        cells: {
          ...row.cells,
          [newColumn.key]: { value: undefined },
        },
      })),
    };
  }
  throw new Error(`Invalid anchor name: ${anchorName}`);
};

export default getUpdatedDataGridContainer;
