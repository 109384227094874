import type { APMClient } from '@cognite/apm-client';
import { VIEW_VERSIONS } from '@cognite/apm-client';
import type {
  Cognite3DModel,
  CogniteCADRevision,
} from '@cognite/apm-client/src/three-d/types';
import { FDMClient } from '@cognite/fdm-client';
import type {
  AnnotationFilterProps,
  CogniteClient,
  Model3D,
  Revision3D,
} from '@cognite/sdk';

import { getAssetModelsQuery } from './dms-queries';
import type { ModelAndRevision3D, ThreeDModelIdentifier } from './types';
import { getModelIdFromExternalId } from './utils';

export class ThreeDService extends FDMClient {
  client;
  apmClient;
  constructor(client: CogniteClient, apmClient: APMClient) {
    super(client);
    this.client = client;
    this.apmClient = apmClient;
  }

  async get3DModels() {
    const { items: threeDModels } = await this.client.models3D.list();
    const revisionItems = await Promise.all(
      threeDModels.map(async (model: Model3D) => {
        const { items } = await this.client.revisions3D.list(model.id);
        return items.map((revision: Revision3D) => {
          return {
            thumbnailURL: revision.thumbnailURL,
            revisionId: revision.id,
            modelId: model.id,
            name: model.name,
            createdTime: revision.createdTime,
            thumbnailFileId: revision.thumbnailThreedFileId,
          } as ModelAndRevision3D;
        });
      })
    );

    return revisionItems.flat(1);
  }

  async get3DModelById(modelId: number) {
    return this.client.models3D.retrieve(modelId);
  }

  async filterAssetMappingsByAssetId(
    modelId: number,
    revisionId: number,
    assetId: number
  ) {
    return this.client.assetMappings3D
      .filter(modelId, revisionId, {
        filter: { assetIds: [assetId] },
      })
      .autoPagingToArray();
  }

  async get3DNodesByNodeId(
    modelId: number,
    revisionId: number,
    nodeId: number
  ) {
    const { items } = await this.client.revisions3D.list3DNodes(
      modelId,
      revisionId,
      {
        nodeId,
        depth: 0,
        limit: 1,
      }
    );

    return items;
  }

  async getAnnotations(annotationFilterProps: AnnotationFilterProps) {
    return this.client.annotations.list({
      filter: annotationFilterProps,
    });
  }

  // Download all 3d nodes
  // that's designed to be used only with lightweight models, where nodes number is around a hundred,
  // in full size models there can be millions of 3d nodes so this one should not be used
  async getAll3DNodesOfAModel(modelId: number, revisionId: number) {
    return this.client.revisions3D
      .list3DNodes(modelId, revisionId)
      .autoPagingToArray({ limit: 1000 });
  }

  // Get all mapped models of a classic asset
  async getClassicAssetModels(assetId: number) {
    return this.client.get<{
      items: ThreeDModelIdentifier[];
    }>(
      `api/v1/projects/${this.client.project}/3d/mappings/${assetId}/modelnodes`
    );
  }

  // Get all mapped models of an asset in IDM
  async getIdmAssetModels(
    assetExternalId: string
  ): Promise<ThreeDModelIdentifier[]> {
    // TODO(FUS-000): Move it to a better place
    const space = 'cdf_cdm';

    const queryData = getAssetModelsQuery(
      assetExternalId,
      this.apmClient.assets.viewReference
    );

    const { threeDModels } = await this.cogniteClient
      .post<{
        items: {
          asset: any[];
          object3D: any[];
          cadNode: any[];
          revisions: any[];
          model3D: any[];
        };
      }>(`${this.BASE_URL}/models/instances/query`, {
        data: queryData,
        withCredentials: true,
      })
      .then(({ data: { items } }) => {
        const models: Cognite3DModel[] = items.model3D?.map((revision) => {
          return {
            ...revision.properties[space][
              `Cognite3DModel/${VIEW_VERSIONS.COGNITE_3D_MODEL}`
            ],
            externalId: revision.externalId,
            space: revision.space,
          };
        });

        const revisions: CogniteCADRevision[] = items.revisions?.map(
          (revision) => {
            return {
              ...revision.properties[space][
                `CogniteCADRevision/${VIEW_VERSIONS.COGNITE_CAD_REVISION}`
              ],
              externalId: revision.externalId,
            };
          }
        );

        const threeDModels: ThreeDModelIdentifier[] = revisions.map(
          (revision) => {
            return {
              revisionId: revision.revisionId,
              modelId: getModelIdFromExternalId(revision.model3D.externalId),
              name: models.find(
                (model) =>
                  model.externalId === revision.model3D.externalId &&
                  model.space === revision.model3D.space
              )?.name,
            };
          }
        );

        return { threeDModels };
      });

    return threeDModels;
  }
}
