import mime from 'mime';

import opfsWorkerContent from '../../workers/opfs.worker.esm';

const OPFS_WORKER_URL = window.URL.createObjectURL(
  new Blob([opfsWorkerContent], { type: 'text/javascript' })
);

type WorkerCommand = 'set' | 'get' | 'delete';
export type FileData = {
  data: ArrayBuffer;
  contentType: string | null;
};
export type DataToCache = { data: ArrayBuffer; fileExtension: string | null };

type WorkerMessage = {
  command: WorkerCommand;
  messageId: string;
  key?: string;
  data?: DataToCache;
};
type WorkerResponseData = {
  command: 'get';
  messageId: string;
  key: string;
  data: DataToCache | undefined;
};
type ResolverFn = (data: FileData | undefined) => void;

interface TypedWorker<T> extends Omit<Worker, 'postMessage'> {
  postMessage(message: T, transfer: Transferable[]): void;
  postMessage(message: T, options?: StructuredSerializeOptions): void;
}

class OPFSCache {
  private worker: TypedWorker<WorkerMessage>;
  private messageResolvers: Map<string, ResolverFn> = new Map();

  public constructor() {
    this.worker = new Worker(OPFS_WORKER_URL);
    this.worker.onmessage = (event: MessageEvent<WorkerResponseData>) => {
      const { command, messageId, data: cachedData } = event.data;

      const resolveFn = this.messageResolvers.get(messageId);
      if (resolveFn === undefined) {
        return;
      }

      resolveFn(
        command === 'get' && cachedData !== undefined
          ? {
              data: cachedData.data,
              contentType: mime.getType(cachedData.fileExtension ?? ''),
            }
          : undefined
      );
      this.messageResolvers.delete(messageId);
    };
  }

  private async sendMessage(
    command: WorkerCommand,
    key: string,
    data?: DataToCache
  ): Promise<FileData | undefined> {
    const messageId = crypto.randomUUID();
    return new Promise((resolve) => {
      this.messageResolvers.set(messageId, resolve);
      this.worker.postMessage(
        { command, key, messageId, data },
        data ? [data.data] : []
      );
    });
  }

  public async set(key: string, data: DataToCache): Promise<void> {
    await this.sendMessage('set', key, data);
  }

  public async get(key: string): Promise<FileData | undefined> {
    return this.sendMessage('get', key);
  }

  public async delete(key: string): Promise<void> {
    await this.sendMessage('delete', key);
  }
}

export default OPFSCache;
