import type { APMAppDataServiceOptions } from '../apm-app-data-service';
import { APMAppDataService } from '../apm-app-data-service';
import type { ServiceContract } from '../types';
import { VIEW_VERSIONS } from '../utils';

import type { ConditionalAction } from './types';

export class ConditionalActionsService extends APMAppDataService<ConditionalAction> {
  constructor(contract: ServiceContract, options: APMAppDataServiceOptions) {
    super(
      contract,
      'ConditionalAction',
      VIEW_VERSIONS.CONDITIONAL_ACTION,
      options
    );
  }
}
