import { Infobox, Skeleton } from '@cognite/cogs.js-v10';
import { useClassicOrAPMAssetQuery } from '@infield/features/asset';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import type { FC } from 'react';

import { ComponentErrorBoundary } from '../ui/component-error-boundary';

import { Asset3dViewer } from './asset-3d-viewer';

interface Props {
  assetExternalId: string;
  modelId: number;
  revisionId: number;
  showSidebar?: boolean;
}

export const Asset3d: FC<Props> = ({
  assetExternalId,
  modelId,
  revisionId,
  showSidebar,
}) => {
  const {
    data: assetData,
    isInitialLoading,
    isSuccess,
  } = useClassicOrAPMAssetQuery(assetExternalId);
  const { t } = useTranslation(LOCIZE_NAMESPACES.asset);
  const assetId = assetData && 'id' in assetData ? assetData.id : undefined;
  const space = assetData && 'space' in assetData ? assetData.space : undefined;

  if (isInitialLoading)
    return <Skeleton.Rectangle width="100vw" height="100vh" />;

  if (isSuccess && assetData)
    return (
      <ComponentErrorBoundary sentryTag={'Asset3d'}>
        <Asset3dViewer
          assetId={assetId}
          assetExternalId={assetExternalId}
          space={space}
          modelId={modelId}
          revisionId={revisionId}
          showSidebar={showSidebar}
        />
      </ComponentErrorBoundary>
    );

  return (
    <Infobox status="warning">
      {t(
        'noDataforAssetId',
        'Did not find any data for the given assetExternalId'
      )}
    </Infobox>
  );
};
