import type { Observation, ObservationStatus } from '@cognite/apm-client';
import type { DataGridColumn, GridFilterModel } from '@cognite/apm-observation';
import type { DataGridProps, GridFilterOptionsType } from '@cognite/cogs-lab';
import { DataGrid, DataGridHeaderCell, makeToast } from '@cognite/cogs-lab';
import {
  Button,
  CheckmarkIcon,
  DeleteIcon,
  FilterIcon,
  Infobox,
  SendIcon,
} from '@cognite/cogs.js-v10';
import type { Filters } from '@cognite/fdm-client';
import { useMetrics } from '@cognite/metrics';
import {
  useFeatureToggleConfig,
  useIsTemplateAdminQuery,
  useSelectedRootLocationConfiguration,
} from '@infield/features/app-config';
import { LOCIZE_NAMESPACES } from '@infield/features/i18n';
import { useTranslation } from '@infield/features/i18n';
import { METRICS_NAMESPACES } from '@infield/features/metrics';
import {
  getIsAllRequiredFieldsFilled,
  getShowCompleteButton,
  getShowSendToSapButton,
  observationFieldInfoList,
  observationFilterableFields,
  useObservationFilterLocationOptions,
  useObservationsAggregateFilterOptions,
  useObservationsUpsertMutation,
  useSendObservationsToSap,
} from '@infield/features/observation';
import { useObservationFields } from '@infield/features/observation/hooks/use-observation-fields';
import { ActionMenu } from '@infield/features/ui';
import { MediaCollectionViewer } from '@infield/features/ui/media-collection-viewer';
import { gridFilterToFDMFilter } from '@infield/utils/grid-filter-to-fdm-filter';
import type { FetchNextPageOptions } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import type { FC } from 'react';

import * as S from './elements';
import type { FDMFileWithDataUrl } from './observations-data-grid-media';
import { getObservationColumns } from './utils/getObservationColumns';
import { isObservationMediaLocked } from './utils/isObservationMediaLocked';

type Props = {
  data: Observation[];
  isLoading?: boolean;
  isError?: boolean;
  selectedFilterLocations: GridFilterOptionsType[];
  onClickAsset: (observationExternalId: string) => void;
  onClickObservation: (observationExternalId: string) => void;
  setFilter: (filter: Filters | undefined) => void;
  setSelectedLocationFilterOptions: (options: GridFilterOptionsType[]) => void;
  media?: FDMFileWithDataUrl[];
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<unknown>;
};

export const ObservationsDataGrid: FC<Props> = ({
  data,
  isLoading,
  isError,
  media,
  selectedFilterLocations,
  onClickAsset,
  onClickObservation,
  setFilter,
  setSelectedLocationFilterOptions,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}) => {
  const { t } = useTranslation(LOCIZE_NAMESPACES.observations);
  const metrics = useMetrics(METRICS_NAMESPACES.observation);
  const { data: isTemplateAdmin } = useIsTemplateAdminQuery();
  const { isSending, sendToSap } = useSendObservationsToSap();
  const { observationFields } = useObservationFields();
  const { mutateAsync: upsertObservations, isLoading: isUpserting } =
    useObservationsUpsertMutation();
  const [selectedRows, setSelectedRows] = useState<(number | string)[]>([]);
  const selectedRootLocation = useSelectedRootLocationConfiguration();
  const { config: rootLocationFeatureToggles } = useFeatureToggleConfig(
    selectedRootLocation?.externalId ||
      selectedRootLocation?.assetExternalId ||
      ''
  );
  const [filterState, setFilterState] = useState<GridFilterModel>([]);
  const [selectedFileInstanceId, setSelectedFileInstanceId] = useState<{
    externalId: string;
    space?: string;
  }>();

  const [lastClickedFilterField, setLastClickedFilterField] =
    useState<string>('');

  const {
    isLoadingLocation: isLoadingFilterLocationOptions,
    locationOptions: locationFilterOptions,
    setSearchInput,
    isBackendSearchEnabled,
  } = useObservationFilterLocationOptions(
    lastClickedFilterField,
    selectedFilterLocations
  );

  const {
    data: aggregateFilterOptions = {},
    isLoading: isLoadingFilterOptions,
  } = useObservationsAggregateFilterOptions(
    observationFilterableFields,
    lastClickedFilterField
  );

  const isSapWriteBackEnabled = Boolean(
    rootLocationFeatureToggles?.observations?.isWriteBackEnabled
  );

  const handleLocationFilterSearch = useCallback(
    (searchInput: string) => {
      if (!isBackendSearchEnabled) {
        setSearchInput(searchInput);
      }
    },
    [isBackendSearchEnabled, setSearchInput]
  );

  const handleRowClick = useCallback(
    (externalId: string) => onClickObservation(externalId),
    [onClickObservation]
  );

  const handleAssetClick: DataGridColumn = useCallback(
    ({ row: { externalId, asset } }) => {
      if (asset) {
        onClickAsset(externalId);
        return;
      }

      handleRowClick(externalId);
    },
    [handleRowClick, onClickAsset]
  );

  const handleScrollToEnd = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const handleMediaClick = (externalId: string, space?: string) => {
    setSelectedFileInstanceId({ externalId, space });
  };

  const handleRowSelectionChange = (nextSelectedRows: (number | string)[]) => {
    setSelectedRows(nextSelectedRows);
  };

  const selectedObservations = data.filter(({ externalId }) =>
    selectedRows.includes(externalId)
  );

  const isAllRequiredFieldsFilled = selectedObservations.every(
    (observation) => {
      return getIsAllRequiredFieldsFilled(observation, observationFields);
    }
  );

  const isCompleteButtonEnabled =
    selectedObservations.every(({ status }) => {
      return getShowCompleteButton(isSapWriteBackEnabled, status);
    }) && isAllRequiredFieldsFilled;

  const isSendToSapButtonEnabled =
    selectedObservations.every(({ status }) => {
      return getShowSendToSapButton(isSapWriteBackEnabled, status);
    }) && isAllRequiredFieldsFilled;

  const isDeletingEnabled = selectedObservations.every(({ status }) => {
    return status !== 'Sent' && status !== 'File not sent';
  });

  const handleOnComplete = () => {
    const completedObservations = selectedObservations.map(
      ({ externalId }) => ({
        externalId,
        status: 'Completed' as ObservationStatus,
      })
    );
    upsertObservations({ observations: completedObservations }).finally(() => {
      makeToast({
        type: 'success',
        body: t('OBSERVATION_STEP_COMPLETED_TITLE', 'Observation completed', {
          count: selectedRows.length,
        }),
      });
      setSelectedRows([]);
    });
  };

  const handleOnDelete = () => {
    upsertObservations({
      observations: selectedObservations.map((observation) => ({
        ...observation,
        isArchived: true,
      })),
    }).finally(() => {
      makeToast({
        type: 'success',
        body: t(
          'OBSERVATION_DATA_GRID_DELETE_OBSERVATIONS_SUCCESS_TOAST_NEW',
          'Observation deleted',
          { count: selectedRows.length }
        ),
      });
      setSelectedRows([]);
    });
  };

  const onWriteBackSuccess = () => {
    makeToast({
      type: 'success',
      body: t(
        'OBSERVATION_DATA_GRID_WRITE_BACK_SUCCESS_TOAST_NEW',
        'Observation sent',
        { count: selectedRows.length }
      ),
    });
    setSelectedRows([]);
  };

  const handleFilterModelChange = useCallback(
    (model: DataGridProps['filterModel']) => {
      const modelItems = model?.items || [];
      setFilter(
        gridFilterToFDMFilter(modelItems, {}, observationFieldInfoList)
      );

      const selectedLocations: string[] | undefined = modelItems.find(
        (filterItem) => filterItem.field === 'asset'
      )?.value;

      const nextSelectedLocationFilterOptions = (selectedLocations
        ?.map((location) =>
          locationFilterOptions.find((p) => p.value === location)
        )
        .filter(Boolean) || []) as GridFilterOptionsType[];

      setSelectedLocationFilterOptions(nextSelectedLocationFilterOptions);
      setFilterState(modelItems);
    },
    [setFilter, locationFilterOptions, setSelectedLocationFilterOptions]
  );

  const localeText = useMemo(() => {
    return {
      noResultsFound: {
        description: t(
          'OBSERVATION_TABLE_NO_RESULT_FOUNT_DESCRIPTION',
          'Try adjusting your search or filters or clear the filters'
        ),
      },
    };
  }, [t]);

  const filterModel = useMemo(() => {
    return {
      items: filterState,
    };
  }, [filterState]);

  const enrichedColumns = useMemo(() => {
    return getObservationColumns({
      t,
      observationFields,
      media,
      filterOptionsMap: aggregateFilterOptions,
      locationFilterOptions,
      isLoading: isLoadingFilterOptions,
      isLoadingFilterLocationOptions,
      onRowClick: (params) => handleRowClick(params.row.externalId),
      onAssetClick: handleAssetClick,
      onLocationFilterSearch: handleLocationFilterSearch,
      onMediaClick: handleMediaClick,
    }).map((column) => {
      return {
        ...column,
        renderHeader: (params: any) => {
          return (
            <DataGridHeaderCell
              filterTrigger={
                <Button
                  type="ghost"
                  icon={<FilterIcon />}
                  onClick={() => {
                    setLastClickedFilterField(params.colDef.field);
                  }}
                />
              }
              localeText={localeText}
              colDef={params.colDef}
              filterModel={filterModel}
              onFilterModelChange={handleFilterModelChange}
            />
          );
        },
      };
    });
  }, [
    aggregateFilterOptions,
    filterModel,
    handleAssetClick,
    handleFilterModelChange,
    handleLocationFilterSearch,
    handleRowClick,
    isLoadingFilterLocationOptions,
    isLoadingFilterOptions,
    localeText,
    locationFilterOptions,
    media,
    observationFields,
    t,
  ]);

  if (isError) {
    return (
      <S.InfoboxWrapper>
        <Infobox
          title={t(
            'OBSERVATION_DATA_GRID_ERROR_RECEIVING_DATA',
            'Error receiving data'
          )}
          status="critical"
        >
          {t(
            'OBSERVATION_DATA_GRID_ERROR_RECEIVING_DATA_DESCRIPTION',
            'We encountered an error receiving observation data.'
          )}
        </Infobox>
      </S.InfoboxWrapper>
    );
  }

  return (
    <>
      <S.Container $hasExtraMargin={selectedRows.length > 0}>
        <DataGrid
          size="large"
          checkboxSelection={isTemplateAdmin}
          pagination={false}
          loading={isLoading || isFetchingNextPage}
          loadingRows={10}
          onRowsScrollEnd={handleScrollToEnd}
          data={data.map((rowData) => ({ ...rowData, id: rowData.externalId }))}
          columns={enrichedColumns}
          onRowSelectionChange={handleRowSelectionChange}
          selectedRows={selectedRows}
          disableRowSelectionOnRowClick
          onFilterModelChange={handleFilterModelChange}
          filterModel={filterModel}
          localeText={localeText}
        />
      </S.Container>
      {selectedFileInstanceId && (
        <MediaCollectionViewer
          fileInstanceIds={[selectedFileInstanceId]}
          onClose={() => setSelectedFileInstanceId(undefined)}
          hideDeleteButton={isObservationMediaLocked(
            selectedFileInstanceId.externalId,
            data
          )}
        />
      )}
      {selectedRows.length > 0 && (
        <ActionMenu
          visible
          subTitle={t('OBSERVATIONS_ACTION_MENU_TITLE', {
            defaultValue: '{{observationLength}} observations',
            observationLength: selectedRows.length,
            count: selectedRows.length,
          })}
          actions={[
            {
              key: 'Send',
              name: t('OBSERVATION_ACTION_MENU_BUTTON_SEND', 'Send'),
              icon: <SendIcon />,
              disabled: !isSendToSapButtonEnabled,
              loading: isSending,
              tooltip: !isSendToSapButtonEnabled
                ? t(
                    'OBSERVATION_ACTION_MENU_BUTTON_SEND_REQUIRED_FIELDS_MESSAGE',
                    `Observation must be in status other than 'Sent' and have all required fields to be sent`,
                    { count: selectedRows.length }
                  )
                : undefined,
              hidden: !isSapWriteBackEnabled,
            },
            {
              key: 'Complete',
              name: 'Complete',
              icon: <CheckmarkIcon />,
              disabled: !isCompleteButtonEnabled,
              loading: isUpserting,
              tooltip: !isCompleteButtonEnabled
                ? t(
                    'OBSERVATION_ACTION_MENU_BUTTON_COMPLETE_REQUIRED_FIELDS_MESSAGE',
                    `Observation in status other than 'Completed' and have all required fields to be completed`,
                    { count: selectedRows.length }
                  )
                : undefined,
              hidden: isSapWriteBackEnabled,
            },
            {
              key: 'Delete',
              name: t('OBSERVATION_ACTION_MENU_BUTTON_DELETE', 'Delete'),
              icon: <DeleteIcon />,
              disabled: !isDeletingEnabled,
              loading: isUpserting,
              tooltip: !isDeletingEnabled
                ? t(
                    'OBSERVATION_ACTION_MENU_BUTTON_DELETE_DISABLED_TOOLTIP_NEW',
                    `Observations with 'Sent' or 'File not sent' status can not be deleted.`
                  )
                : undefined,
            },
          ]}
          onClose={() => setSelectedRows([])}
          onActionClick={(key) => {
            switch (key) {
              case 'Send':
                sendToSap(selectedObservations, onWriteBackSuccess);
                metrics.track('observationWasSentToSAP');
                break;
              case 'Complete':
                handleOnComplete();
                break;
              case 'Delete':
                handleOnDelete();
            }
          }}
        />
      )}
    </>
  );
};
