import Konva from 'konva';
import { IRect } from 'konva/cmj/types';
import { isEqual, keyBy, partition, throttle, uniqBy } from 'lodash';
import maxBy from 'lodash/maxBy';

import UnifiedViewerEventType from '../../core/UnifiedViewerEventType';
import getAnnotationSerializerByType from '../annotations/getAnnotationSerializerByType';
import getAnnotationWithAbsoluteUnitsScaled from '../annotations/getAnnotationWithAbsoluteUnitScaled';
import {
  Annotation,
  type ConnectionPolylineAnnotation,
  isConnectionPolylineAnnotation,
  isSingleDocumentAnnotation,
  isCauseMapNodeAnnotationNode,
  isCauseMapNodeAnnotation,
  CauseMapNodeAnnotation,
  isPolylineAnnotation,
  isCommentAnnotationNode,
  isCommentAnnotation,
} from '../annotations/types';
import validate from '../annotations/validate';
import { Container } from '../containers';
import { ContainerEventType } from '../containers/ContainerEventType';
import { ContainerConfig } from '../containers/types';
import validateContainerConfigs from '../containers/validateContainerConfigs';
import {
  isAnnotation,
  isContainerAnnotation,
  UNIFIED_VIEWER_NODE_TYPE_KEY,
  type UnifiedViewerNode,
  UnifiedViewerNodeType,
} from '../types';
import { UnifiedViewer, UnifiedViewerErrorEvent } from '../UnifiedViewer';
import isAnnotationNode from '../utils/isAnnotationNode';
import isContainerNode from '../utils/isContainerNode';
import isNotUndefined from '../utils/isNotUndefined';
import isSingleDocumentAnnotationNode from '../utils/isSingleDocumentAnnotationNode';
import isSizeAndPositionApproximatelyEqual from '../utils/isSizeAndPositionApproximatelyEqual';
import { isResizableNode, isSelectableNode } from '../utils/nodeUtils';
import partitionIntoContainersAndAnnotations from '../utils/partitionIntoContainersAndAnnotations';
import {
  addMarginToRect,
  areRectsOverlapping,
  isRectInsideRect,
} from '../utils/rectUtils';

import ContainerCache from './ContainerCache/ContainerCache';
import reconcileAnnotations from './reconcileAnnotations';

const STAGE_MARGIN_PERCENTAGE = 0.25;
const UPDATE_NODE_VISIBILITY_THROTTLE_MS = 500;
const COMMENT_ANNOTATION_SCALE_THROTTLE_MS = 15;

class UnifiedViewerRenderer {
  private unifiedViewer: UnifiedViewer;
  private previousContainers: ContainerConfig[] | undefined = undefined;
  private currentContainers: ContainerConfig[] | undefined = undefined;
  private annotations: Annotation[] = [];
  private renderedNodesById: Map<
    string,
    Konva.Group | Konva.Shape | Konva.Node
  > = new Map();
  private clonedOriginalNodes: Konva.Node[] = [];
  private dirtyNodes: any = [];
  private containerCache: ContainerCache = new ContainerCache();
  private containerById = new Map<string, Container>();
  private canvasNodeIndexById = new Map<string, number>();
  private prevCanvasNodeIndexById = new Map<string, number>();
  private declaredNodes: UnifiedViewerNode[] = [];
  private haveContainersLoadedOnce = false;
  private haveAnnotationsLoadedOnce = false;

  public constructor(unifiedViewer: UnifiedViewer) {
    this.unifiedViewer = unifiedViewer;

    this.unifiedViewer.addEventListener(
      UnifiedViewerEventType.ON_VIEWPORT_CHANGE,
      this.updateNodeVisibilityBasedOnViewport
    );

    this.unifiedViewer.addEventListener(
      UnifiedViewerEventType.ON_ZOOM_CHANGE,
      this.applyScaleToCommentAnnotations
    );
    this.unifiedViewer.addEventListener(
      UnifiedViewerEventType.ON_ZOOM_END,
      this.applyScaleToCommentAnnotations
    );
  }

  private applyScaleToCommentAnnotations = throttle((scale: number): void => {
    const newScale = { x: 1 / scale, y: 1 / scale };
    Array.from(this.renderedNodesById.values()).forEach((node) => {
      if (isCommentAnnotationNode(node)) {
        node.scale(newScale);
        this.unifiedViewer.getTransformer()?.updateSelectionRectangles();
      }
    });
  }, COMMENT_ANNOTATION_SCALE_THROTTLE_MS);

  public renderContainers = async (
    containerConfigs: ContainerConfig[]
  ): Promise<void> => {
    if (
      this.currentContainers !== undefined &&
      isEqual(this.currentContainers, containerConfigs)
    ) {
      // Skip update if config is the same
      return;
    }

    const validationResult = validateContainerConfigs(containerConfigs);
    if (validationResult !== undefined) {
      throw new Error(validationResult.join(''));
    }
    this.previousContainers = this.currentContainers;
    this.currentContainers = containerConfigs;

    this.containerCache.removeStaleCachedContainers(containerConfigs);

    this.unifiedViewer.layers.main.removeAllListeners();
    this.unifiedViewer.layers.main.removeChildren();
    this.containerById.clear();

    // (Re-)Render containers
    this.unifiedViewer.layers.main.setContainerConfigs(containerConfigs);
    containerConfigs.forEach((containerConfig) => {
      const container = this.containerCache.getContainer(
        containerConfig,
        this.unifiedViewer
      );
      container.setProps(containerConfig);
      this.unifiedViewer.layers.main.addChild(container);
      this.containerById.set(container.id, container);
    });

    const onContainersReady = () => {
      // TODO(FUS-000): Does this need to be a separate event? Could be ON_READY
      this.haveContainersLoadedOnce = true;
      this.unifiedViewer.emit(UnifiedViewerEventType.ON_CONTAINER_LOAD);
      this.checkNodesLoaded();

      const haveAnnotationsPreviouslyRendered = this.annotations.length !== 0;
      if (haveAnnotationsPreviouslyRendered) {
        this.setAnnotations(this.annotations);
      }
    };

    const onError = (
      containerConfig: ContainerConfig,
      error: UnifiedViewerErrorEvent
    ): void => {
      this.unifiedViewer.emit(
        UnifiedViewerEventType.ON_CONTAINER_ERROR,
        containerConfig,
        error
      );
    };

    if (
      containerConfigs.length === 0 ||
      this.unifiedViewer.layers.main.getIsReady()
    ) {
      onContainersReady();
    }

    this.unifiedViewer.layers.main.addEventListener(
      ContainerEventType.ON_READY_STATE_CHANGE,
      (container) => {
        if (container.getIsReady()) {
          onContainersReady();
        }
      }
    );

    this.unifiedViewer.layers.main.addEventListener(
      ContainerEventType.ON_CONTAINER_ERROR,
      (container, error) => {
        if (
          container.getHasErrorOccurred &&
          container.getHasErrorOccurred() &&
          container.serialize !== undefined
        ) {
          onError(container.serialize(), error);
        }
      }
    );
    this.reorderCanvasNodes();
  };

  public getContainerRectRelativeToStageById = (
    id: string
  ): IRect | undefined => {
    const containerContentNode = this.getContainerById(id)?.getContentNode();
    if (containerContentNode === undefined) {
      return undefined;
    }
    return this.getNodeRectRelativeToStage(containerContentNode);
  };

  public getAnnotationRectRelativeToStageById = (
    id: string
  ): IRect | undefined => {
    const annotationNode = this.getAnnotationNodeById(id);
    if (annotationNode === undefined) {
      return undefined;
    }
    return this.getNodeRectRelativeToStage(annotationNode);
  };

  public getNodeRectRelativeToStage = (node: Konva.Node): IRect | undefined => {
    const rect = node.getClientRect({
      // @ts-ignore - relativeTo DOES accept layers just fine.
      relativeTo: this.unifiedViewer.layers.main.getNode(),
    });

    if (rect === undefined) {
      return undefined;
    }

    // Konva can return NaN as object values if the element has not yet been rendered
    if (Object.values(rect).some((value) => Number.isNaN(value))) {
      return undefined;
    }

    return rect;
  };

  private areConnectionAnnotationEndpointsRendered = (
    node: ConnectionPolylineAnnotation
  ): boolean => {
    if (
      node.fromId !== undefined &&
      this.getContainerOrAnnotationNodeById(node.fromId) === undefined
    ) {
      return false;
    }

    if (
      node.toId !== undefined &&
      this.getContainerOrAnnotationNodeById(node.toId) === undefined
    ) {
      return false;
    }

    return true;
  };

  private refreshCanvasNodeIndices = (): void => {
    this.prevCanvasNodeIndexById = this.canvasNodeIndexById;
    this.canvasNodeIndexById = new Map(
      this.declaredNodes
        // Ignore container annotations
        .filter((node) => !isContainerAnnotation(node))
        // Ignore connection lines with endpoint(s) that aren't rendered yet
        .filter(
          (node) =>
            !(
              isAnnotation(node) &&
              isConnectionPolylineAnnotation(node) &&
              !this.areConnectionAnnotationEndpointsRendered(node)
            )
        )
        // Ignore annotations that haven't been rendered yet
        .filter(
          (node) => !isAnnotation(node) || this.renderedNodesById.has(node.id)
        )
        .map((node, index) => [node.id, index])
    );
  };

  public setNodes = (nodes: UnifiedViewerNode[]): void => {
    this.declaredNodes = nodes;
    // Render the containers and annotations separately
    const { containers, annotations } =
      partitionIntoContainersAndAnnotations(nodes);
    this.renderContainers(containers);

    // Filter comments and make sure they are rendered last (on top of other annotations)
    const [commentAnnotations, otherAnnotations] = partition(
      annotations,
      isCommentAnnotation
    );
    this.setAnnotations([...otherAnnotations, ...commentAnnotations]);
  };

  private reorderCanvasNodes = (): void => {
    if (this.unifiedViewer.options.shouldUseShamefulFastMode === true) {
      return;
    }

    this.refreshCanvasNodeIndices();

    let shouldRedraw = false;
    Konva.autoDrawEnabled = false;
    // We explicitly create a local copy of the children list since the list,
    // maintained by Konva, will be reordered when we call setZIndex for each child
    const children = [...this.unifiedViewer.layers.main.layer.getChildren()];
    children.forEach((child) => {
      const zIndex = this.canvasNodeIndexById.get(child.id());
      if (zIndex === undefined || zIndex === child.getZIndex()) {
        return;
      }
      shouldRedraw = true;
      child.setZIndex(zIndex);
    });
    Konva.autoDrawEnabled = true;
    if (shouldRedraw) {
      this.unifiedViewer.stage.draw();
    }
  };

  public checkNodesLoaded = (): void => {
    if (this.haveContainersLoadedOnce && this.haveAnnotationsLoadedOnce) {
      this.unifiedViewer.emit(UnifiedViewerEventType.ON_NODES_LOAD);
    }
  };

  public onAnnotationsReady = (): void => {
    this.haveAnnotationsLoadedOnce = true;
    this.unifiedViewer.emit(UnifiedViewerEventType.ON_ANNOTATIONS_LOAD);
    this.checkNodesLoaded();
  };

  public setAnnotations = (annotations: Annotation[]): void => {
    // Rendering annotations peu a peu can lead to weird race conditions and click stealing
    // instead, we want to flush all of our changes, in order, and then draw that in one go
    // hence we disable the autoDraw feature of Konva and draw it once we are ready
    Konva.autoDrawEnabled = false;

    const annotationIds = new Set();
    const validAnnotations = annotations.filter((annotation) => {
      if (annotationIds.has(annotation.id)) {
        // eslint-disable-next-line no-console
        console.warn(`Duplicate annotation id: ${annotation.id}`);
        return false;
      }
      annotationIds.add(annotation.id);

      const validationResult = validate(annotation);
      if (validationResult === undefined) {
        return true;
      }

      // eslint-disable-next-line no-console
      console.warn('Invalid annotation:', validationResult);
      return false;
    });

    // The transformer might be attached to a node that we destroy
    // so we need to keep track of which nodes were attached to the transformer
    // so that we can reattach them after the nodes have been recreated
    this.shamefulCaptureAttachedTransformerNodeIds();
    const {
      annotationNodesToKeep,
      annotationAndNodesToUpdate,
      annotationNodesToRemove,
      annotationsToAdd,
    } = reconcileAnnotations({
      previousContainers: this.previousContainers,
      currentContainers: this.currentContainers,
      dirtyNodes: this.dirtyNodes.filter(isAnnotationNode),
      currentAnnotationNodes: Array.from(
        this.renderedNodesById.values()
      ).filter(isAnnotationNode),
      previousAnnotations: this.annotations,
      currentAnnotations: validAnnotations,
    });

    const annotationNodeIdsToRemove = new Set(
      annotationNodesToRemove.map((annotationNode) => annotationNode.id())
    );
    this.unifiedViewer.layers.main
      .find((annotationNode) =>
        annotationNodeIdsToRemove.has(annotationNode.id())
      )
      .forEach((annotationNode) => {
        this.unifiedViewer.eventHandler.removeAllEventListeners(annotationNode);
        annotationNode.destroy();
      });

    this.renderedNodesById = new Map(
      [...annotationNodesToKeep].map((node) => [node.id(), node])
    );

    // NOTE: We need to render or update the non-connection annotations first since the connection annotations
    //       needs the nodes to have its final position to layout the connection lines
    const [
      connectionAnnotationAndNodesToUpdate,
      nonConnectionAnnotationAndNodesToUpdate,
    ] = partition(annotationAndNodesToUpdate, ({ annotation }) =>
      isConnectionPolylineAnnotation(annotation)
    );
    const [connectionAnnotationToAdd, nonConnectionAnnotationToAdd] = partition(
      annotationsToAdd,
      isConnectionPolylineAnnotation
    );

    // Update the non-connection annotations first
    nonConnectionAnnotationAndNodesToUpdate.forEach(({ annotation, node }) => {
      this.updateNode(node, annotation);
      this.renderedNodesById.set(node.id(), node);
    });

    // Add the non-connection annotations, then the connection annotations
    [...nonConnectionAnnotationToAdd, ...connectionAnnotationToAdd].forEach(
      (annotation) => {
        const annotationNode = this.getNodeFromAnnotation(annotation);
        if (annotationNode === undefined) {
          return;
        }
        this.addAnnotationNodeToContainer(annotationNode);
      }
    );

    // Update the connection annotations last
    connectionAnnotationAndNodesToUpdate.forEach(({ annotation, node }) => {
      this.updateNode(node, annotation);
      this.renderedNodesById.set(node.id(), node);
    });

    this.reorderContainerAnnotations(validAnnotations);
    this.reorderCanvasNodes();
    const prevAnnotations = this.annotations;
    this.annotations = validAnnotations;
    this.shamefulRehydrateAttachedTransformerNodes();

    Konva.autoDrawEnabled = true;
    this.unifiedViewer.layers.main.draw();
    this.onAnnotationsReady();

    this.refreshCauseMaps({
      annotationsToAdd,
      annotationAndNodesToUpdate,
      annotationNodesToRemove,
      connectionAnnotationAndNodesToUpdate,
      connectionAnnotationToAdd,
      prevAnnotations,
    });
  };

  private refreshCauseMaps = ({
    annotationsToAdd,
    annotationAndNodesToUpdate,
    annotationNodesToRemove,
    prevAnnotations,
    connectionAnnotationToAdd,
    connectionAnnotationAndNodesToUpdate,
  }: {
    annotationsToAdd: Annotation[];
    annotationAndNodesToUpdate: {
      annotation: Annotation;
      node: Konva.Node;
    }[];
    annotationNodesToRemove: Konva.Node[];
    connectionAnnotationToAdd: ConnectionPolylineAnnotation[];
    prevAnnotations: Annotation[];
    connectionAnnotationAndNodesToUpdate: {
      annotation: Annotation;
      node: Konva.Node;
    }[];
  }): void => {
    const causeMapAnnotationsToAdd = annotationsToAdd.filter(
      isCauseMapNodeAnnotation
    );

    // Get edges in the cause map graph
    const prevCauseMapNodesById = new Map(
      prevAnnotations.filter(isCauseMapNodeAnnotation).map((a) => [a.id, a])
    );
    const prevConnectionsById = new Map(
      prevAnnotations.filter(isPolylineAnnotation).map((a) => [a.id, a])
    );
    const connections = [
      ...connectionAnnotationToAdd,
      ...connectionAnnotationAndNodesToUpdate
        .map(({ annotation }) => annotation)
        .filter(isPolylineAnnotation)
        .filter(({ id, fromId, toId }) => {
          const prevConnection = prevConnectionsById.get(id);
          return (
            prevConnection !== undefined &&
            (prevConnection.fromId !== fromId || prevConnection.toId !== toId)
          );
        }),
    ]
      .map(({ fromId, toId }) => ({ fromId, toId }))
      .filter(
        (edge): edge is { fromId: string; toId: string } =>
          edge.fromId !== undefined && edge.toId !== undefined
      );

    const causeMapNodesToRemove = annotationNodesToRemove.filter(
      isCauseMapNodeAnnotationNode
    );

    const updatedCauseMapAnnotationAndNodes = annotationAndNodesToUpdate.filter(
      (
        item
      ): item is {
        annotation: CauseMapNodeAnnotation;
        node: Konva.Label;
      } =>
        isCauseMapNodeAnnotation(item.annotation) &&
        isCauseMapNodeAnnotationNode(item.node)
    );
    const causeMapAnnotationsWithFontSizeUpdated: CauseMapNodeAnnotation[] =
      updatedCauseMapAnnotationAndNodes
        .filter(({ annotation }) => {
          const prevCauseMap = prevCauseMapNodesById.get(annotation.id);
          if (prevCauseMap === undefined) {
            return false;
          }
          return (
            annotation.style.fontSize !== undefined &&
            prevCauseMap.style.fontSize !== undefined &&
            prevCauseMap.style.fontSize !== annotation.style.fontSize
          );
        })
        .map(({ node, annotation }) => ({
          ...annotation,
          // It might be that the font size was corrected because the cause map
          // text was overflowing, hence why we override the annotation size
          // with the node size
          ...node.size(),
        }));

    if (
      causeMapAnnotationsToAdd.length > 1 &&
      !this.unifiedViewer.needsRelayout(causeMapAnnotationsToAdd) &&
      causeMapAnnotationsWithFontSizeUpdated.length === 0
    ) {
      return;
    }
    const causeMapAnnotationById = new Map(
      this.annotations.filter(isCauseMapNodeAnnotation).map((a) => [a.id, a])
    );
    const causeMapEdges = connections.filter(
      ({ fromId, toId }) =>
        causeMapAnnotationById.has(fromId) && causeMapAnnotationById.has(toId)
    );

    const allAddedCauseMapAnnotations = [
      ...causeMapAnnotationsWithFontSizeUpdated,
      ...causeMapAnnotationsToAdd,
      ...causeMapEdges
        .flatMap(({ fromId, toId }) => [
          causeMapAnnotationById.get(fromId),
          causeMapAnnotationById.get(toId),
        ])
        .filter(isNotUndefined),
    ];

    const shouldRelayoutCauseMapNodes =
      causeMapNodesToRemove.length > 0 ||
      allAddedCauseMapAnnotations.length > 0;
    if (shouldRelayoutCauseMapNodes) {
      this.unifiedViewer.relayoutCauseMapNodes(
        // TODO(FUS-000): Currently, if a node is deleted, we refresh all graphs. This
        // could be optimized to only refresh the affected graphs.
        causeMapNodesToRemove.length > 0
          ? this.annotations.filter(isCauseMapNodeAnnotation)
          : allAddedCauseMapAnnotations
      );
    }
  };

  private reorderContainerAnnotations = (annotations: Annotation[]): void => {
    if (this.unifiedViewer.options.shouldUseShamefulFastMode === true) {
      return;
    }

    annotations.filter(isSingleDocumentAnnotation).forEach((annotation) => {
      const node = this.renderedNodesById.get(annotation.id);
      if (node === undefined) {
        return;
      }

      const parent = node.parent;
      if (parent === null) {
        return;
      }

      node.remove();
      parent.add(node);
    });
  };

  private addAnnotationNodeToContainer = (node: Konva.Node): void => {
    const annotationNodeContainerId = node.getAttr('containerId');
    const container = this.getContainerById(annotationNodeContainerId);
    if (annotationNodeContainerId !== undefined && container === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        `Could not find container with id ${annotationNodeContainerId}`
      );
      return;
    }

    // NOTE: This is a source for potential errors. We are adding connections directly
    // to the main layer, but the main layer keep track of it's children internally
    // in the LayerInstance, but the added node is not tracked there.
    const addToContainer = container ?? this.unifiedViewer.layers.main;
    if (isSingleDocumentAnnotationNode(node) && !addToContainer?.getIsReady()) {
      // Skip adding container annotations if the container is not ready
      return;
    }

    addToContainer.getNode().add(node as any);
    this.renderedNodesById.set(node.id(), node);
  };

  private isNodeInStage = (node: Konva.Node): boolean => {
    return this.unifiedViewer.stage.findOne(`#${node.id()}`) !== undefined;
  };

  public setDirtyNodes = (nodes: Konva.Node[]): void => {
    // Dirty nodes are temporarily owned by the library.
    // Once an interaction has been completed, commitDirtyNodes is called,
    // the dirty node is destroyed and if there was an original node, it is restored,
    // and the application is notified and can decide if they want to apply the change or not
    this.dirtyNodes.forEach((node: any) => node.destroy());
    this.dirtyNodes = nodes;

    nodes.forEach((node) => {
      if (this.isNodeInStage(node)) {
        // Save a copy of the original nodes for after commit
        const clonedOriginalNode = node.clone();
        // Save a reference to the original parent, so that when we discard the dirty nodes
        // we can add the original node back to the same place. For annotations this could be gotten
        // directly via a lookup on the annotation.containerId, but for containers, you would
        // otherwise have to search through all of the parents to know where to put it back.
        clonedOriginalNode.setAttr('originalNodeParent', node.parent);
        this.clonedOriginalNodes.push(clonedOriginalNode);
        // Don't add the node if it already exists in stage
        return;
      }

      const containerGroup =
        node.attrs.containerId !== undefined
          ? this.getContainerById(node.attrs.containerId)?.getContentNode()
          : this.unifiedViewer.layers.main.getContentNode();

      if (containerGroup === undefined) {
        throw new Error(
          'Could not find container to add node to. This should not happen'
        );
      }

      // @ts-expect-error
      containerGroup.add(node);
    });
    this.reorderCanvasNodes();
  };

  private getAnnotationsScaledByContainer = (): Annotation[] => {
    return this.dirtyNodes
      .filter(
        (node: Konva.Node) => isContainerNode(node) && node.scaleX() !== 1
      )
      .flatMap((containerNode: Konva.Node) => {
        return this.annotations
          .filter(
            (annotation) =>
              isSingleDocumentAnnotation(annotation) &&
              annotation.containerId === containerNode.id()
          )
          .map((annotation) =>
            getAnnotationWithAbsoluteUnitsScaled(
              annotation,
              containerNode.scaleX()
            )
          )
          .filter(isNotUndefined);
      });
  };

  public commitDirtyNodes = (): void => {
    if (this.dirtyNodes.length === 0) {
      return;
    }

    const annotations: Annotation[] = uniqBy(
      [
        ...this.dirtyNodes
          .filter(isAnnotationNode)
          .map((node: Konva.Node) => this.getAnnotationFromNode(node)),
        ...this.getAnnotationsScaledByContainer(),
      ],
      (annotation) => annotation.id
    );

    const containers: ContainerConfig[] = this.dirtyNodes
      .filter(isContainerNode)
      .map((node: Konva.Node) => node.attrs.container.serialize());

    const serializedUpdateBeforeResettingNodes = {
      containers,
      annotations,
    };

    this.shamefulCaptureAttachedTransformerNodeIds();

    this.dirtyNodes.forEach((node: any) => node.destroy());
    this.dirtyNodes = [];
    this.unifiedViewer.shamefullyRefreshAnchors([]);

    const clonedOriginalNodeIds = this.clonedOriginalNodes.map((node) =>
      node.id()
    );
    clonedOriginalNodeIds.forEach((id) => {
      this.renderedNodesById.delete(id);
    });

    this.clonedOriginalNodes.forEach((clonedOriginalNode: Konva.Node) => {
      // Restore cloned original nodes after commit
      const parentGroupToRenderIn =
        clonedOriginalNode.getAttr('originalNodeParent');

      const nodeType = clonedOriginalNode.getAttr(UNIFIED_VIEWER_NODE_TYPE_KEY);
      if (nodeType === UnifiedViewerNodeType.CONTAINER_GROUP) {
        // Currently - the id of the Container.group is set to the same as the id of the Container
        // this is an implicit/hidden assumption that we should try to enforce.
        const containerId = clonedOriginalNode.getAttr('id');

        // We find the corresponding UFV Container and make sure to update it's internal references
        // otherwise they would now be pointing to stale nodes as the original nodes
        // (that were marked dirty and transformed) have since been destroyed
        // NOTE: Could this whole thing (less performantly but maybe more cleanly) be done by
        // forcing the Container to re-render itself?
        const containerRef = this.getContainerById(containerId);
        if (containerRef !== undefined) {
          containerRef.setNode(clonedOriginalNode as Konva.Group);
        }
      }
      parentGroupToRenderIn.add(clonedOriginalNode);

      this.renderedNodesById.set(clonedOriginalNode.id(), clonedOriginalNode);
    });

    this.shamefulRefreshRenderedAnnotationNodes();

    this.clonedOriginalNodes = [];

    const prevAnnotations = this.annotations;
    this.unifiedViewer.emit(
      UnifiedViewerEventType.ON_UPDATE_REQUEST,
      serializedUpdateBeforeResettingNodes
    );
    this.shamefulRehydrateAttachedTransformerNodes();

    const prevCauseMapNodesById = new Map(
      prevAnnotations.filter(isCauseMapNodeAnnotation).map((a) => [a.id, a])
    );
    const causeMapNodes = serializedUpdateBeforeResettingNodes.annotations
      .filter(isCauseMapNodeAnnotation)
      .filter((node) => {
        const prevNode = prevCauseMapNodesById.get(node.id);
        return (
          prevNode !== undefined &&
          !isSizeAndPositionApproximatelyEqual(prevNode, node)
        );
      });
    if (causeMapNodes.length > 0) {
      this.unifiedViewer.relayoutCauseMapNodes(causeMapNodes);
    }
  };

  private attachedTransformerNodeIds: string[] = [];

  private shamefulCaptureAttachedTransformerNodeIds = (): void => {
    this.attachedTransformerNodeIds =
      this.unifiedViewer
        .getTransformer()
        ?.getNodes()
        .map((node) => node.id()) ?? [];
  };

  private getNodesInStageById = (): Record<string, Konva.Node> => {
    return keyBy(
      this.unifiedViewer.stage.find((node: Konva.Node) => {
        return node.id() !== undefined && node.id() !== '';
      }),
      (node) => node.id()
    );
  };

  private shamefulRehydrateAttachedTransformerNodes = (): void => {
    const transformer = this.unifiedViewer.getTransformer();
    if (transformer === undefined) {
      return;
    }

    const nodesInStageById = this.getNodesInStageById();

    transformer.setNodes(
      this.attachedTransformerNodeIds
        .map((id) => nodesInStageById[id])
        .filter((n) => isNotUndefined(n) && isSelectableNode(n))
    );
    transformer.setResizingEnabled(
      transformer.getNodes().every(isResizableNode) &&
        !transformer.areAnchorsOverlapping()
    );
    transformer.updateSelectionRectangles();
    this.unifiedViewer.shamefullyRefreshAnchors(transformer.getNodes());
  };

  public getContainerById = (id: string): Container | undefined =>
    this.containerById.get(id);

  public getContainers = (): Container[] =>
    Array.from(this.containerById.values());

  public getAnnotations = (): Annotation[] => this.annotations;

  public getRectById = (
    id: string,
    options?: { shouldExcludeContainerLabel?: boolean }
  ): IRect | undefined => {
    const { shouldExcludeContainerLabel = false } = options ?? {};

    const containerNodeOrUndefined = shouldExcludeContainerLabel
      ? this.containerById.get(id)?.getContentNode()
      : this.containerById.get(id)?.getNode();
    const node = containerNodeOrUndefined ?? this.renderedNodesById.get(id);

    if (node === undefined) {
      return undefined;
    }

    const rect = node.getClientRect({});
    return {
      x: rect.x,
      y: rect.y,
      height: rect.height,
      width: rect.width,
    };
  };

  public getRectByIds = (
    ids: string[],
    options?: { shouldExcludeContainerLabel?: boolean }
  ): IRect | undefined => {
    const { shouldExcludeContainerLabel = false } = options ?? {};

    const rects = ids
      .map((id) => this.getRectById(id, { shouldExcludeContainerLabel }))
      .filter(isNotUndefined);

    if (rects.length === 0) {
      return undefined;
    }

    const minX = Math.min(...rects.map((rect) => rect.x));
    const minY = Math.min(...rects.map((rect) => rect.y));

    const maxXRect = maxBy(rects, (rect) => rect.x + rect.width);
    const maxYRect = maxBy(rects, (rect) => rect.y + rect.height);

    if (maxXRect === undefined || maxYRect === undefined) {
      throw new Error('Could not find max rect, this should not happen');
    }

    return {
      x: minX,
      y: minY,
      width: maxXRect.x + maxXRect.width - minX,
      height: maxYRect.y + maxYRect.height - minY,
    };
  };

  public getContainerOrAnnotationNodeById = (
    id: string
  ): Konva.Node | undefined =>
    this.getContainerById(id)?.getContentNode() ??
    this.getAnnotationNodeById(id);

  public getAnnotationNodeById = (
    id: string,
    shouldIncludeDirtyNodes = true
  ): Konva.Node | undefined => {
    const node = this.renderedNodesById.get(id);
    if (
      node === undefined ||
      !isAnnotationNode(node) ||
      (!shouldIncludeDirtyNodes && this.dirtyNodes.includes(node))
    ) {
      return undefined;
    }
    return node;
  };

  private getNodeFromAnnotation = (
    annotation: Annotation
  ): Konva.Node | undefined => {
    return getAnnotationSerializerByType(annotation.type).deserialize(
      annotation as any, // TODO(FUS-000): Fix typing,
      this.unifiedViewer,
      this
    );
  };

  public getAnnotationFromNode = (node: Konva.Node): Annotation => {
    return getAnnotationSerializerByType(node.attrs.annotationType).serialize(
      node,
      this
    );
  };

  private updateNode = (node: Konva.Node, annotation: Annotation): void => {
    // NOTE: An interesting edge-case here is that the connection annotations are removed
    //       when `renderRootContainer` is called since it removes all the nodes from the main layer,
    //       where the connection annotations are placed.
    const serializer = getAnnotationSerializerByType(annotation.type);
    serializer.updateNode(
      node,
      annotation as any, // TODO(FUS-000): Fix typing,
      this.unifiedViewer,
      this
    );
  };

  private shamefulRefreshRenderedAnnotationNodes = () => {
    // When we clone a container, the children are also cloned, but then we end up with
    // stale references in this.renderedNodes, so this is a workaround to refresh those
    // references by doing simple lookups. This could perhaps in the future be resolved
    // by instead just keeping the id of the node and doing lookups whenever we want to
    // access the rendered node.
    const nodesInStageById = this.getNodesInStageById();
    this.renderedNodesById = new Map(
      Array.from(this.renderedNodesById.values()).map((renderedNode) => {
        if (!isAnnotationNode(renderedNode)) {
          return [renderedNode.id(), renderedNode];
        }

        const renderedNodeReference = nodesInStageById[renderedNode.id()];

        if (renderedNodeReference === undefined) {
          throw new Error(
            'Could not find node in stage. This should not happen'
          );
        }

        return [renderedNodeReference.id(), renderedNodeReference];
      })
    );
  };

  public updateNodeVisibilityBasedOnViewport = throttle(
    () => {
      const viewportMargin =
        (STAGE_MARGIN_PERCENTAGE *
          Math.max(
            this.unifiedViewer.stage.width(),
            this.unifiedViewer.stage.height()
          )) /
        Math.max(
          this.unifiedViewer.stage.scaleX(),
          this.unifiedViewer.stage.scaleY()
        );

      Array.from(this.renderedNodesById.values()).forEach((node) => {
        node.visible(
          this.isNodeInViewport(node, {
            viewportMargin,
            shouldAllowPartialFit: true,
          })
        );
      });
    },
    UPDATE_NODE_VISIBILITY_THROTTLE_MS,
    // There's no point in firing in the leading edge since it will likely
    // be too early to have a visible impact for the user.
    { leading: false, trailing: true }
  );

  public isNodeInViewport = (
    node: Konva.Node,
    options?: {
      viewportMargin?: number;
      shouldAllowPartialFit?: boolean;
    }
  ): boolean => {
    const nodeRect = this.getNodeRectRelativeToStage(node);
    if (nodeRect === undefined) {
      return false;
    }

    const viewportRect = {
      // NOTE: The stage is offset in the opposite direction of the pan direction.
      //       For example, panning the viewer to the right, will move the stage to the left.
      //       Thus, to correct for this "negative stage offset", we negate the stage's position.
      x: -this.unifiedViewer.stage.x() / this.unifiedViewer.stage.scaleX(),
      y: -this.unifiedViewer.stage.y() / this.unifiedViewer.stage.scaleY(),
      width:
        this.unifiedViewer.stage.width() / this.unifiedViewer.stage.scaleX(),
      height:
        this.unifiedViewer.stage.height() / this.unifiedViewer.stage.scaleY(),
    };
    const viewportRectWithMargin =
      options?.viewportMargin !== undefined
        ? addMarginToRect(viewportRect, options.viewportMargin)
        : viewportRect;

    if (options?.shouldAllowPartialFit === true) {
      return areRectsOverlapping(nodeRect, viewportRectWithMargin);
    }
    return isRectInsideRect(nodeRect, viewportRectWithMargin);
  };

  public getNodeById = (id: string): Konva.Node | undefined => {
    return (
      this.getContainerById(id)?.getNode() ?? this.getAnnotationNodeById(id)
    );
  };
}

export default UnifiedViewerRenderer;
